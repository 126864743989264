import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdministrationRoutingModule, DashboardRoutingModule, UserProfileRoutingModule } from "@dtm-frontend/dss-admin-lib";
import { FlightZoneConsultationRoutingModule } from "@dtm-frontend/dss-admin-lib/flight-zone-consultation";
import { FlightZoneManagementRoutingModule } from "@dtm-frontend/dss-admin-lib/flight-zone-management";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        FlightZoneManagementRoutingModule,
        FlightZoneConsultationRoutingModule,
        DashboardRoutingModule,
        AdministrationRoutingModule,
        UserProfileRoutingModule,
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: ["dss-admin"],
        },
    ],
})
export class AppRoutingModule {}
