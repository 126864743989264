<ul *ngIf="restrictionExclusions$ | ngrxPush; let restrictionExclusions">
    <li *ngIf="restrictionExclusions.isManagedByGovernment">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.managedByGovernmentLabel" | transloco }}
    </li>
    <li *ngIf="restrictionExclusions.agreedExclusion">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{
            "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.agreedExclusionLabel"
                | transloco
                    : {
                          email: restrictionExclusions.agreedExclusion.email,
                          phoneNumber: restrictionExclusions.agreedExclusion.phoneNumber | formatPhoneNumber
                      }
        }}
    </li>
    <li *ngIf="restrictionExclusions.areOwnedFlights">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.ownedFlightsLabel" | transloco }}
    </li>
    <li *ngIf="restrictionExclusions.uavMtomExclusion">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{
            "dssSharedLibFlightZone.applicationSummary.uavMtomExclusionLabel"
                | transloco
                    : {
                          from: restrictionExclusions.uavMtomExclusion.from,
                          to: restrictionExclusions.uavMtomExclusion.to
                      }
        }}
    </li>
    <li *ngIf="restrictionExclusions.isWithoutEngine">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.withoutEngineLabel" | transloco }}
    </li>
    <li *ngIf="restrictionExclusions.isWithInstrumentalProcedures">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.withInstrumentalProceduresLabel" | transloco }}
    </li>
    <li *ngIf="restrictionExclusions.isSpecialStatusFlights">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.specialStatusFlightsLabel" | transloco }}
    </li>
    <li *ngIf="restrictionExclusions.courses.length">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.coursesLabel" | transloco }}:
        {{ restrictionExclusions.courses | invoke : formatCoursesToDisplay }}
    </li>
    <li *ngIf="restrictionExclusions.otherExclusion">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.applicationSummary.otherExclusionLabel" | transloco }}:
        {{ restrictionExclusions.otherExclusion }}
    </li>
</ul>
