import { Injectable } from "@angular/core";
import { BaseNotificationEventsService, DssNotification } from "@dtm-frontend/dss-shared-lib";
import { NotificationDisplay } from "@dtm-frontend/shared/notifications";
import { AnspApplicationNotificationEventsService } from "./ansp-application-notification-events.service";
import { AnspAssignmentNotificationEventsService } from "./ansp-assignment-notification-events.service";
import { AnspCommentNotificationEventsService } from "./ansp-comment-notification-events.service";
import { AnspConsultationNotificationEventsService } from "./ansp-consultation-notification-events.service";
import { AnspNotamNotificationEventsService } from "./ansp-notam-notification-events.service";
import { AnspRestrictionModificationNotificationEventsService } from "./ansp-restriction-modification-notification-events.service";
import { AnspTeamNotificationEventsService } from "./ansp-team-notification.service";

@Injectable({
    providedIn: "root",
})
export class AnspNotificationEventsService implements BaseNotificationEventsService {
    constructor(
        private readonly anspApplicationNotificationEventsService: AnspApplicationNotificationEventsService,
        private readonly anspNotamNotificationEventsService: AnspNotamNotificationEventsService,
        private readonly anspTeamNotificationEventsService: AnspTeamNotificationEventsService,
        private readonly anspCommentNotificationEventsService: AnspCommentNotificationEventsService,
        private readonly anspAssignmentNotificationEventsService: AnspAssignmentNotificationEventsService,
        private readonly anspConsultationNotificationEventsService: AnspConsultationNotificationEventsService,
        private readonly anspRestrictionModificationNotificationEventsService: AnspRestrictionModificationNotificationEventsService
    ) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return [
            ...this.anspApplicationNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspNotamNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspTeamNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspCommentNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspAssignmentNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspConsultationNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.anspRestrictionModificationNotificationEventsService.getEventsList(notificationsToFormat),
        ];
    }
}
