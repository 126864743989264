import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged } from "rxjs";
import { map } from "rxjs/operators";
import { UserDetails, UsersListTableColumns } from "../../models/administration.models";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-users-list-container",
    templateUrl: "./users-list-container.component.html",
    styleUrls: ["./users-list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListContainerComponent implements OnInit {
    protected readonly displayedColumns: UsersListTableColumns = ["firstName", "lastName", "email", "phoneNumber", "userType", "actions"];

    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly usersList$ = this.store.select(AdministrationState.usersList);
    protected readonly hasListDataRetrievalError$ = this.store.select(AdministrationState.listDataRetrievalError).pipe(map(Boolean));

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService
    ) {}

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };
        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new AdministrationActions.GetUsersList(this.route.snapshot.queryParams));
    }

    protected deleteUser(userDetails: UserDetails): void {
        this.store
            .dispatch(new AdministrationActions.DeleteUser(userDetails.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(AdministrationState.userDeleteError)) {
                    this.toastrService.error(this.transloco.translate("dssAdminLibAdministration.usersList.deleteUserErrorMessage"));

                    return;
                }

                this.refreshList();
                this.toastrService.success(this.transloco.translate("dssAdminLibAdministration.usersList.deleteUserSuccessMessage"));
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new AdministrationActions.GetUsersList(filterParams));
        });
    }
}
