import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { FlightZoneApplicationReviewStatus } from "@dtm-frontend/dss-shared-lib";
import { Subject } from "rxjs";

const MAX_COMMENT_LENGTH = 500;

@Component({
    selector: "dss-admin-lib-consultation-review-confirm-dialog",
    templateUrl: "./consultation-review-confirm-dialog.component.html",
    styleUrls: ["./consultation-review-confirm-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationReviewConfirmDialogComponent {
    protected readonly FlightZoneApplicationReviewStatus = FlightZoneApplicationReviewStatus;
    protected readonly MAX_COMMENT_LENGTH = MAX_COMMENT_LENGTH;
    protected readonly reviewCommentControl = new FormControl<string>("", {
        validators: Validators.maxLength(MAX_COMMENT_LENGTH),
        nonNullable: true,
    });

    private readonly confirmReviewSubject = new Subject<string>();
    public readonly confirmReview$ = this.confirmReviewSubject.asObservable();

    constructor(@Inject(MAT_DIALOG_DATA) public data: { reviewStatus: FlightZoneApplicationReviewStatus }) {}

    public confirm(): void {
        if (this.reviewCommentControl.invalid) {
            return;
        }

        this.confirmReviewSubject.next(this.reviewCommentControl.value);
    }
}
