<div class="grid">
    <ng-container *ngrxLet="{ acceptedApplicationsList: acceptedApplicationsList$ } as vm">
        <dss-shared-lib-applications-list-filters
            *ngrxLet="listFiltersCapabilities$ as listFiltersCapabilities"
            [initialFilters]="initialFilters"
            [flightZonePurposes]="listFiltersCapabilities?.flightZonePurposes"
            [institutions]="institutionsFilterOptions$ | ngrxPush"
            (filtersChange)="applyFilters($event)"
            (institutionSearchTextChange)="findInstitutionByText($event)"
            (filtersListsClear)="clearFilterLists()"
        >
            <ng-container totalCount>
                {{
                    "dssAdminLibFlightZoneManagement.applicationListShared.totalApplicationsCountLabel"
                        | transloco : { totalCount: vm.acceptedApplicationsList?.page?.totalElements ?? 0 }
                }}
            </ng-container>
        </dss-shared-lib-applications-list-filters>

        <dss-admin-lib-applications-management-table
            [actionButtons]="actionButtons"
            [displayedColumns]="displayedColumns"
            [flightZoneApplications]="vm.acceptedApplicationsList?.content"
            [page]="vm.acceptedApplicationsList?.page"
            [isProcessing]="isProcessing$ | ngrxPush"
            [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
            [basePreviewRoutePath]="basePreviewRoutePath"
            (pageChange)="changePage($event)"
            (listRefresh)="refreshList()"
            (kmlDownload)="downloadKml($event)"
        ></dss-admin-lib-applications-management-table>
    </ng-container>
</div>
