<button type="button" class="chat-switcher" (click)="toggleChatSidebar()">
    <dtm-ui-icon name="chat"></dtm-ui-icon>
</button>
<div class="chat-overflow-container" *ngrxLet="isReadOnly$ as isReadOnly">
    <div class="drawer">
        <div class="chat-container" [class.full-height]="isReadOnly">
            <h6 class="chat-heading">{{ "dssSharedLibFlightZone.applicationChangesChat.chatHeading" | transloco }}</h6>

            <div
                *ngFor="let message of messages$ | ngrxPush"
                class="message"
                [ngClass]="{ 'own-message': (userId$ | ngrxPush) === message.author.id }"
                #messageElement
            >
                <div class="message-date">{{ message.createdAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}</div>
                <div class="message-text">{{ message.content }}</div>
                <div class="message-author">{{ message.author.firstName }} {{ message.author.lastName }}</div>
            </div>
        </div>

        <div class="chat-footer" *ngIf="!isReadOnly">
            <dtm-ui-textarea-field [maxLength]="MESSAGE_MAX_LENGTH">
                <textarea
                    class="message-input"
                    matInput
                    [rows]="8"
                    [maxLength]="MESSAGE_MAX_LENGTH"
                    [placeholder]="'dssSharedLibFlightZone.applicationChangesChat.commentPlaceholder' | transloco"
                    [formControl]="commentFormControl"
                ></textarea>
            </dtm-ui-textarea-field>

            <button class="button-primary submit-button" [disabled]="isProcessing$ | ngrxPush" type="button" (click)="submitMessage()">
                {{ "dssSharedLibFlightZone.applicationChangesChat.sendMessageButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</div>
