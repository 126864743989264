<table *ngrxLet="members$ as members" mat-table [dataSource]="members" class="dtm-table">
    <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
            {{ "dssAdminLibAdministration.membersList.firstNameHeader" | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
            {{ "dssAdminLibAdministration.membersList.lastNameHeader" | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
            {{ "dssAdminLibAdministration.membersList.emailHeader" | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="isSupervisor">
        <th mat-header-cell *matHeaderCellDef>
            {{ "dssAdminLibAdministration.membersList.isSupervisorHeader" | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ "dssAdminLibAdministration.membersList.isSupervisorValue" | transloco : { value: element.isSupervisor } }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
            {{ "dssAdminLibAdministration.membersList.actionsHeader" | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
            <button type="button" class="button-icon" (click)="memberRemove.emit(element)">
                <dtm-ui-icon name="close-circle"></dtm-ui-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <dtm-ui-no-results></dtm-ui-no-results>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" [class.hide-footer]="members.length" class="no-results-row"></tr>
</table>
