<form [formGroup]="institutionDetailForm" dtmUiInvalidFormScrollable (ngSubmit)="saveForm()">
    <mat-card>
        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.institutionDetail.nameLabel" | transloco }}</label>
            <input matInput type="text" formControlName="name" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: institutionDetailForm.controls.name }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.institutionDetail.unitNameLabel" | transloco }}</label>
            <input matInput type="text" formControlName="unitName" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: institutionDetailForm.controls.unitName }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.institutionDetail.emailLabel" | transloco }}</label>
            <input matInput type="email" formControlName="email" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: institutionDetailForm.controls.email }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-phone-number-field formControlName="phoneNumber">
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: institutionDetailForm.controls.phoneNumber }"
                ></ng-container>
            </div>
        </dtm-ui-phone-number-field>

        <dtm-ui-country-select-field formControlName="country">
            <label>
                {{ "dssAdminLibAdministration.institutionDetail.countryLabel" | transloco }}
            </label>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: institutionDetailForm.controls.country }"
                ></ng-container>
            </div>
        </dtm-ui-country-select-field>

        <dtm-ui-address-form
            formControlName="address"
            dtmUiMarkValueAccessorControlsAsTouched
            [isSystemDefaultCountrySelected]="institutionDetailForm.value.country === DEFAULT_COUNTRY_CODE"
        >
        </dtm-ui-address-form>

        <ng-container formArrayName="purposes">
            <div
                class="institution-purpose"
                *ngFor="let purposeControl of institutionDetailForm.controls.purposes.controls; index as purposeIndex"
                [formGroupName]="purposeIndex"
            >
                <dtm-ui-checkbox-field
                    formControlName="isSelected"
                    (change)="changeStateSecurityRestrictionDisabledState($event, purposeIndex)"
                >
                    {{
                        "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel"
                            | transloco : { value: purposeControl.controls.purpose.value }
                    }}
                </dtm-ui-checkbox-field>

                <dtm-ui-checkbox-field formControlName="hasStateSecurityRestriction">
                    {{ "dssAdminLibAdministration.institutionDetail.areStateSecurityRestrictionsEnabledLabel" | transloco }}
                </dtm-ui-checkbox-field>
            </div>
        </ng-container>

        <div class="buttons-container">
            <button type="button" class="button-secondary" (click)="cancel.emit()">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dssAdminLibAdministration.institutionDetail.cancelButtonLabel" | transloco }}
            </button>
            <button type="submit" class="button-primary">
                {{ "dssAdminLibAdministration.institutionDetail.saveButtonLabel" | transloco }}
            </button>
        </div>
    </mat-card>
</form>

<ng-template #formErrorsTemplate let-formControl="formControl">
    <ng-container *dtmUiFieldHasError="formControl; name: ['required', 'pattern']">
        {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
    </ng-container>

    <ng-container *dtmUiFieldHasError="formControl; name: 'email'">
        {{ "dssAdminLibAdministration.validationErrors.invalidEmailError" | transloco }}
    </ng-container>

    <ng-container *dtmUiFieldHasError="formControl; name: 'invalidNumber'">
        {{ "dssAdminLibAdministration.validationErrors.invalidPhoneNumberError" | transloco }}
    </ng-container>
</ng-template>
