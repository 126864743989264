import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    FlightZoneApplicationPurpose,
    FlightZoneApplicationsForConsultationList,
    FlightZoneListFilters,
} from "@dtm-frontend/dss-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Observable, distinctUntilChanged } from "rxjs";
import { map } from "rxjs/operators";
import { FlightZoneConsultationStateModel } from "../../../models/flight-zone-consultation.models";
import { FlightZoneConsultationActions } from "../../../state/flight-zone-consultation.actions";
import { FlightZoneConsultationState } from "../../../state/flight-zone-consultation.state";

interface TabLink {
    path: string;
    label: string;
    dispatchAction: new (filterParams: Params) => unknown;
    totalCount$: Observable<number>;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-applications-for-consultation-tabs-container",
    templateUrl: "./applications-for-consultation-tabs-container.component.html",
    styleUrls: ["./applications-for-consultation-tabs-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationsForConsultationTabsContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly flightZonePurposes: FlightZoneApplicationPurpose[] = Object.values(FlightZoneApplicationPurpose);
    protected readonly tabLinks: TabLink[] = [
        {
            path: "in-progress",
            label: "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.inProgressTabLabel",
            dispatchAction: FlightZoneConsultationActions.GetConsultationsInProgressList,
            totalCount$: this.getTotalCountObservable(FlightZoneConsultationState.consultationsInProgressList),
        },
        {
            path: "completed",
            label: "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.completedTabLabel",
            dispatchAction: FlightZoneConsultationActions.GetConsultationsCompletedList,
            totalCount$: this.getTotalCountObservable(FlightZoneConsultationState.consultationsCompletedList),
        },
    ];

    constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly store: Store) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route.firstChild,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            const listsFetchActions = this.tabLinks.map(({ dispatchAction }) => new dispatchAction(filterParams));

            this.store.dispatch(listsFetchActions);
        });
    }

    private getTotalCountObservable(
        selector: (state: FlightZoneConsultationStateModel) => FlightZoneApplicationsForConsultationList | undefined
    ): Observable<number> {
        return this.store.select(selector).pipe(map((listData) => listData?.page.totalElements ?? 0));
    }
}
