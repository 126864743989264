import { InjectionToken } from "@angular/core";

export interface UserProfileEndpoints {
    userDataManagement: string;
    requestUserAccountDelete: string;
    getNotifications: string;
    updateNotification: string;
}

export const USER_PROFILE_ENDPOINTS = new InjectionToken<UserProfileEndpoints>("User profile endpoints");
