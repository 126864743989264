import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import {
    FlightZoneApiService,
    FlightZoneCapabilitiesState,
    FlightZoneGeometryTranslationProvider,
    FlightZoneRestrictionState,
    FlightZoneSharedModule,
    ListStatusTranslationService,
} from "@dtm-frontend/dss-shared-lib";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, TranslationHelperService, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { AcceptedApplicationsListContainerComponent } from "./components/accepted-applications-list-container/accepted-applications-list-container.component";
import { AdditionalReceiversComponent } from "./components/application-management-preview/additional-receivers/additional-receivers.component";
import { AirspaceClassificationComponent } from "./components/application-management-preview/airspace-classification/airspace-classification.component";
import { AnspCaseNumberComponent } from "./components/application-management-preview/ansp-case-number/ansp-case-number.component";
import { AnspSupervisorAcceptButtonComponent } from "./components/application-management-preview/ansp-supervisor-accept-button/ansp-supervisor-accept-button.component";
import { AnspSupervisorRejectButtonComponent } from "./components/application-management-preview/ansp-supervisor-reject-button/ansp-supervisor-reject-button.component";
import { ApplicationManagementPreviewComponent } from "./components/application-management-preview/application-management-preview.component";
import { DetailedDurationComponent } from "./components/application-management-preview/detailed-duration/detailed-duration.component";
import { EditSuggestedRestrictionTypeDialogComponent } from "./components/application-management-preview/edit-suggested-restriction-type-dialog/edit-suggested-restriction-type-dialog.component";
import { NotamLocationFormComponent } from "./components/application-management-preview/notam-location-form/notam-location-form.component";
import { ReviewStatusBadgeComponent } from "./components/application-management-preview/review-status-badge/review-status-badge.component";
import { SetCustomDesignatorDialogComponent } from "./components/application-management-preview/set-custom-designator-dialog/set-custom-designator-dialog.component";
import { TeamChangeDialogComponent } from "./components/application-management-preview/team-change-dialog/team-change-dialog.component";
import { ApplicationManagementStatusBadgeComponent } from "./components/application-management-status-badge/application-management-status-badge.component";
import { ApplicationsAssignedToUserListContainerComponent } from "./components/applications-assigned-to-user-list-container/applications-assigned-to-user-list-container.component";
import { ApplicationsForApprovalListContainerComponent } from "./components/applications-for-approval-list-container/applications-for-approval-list-container.component";
import { ApplicationsManagementTableComponent } from "./components/applications-management-table/applications-management-table.component";
import { AssignedApplicationsListContainerComponent } from "./components/assigned-applications-list-container/assigned-applications-list-container.component";
import { AssigneeConfirmDialogComponent } from "./components/assignee-confirm-dialog/assignee-confirm-dialog.component";
import { NotamContentComponent } from "./components/notam-edit/notam-content/notam-content.component";
import { NotamEditComponent } from "./components/notam-edit/notam-edit.component";
import { NotamNumberConfirmDialogComponent } from "./components/notam-edit/notam-number-confirm-dialog/notam-number-confirm-dialog.component";
import { NotamListContainerComponent } from "./components/notam-list/container/notam-list-container.component";
import { NotamListComponent } from "./components/notam-list/list/notam-list.component";
import { RejectedApplicationsListContainerComponent } from "./components/rejected-applications-list-container/rejected-applications-list-container.component";
import { RestrictionPreviewComponent } from "./components/restriction-preview/restriction-preview.component";
import { RestrictionsListContainerComponent } from "./components/restrictions-list-container/restrictions-list-container.component";
import { RestrictionsTableComponent } from "./components/restrictions-table/restrictions-table.component";
import { SubmittedListsContainerComponent } from "./components/submitted-lists-container/submitted-lists-container.component";
import { UnassignedApplicationsListContainerComponent } from "./components/unassigned-applications-list-container/unassigned-applications-list-container.component";
import { ApplicationManagementStatusTranslationService } from "./services/application-management-status-translation.service";
import { FlightZoneManagementGeometryTranslatorService } from "./services/flight-zone-management-geometry-translator.service";
import { FlightZoneApplicationState } from "./state/flight-zone-application.state";
import { FlightZoneListsState } from "./state/flight-zone-lists.state";
import { FlightZoneManagementState } from "./state/flight-zone-management.state";

@NgModule({
    declarations: [
        UnassignedApplicationsListContainerComponent,
        AssigneeConfirmDialogComponent,
        AssignedApplicationsListContainerComponent,
        ApplicationManagementPreviewComponent,
        RejectedApplicationsListContainerComponent,
        AcceptedApplicationsListContainerComponent,
        NotamListContainerComponent,
        NotamListComponent,
        NotamNumberConfirmDialogComponent,
        NotamEditComponent,
        ApplicationsForApprovalListContainerComponent,
        ApplicationsAssignedToUserListContainerComponent,
        ReviewStatusBadgeComponent,
        AnspSupervisorAcceptButtonComponent,
        AnspSupervisorRejectButtonComponent,
        SubmittedListsContainerComponent,
        ApplicationsManagementTableComponent,
        ApplicationManagementStatusBadgeComponent,
        RestrictionsListContainerComponent,
        NotamLocationFormComponent,
        RestrictionsTableComponent,
        RestrictionPreviewComponent,
        AnspCaseNumberComponent,
        EditSuggestedRestrictionTypeDialogComponent,
        TeamChangeDialogComponent,
        NotamContentComponent,
        AdditionalReceiversComponent,
        DetailedDurationComponent,
        AirspaceClassificationComponent,
        SetCustomDesignatorDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatTableModule,
        MatCardModule,
        MatMenuModule,
        MatSelectModule,
        MatDialogModule,
        MatInputModule,
        MatTooltipModule,
        MatTabsModule,
        MatDatepickerModule,
        NgxMaskModule,
        NgxsModule.forFeature([
            FlightZoneManagementState,
            FlightZoneApplicationState,
            FlightZoneRestrictionState,
            FlightZoneListsState,
            FlightZoneCapabilitiesState,
        ]),
        LetModule,
        PushModule,
        RouterModule,
        SharedToastModule.forRoot(),
        SharedI18nModule,
        CesiumMapModule,
        SharedUiModule,
        FlightZoneSharedModule,
        ClipboardModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssAdminLibFlightZoneManagement",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: FlightZoneApiService,
            useValue: undefined,
        },
        {
            provide: FlightZoneGeometryTranslationProvider,
            useClass: FlightZoneManagementGeometryTranslatorService,
        },
        {
            provide: ListStatusTranslationService,
            useClass: ApplicationManagementStatusTranslationService,
        },
        TranslationHelperService,
    ],
    exports: [
        UnassignedApplicationsListContainerComponent,
        AssigneeConfirmDialogComponent,
        AssignedApplicationsListContainerComponent,
        ApplicationManagementPreviewComponent,
        RejectedApplicationsListContainerComponent,
        ApplicationsManagementTableComponent,
        NotamListComponent,
        ReviewStatusBadgeComponent,
        AnspSupervisorAcceptButtonComponent,
        AnspSupervisorRejectButtonComponent,
        ApplicationManagementStatusBadgeComponent,
        NotamLocationFormComponent,
        RestrictionsTableComponent,
        AnspCaseNumberComponent,
        AdditionalReceiversComponent,
        DetailedDurationComponent,
        AirspaceClassificationComponent,
    ],
})
export class FlightZoneManagementModule {
    public static forRoot(): ModuleWithProviders<FlightZoneManagementModule> {
        return {
            ngModule: FlightZoneManagementModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useClass: FlightZoneApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<FlightZoneManagementModule> {
        return {
            ngModule: FlightZoneManagementModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useValue: null,
                },
            ],
        };
    }
}
