<ng-container *ngrxLet="isCollapsed$; let isCollapsed">
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssAdmin.menu.dashboardMenuItemLabel' | transloco,
                    icon: 'dashboard',
                    link: '/dashboard'
                }"
            ></ng-template>
        </li>
    </ul>

    <ng-container [ngSwitch]="userMainRole$ | ngrxPush">
        <ng-container *ngSwitchCase="DssUserRoles.ManageNotam" [ngTemplateOutlet]="notamOfficeWorkerLinksTemplate"></ng-container>

        <ng-container *ngSwitchCase="DssUserRoles.Administrator" [ngTemplateOutlet]="administratorLinksTemplate"></ng-container>

        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultLinksTemplate"></ng-container>
    </ng-container>

    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link">
        <a
            [routerLink]="link"
            routerLinkActive="active-route"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>

    <ng-template #defaultLinksTemplate>
        <h4
            class="menu-section-header"
            [matTooltip]="'dssAdmin.menu.applicationsHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "dssAdmin.menu.applicationsHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.applicationsSubmittedListMenuItemLabel' | transloco,
                        icon: 'file-text',
                        link: '/flight-zone/submitted-list'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.modificationsSubmittedListMenuItemLabel' | transloco,
                        icon: 'draft',
                        link: '/modifications/submitted-list'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.applicationsForConsultationsListMenuItemLabel' | transloco,
                        icon: 'user-follow',
                        link: '/consultations'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.acceptedApplicationsListMenuItemLabel' | transloco,
                        icon: 'file-mark',
                        link: '/flight-zone/accepted-applications-list'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.acceptedModificationsListMenuItemLabel' | transloco,
                        icon: 'file-mark',
                        link: '/modifications/accepted-modifications-list'
                    }"
                ></ng-template>
            </li>
        </ul>
        <h4
            class="menu-section-header"
            [matTooltip]="'dssAdmin.menu.restrictionsHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "dssAdmin.menu.restrictionsHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.publishedRestrictionsListMenuItemLabel' | transloco,
                        icon: 'treasure-map',
                        link: '/flight-zone/published-restrictions-list'
                    }"
                ></ng-template>
            </li>
        </ul>

        <h4
            class="menu-section-header"
            [matTooltip]="'dssAdmin.menu.archivesHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "dssAdmin.menu.archivesHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.rejectedApplicationsListMenuItemLabel' | transloco,
                        icon: 'close-circle',
                        link: '/flight-zone/rejected-applications-list'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.endedRestrictionsListMenuItemLabel' | transloco,
                        icon: 'close-circle',
                        link: '/flight-zone/ended-restrictions-list'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-template>

    <ng-template #notamOfficeWorkerLinksTemplate>
        <h4
            class="menu-section-header"
            [matTooltip]="'dssAdmin.menu.applicationsHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "dssAdmin.menu.applicationsHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.applicationsWaitingForNotam' | transloco,
                        icon: 'file-list',
                        link: '/flight-zone/applications-waiting-for-notam'
                    }"
                ></ng-template>
            </li>

            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.applicationsWithNotam' | transloco,
                        icon: 'file-list',
                        link: '/flight-zone/applications-with-notam'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-template>

    <ng-template #administratorLinksTemplate>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.usersManagement' | transloco,
                        icon: 'group',
                        link: '/admin/users'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.institutionsManagement' | transloco,
                        icon: 'school',
                        link: '/admin/institutions'
                    }"
                ></ng-template>
            </li>
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssAdmin.menu.anspTeamsManagement' | transloco,
                        icon: 'parent',
                        link: '/admin/ansp-teams'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-template>
</ng-container>
