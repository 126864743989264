<div class="grid">
    <dss-shared-lib-applications-list-filters
        [flightZonePurposes]="flightZonePurposes"
        [initialFilters]="initialFilters"
        (filtersChange)="applyFilters($event)"
    ></dss-shared-lib-applications-list-filters>

    <div class="card-box">
        <h4 class="tabs-header">
            <dtm-ui-icon name="treasure-map"></dtm-ui-icon>
            {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.tabsHeader" | transloco }}
        </h4>
        <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="dtm-tabs" color="accent">
            <a
                mat-tab-link
                *ngFor="let tab of tabLinks"
                [routerLink]="tab.path"
                queryParamsHandling="preserve"
                [state]="{ skipLoading: true }"
                routerLinkActive
                #routerLinkActive="routerLinkActive"
                [active]="routerLinkActive.isActive"
            >
                {{ tab.label | transloco }} ({{ tab.totalCount$ | ngrxPush }})
            </a>
        </nav>

        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>
    </div>
</div>
