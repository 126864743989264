import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DssUserRoles, FlightZoneGeometryTranslationProvider } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ConsultationsCompletedContainerComponent } from "./components/applications-for-consultation-list/consultations-completed-container/consultations-completed-container.component";
import { ConsultationsInProgressContainerComponent } from "./components/applications-for-consultation-list/consultations-in-progress-container/consultations-in-progress-container.component";
import { ApplicationsForConsultationTabsContainerComponent } from "./components/applications-for-consultation-list/tabs-container/applications-for-consultation-tabs-container.component";
import { ConsultationPreviewComponent } from "./components/consultation-preview/consultation-preview.component";
import { FlightZoneConsultationGeometryTranslatorService } from "./services/flight-zone-consultation-geometry-translator.service";
import {
    FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME,
    FlightZoneConsultationDataResolver,
} from "./services/flight-zone-consultation.resolvers";

const routes: Routes = [
    {
        path: `consultation/:${FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME}`,
        component: ConsultationPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: FlightZoneConsultationDataResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneConsultation.routeTitles.consultation",
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
        providers: [
            {
                provide: FlightZoneGeometryTranslationProvider,
                useClass: FlightZoneConsultationGeometryTranslatorService,
            },
        ],
    },
    {
        path: "consultations",
        component: ApplicationsForConsultationTabsContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneConsultation.routeTitles.applicationsForConsultationList",
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
        children: [
            {
                path: "in-progress",
                component: ConsultationsInProgressContainerComponent,
            },
            {
                path: "completed",
                component: ConsultationsCompletedContainerComponent,
            },
            {
                path: "",
                redirectTo: "in-progress",
                pathMatch: "full",
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [FlightZoneConsultationDataResolver],
})
export class FlightZoneConsultationRoutingModule {}
