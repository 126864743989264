import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FlightZoneListFilters } from "@dtm-frontend/dss-shared-lib";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { distinctUntilChanged } from "rxjs";
import { NotamListRouteData, NotamsTableColumns } from "../../../models/flight-zone-management.models";
import { FlightZoneListsState } from "../../../state/flight-zone-lists.state";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-notam-list-container",
    templateUrl: "./notam-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotamListContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly isProcessing$ = this.store.select(FlightZoneListsState.isProcessing);
    protected readonly notamList$ = this.store.select(this.routeData.selector);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly listFiltersCapabilities$ = this.store.select(FlightZoneListsState.listFiltersCapabilities);
    protected readonly displayedColumns: NotamsTableColumns = this.routeData.displayedColumns;

    private get routeData(): NotamListRouteData {
        return this.route.snapshot.data as NotamListRouteData;
    }

    constructor(private readonly store: Store, private readonly route: ActivatedRoute, private readonly router: Router) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new this.routeData.action(this.route.snapshot.queryParams));
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new this.routeData.action(filterParams));
        });
    }
}
