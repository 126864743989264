import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UserData } from "../../models/user-profile.models";

interface UserProfilePersonalDataComponentState {
    firstName: string;
    lastName: string;
}

interface PersonalDataForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
}

const MAX_NAME_LENGTH = 100;

@Component({
    selector: "dss-shared-lib-user-profile-personal-data[firstName][lastName]",
    templateUrl: "./user-profile-personal-data.component.html",
    styleUrls: ["./user-profile-personal-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserProfilePersonalDataComponent {
    protected readonly personalDataForm = new FormGroup<PersonalDataForm>({
        firstName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        lastName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
    });

    protected readonly firstName$ = this.localStore.selectByKey("firstName");
    protected readonly lastName$ = this.localStore.selectByKey("lastName");

    @Input() public set firstName(value: string | undefined) {
        this.localStore.patchState({ firstName: value ?? "" });
    }

    @Input() public set lastName(value: string | undefined) {
        this.localStore.patchState({ lastName: value ?? "" });
    }

    @Output() public readonly userDataUpdate = new EventEmitter<Partial<UserData>>();

    constructor(private readonly localStore: LocalComponentStore<UserProfilePersonalDataComponentState>) {
        this.localStore.setState({
            firstName: "",
            lastName: "",
        });
    }

    protected changeEditMode(isEditMode: boolean): void {
        if (isEditMode) {
            this.personalDataForm.setValue({
                firstName: this.localStore.selectSnapshotByKey("firstName"),
                lastName: this.localStore.selectSnapshotByKey("lastName"),
            });
        } else {
            this.personalDataForm.reset();
        }
    }

    protected save(): void {
        this.personalDataForm.markAllAsTouched();

        if (this.personalDataForm.valid) {
            this.userDataUpdate.emit(this.personalDataForm.value as Partial<UserData>);
        } else {
            this.personalDataForm.updateValueAndValidity();
        }
    }
}
