import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Notification, NotificationDisplay } from "@dtm-frontend/shared/notifications";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { BaseNotificationEventsService } from "../../services/notification-events/base-notification-events.service";

interface NotificationsComponentState {
    notificationList: NotificationDisplay[];
}

@Component({
    selector: "dss-shared-lib-notifications[notificationsList]",
    templateUrl: "./notifications.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NotificationsComponent {
    @Input() public set notificationsList(value: Notification[] | undefined) {
        if (value) {
            this.localStore.patchState({ notificationList: this.formatNotificationList(value) });
        } else {
            this.localStore.patchState({ notificationList: [] });
        }
    }

    protected readonly notificationsList$ = this.localStore.selectByKey("notificationList");

    constructor(
        private readonly localStore: LocalComponentStore<NotificationsComponentState>,
        private readonly notificationEventsService: BaseNotificationEventsService
    ) {
        localStore.setState({ notificationList: [] });
    }

    private formatNotificationList(notificationsToFormat: Notification[]): NotificationDisplay[] {
        return this.notificationEventsService
            .getEventsList(notificationsToFormat)
            .sort((left, right) => new Date(right.date).getTime() - new Date(left.date).getTime());
    }
}
