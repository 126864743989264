import { Injectable } from "@angular/core";
import { NotamPreviewComponent } from "@dtm-frontend/dss-shared-lib";
import { DialogService } from "@dtm-frontend/shared/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { switchMap, take } from "rxjs";
import { FlightZoneManagementActions } from "../state/flight-zone-management.actions";
import { FlightZoneManagementState } from "../state/flight-zone-management.state";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class NotamModalService {
    constructor(private readonly store: Store, private readonly dialogService: DialogService) {}

    public openNotamPreview(flightZoneId: string): void {
        this.store
            .dispatch(new FlightZoneManagementActions.GetNotamData(flightZoneId))
            .pipe(
                switchMap(() => this.store.select(FlightZoneManagementState.notamData)),
                take(1),
                untilDestroyed(this)
            )
            .subscribe((notamData) => {
                this.dialogService.open(NotamPreviewComponent, { data: notamData?.nativeNotam });
            });
    }
}
