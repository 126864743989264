import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";

interface TextEditorSectionComponentState {
    text: string | undefined;
    isReadonly: boolean;
    isProcessing: boolean;
}

const MAX_TEXT_PREVIEW_LENGTH = 300;
const TEXT_WITHOUT_HTML_REGEXP = /(<([^>]+)>)/gi;

@Component({
    selector: "dss-shared-lib-text-editor-section[text]",
    templateUrl: "text-editor-section.component.html",
    styleUrls: ["text-editor-section.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TextEditorSectionComponent {
    @Input() public set text(value: string | undefined) {
        this.localStore.patchState({ text: value });
    }

    @Input() public set isReadonly(value: BooleanInput) {
        this.localStore.patchState({ isReadonly: coerceBooleanProperty(value) });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() protected readonly dialogOpen = new EventEmitter<string>();

    protected readonly text$ = this.localStore.selectByKey("text");
    protected readonly isReadonly$ = this.localStore.selectByKey("isReadonly");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<TextEditorSectionComponentState>) {
        this.localStore.setState({
            text: undefined,
            isReadonly: false,
            isProcessing: false,
        });
    }

    protected openDialog(): void {
        this.dialogOpen.emit(this.localStore.selectSnapshotByKey("text") ?? "");
    }

    protected formatTextForPreview(htmlString: string): string {
        const textWithoutHMTL = htmlString.replace(TEXT_WITHOUT_HTML_REGEXP, " ");

        return StringUtils.ellipsifyText(textWithoutHMTL, MAX_TEXT_PREVIEW_LENGTH);
    }
}
