<ng-container
    *ngrxLet="{
        isFormExpanded: isFormExpanded$,
        airspaceClassification: airspaceClassification$,
        isProcessing: isProcessing$,
        isReadOnly: isReadOnly$
    } as vm"
>
    <dtm-ui-presentation-tile-with-action
        [titleTile]="'dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.header' | transloco"
        [class.read-only]="vm.isReadOnly"
    >
        <button
            *ngIf="!vm.isReadOnly && !vm.isFormExpanded"
            actionButtonSlot
            class="button-secondary"
            type="button"
            (click)="changeExpansionState(true)"
        >
            <ng-container *ngIf="vm.airspaceClassification; else emptyClassificationTemplate">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.editButtonLabel" | transloco }}
            </ng-container>

            <ng-template #emptyClassificationTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.addButtonLabel" | transloco }}
            </ng-template>
        </button>

        <button
            *ngIf="!vm.isReadOnly && vm.isFormExpanded"
            actionButtonSlot
            class="button-icon"
            type="button"
            (click)="changeExpansionState(false)"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <form *ngIf="!vm.isReadOnly && vm.isFormExpanded" [formGroup]="airspaceClassificationForm" (submit)="submitForm()" @slideIn>
            <dtm-ui-select-field
                *ngIf="classifications$ | ngrxPush as classifications"
                formControlName="airspaceClassification"
                [isClearable]="false"
            >
                <label>{{ "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.header" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let classification of classifications" [value]="classification">
                    {{
                        "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.valueLabel"
                            | transloco : { classification }
                    }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <div class="buttons-container">
                <button class="button-secondary" type="button" (click)="cancelChanges()">
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.cancelButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary"
                    type="submit"
                    [disabled]="(airspaceClassificationForm.dirty && airspaceClassificationForm.invalid) || vm.isProcessing"
                >
                    <dtm-ui-icon name="save"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.saveButtonLabel" | transloco }}
                </button>
            </div>
        </form>

        <dtm-ui-label-value
            *ngIf="!vm.isFormExpanded && vm.airspaceClassification"
            [label]="'dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.header' | transloco"
            [value]="
                'dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.valueLabel'
                    | transloco : { classification: vm.airspaceClassification }
            "
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngIf="vm.isReadOnly && !vm.airspaceClassification"
            [label]="'dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassification.header' | transloco"
            [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
        ></dtm-ui-label-value>
    </dtm-ui-presentation-tile-with-action>
</ng-container>
