import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DssUserRoles } from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";

@Component({
    selector: "dss-admin-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    public readonly DssUserRoles = DssUserRoles;

    public readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    public readonly userMainRole$ = this.store.select(AuthState.roles).pipe(
        RxjsUtils.filterFalsy(),
        map((roles) => {
            if (roles.includes(DssUserRoles.AnspSupervisor)) {
                return DssUserRoles.AnspSupervisor;
            } else if (roles.includes(DssUserRoles.ManageNotam)) {
                return DssUserRoles.ManageNotam;
            } else if (roles.includes(DssUserRoles.Administrator)) {
                return DssUserRoles.Administrator;
            }

            return null;
        })
    );

    constructor(private readonly store: Store, private readonly deviceSizeService: DeviceSizeService) {}

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }
}
