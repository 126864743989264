import { Injectable } from "@angular/core";
import { ApplicationType, FlightZonesManagementListItem } from "@dtm-frontend/dss-shared-lib";
import { KmlExporterService } from "@dtm-frontend/shared/map";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap, tap } from "rxjs";
import { FlightZoneListsActions } from "../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../state/flight-zone-lists.state";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class ApplicationKmlExportService {
    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly kmlExporterService: KmlExporterService
    ) {}

    public downloadKml(flightZone: FlightZonesManagementListItem, applicationType: ApplicationType): void {
        this.store
            .dispatch(new FlightZoneListsActions.GetApplicationGeoJson(flightZone, applicationType))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(FlightZoneListsState.error);

                    if (error) {
                        this.toastService.error(
                            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationListShared.getZoneGeoJsonErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(FlightZoneListsState.zoneGeoJson)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((geoJson) => {
                this.kmlExporterService.geoJsonToKml(geoJson, flightZone.title);
            });
    }
}
