<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dssAdminLibFlightZoneConsultation.consultationReviewConfirmDialog.title" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-textarea-field [maxLength]="MAX_COMMENT_LENGTH">
        <label>{{ "dssAdminLibFlightZoneConsultation.consultationReviewConfirmDialog.commentLabel" | transloco }}</label>
        <textarea matInput [formControl]="reviewCommentControl" [rows]="8"></textarea>
        <div class="field-error" *dtmUiFieldHasError="reviewCommentControl; name: 'maxlength'; error as error">
            {{ "dssAdminLibFlightZoneConsultation.maxLengthErrorHint" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" [mat-dialog-close]="false">
        {{ "dssAdminLibFlightZoneConsultation.consultationReviewConfirmDialog.cancelButtonLabel" | transloco }}
    </button>
    <ng-container [ngSwitch]="data.reviewStatus">
        <button
            *ngSwitchCase="FlightZoneApplicationReviewStatus.Approve"
            type="button"
            class="button-primary"
            [disabled]="reviewCommentControl.invalid"
            (click)="confirm()"
        >
            {{ "dssAdminLibFlightZoneConsultation.consultationReviewConfirmDialog.approveButtonLabel" | transloco }}
        </button>

        <button
            *ngSwitchCase="FlightZoneApplicationReviewStatus.Disapprove"
            type="button"
            class="button-warn"
            [disabled]="reviewCommentControl.invalid"
            (click)="confirm()"
        >
            {{ "dssAdminLibFlightZoneConsultation.consultationReviewConfirmDialog.disapproveButtonLabel" | transloco }}
        </button>
    </ng-container>
</div>
