<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dssAdminLibFlightZoneManagement.teamChangeDialog.dialogHeader" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="dialog-description">
        {{ "dssAdminLibFlightZoneManagement.teamChangeDialog.dialogDescription" | transloco }}
    </div>

    <dtm-ui-select-field [formControl]="selectedTeamFormControl" id="selectedTeamFormControl">
        <label for="selectedTeamFormControl">
            {{ "dssAdminLibFlightZoneManagement.teamChangeDialog.selectTeamLabel" | transloco }}
        </label>

        <dtm-ui-select-option *ngFor="let team of data.anspTeams" [value]="team">
            {{ team.name }}
        </dtm-ui-select-option>

        <div class="field-error" *dtmUiFieldHasError="selectedTeamFormControl; name: 'required'">
            {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssAdminLibFlightZoneManagement.teamChangeDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="onConfirmClick()">
        {{ "dssAdminLibFlightZoneManagement.teamChangeDialog.confirmButtonLabel" | transloco }}
    </button>
</div>
