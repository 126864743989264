<mat-card *ngrxLet="anspTeamWorkCalendar$ as anspTeamWorkCalendar">
    <div class="team-name">{{ anspTeamWorkCalendar?.teamName }}</div>

    <form [formGroup]="standardWorkCalendarForm">
        <div class="calendar-heading">
            {{ "dssAdminLibAdministration.anspTeamWorkCalendar.standardWorkHoursLabel" | transloco }}
        </div>
        <div class="work-hours">
            <dtm-ui-time-field formControlName="startAt">
                <label>{{ "dssAdminLibAdministration.anspTeamWorkCalendar.startAtLabel" | transloco }}</label>
                <div class="field-error" *dtmUiFieldHasError="standardWorkCalendarForm.controls.startAt; name: 'requiredTouched'">
                    {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-time-field>

            <dtm-ui-time-field formControlName="endAt" [minTime]="minEndTime$ | ngrxPush">
                <label>{{ "dssAdminLibAdministration.anspTeamWorkCalendar.endAtLabel" | transloco }}</label>
                <div class="field-error" *dtmUiFieldHasError="standardWorkCalendarForm.controls.endAt; name: 'requiredTouched'">
                    {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="standardWorkCalendarForm.controls.endAt; name: 'min'; error as error">
                    {{
                        "dssAdminLibAdministration.anspTeamWorkCalendar.minTimeError"
                            | transloco : { min: error.min | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
            </dtm-ui-time-field>
        </div>

        <div class="calendar-heading">{{ "dssAdminLibAdministration.anspTeamWorkCalendar.standardWorkDaysLabel" | transloco }}</div>
        <mat-button-toggle-group
            formControlName="days"
            multiple
            class="days-toggle-group"
            [class.error]="standardWorkCalendarForm.controls.days.touched && standardWorkCalendarForm.controls.days.invalid"
        >
            <mat-button-toggle *ngFor="let day of WorkCalendarDays" [value]="day">
                {{ "dssAdminLibAdministration.anspTeamWorkCalendar.workDaysValue" | transloco : { value: day } }}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="standardWorkCalendarForm.controls.days; name: 'required'">
                {{ "dssAdminLibAdministration.anspTeamWorkCalendar.requiredDaysError" | transloco }}
            </div>
        </dtm-ui-form-errors>

        <div class="buttons">
            <button type="button" class="button-secondary" routerLink="/admin/ansp-teams">
                {{ "dssAdminLibAdministration.anspTeamWorkCalendar.cancelButtonLabel" | transloco }}
            </button>
            <button type="submit" class="button-primary" (click)="save()">
                {{ "dssAdminLibAdministration.anspTeamWorkCalendar.saveButtonLabel" | transloco }}
            </button>
        </div>
    </form>
</mat-card>
