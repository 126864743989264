import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ApplicationAssignment, FlightZoneApplicationReviewStatus } from "@dtm-frontend/dss-shared-lib";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AnspSupervisorAcceptButtonComponentState {
    isProcessing: boolean;
    isDisabled: boolean;
    assignment: ApplicationAssignment | undefined;
}

@Component({
    selector: "dss-admin-lib-ansp-supervisor-accept-button[assignment]",
    templateUrl: "./ansp-supervisor-accept-button.component.html",
    styleUrls: ["./ansp-supervisor-accept-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AnspSupervisorAcceptButtonComponent {
    public readonly assignment$ = this.localStore.selectByKey("assignment");
    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    @Input()
    public set assignment(value: ApplicationAssignment | undefined) {
        this.localStore.patchState({ assignment: value });
    }

    @Input()
    public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input()
    public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public acceptApplication = new EventEmitter<void>();
    @Output() public rejectApplication = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<AnspSupervisorAcceptButtonComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            isDisabled: false,
            assignment: undefined,
        });
    }

    public acceptReview(reviewStatus: FlightZoneApplicationReviewStatus): void {
        switch (reviewStatus) {
            case FlightZoneApplicationReviewStatus.Approve: {
                this.acceptApplication.emit();
                break;
            }
            case FlightZoneApplicationReviewStatus.Disapprove: {
                this.rejectApplication.emit();
                break;
            }
        }
    }
}
