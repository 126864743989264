import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AZURE_MAPS_LAYER_OPTIONS, DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MapLayerProviderOptions, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";

interface MapContainerComponentState {
    shouldShowActiveRestrictionsLayer: boolean;
}

@Component({
    selector: "dss-shared-lib-map-container",
    templateUrl: "./map-container.component.html",
    styleUrls: ["./map-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MapContainerComponent {
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly shouldShowActiveRestrictionsLayer$ = this.localStore.selectByKey("shouldShowActiveRestrictionsLayer");

    @Input()
    public set shouldShowActiveRestrictionsLayer(value: BooleanInput) {
        this.localStore.patchState({ shouldShowActiveRestrictionsLayer: coerceBooleanProperty(value) });
    }

    @Output() protected readonly pointLimitReached = new EventEmitter<number>();

    constructor(viewerConfiguration: ViewerConfiguration, private readonly localStore: LocalComponentStore<MapContainerComponentState>) {
        localStore.setState({
            shouldShowActiveRestrictionsLayer: false,
        });
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };
    }
}
