import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotamLocation } from "@dtm-frontend/dss-shared-lib";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

const MAX_NAME_LENGTH = 100;
const LOCATION_CODE_MASK = "AAAA";

interface NotamLocationFormComponentState {
    location: NotamLocation | undefined;
    isFormExpanded: boolean;
    isProcessing: boolean;
    hasLocationMissingError: boolean;
    isReadOnly: boolean;
}

@Component({
    selector: "dss-admin-lib-notam-location-form[location]",
    templateUrl: "./notam-location-form.component.html",
    styleUrls: ["./notam-location-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class NotamLocationFormComponent {
    protected readonly LOCATION_CODE_MASK = LOCATION_CODE_MASK;
    protected readonly notamLocationForm = new FormGroup({
        code: new FormControl<string>("", { validators: [Validators.required], nonNullable: true }),
        name: new FormControl<string>("", {
            validators: [Validators.required, Validators.maxLength(MAX_NAME_LENGTH)],
            nonNullable: true,
        }),
    });
    protected readonly isFormExpanded$ = this.localStore.selectByKey("isFormExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasLocationMissingError$ = this.localStore.selectByKey("hasLocationMissingError");
    protected readonly location$ = this.localStore.selectByKey("location");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input()
    public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input()
    public set location(value: NotamLocation | undefined) {
        if (!value) {
            return;
        }

        this.localStore.patchState({ location: value });
        this.notamLocationForm.patchValue(value);
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Input()
    public set hasLocationMissingError(value: BooleanInput) {
        this.localStore.patchState({ hasLocationMissingError: coerceBooleanProperty(value) });

        if (value) {
            this.changeExpansionState(true);
        }
    }

    @Output() public notamLocationChange = new EventEmitter<NotamLocation>();

    constructor(private readonly localStore: LocalComponentStore<NotamLocationFormComponentState>) {
        this.localStore.setState({
            location: undefined,
            isFormExpanded: false,
            isProcessing: false,
            hasLocationMissingError: false,
            isReadOnly: false,
        });
    }

    protected changeExpansionState(value: boolean): void {
        this.localStore.patchState({ isFormExpanded: value });
    }

    protected cancelChanges(): void {
        const lastSavedLocation = this.localStore.selectSnapshotByKey("location");

        this.changeExpansionState(false);
        this.notamLocationForm.reset(lastSavedLocation);
    }

    protected submitForm(): void {
        this.notamLocationForm.markAllAsTouched();

        if (!this.notamLocationForm.valid) {
            return;
        }

        this.notamLocationChange.emit(this.notamLocationForm.getRawValue());
        this.changeExpansionState(false);
    }
}
