import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Feature, Geometry } from "@turf/helpers/dist/js/lib/geojson";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { FLIGHT_ZONE_ENDPOINTS, FlightZoneEndpoints } from "../../flight-zone.tokens";
import {
    AnspTeam,
    ApplicationAssignee,
    ApplicationType,
    AssignmentCapabilities,
    ConsultationData,
    FlightZoneApplication,
    FlightZoneApplicationChatMessage,
    FlightZoneApplicationReviewStatus,
    FlightZoneApplicationsForConsultationList,
    FlightZoneApplicationsList,
    FlightZoneCapabilities,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneRestriction,
    FlightZonesManagementList,
    Institution,
    NotamCapabilities,
    NotamData,
    NotamList,
    NotamLocation,
    NotamNumber,
    RestrictionType,
    RestrictionsList,
} from "../../models/flight-zone-shared.models";
import {
    AnspTeamResponseBody,
    ApplicationsForConsultationListResponseBody,
    ApplicationsListResponseBody,
    ChatMessageResponseBody,
    ChatMessagesListResponseBody,
    FiltersCapabilitiesResponseBody,
    FlightZoneApplicationResponseBody,
    FlightZoneCapabilitiesResponseBody,
    FlightZoneRestrictionResponseBody,
    GeoZonesSystemErrorType,
    GetDesignatorForbiddenRangeResponseBody,
    InstitutionsFilterOptionsResponseBody,
    NotamCapabilitiesResponseBody,
    NotamListResponseBody,
    NotamResponseBody,
    RestrictionConsultationResponseBody,
    RestrictionsListResponseBody,
    SaveDraftErrorResponseBody,
    ZoneBoundaryValidationErrorType,
    convertAnspTeamResponseBodyToAnspTeamList,
    convertApplicationsListResponseBodyToFlightZoneApplicationsForConsultationList,
    convertApplicationsListResponseBodyToFlightZoneApplicationsList,
    convertApplicationsListResponseBodyToFlightZonesManagementList,
    convertChatMessageResponseBodyToFlightZoneApplicationChatMessage,
    convertChatMessagesResponseBodyToFlightZoneApplicationChatMessageList,
    convertFilterParamsToHttpParams,
    convertFlightZoneApplicationResponseBodyToFlightZoneApplication,
    convertFlightZoneApplicationToFlightZoneApplicationRequestPayload,
    convertFlightZoneCapabilitiesResponseBodyToFlightZoneCapabilities,
    convertFlightZoneRestrictionResponseBodyToFlightZoneRestriction,
    convertGeoJsonGeometryToFeature,
    convertInstitutionsFilterOptionsResponseBodyToInstitutions,
    convertNotamCapabilitiesResponseBodyToNotamCapabilities,
    convertNotamListResponseBodyToFlightZonesManagementList,
    convertNotamResponseBodyToNotamData,
    convertNotamsToPublishNotamRequestPayload,
    convertRestrictionConsultationResponseBodyToConsultationData,
    convertRestrictionsListResponseBodyToRestrictionsList,
} from "./flight-zone-api.converters";

@Injectable({
    providedIn: "root",
})
export class FlightZoneApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(FLIGHT_ZONE_ENDPOINTS) private readonly endpoints: FlightZoneEndpoints) {}

    public getFlightZoneCapabilities(): Observable<FlightZoneCapabilities> {
        return this.httpClient.get<FlightZoneCapabilitiesResponseBody>(this.endpoints.getCapabilities).pipe(
            map((response) => convertFlightZoneCapabilitiesResponseBodyToFlightZoneCapabilities(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetCapabilities)))
        );
    }

    public getListFiltersCapabilities(): Observable<{ institutions: Institution[]; anspEmployees: ApplicationAssignee[] }> {
        return this.httpClient
            .get<FiltersCapabilitiesResponseBody>(this.endpoints.getFiltersCapabilities)
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetCapabilities))
                )
            );
    }

    public getInstitutionsFilterOptions(search?: string): Observable<Institution[]> {
        let params;

        if (search) {
            params = new HttpParams().set("search", search);
        }

        return this.httpClient
            .get<InstitutionsFilterOptionsResponseBody>(this.endpoints.getInstitutionsFilterOptions, {
                params,
            })
            .pipe(map((response) => convertInstitutionsFilterOptionsResponseBodyToInstitutions(response)));
    }

    public getFlightZoneApplicationData(
        flightZoneId: string,
        applicationType: ApplicationType,
        shouldGetApplicationForAnsp: boolean
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("applicationType", applicationType).set("ansp", shouldGetApplicationForAnsp);

        return this.httpClient
            .get<FlightZoneApplicationResponseBody>(StringUtils.replaceInTemplate(this.endpoints.applicationsEntity, { flightZoneId }), {
                params,
            })
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplication))
                )
            );
    }

    public getFlightZoneRestrictionData(restrictionId: string): Observable<FlightZoneRestriction> {
        return this.httpClient
            .get<FlightZoneRestrictionResponseBody>(StringUtils.replaceInTemplate(this.endpoints.restrictionsEntity, { restrictionId }))
            .pipe(
                map((response) => convertFlightZoneRestrictionResponseBodyToFlightZoneRestriction(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplication))
                )
            );
    }

    public deleteFlightZoneApplication(flightZoneId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.restrictionApplicationsEntity, { flightZoneId }))
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotDeleteApplication))
                )
            );
    }

    public confirmCorrections(flightZoneId: string, version: number | undefined): Observable<void> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.institutionReAcceptance, { flightZoneId }), {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotConfirmCorrections))
                )
            );
    }

    public saveNewFlightZoneApplicationDraft(newFlightZoneApplicationData: FlightZoneApplication): Observable<FlightZoneApplication> {
        const payload = convertFlightZoneApplicationToFlightZoneApplicationRequestPayload(newFlightZoneApplicationData);

        return this.httpClient.post<FlightZoneApplicationResponseBody>(this.endpoints.restrictionApplications, payload).pipe(
            map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
            catchError((error) => throwError(() => this.transformSaveDraftErrorResponse(error)))
        );
    }

    public updateFlightZoneApplicationDraft(
        updatedFlightZoneApplicationData: FlightZoneApplication,
        flightZoneId: string,
        version: number | undefined
    ): Observable<FlightZoneApplication> {
        const params = this.getVersionParams(version);
        const payload = convertFlightZoneApplicationToFlightZoneApplicationRequestPayload(updatedFlightZoneApplicationData);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.restrictionApplicationsEntity, { flightZoneId }),
                payload,
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) => throwError(() => this.transformSaveDraftErrorResponse(error)))
            );
    }

    public sendInstitutionAcceptanceRequest(flightZoneId: string, version: number | undefined): Observable<void> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.institutionAcceptanceRequest, { flightZoneId }), {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotSubmitApplication))
                )
            );
    }

    public sendApplicationToAnsp(flightZoneId: string, version: number | undefined): Observable<void> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.institutionAcceptance, { flightZoneId }), {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotSubmitApplication))
                )
            );
    }

    public acceptFlightZoneApplication(
        flightZoneId: string,
        version: number,
        applicationType: ApplicationType,
        restrictionType: RestrictionType
    ): Observable<void> {
        const params = new HttpParams()
            .set("applicationType", applicationType)
            .set("version", version)
            .set("restrictionType", restrictionType);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.anspAcceptance, { flightZoneId }), {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotSubmitApplication))
                )
            );
    }

    public lockFlightZoneApplication(flightZoneId: string, version: number, applicationType: ApplicationType): Observable<void> {
        const params = new HttpParams().set("applicationType", applicationType).set("version", version);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.lockApplication, { flightZoneId }), {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotToggleLockedStatus))
                )
            );
    }

    public unlockFlightZoneApplication(flightZoneId: string, version: number, applicationType: ApplicationType): Observable<void> {
        const params = new HttpParams().set("applicationType", applicationType).set("version", version);

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.unlockApplication, { flightZoneId }), { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotToggleLockedStatus))
                )
            );
    }

    public reviewFlightZoneApplication(
        assignmentId: string,
        reviewStatus: FlightZoneApplicationReviewStatus,
        version: number | undefined
    ): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.applicationReview, { assignmentId }), {
                reviewStatus,
                applicationVersion: version,
            })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotReviewApplication))
                )
            );
    }

    public updateApplicationNote(
        flightZoneId: string,
        note: string,
        applicationType: ApplicationType,
        version: number
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("applicationType", applicationType).set("version", version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateApplicationNote, { flightZoneId }),
                { note },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotUpdateApplicationNote))
                )
            );
    }

    public rejectApplicationReview(assignmentId: string, version: number): Observable<void> {
        const params = new HttpParams().set("version", version);

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.assignmentUpdate, { assignmentId }), { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotRejectReview))
                )
            );
    }

    public getApplicationsManagementPaginatedList(filterParams: Params): Observable<FlightZonesManagementList> {
        return this.httpClient
            .get<ApplicationsListResponseBody>(this.endpoints.restrictionApplications, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertApplicationsListResponseBodyToFlightZonesManagementList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getRestrictionsList(filterParams: Params): Observable<RestrictionsList> {
        return this.httpClient
            .get<RestrictionsListResponseBody>(this.endpoints.getRestrictions, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertRestrictionsListResponseBodyToRestrictionsList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getApplicationsForConsultationList(filterParams: Params): Observable<FlightZoneApplicationsForConsultationList> {
        return this.httpClient
            .get<ApplicationsForConsultationListResponseBody>(this.endpoints.consultationsManagement, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertApplicationsListResponseBodyToFlightZoneApplicationsForConsultationList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getRestrictionsModificationsManagementList(filterParams: Params): Observable<FlightZonesManagementList> {
        return this.httpClient
            .get<ApplicationsListResponseBody>(this.endpoints.restrictionApplicationsModification, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertApplicationsListResponseBodyToFlightZonesManagementList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getNotamList(filterParams: Params): Observable<NotamList> {
        return this.httpClient
            .get<NotamListResponseBody>(this.endpoints.getNotams, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertNotamListResponseBodyToFlightZonesManagementList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getApplicationsPaginatedList(filterParams: Params): Observable<FlightZoneApplicationsList> {
        return this.httpClient
            .get<ApplicationsListResponseBody>(this.endpoints.restrictionApplications, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertApplicationsListResponseBodyToFlightZoneApplicationsList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public getRestrictionsModificationsList(filterParams: Params): Observable<FlightZoneApplicationsList> {
        return this.httpClient
            .get<ApplicationsListResponseBody>(this.endpoints.restrictionApplicationsModification, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertApplicationsListResponseBodyToFlightZoneApplicationsList(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplicationList))
                )
            );
    }

    public rejectFlightZoneApplication(flightZoneId: string, version: number | undefined): Observable<void> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.institutionRejection, { flightZoneId }), { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotRejectApplication))
                )
            );
    }

    public rejectFlightZoneManagementApplication(
        flightZoneId: string,
        reason: string,
        version: number,
        applicationType: ApplicationType
    ): Observable<void> {
        const params = new HttpParams().set("version", version).set("applicationType", applicationType);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.anspRejection, { flightZoneId }), { reason }, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotRejectApplication))
                )
            );
    }

    public getApplicationAssignees(): Observable<ApplicationAssignee[]> {
        return this.httpClient
            .get<ApplicationAssignee[]>(this.endpoints.subordinates)
            .pipe(catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error))));
    }

    public getAssignmentCapabilities(): Observable<AssignmentCapabilities> {
        return this.httpClient
            .get<AssignmentCapabilities>(this.endpoints.getAssignmentCapabilities)
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetCapabilities))
                )
            );
    }

    public addApplicationAssignee(employeeId: string, applicationId: string, applicationType: string): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.assignmentManagement, { applicationId, employeeId, applicationType })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeAssignee))
                )
            );
    }

    public changeApplicationAssignee(employeeId: string, flightZoneId: string): Observable<void> {
        const params = new HttpParams().set("employeeId", employeeId).set("applicationId", flightZoneId);

        return this.httpClient
            .put<void>(this.endpoints.editAssignmentEmployee, {}, { params })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeAssignee))
                )
            );
    }

    public getNotamCapabilities(): Observable<NotamCapabilities> {
        return this.httpClient.get<NotamCapabilitiesResponseBody>(this.endpoints.getNotamCapabilities).pipe(
            map((response) => convertNotamCapabilitiesResponseBodyToNotamCapabilities(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetCapabilities)))
        );
    }

    public getNotamsNotice(flightZoneId: string): Observable<NotamData> {
        const params = new HttpParams().set("applicationId", flightZoneId);

        return this.httpClient.get<NotamResponseBody>(this.endpoints.getNotamsNotice, { params }).pipe(
            map((response) => convertNotamResponseBodyToNotamData(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetNotam)))
        );
    }

    public publishNotams(notamId: string, updatedNotamNumber: NotamNumber, notamData: NotamData): Observable<void> {
        const payload = convertNotamsToPublishNotamRequestPayload(updatedNotamNumber, notamData);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.publishNotams, { notamId }), payload)
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotAssignNotamNumber))
                )
            );
    }

    public getChatMessages(flightZoneId: string): Observable<FlightZoneApplicationChatMessage[]> {
        const params = new HttpParams().set("applicationId", flightZoneId);

        return this.httpClient.get<ChatMessagesListResponseBody>(this.endpoints.commentsManagement, { params }).pipe(
            map((response) => convertChatMessagesResponseBodyToFlightZoneApplicationChatMessageList(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetChatMessages)))
        );
    }

    public postChatMessage(flightZoneId: string, applicationType: string, content: string): Observable<FlightZoneApplicationChatMessage> {
        return this.httpClient
            .post<ChatMessageResponseBody>(this.endpoints.commentsManagement, { applicationId: flightZoneId, applicationType, content })
            .pipe(
                map((response) => convertChatMessageResponseBodyToFlightZoneApplicationChatMessage(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotPostChatMessage))
                )
            );
    }

    public updateNotamLocation(flightZoneId: string, location: NotamLocation, version: number): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.notamLocationUpdate, { flightZoneId }),
                {
                    ...location,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotUpdateLocation))
                )
            );
    }

    public changeZoneDuration(restrictionId: string, startAt: Date, endAt: Date): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.restrictionApplicationsModification, {
                restrictionId,
                startAt,
                endAt,
            })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeZoneDuration))
                )
            );
    }

    public cancelZoneDuration(restrictionId: string): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.cancelZoneDuration, { restrictionId })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeZoneDuration))
                )
            );
    }

    public editRestrictionModification(
        flightZoneId: string,
        startAt: Date,
        endAt: Date,
        version: number
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.restrictionModificationEntity, { flightZoneId }),
                {
                    startAt,
                    endAt,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeZoneDuration))
                )
            );
    }

    public getConsultants(): Observable<ApplicationAssignee[]> {
        return this.httpClient
            .get<ApplicationAssignee[]>(this.endpoints.getConsultants)
            .pipe(catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error))));
    }

    public assignConsultant(employeeId: string, applicationId: string, applicationType: string): Observable<ConsultationData> {
        return this.httpClient
            .post<RestrictionConsultationResponseBody>(this.endpoints.consultationsManagement, {
                applicationType,
                employeeId,
                applicationId,
            })
            .pipe(
                map((response) => convertRestrictionConsultationResponseBodyToConsultationData(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotAssignConsultant))
                )
            );
    }

    public getAnspTeams(): Observable<AnspTeam[]> {
        return this.httpClient.get<AnspTeamResponseBody>(this.endpoints.anspTeamsManagement).pipe(
            map((response) => convertAnspTeamResponseBodyToAnspTeamList(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error)))
        );
    }

    public changeAnspTeam(teamId: string, applicationId: string): Observable<void> {
        return this.httpClient
            .put<void>(this.endpoints.anspTeamsManagement, { teamId, applicationId })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeAnspTeam))
                )
            );
    }

    public getFlightZoneApplicationConsultationData(consultationId: string): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("consultationId", consultationId);

        return this.httpClient
            .get<FlightZoneApplicationResponseBody>(this.endpoints.applications, {
                params,
            })
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetApplication))
                )
            );
    }

    public consultationReview(
        id: string,
        reviewStatus: FlightZoneApplicationReviewStatus,
        comment: string,
        applicationType: ApplicationType,
        applicationVersion: number
    ): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.consultationReview, { id }), {
                reviewStatus,
                applicationType,
                comment,
                applicationVersion,
            })
            .pipe(
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotReviewApplication))
                )
            );
    }

    public updateAnspCaseNumber(
        id: string,
        version: number,
        applicationType: string,
        anspCaseNumber: string
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version).set("applicationType", applicationType);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.anspCaseNumberModification, { id }),
                {
                    anspCaseNumber,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotUpdateAnspCaseNumber))
                )
            );
    }

    public getAvailableRestrictionTypes(): Observable<RestrictionType[]> {
        return this.httpClient.get<{ types: RestrictionType[] }>(this.endpoints.getRestrictionTypeCapabilities).pipe(
            map((response) => response.types),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error)))
        );
    }

    public changeSuggestedRestrictionType(
        restrictionType: RestrictionType,
        flightZoneId: string,
        version: number
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.changeRestrictionType, { flightZoneId }),
                {
                    restrictionType,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeRestrictionType))
                )
            );
    }

    public changeDetailedDuration(
        applicationType: ApplicationType,
        flightZoneId: string,
        version: number,
        detailedDuration: string
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version).set("applicationType", applicationType);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.detailedDurationManagement, { flightZoneId }),
                {
                    detailedDuration,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeDetailedDuration))
                )
            );
    }

    public getAirspaceClassificationCapabilities(): Observable<string[]> {
        return this.httpClient.get<{ airspaceClassifications: string[] }>(this.endpoints.airspaceClassificationCapabilities).pipe(
            map((response) => response.airspaceClassifications),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetCapabilities)))
        );
    }

    public changeAdditionalReceivers(
        flightZoneId: string,
        version: number,
        applicationType: ApplicationType,
        emails: string[]
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version).set("applicationType", applicationType);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.additionalReceiversManagement, { flightZoneId }),
                {
                    emails,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeAdditionalReceivers))
                )
            );
    }

    public changeAirspaceClassification(
        flightZoneId: string,
        version: number,
        airspaceClassification: string
    ): Observable<FlightZoneApplication> {
        const params = new HttpParams().set("version", version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.airspaceClassificationManagement, { flightZoneId }),
                {
                    airspaceClassification,
                },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotChangeAirspaceClassification))
                )
            );
    }

    public downloadPdf(restrictionId: string, restrictionTitle: string, file: Blob): Observable<Blob> {
        const formData: FormData = new FormData();
        formData.append("file", file);

        return this.httpClient
            .post(StringUtils.replaceInTemplate(this.endpoints.downloadRestrictionPdf, { restrictionId }), formData, {
                responseType: "blob",
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, restrictionTitle)),
                catchError(() => throwError(() => ({ type: FlightZoneErrorType.CannotDownloadRestrictionPdf })))
            );
    }

    public downloadConfirmationOfApplicationSubmissionPdf(flightZoneId: string, fileName: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadConfirmationOfApplicationSubmissionPdf, { flightZoneId }), {
                responseType: "blob",
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError(() => throwError(() => ({ type: FlightZoneErrorType.CannotDownloadConfirmationOfApplicationSubmissionPdf })))
            );
    }

    public getApplicationGeoJson(id: string, applicationType: ApplicationType): Observable<Feature> {
        const params = new HttpParams().set("applicationType", applicationType);

        return this.httpClient
            .get<Geometry>(StringUtils.replaceInTemplate(this.endpoints.getApplicationGeoJson, { id }), {
                params,
            })
            .pipe(
                map((response) => convertGeoJsonGeometryToFeature(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetZoneGeoJson))
                )
            );
    }

    public getRestrictionGeoJson(id: string): Observable<Feature> {
        return this.httpClient.get<Geometry>(StringUtils.replaceInTemplate(this.endpoints.getRestrictionGeoJson, { id })).pipe(
            map((response) => convertGeoJsonGeometryToFeature(response)),
            catchError((error) => throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotGetZoneGeoJson)))
        );
    }

    public getForbiddenDesignatorRange(restrictionType: RestrictionType): Observable<GetDesignatorForbiddenRangeResponseBody> {
        return this.httpClient
            .get<GetDesignatorForbiddenRangeResponseBody>(this.endpoints.getForbiddenDesignatorRange, { params: { restrictionType } })
            .pipe(catchError(() => throwError(() => ({ type: FlightZoneErrorType.Unknown }))));
    }

    public setCustomDesignator(designatorNumber: number, flightZoneId: string, version: number): Observable<FlightZoneApplication> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .put<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.setCustomDesignator, { flightZoneId }),
                { designatorNumber },
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotSetCustomDesignator))
                )
            );
    }

    public removeCustomDesignator(flightZoneId: string, version: number): Observable<FlightZoneApplication> {
        const params = this.getVersionParams(version);

        return this.httpClient
            .delete<FlightZoneApplicationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.removeCustomDesignator, { flightZoneId }),
                { params }
            )
            .pipe(
                map((response) => convertFlightZoneApplicationResponseBodyToFlightZoneApplication(response)),
                catchError((error) =>
                    throwError(() => this.transformFlightZoneErrorResponse(error, FlightZoneErrorType.CannotRemoveCustomDesignator))
                )
            );
    }

    private transformFlightZoneErrorResponse(errorResponse: HttpErrorResponse, defaultErrorType?: FlightZoneErrorType): FlightZoneError {
        switch (errorResponse.status) {
            case HttpStatusCode.Forbidden:
                return { type: FlightZoneErrorType.NotAuthorized };
            case HttpStatusCode.Conflict:
                return { type: FlightZoneErrorType.InvalidApplicationVersion };
            case HttpStatusCode.BadGateway:
                if (Object.values(GeoZonesSystemErrorType).includes(errorResponse.error.generalMessage)) {
                    return {
                        type: FlightZoneErrorType.GeoZonesSystemError,
                        messageKey: errorResponse.error.generalMessage,
                        args: errorResponse.error.args,
                    };
                }

                return { type: defaultErrorType ?? FlightZoneErrorType.Unknown, messageCode: errorResponse.error?.messageCode };
            default:
                return { type: defaultErrorType ?? FlightZoneErrorType.Unknown, messageCode: errorResponse.error?.messageCode };
        }
    }

    private transformSaveDraftErrorResponse({ error }: SaveDraftErrorResponseBody): FlightZoneError {
        switch (error.status) {
            case HttpStatusCode.Forbidden:
                return { type: FlightZoneErrorType.NotAuthorized };
            case HttpStatusCode.Conflict:
                return { type: FlightZoneErrorType.InvalidApplicationVersion };
            case HttpStatusCode.BadRequest:
                if (error.fieldErrors.find((fieldError) => fieldError.fieldName === "startTime" && fieldError.code === "Invalid")) {
                    return { type: FlightZoneErrorType.InvalidApplicationStartDate };
                }

                if ((Object.values(ZoneBoundaryValidationErrorType) as string[]).includes(error.generalMessage)) {
                    return {
                        type: FlightZoneErrorType.InvalidZoneBoundaryError,
                        messageKey: error.generalMessage,
                        args: error.args,
                    };
                }

                return { type: FlightZoneErrorType.CannotSubmitApplicationDraft };
            default:
                return { type: FlightZoneErrorType.CannotSubmitApplicationDraft, messageCode: error?.messageCode };
        }
    }

    private getVersionParams(version: number | undefined): HttpParams {
        return new HttpParams().set("version", version ?? 0);
    }
}
