import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UsersListItem } from "../../models/administration.models";

@Component({
    selector: "dss-admin-lib-add-member-dialog",
    templateUrl: "./add-member-dialog.component.html",
    styleUrls: ["./add-member-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMemberDialogComponent {
    protected readonly memberFormControl = new FormControl<UsersListItem | null>(null, [Validators.required]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { availableMembers: UsersListItem[] },
        private readonly dialogRef: MatDialogRef<AddMemberDialogComponent>
    ) {}

    public onConfirmClick(): void {
        this.memberFormControl.markAllAsTouched();

        if (this.memberFormControl.invalid) {
            return;
        }

        this.dialogRef.close(this.memberFormControl.value);
    }
}
