<div class="grid">
    <div class="action-buttons">
        <button type="button" routerLink="new" class="button-primary">
            <dtm-ui-icon name="user-add"></dtm-ui-icon>
            {{ "dssAdminLibAdministration.usersList.addUserButtonLabel" | transloco }}
        </button>
    </div>

    <dss-admin-lib-users-list
        *ngrxLet="{ usersList: usersList$, isProcessing: isProcessing$, hasListDataRetrievalError: hasListDataRetrievalError$ } as vm"
        [users]="vm.usersList?.content"
        [page]="vm.usersList?.page"
        [displayedColumns]="displayedColumns"
        [isProcessing]="vm.isProcessing"
        [hasDataRetrievalError]="vm.hasListDataRetrievalError"
        (pageChange)="changePage($event)"
        (listRefresh)="refreshList()"
        (userDelete)="deleteUser($event)"
    ></dss-admin-lib-users-list>
</div>
