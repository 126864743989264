import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DEFAULT_DEBOUNCE_TIME } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, debounceTime, distinctUntilChanged, filter } from "rxjs";
import { map } from "rxjs/operators";
import { ActiveRestrictionsMapFilters } from "../../../models/active-restrictions-map-layer.models";

enum AirspaceStructures {
    CTR = "CTR",
    MCTR = "MCTR",
    TMA = "TMA",
    MTMA = "MTMA",
    TRA = "TRA",
    TSA = "TSA",
    R = "R",
    P = "P",
    D = "D",
    MRT = "MRT",
    ATZ = "ATZ",
}

enum GeographicalZones {
    DRAR = "DRA-R",
    DRAP = "DRA-P",
    DRAI = "DRA-I",
    DRAU = "DRA-U",
}

type AirspaceStructuresFormGroup = {
    [key in AirspaceStructures]: FormControl<boolean | null>;
};

type GeographicalZonesFormGroup = {
    [key in GeographicalZones]: FormControl<boolean | null>;
};

interface ActiveRestrictionsFiltersForm {
    allAirspaceStructures: FormControl<boolean>;
    allGeographicalZones: FormControl<boolean>;
    airspaceStructures: FormGroup<AirspaceStructuresFormGroup>;
    geographicalZones: FormGroup<GeographicalZonesFormGroup>;
    lowerLimit: FormControl<number>;
    upperLimit: FormControl<number>;
}

const MIN_HEIGHT_FILTER_VALUE = 0;
const MAX_HEIGHT_FILTER_VALUE = 66000;
const INTEGER_ONLY_MASK = "9*";

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-active-restrictions-filters",
    templateUrl: "./active-restrictions-filters.component.html",
    styleUrls: ["./active-restrictions-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveRestrictionsFiltersComponent implements AfterViewInit {
    protected readonly AirspaceStructures = AirspaceStructures;
    protected readonly GeographicalZones = GeographicalZones;
    protected readonly INTEGER_ONLY_MASK = INTEGER_ONLY_MASK;
    protected readonly restrictionsFiltersForm = new FormGroup<ActiveRestrictionsFiltersForm>({
        allAirspaceStructures: new FormControl(true, { nonNullable: true }),
        airspaceStructures: new FormGroup({
            CTR: new FormControl(true),
            MCTR: new FormControl(true),
            TMA: new FormControl(true),
            MTMA: new FormControl(true),
            TRA: new FormControl(true),
            TSA: new FormControl(true),
            R: new FormControl(true),
            P: new FormControl(true),
            D: new FormControl(true),
            MRT: new FormControl(true),
            ATZ: new FormControl(true),
        }),
        allGeographicalZones: new FormControl(false, { nonNullable: true }),
        geographicalZones: new FormGroup({
            "DRA-R": new FormControl({ value: true, disabled: true }),
            "DRA-P": new FormControl({ value: true, disabled: true }),
            "DRA-I": new FormControl({ value: true, disabled: true }),
            "DRA-U": new FormControl({ value: true, disabled: true }),
        }),
        lowerLimit: new FormControl(NaN, {
            validators: [Validators.min(MIN_HEIGHT_FILTER_VALUE), Validators.max(MAX_HEIGHT_FILTER_VALUE - 1)],
            nonNullable: true,
        }),
        upperLimit: new FormControl(NaN, {
            validators: [Validators.min(MIN_HEIGHT_FILTER_VALUE + 1), Validators.max(MAX_HEIGHT_FILTER_VALUE)],
            nonNullable: true,
        }),
    });
    private readonly initialFormValue = this.restrictionsFiltersForm.value;
    // NOTE: initialFormRawValue is used to reset form while preserving disabled fields state
    private readonly initialFormRawValue = this.restrictionsFiltersForm.getRawValue();

    @Output() public readonly filtersInitialStateChange = new EventEmitter<boolean>();
    @Output() public readonly filtersChange: Observable<ActiveRestrictionsMapFilters> = this.restrictionsFiltersForm.valueChanges.pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        filter(() => this.restrictionsFiltersForm.valid),
        distinctUntilChanged(equal),
        map((value) => {
            // NOTE: clearValue on InputFieldComponent reset input value to "".
            // Code below replaces "" to input's initial value before emitting filtersInitialStateChange
            value.upperLimit = value.upperLimit || NaN;
            value.lowerLimit = value.lowerLimit || NaN;

            this.filtersInitialStateChange.emit(!equal(this.initialFormValue, value));

            const checkedAirspaceStructuresFilters = this.getCheckedRestrictionTypes(value.airspaceStructures);
            const checkedGeographicalZonesFilters = this.getCheckedRestrictionTypes(value.geographicalZones);

            return {
                type: checkedAirspaceStructuresFilters,
                geoZone: checkedGeographicalZonesFilters,
                lowerLimit: value.lowerLimit,
                upperLimit: value.upperLimit,
            };
        })
    );

    public ngAfterViewInit(): void {
        this.listenToToggleControlsChanges();
        this.updateLowerLimitValidators();
    }

    protected resetFilters(): void {
        this.restrictionsFiltersForm.reset(this.initialFormRawValue);
    }

    private getCheckedRestrictionTypes(value: Record<string, boolean | null> | undefined): string[] {
        if (!value) {
            return [];
        }

        return Object.entries(value).reduce((result: string[], [filterKey, filterValue]) => {
            if (filterValue) {
                result.push(filterKey);
            }

            return result;
        }, []);
    }

    private updateLowerLimitValidators(): void {
        this.restrictionsFiltersForm.controls.upperLimit.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.restrictionsFiltersForm.controls.lowerLimit.setValidators([
                Validators.min(MIN_HEIGHT_FILTER_VALUE),
                Validators.max(value ? Math.min(MAX_HEIGHT_FILTER_VALUE, value) - 1 : MAX_HEIGHT_FILTER_VALUE),
            ]);
            this.restrictionsFiltersForm.controls.lowerLimit.updateValueAndValidity({ emitEvent: false });
        });
    }

    private listenToToggleControlsChanges(): void {
        this.restrictionsFiltersForm.controls.allAirspaceStructures.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (value) {
                this.restrictionsFiltersForm.controls.allGeographicalZones.setValue(false, { emitEvent: false });
                this.restrictionsFiltersForm.controls.airspaceStructures.enable();
                this.restrictionsFiltersForm.controls.geographicalZones.disable();
            } else {
                this.restrictionsFiltersForm.controls.airspaceStructures.disable();
            }
        });

        this.restrictionsFiltersForm.controls.allGeographicalZones.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (value) {
                this.restrictionsFiltersForm.controls.allAirspaceStructures.setValue(false, { emitEvent: false });
                this.restrictionsFiltersForm.controls.geographicalZones.enable();
                this.restrictionsFiltersForm.controls.airspaceStructures.disable();
            } else {
                this.restrictionsFiltersForm.controls.geographicalZones.disable();
            }
        });
    }
}
