import { Injectable } from "@angular/core";

import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";
import { switchMap } from "rxjs";
import { UserProfileActions } from "../state/user-profile.actions";

@Injectable()
export class UserProfileResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store
            .select(AuthState.userId)
            .pipe(
                switchMap((userId) =>
                    this.store.dispatch([new UserProfileActions.GetUserData(userId), UserProfileActions.GetNotificationsList])
                )
            );
    }
}
