import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { Notam } from "../../models/flight-zone-shared.models";

@Component({
    selector: "dss-shared-lib-notam-preview",
    templateUrl: "./notam-preview.component.html",
    styleUrls: ["./notam-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotamPreviewComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly notam: Notam | undefined,
        private readonly dialogRef: MatDialogRef<NotamPreviewComponent>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        if (!notam) {
            const errorMessage = this.transloco.translate("dssSharedLibFlightZone.notamPreview.cannotGetNotamErrorMessage");

            this.toastService.error(errorMessage);
        }
    }

    public notamCopied(): void {
        const message = this.transloco.translate("dssSharedLibFlightZone.notamPreview.notamCopied");

        this.toastService.info(message);
    }
}
