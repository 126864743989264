import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
    ApplicationManagementListItemStatus,
    FlightZoneApplicationStatus,
    FlightZonesManagementListItem,
} from "@dtm-frontend/dss-shared-lib";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map, withLatestFrom } from "rxjs/operators";
import { ApplicationManagementStatusTranslationService } from "../../services/application-management-status-translation.service";

interface ApplicationStatusBadge {
    iconName: IconName;
    className: string;
    type: ApplicationManagementListItemStatus;
    label: string;
}

interface ApplicationManagementStatusBadgeComponentState {
    listElement: FlightZonesManagementListItem | undefined;
    hasSupervisorPermissions: boolean;
}

@Component({
    selector: "dss-admin-lib-application-management-status-badge[listElement]",
    templateUrl: "./application-management-status-badge.component.html",
    styleUrls: ["./application-management-status-badge.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationManagementStatusBadgeComponent {
    public readonly badge$ = this.localStore.selectByKey("listElement").pipe(
        RxjsUtils.filterFalsy(),
        withLatestFrom(this.localStore.selectByKey("hasSupervisorPermissions")),
        map(([listElement, hasSupervisorPermissions]) => this.getBadgeSettings(listElement, hasSupervisorPermissions)),
        RxjsUtils.filterFalsy()
    );

    @Input()
    public set hasSupervisorPermissions(value: boolean) {
        this.localStore.patchState({ hasSupervisorPermissions: value });
    }

    @Input()
    public set listElement(value: FlightZonesManagementListItem) {
        this.localStore.patchState({ listElement: value });
    }

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationManagementStatusBadgeComponentState>,
        private readonly statusTranslationService: ApplicationManagementStatusTranslationService
    ) {
        this.localStore.setState({
            listElement: undefined,
            hasSupervisorPermissions: false,
        });
    }

    private getBadgeSettings(
        listItem: FlightZonesManagementListItem,
        hasSupervisorPermissions: boolean
    ): ApplicationStatusBadge | undefined {
        const statusLabels = this.statusTranslationService.getStatusTranslationsMap(hasSupervisorPermissions);

        if (!listItem.isLocked) {
            return {
                iconName: "file-edit",
                className: "warning",
                type: ApplicationManagementListItemStatus.Correction,
                label: statusLabels[ApplicationManagementListItemStatus.Correction],
            };
        }

        switch (listItem.status) {
            case FlightZoneApplicationStatus.New:
            case FlightZoneApplicationStatus.SentToANSP:
            case FlightZoneApplicationStatus.Assigned:
                return {
                    iconName: "file-unknow",
                    className: "secondary",
                    type: ApplicationManagementListItemStatus.Analysis,
                    label: statusLabels[ApplicationManagementListItemStatus.Analysis],
                };

            case FlightZoneApplicationStatus.Reviewed:
                return {
                    iconName: "file-user",
                    className: "disabled",
                    type: ApplicationManagementListItemStatus.ForApproval,
                    label: statusLabels[ApplicationManagementListItemStatus.ForApproval],
                };
            case FlightZoneApplicationStatus.Accepted:
                return {
                    iconName: "file-mark",
                    className: "success",
                    type: ApplicationManagementListItemStatus.Accepted,
                    label: statusLabels[ApplicationManagementListItemStatus.Accepted],
                };
            default:
                return;
        }
    }
}
