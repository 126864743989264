import { Params } from "@angular/router";
import { FlightZoneApplicationReviewStatus } from "@dtm-frontend/dss-shared-lib";

export namespace FlightZoneConsultationActions {
    export class GetApplicationForConsultationData {
        public static readonly type = "[Flight Zone Consultation] Get flight zone application for consultation data";

        constructor(public consultationId: string) {}
    }

    export class ReviewConsultation {
        public static readonly type = "[Flight Zone Consultation] Review application for consultation";

        constructor(public consultationId: string, public reviewStatus: FlightZoneApplicationReviewStatus, public comment: string) {}
    }

    export class GetConsultationsInProgressList {
        public static readonly type = "[Flight Zone Consultation] Get applications for consultation in progress list";

        constructor(public filterParams: Params) {}
    }

    export class GetConsultationsCompletedList {
        public static readonly type = "[Flight Zone Consultation] Get applications for consultation completed list";

        constructor(public filterParams: Params) {}
    }
}
