<div class="dialog-header">
    <h3 mat-dialog-title>{{ "dssSharedLibFlightZone.restrictionPdfDownloadConfirmDialog.dialogHeader" | transloco }}</h3>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    {{ "dssSharedLibFlightZone.restrictionPdfDownloadConfirmDialog.dialogText" | transloco }}
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="false">
        {{ "dssSharedLibFlightZone.restrictionPdfDownloadConfirmDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" [mat-dialog-close]="true">
        {{ "dssSharedLibFlightZone.restrictionPdfDownloadConfirmDialog.confirmButtonLabel" | transloco }}
    </button>
</div>
