<ng-container *ngrxLet="notams$ as notams">
    <ng-container *ngrxLet="displayedColumns$ as displayedColumns">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <table mat-table multiTemplateDataRows [dataSource]="notams" *ngrxLet="expandedElement$ as expandedElement" class="dtm-table">
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.titleHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="notamNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.notamHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.notamNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="designator">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.zoneNumberHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.designator }}</td>
                </ng-container>

                <ng-container matColumnDef="startAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.startAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.startAt; else restrictionCancelledDateTemplate"
                            [date]="element.startAt"
                        ></dss-shared-lib-utc-date-display>

                        <dtm-ui-popover
                            *ngIf="element.notamType === NotamType.EndRestriction"
                            popoverIcon="forbid"
                            [popoverText]="'dssAdminLibFlightZoneManagement.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.endAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.endAt; else restrictionCancelledDateTemplate"
                            [date]="element.endAt"
                        ></dss-shared-lib-utc-date-display>

                        <dtm-ui-popover
                            *ngIf="element.notamType === NotamType.EndRestriction"
                            popoverIcon="forbid"
                            [popoverText]="'dssAdminLibFlightZoneManagement.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="element.reviewer"
                            type="button"
                            class="button-icon"
                            (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                        >
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.reviewer"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.assigneeHeader' | transloco"
                            >
                                {{ element.reviewer.firstName }} {{ element.reviewer.lastName }}
                            </dtm-ui-label-value>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.actionsHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-error
                            *ngIf="hasDataRetrievalError$ | ngrxPush; else emptyListTemplate"
                            (reload)="listRefresh.emit()"
                        ></dtm-ui-error>

                        <ng-template #emptyListTemplate>
                            <dtm-ui-no-results></dtm-ui-no-results>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-pagination
                            *ngIf="applicationsPage$ | ngrxPush as page"
                            [pagination]="page"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="row.reviewer && toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="notams.length || isProcessing"
                    class="no-results-row"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!notams.length" class="pagination-row"></tr>
            </table>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <ng-container [ngSwitch]="element.type">
            <a
                *ngSwitchCase="ApplicationType.RestrictionApplication"
                [routerLink]="['/flight-zone', 'preview', element.applicationId]"
                mat-menu-item
            >
                <dtm-ui-icon name="file-text"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewActionLabel" | transloco }}</span>
            </a>
            <a
                *ngSwitchCase="ApplicationType.RestrictionModificationApplication"
                [routerLink]="['/modifications', 'preview', element.applicationId]"
                mat-menu-item
            >
                <dtm-ui-icon name="file-text"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewActionLabel" | transloco }}</span>
            </a>
        </ng-container>

        <ng-container *ngIf="element.notamNumber; else previewSuggestedNotamActionTemplate">
            <button mat-menu-item type="button" (click)="openNotamPreview(element.applicationId)">
                <dtm-ui-icon name="eye"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewNotamActionLabel" | transloco }}</span>
            </button>
        </ng-container>

        <ng-template #previewSuggestedNotamActionTemplate>
            <a [routerLink]="['/flight-zone', 'preview', element.applicationId, 'notam-edit']" mat-menu-item>
                <dtm-ui-icon name="file-paper"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewSuggestedNotamActionLabel" | transloco }}</span>
            </a>
        </ng-template>
    </ng-template>
</mat-menu>

<ng-template #restrictionCancelledDateTemplate>
    {{ "dssAdminLibFlightZoneManagement.applicationListShared.restrictionCancelledDateLabel" | transloco }}
</ng-template>
