<ng-container
    *ngrxLet="{
        isFormExpanded: isFormExpanded$,
        detailedDuration: detailedDuration$,
        isProcessing: isProcessing$,
        isReadOnly: isReadOnly$
    } as vm"
>
    <dtm-ui-presentation-tile-with-action
        [titleTile]="'dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.header' | transloco"
        [class.read-only]="vm.isReadOnly"
    >
        <button
            *ngIf="!vm.isReadOnly && !vm.isFormExpanded"
            actionButtonSlot
            class="button-secondary"
            type="button"
            (click)="changeExpansionState(true)"
        >
            <ng-container *ngIf="vm.detailedDuration?.length; else emptyDurationTemplate">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.editButtonLabel" | transloco }}
            </ng-container>

            <ng-template #emptyDurationTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.addButtonLabel" | transloco }}
            </ng-template>
        </button>

        <button
            *ngIf="!vm.isReadOnly && vm.isFormExpanded"
            actionButtonSlot
            class="button-icon"
            type="button"
            (click)="changeExpansionState(false)"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <form *ngIf="!vm.isReadOnly && vm.isFormExpanded" [formGroup]="detailedDurationForm" (submit)="submitForm()" @slideIn>
            <dtm-ui-textarea-field [maxLength]="MAX_DETAILED_DURATION_LENGTH">
                <label>{{ "dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.header" | transloco }}</label>
                <textarea formControlName="detailedDuration" matInput [rows]="5"></textarea>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="detailedDurationForm.controls.detailedDuration; name: 'maxlength'; error as error"
                >
                    {{ "dssAdminLibFlightZoneManagement.maxLengthErrorHint" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-textarea-field>

            <div class="buttons-container">
                <button class="button-secondary" type="button" (click)="cancelChanges()">
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.cancelButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary"
                    type="submit"
                    [disabled]="(detailedDurationForm.dirty && detailedDurationForm.invalid) || vm.isProcessing"
                >
                    <dtm-ui-icon name="save"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.saveButtonLabel" | transloco }}
                </button>
            </div>
        </form>

        <div *ngIf="!vm.isFormExpanded && vm.detailedDuration?.length">
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.header' | transloco"
                [value]="vm.detailedDuration"
            ></dtm-ui-label-value>
        </div>

        <dtm-ui-label-value
            *ngIf="vm.isReadOnly && !vm.detailedDuration?.length"
            [label]="'dssAdminLibFlightZoneManagement.applicationPreview.detailedDuration.header' | transloco"
            [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
        ></dtm-ui-label-value>
    </dtm-ui-presentation-tile-with-action>
</ng-container>
