<dtm-ui-input-field matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin">
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <input
        matInput
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="autocompleteOrigin"
        [formControl]="searchControl"
        [placeholder]="searchPlaceholder$ | ngrxPush | transloco"
    />
    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
    <mat-autocomplete (optionSelected)="elementSelected($event)" [displayWith]="displayAutocompleteValueFn" #auto="matAutocomplete">
        <mat-option *ngFor="let element of elements$ | ngrxPush" [value]="element">
            {{ element.name }}
        </mat-option>
    </mat-autocomplete>
</dtm-ui-input-field>
