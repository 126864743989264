import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { PrismContextMenu, PrismContextMenuData } from "@dtm-frontend/shared/map/cesium";

@Component({
    selector: "dss-shared-lib-map-context-menu",
    templateUrl: "./map-context-menu.component.html",
    styleUrls: ["./map-context-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapContextMenuComponent implements PrismContextMenu {
    public readonly data!: PrismContextMenuData;

    @HostListener("contextmenu", ["$event"])
    public onRightClick(event: Event) {
        event.preventDefault();
    }
}
