import { Injectable } from "@angular/core";
import {
    ApplicationAssignee,
    ApplicationType,
    AssignmentCapabilities,
    FlightZoneApiService,
    FlightZoneError,
    FlightZoneRestrictionState,
    NotamCapabilities,
    NotamData,
} from "@dtm-frontend/dss-shared-lib";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { FlightZoneApplicationState } from "./flight-zone-application.state";
import { FlightZoneListsState } from "./flight-zone-lists.state";
import { FlightZoneManagementActions } from "./flight-zone-management.actions";

interface FlightZoneManagementStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    availableApplicationAssignees: ApplicationAssignee[];
    assignmentCapabilities: AssignmentCapabilities | undefined;
    notamData: NotamData | undefined;
    notamCapabilities: NotamCapabilities;
}

const defaultState: FlightZoneManagementStateModel = {
    error: undefined,
    isProcessing: false,
    availableApplicationAssignees: [],
    assignmentCapabilities: undefined,
    notamData: undefined,
    notamCapabilities: {
        notamNumberSeries: [],
    },
};

@State<FlightZoneManagementStateModel>({
    name: "flightZoneManagement",
    defaults: defaultState,
    children: [FlightZoneApplicationState, FlightZoneListsState, FlightZoneRestrictionState],
})
@Injectable()
export class FlightZoneManagementState {
    @Selector()
    public static error(state: FlightZoneManagementStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector([FlightZoneApplicationState.isProcessing, FlightZoneListsState.isProcessing, FlightZoneRestrictionState.isProcessing])
    public static isProcessing(
        state: FlightZoneManagementStateModel,
        isFlightZoneApplicationStateProcessing: boolean,
        isFlightZoneListsStateProcessing: boolean,
        isFlightZoneRestrictionStateProcessing: boolean
    ): boolean {
        return (
            state.isProcessing ||
            isFlightZoneApplicationStateProcessing ||
            isFlightZoneListsStateProcessing ||
            isFlightZoneRestrictionStateProcessing
        );
    }

    @Selector()
    public static availableApplicationAssignees(state: FlightZoneManagementStateModel): ApplicationAssignee[] {
        return state.availableApplicationAssignees;
    }

    @Selector()
    public static notamData(state: FlightZoneManagementStateModel): NotamData | undefined {
        return state.notamData;
    }

    @Selector()
    public static notamNumberSeries(state: FlightZoneManagementStateModel): string[] {
        return state.notamCapabilities.notamNumberSeries;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {
        if (flightZoneApi === undefined) {
            throw new Error("Initialize FlightZoneManagementModule with .forRoot()");
        }
    }

    @Action(FlightZoneManagementActions.GetAvailableApplicationAssignees)
    public getAvailableApplicationAssignees(context: StateContext<FlightZoneManagementStateModel>) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getApplicationAssignees().pipe(
            tap((availableApplicationAssignees) => {
                context.patchState({
                    availableApplicationAssignees,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneManagementActions.GetAssignmentCapabilities)
    public getAssignmentCapabilities(context: StateContext<FlightZoneManagementStateModel>) {
        context.patchState({ isProcessing: true, assignmentCapabilities: undefined });

        return this.flightZoneApi.getAssignmentCapabilities().pipe(
            tap((assignmentCapabilities: AssignmentCapabilities) => {
                context.patchState({
                    assignmentCapabilities,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneManagementActions.ChangeApplicationAssignee)
    public changeApplicationAssignee(
        context: StateContext<FlightZoneManagementStateModel>,
        action: FlightZoneManagementActions.ChangeApplicationAssignee
    ) {
        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.changeApplicationAssignee(action.assigneeId, action.flightZoneId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(FlightZoneManagementActions.AddApplicationAssignee)
    public addApplicationAssignee(
        context: StateContext<FlightZoneManagementStateModel>,
        action: FlightZoneManagementActions.AddApplicationAssignee
    ) {
        const applicationType =
            action.applicationType === ApplicationType.RestrictionModificationApplication
                ? context.getState().assignmentCapabilities?.modificationActivityApplicationType
                : context.getState().assignmentCapabilities?.restrictionApplicationType;

        if (!applicationType) {
            return;
        }

        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.addApplicationAssignee(action.assigneeId, action.flightZoneId, applicationType).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(FlightZoneManagementActions.GetNotamCapabilities)
    public getNotamCapabilities(context: StateContext<FlightZoneManagementStateModel>) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getNotamCapabilities().pipe(
            tap((notamCapabilities) => {
                context.patchState({
                    notamCapabilities,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneManagementActions.GetNotamData)
    public getNotam(context: StateContext<FlightZoneManagementStateModel>, action: FlightZoneManagementActions.GetNotamData) {
        context.patchState({ isProcessing: true, notamData: undefined });

        return this.flightZoneApi.getNotamsNotice(action.flightZoneId).pipe(
            tap((notamData) => {
                context.patchState({
                    notamData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneManagementActions.UpdateNotamData)
    public updateNotamData(context: StateContext<FlightZoneManagementStateModel>, action: FlightZoneManagementActions.UpdateNotamData) {
        context.patchState({ notamData: action.notamData });
    }

    @Action(FlightZoneManagementActions.AssignNotamNumber)
    public assignNotamNumber(context: StateContext<FlightZoneManagementStateModel>, action: FlightZoneManagementActions.AssignNotamNumber) {
        const notamData = context.getState().notamData;

        if (!notamData) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.publishNotams(action.notamId, action.notamNumber, notamData).pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
