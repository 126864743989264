import { Injectable } from "@angular/core";
import { CylinderRestrictionArea, FlightZoneGeometryTranslationProvider, FlightZoneUtils } from "@dtm-frontend/dss-shared-lib";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { Store } from "@ngxs/store";
import { FlightZoneApplicationState } from "../state/flight-zone-application.state";

@Injectable()
export class FlightZoneManagementGeometryTranslatorService implements FlightZoneGeometryTranslationProvider {
    constructor(private readonly translationHelper: TranslationHelperService, private readonly store: Store) {}

    public upperHeightLabelProvider(height: number): string | undefined {
        const heightTranslation = this.translationHelper.tryTranslate("dssSharedLibFlightZone.applicationSummary.upperHeightLabel");
        const geometryUnits = this.store.selectSnapshot(FlightZoneApplicationState.applicationData)?.restrictionAreaGeometry.areaUnits;

        if (!geometryUnits) {
            return;
        }

        const heightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(height, geometryUnits.upperHeightUnit, 0);

        return (
            heightTranslation &&
            `${heightTranslation}: ${heightRounded} ${geometryUnits.upperHeightUnit} ${geometryUnits.upperHeightReference}`
        );
    }

    public lowerHeightLabelProvider(height: number): string | undefined {
        const heightTranslation = this.translationHelper.tryTranslate("dssSharedLibFlightZone.applicationSummary.lowerHeightLabel");
        const geometryUnits = this.store.selectSnapshot(FlightZoneApplicationState.applicationData)?.restrictionAreaGeometry.areaUnits;

        if (!geometryUnits) {
            return;
        }

        const heightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(height, geometryUnits.lowerHeightUnit, 0);

        return (
            heightTranslation &&
            `${heightTranslation}: ${heightRounded} ${geometryUnits.lowerHeightUnit} ${geometryUnits.lowerHeightReference}`
        );
    }

    public cylinderEditorRadiusLabelProvider(radius: number): string | undefined {
        const radiusTranslation = this.translationHelper.tryTranslate(
            "dssAdminLibFlightZoneManagement.applicationPreview.cylinderRadiusLabel"
        );
        const area = this.store.selectSnapshot(FlightZoneApplicationState.applicationData)
            ?.restrictionAreaGeometry as CylinderRestrictionArea;
        const unit = area.areaUnits.radiusUnit;

        if (!unit) {
            return;
        }

        const radiusRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(radius, unit, 1);

        return radiusTranslation && `${radiusTranslation}: ${radiusRounded} ${unit}`;
    }
}
