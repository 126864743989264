import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ApplicationType,
    DssUserRoles,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZonesManagementListItem,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { AssigneeChangeParams, FlightZonesManagementTableColumns, TableActionButton } from "../../models/flight-zone-management.models";
import { ApplicationKmlExportService } from "../../services/application-kml-export.service";
import { FlightZoneListsActions } from "../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../state/flight-zone-lists.state";
import { FlightZoneManagementActions } from "../../state/flight-zone-management.actions";
import { FlightZoneManagementState } from "../../state/flight-zone-management.state";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-assigned-applications-list-container",
    templateUrl: "./assigned-applications-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedApplicationsListContainerComponent {
    protected readonly displayedColumns: FlightZonesManagementTableColumns = [
        "expandHandle",
        "status",
        "date",
        "startAt",
        "endAt",
        "applicationPurpose",
        "title",
        "actions",
    ];
    protected readonly basePreviewRoutePath = `/${this.route.snapshot.parent?.url[0].path}`;
    protected readonly isProcessing$ = this.store.select(FlightZoneManagementState.isProcessing);
    protected readonly assignedApplicationsList$ = this.store.select(FlightZoneListsState.assignedApplicationsList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly availableApplicationAssignees$ = this.store.select(FlightZoneManagementState.availableApplicationAssignees);
    protected readonly actionButtons = [TableActionButton.PreviewAction, TableActionButton.ChangeAssignee, TableActionButton.KmlDownload];
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.AnspSupervisor)));

    private get applicationType(): ApplicationType {
        return this.route.snapshot.parent?.data.applicationType;
    }

    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly applicationKmlExportService: ApplicationKmlExportService
    ) {}

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    protected refreshList(): void {
        this.store.dispatch(new FlightZoneListsActions.GetAssignedApplicationsList(this.route.snapshot.queryParams, this.applicationType));
    }

    protected changeAssignee(event: AssigneeChangeParams): void {
        this.store
            .dispatch(new FlightZoneManagementActions.ChangeApplicationAssignee(event.assigneeId, event.flightZoneApplication.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneManagementState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dssAdminLibFlightZoneManagement.changeAssigneeSuccessMessage"));
                this.store.dispatch([
                    new FlightZoneListsActions.GetAssignedApplicationsList(this.route.snapshot.queryParams, this.applicationType),
                    new FlightZoneListsActions.GetApplicationsAssignedToUserList(this.route.snapshot.queryParams, this.applicationType),
                ]);
            });
    }

    protected downloadKml(flightZone: FlightZonesManagementListItem): void {
        this.applicationKmlExportService.downloadKml(flightZone, this.applicationType);
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotChangeAssignee:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotChangeAssigneeErrorMessage");
                break;
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.invalidVersionErrorMessage");
                break;
            default:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
