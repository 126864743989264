import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { FlightZoneError, FlightZoneRestriction } from "../models/flight-zone-shared.models";
import { FlightZoneApiService } from "../services/flight-zone-api/flight-zone-api.service";
import { FlightZoneRestrictionActions } from "./flight-zone-restriction.actions";

interface FlightZoneRestrictionStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    restrictionData: FlightZoneRestriction | undefined;
}

const defaultState: FlightZoneRestrictionStateModel = {
    error: undefined,
    isProcessing: false,
    restrictionData: undefined,
};

@State<FlightZoneRestrictionStateModel>({
    name: "flightZoneRestriction",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneRestrictionState {
    @Selector()
    public static error(state: FlightZoneRestrictionStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static isProcessing(state: FlightZoneRestrictionStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static restrictionData(state: FlightZoneRestrictionStateModel): FlightZoneRestriction | undefined {
        return state.restrictionData;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {}

    @Action(FlightZoneRestrictionActions.GetRestrictionData)
    public getRestrictionData(
        context: StateContext<FlightZoneRestrictionStateModel>,
        action: FlightZoneRestrictionActions.GetRestrictionData
    ) {
        context.patchState({ isProcessing: true, restrictionData: undefined });

        return this.flightZoneApi.getFlightZoneRestrictionData(action.restrictionId).pipe(
            tap((restrictionData) => {
                context.patchState({
                    restrictionData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneRestrictionActions.DownloadPdf, { cancelUncompleted: true })
    public downloadPdf(context: StateContext<FlightZoneRestrictionStateModel>, action: FlightZoneRestrictionActions.DownloadPdf) {
        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.downloadPdf(action.restriction.id, action.restriction.basicData.title, action.file).pipe(
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf, { cancelUncompleted: true })
    public DownloadConfirmationOfApplicationSubmissionPdf(
        context: StateContext<FlightZoneRestrictionStateModel>,
        action: FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf
    ) {
        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.downloadConfirmationOfApplicationSubmissionPdf(action.flightZoneId, action.fileName).pipe(
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
