import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ADMINISTRATION_ENDPOINTS, AdministrationEndpoints } from "../administration.tokens";
import {
    AdministrationCapabilities,
    AdministrationError,
    AdministrationErrorType,
    AnspStandardTeamWorkCalendar,
    AnspTeamDetails,
    AnspTeamWorkCalendar,
    AnspTeamsList,
    InstitutionDetails,
    InstitutionsList,
    NewInstitutionDetails,
    NewUserDetails,
    UserDetails,
    UsersList,
} from "../models/administration.models";
import {
    AnspTeamDetailsResponseBody,
    AnspTeamWorkCalendarResponseBody,
    AnspTeamsListResponseBody,
    GetUserDetailsResponseBody,
    InstitutionsDetailsResponseBody,
    InstitutionsListResponseBody,
    UsersListResponseBody,
    WorkingCalendarResponseBody,
    convertAnspTeamDetailsResponseBodyToAnspTeamDetails,
    convertAnspTeamWorkCalendarResponseBodyToAnspTeamWorkCalendar,
    convertAnspTeamsListResponseBodyToAnspTeamsList,
    convertFilterParamsToHttpParams,
    convertGetUserDetailsResponseBodyToUserDetails,
    convertInstitutionsDetailsResponseBodyToInstitutionDetails,
    convertInstitutionsListResponseBodyToInstitutionsList,
    convertNewInstitutionDetailsToThreadRequestPayload,
    convertNewUserDetailsToCreateUserRequestPayload,
    convertUserDetailsToUpdateUserRequestPayload,
    convertUsersListResponseBodyToUsersList,
    convertWorkingCalendarResponseBodyToAnspStandardTeamWorkCalendar,
    getAddDaysOffRequestPayload,
} from "./administration.converters";

@Injectable()
export class AdministrationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(ADMINISTRATION_ENDPOINTS) private readonly endpoints: AdministrationEndpoints
    ) {}

    public getUsersList(filterParams: Params): Observable<UsersList> {
        return this.httpClient
            .get<UsersListResponseBody>(this.endpoints.getUsersList, { params: convertFilterParamsToHttpParams(filterParams) })
            .pipe(
                map((response) => convertUsersListResponseBodyToUsersList(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public getInstitutionsList(filterParams: Params): Observable<InstitutionsList> {
        return this.httpClient
            .get<InstitutionsListResponseBody>(this.endpoints.getInstitutionsList, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertInstitutionsListResponseBodyToInstitutionsList(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public getInstitutionDetails(institutionId: string): Observable<InstitutionDetails> {
        return this.httpClient
            .get<InstitutionsDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.institutionDetailsManagement, { institutionId })
            )
            .pipe(
                map((response) => convertInstitutionsDetailsResponseBodyToInstitutionDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public assignInstitutionMembers(institutionId: string, userIds: string[]): Observable<InstitutionDetails> {
        return this.httpClient
            .put<InstitutionsDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.assignInstitutionMembers, { institutionId }),
                { userIds }
            )
            .pipe(
                map((response) => convertInstitutionsDetailsResponseBodyToInstitutionDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public removeInstitutionMember(institutionId: string, userId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeInstitutionMember, { institutionId, userId }))
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public getCapabilities(): Observable<AdministrationCapabilities> {
        return this.httpClient
            .get<AdministrationCapabilities>(this.endpoints.getCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public getUserDetails(userId: string): Observable<UserDetails> {
        return this.httpClient
            .get<GetUserDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.userDetailsManagement, { userId }))
            .pipe(
                map((response) => convertGetUserDetailsResponseBodyToUserDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public createUser(userData: NewUserDetails): Observable<UserDetails> {
        return this.httpClient
            .post<GetUserDetailsResponseBody>(this.endpoints.saveNewUser, convertNewUserDetailsToCreateUserRequestPayload(userData))
            .pipe(
                map((response) => convertGetUserDetailsResponseBodyToUserDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public updateUser(userData: UserDetails): Observable<UserDetails> {
        const params = new HttpParams().set("version", userData.version);

        return this.httpClient
            .put<GetUserDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.userDetailsManagement, { userId: userData.id }),
                convertUserDetailsToUpdateUserRequestPayload(userData),
                { params }
            )
            .pipe(
                map((response) => convertGetUserDetailsResponseBodyToUserDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public deleteUser(userId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.userDetailsManagement, { userId }))
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public saveNewInstitution(institution: NewInstitutionDetails): Observable<InstitutionDetails> {
        const body = convertNewInstitutionDetailsToThreadRequestPayload(institution);

        return this.httpClient.post<InstitutionsDetailsResponseBody>(this.endpoints.saveNewInstitution, body).pipe(
            map((response) => convertInstitutionsDetailsResponseBodyToInstitutionDetails(response)),
            catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
        );
    }

    public updateInstitutionDetails(
        institutionId: string,
        institution: NewInstitutionDetails,
        version: number
    ): Observable<InstitutionDetails> {
        const params = new HttpParams().set("version", version);
        const body = convertNewInstitutionDetailsToThreadRequestPayload(institution);

        return this.httpClient
            .put<InstitutionsDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.institutionDetailsManagement, { institutionId }),
                body,
                { params }
            )
            .pipe(
                map((response) => convertInstitutionsDetailsResponseBodyToInstitutionDetails(response)),
                catchError((error) => throwError(() => this.transformAdministrationErrorResponse(error)))
            );
    }

    private transformAdministrationErrorResponse(errorResponse: HttpErrorResponse): AdministrationError {
        switch (errorResponse.status) {
            case HttpStatusCode.Conflict:
                return { type: AdministrationErrorType.InvalidApplicationVersion };
            default: {
                return { type: AdministrationErrorType.Unknown };
            }
        }
    }

    public removeInstitution(institutionId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.institutionDetailsManagement, { institutionId }))
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public getAnspTeamsList(filterParams: Params): Observable<AnspTeamsList> {
        return this.httpClient
            .get<AnspTeamsListResponseBody>(this.endpoints.getAnspTeamsList, {
                params: convertFilterParamsToHttpParams(filterParams),
            })
            .pipe(
                map((response) => convertAnspTeamsListResponseBodyToAnspTeamsList(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public getAnspTeamDetails(teamId: string): Observable<AnspTeamDetails> {
        return this.httpClient
            .get<AnspTeamDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.anspTeamDetailsManagement, { teamId }))
            .pipe(
                map((response) => convertAnspTeamDetailsResponseBodyToAnspTeamDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public assignAnspTeamMembers(teamId: string, userIds: string[]): Observable<AnspTeamDetails> {
        return this.httpClient
            .put<AnspTeamDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.assignAnspTeamMembers, { teamId }), {
                userIds,
            })
            .pipe(
                map((response) => convertAnspTeamDetailsResponseBodyToAnspTeamDetails(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public removeAnspTeamMember(teamId: string, userId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeAnspTeamMember, { teamId, userId }))
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public getTeamWorkCalendar(id: string): Observable<AnspTeamWorkCalendar> {
        return this.httpClient
            .get<AnspTeamWorkCalendarResponseBody>(StringUtils.replaceInTemplate(this.endpoints.anspTeamWorkCalendarManagement, { id }))
            .pipe(
                map((response) => convertAnspTeamWorkCalendarResponseBodyToAnspTeamWorkCalendar(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public updateTeamWorkCalendar(calendar: AnspStandardTeamWorkCalendar): Observable<AnspStandardTeamWorkCalendar> {
        return this.httpClient
            .put<WorkingCalendarResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.anspTeamWorkCalendarManagement, { id: calendar.id }),
                calendar
            )
            .pipe(
                map((response) => convertWorkingCalendarResponseBodyToAnspStandardTeamWorkCalendar(response)),
                catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown })))
            );
    }

    public addTeamDaysOff(daysOff: Date[], calendarId: string): Observable<void> {
        const body = getAddDaysOffRequestPayload(daysOff, calendarId);

        return this.httpClient
            .post<void>(this.endpoints.addTeamDaysOff, body)
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }

    public removeTeamDayOff(dayOffId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeTeamDayOff, { id: dayOffId }))
            .pipe(catchError(() => throwError(() => ({ type: AdministrationErrorType.Unknown }))));
    }
}
