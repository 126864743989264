import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ApplicationAssignment, FlightZoneApplicationReviewStatus } from "@dtm-frontend/dss-shared-lib";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ReviewStatusBadgeComponentState {
    assignment: ApplicationAssignment | undefined;
}

@Component({
    selector: "dss-admin-lib-review-status-badge[assignment]",
    templateUrl: "./review-status-badge.component.html",
    styleUrls: ["./review-status-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReviewStatusBadgeComponent {
    public readonly FlightZoneApplicationReviewStatus = FlightZoneApplicationReviewStatus;

    public readonly assignment$ = this.localStore.selectByKey("assignment");

    @Input() public set assignment(value: ApplicationAssignment | undefined) {
        this.localStore.patchState({ assignment: value });
    }

    constructor(private readonly localStore: LocalComponentStore<ReviewStatusBadgeComponentState>) {
        this.localStore.setState({
            assignment: undefined,
        });
    }
}
