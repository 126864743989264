import { METERS_IN_FOOT, METERS_IN_KILOMETER, METERS_IN_NAUTICAL_MILE } from "@dtm-frontend/shared/utils";
import { HorizontalMeasureUnits, MapUnitsOfMeasure, VerticalMeasureUnits } from "../models/flight-zone-shared.models";

export function otherUnitsOfMeasureToMeters(value: number, unit: MapUnitsOfMeasure): number {
    switch (unit) {
        case VerticalMeasureUnits.Feet:
            return value * METERS_IN_FOOT;
        case HorizontalMeasureUnits.NauticalMiles:
            return value * METERS_IN_NAUTICAL_MILE;
        case HorizontalMeasureUnits.Kilometers:
            return value * METERS_IN_KILOMETER;
        default:
            return value;
    }
}

/**
 * Converts selected units to meters and rounds value
 * @param value - original value
 * @param unit - unit of original value
 * @param decimalPlaces - number of decimal places
 * @returns rounded value meters
 */
export function otherUnitsOfMeasureToMetersRounded(value: number, unit: MapUnitsOfMeasure, decimalPlaces: number): number {
    // eslint-disable-next-line no-magic-numbers
    const roundingScale = Math.pow(10, decimalPlaces);

    return Math.round((otherUnitsOfMeasureToMeters(value, unit) + Number.EPSILON) * roundingScale) / roundingScale;
}
