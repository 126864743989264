<form [formGroup]="restrictionsFiltersForm" class="filters">
    <dtm-ui-expandable-panel [isExpanded]="true">
        <p headerSlot>
            <mat-slide-toggle
                labelPosition="after"
                class="spread allow-wrap"
                formControlName="allAirspaceStructures"
                (click)="$event.stopPropagation()"
            >
                <span class="heading">{{ "dssSharedLibFlightZone.activeRestrictionsFilters.airspaceStructuresLabel" | transloco }}</span>
            </mat-slide-toggle>
        </p>
        <div formGroupName="airspaceStructures" class="panel-content">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.CTR">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.CTR
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.MCTR">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.MCTR
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.TMA">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.TMA
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.MTMA">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.MTMA
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.TRA">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.TRA
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.TSA">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.TSA
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.R">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.R
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.P">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.P
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.D">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.D
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.MRT">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.MRT
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="AirspaceStructures.ATZ">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: AirspaceStructures.ATZ
                    }"
                ></ng-container>
            </mat-slide-toggle>
        </div>
    </dtm-ui-expandable-panel>

    <dtm-ui-expandable-panel [isExpanded]="true">
        <p headerSlot>
            <mat-slide-toggle
                labelPosition="after"
                class="spread allow-wrap"
                formControlName="allGeographicalZones"
                (click)="$event.stopPropagation()"
            >
                {{ "dssSharedLibFlightZone.activeRestrictionsFilters.geographicalZonesLabel" | transloco }}
            </mat-slide-toggle>
        </p>
        <div formGroupName="geographicalZones" class="panel-content">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="GeographicalZones.DRAR">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: GeographicalZones.DRAR
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="GeographicalZones.DRAP">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: GeographicalZones.DRAP
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="GeographicalZones.DRAI">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: GeographicalZones.DRAI
                    }"
                ></ng-container>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="GeographicalZones.DRAU">
                <ng-container
                    [ngTemplateOutlet]="toggleContent"
                    [ngTemplateOutletContext]="{
                        $implicit: GeographicalZones.DRAU
                    }"
                ></ng-container>
            </mat-slide-toggle>
        </div>
    </dtm-ui-expandable-panel>

    <dtm-ui-expandable-panel [isExpanded]="true">
        <p headerSlot>
            {{ "dssSharedLibFlightZone.activeRestrictionsFilters.heightLimitsLabel" | transloco }}
        </p>
        <div class="panel-content">
            <dtm-ui-input-field>
                <label>{{ "dssSharedLibFlightZone.activeRestrictionsFilters.upperHeightLimitLabel" | transloco }}</label>
                <input
                    formControlName="upperLimit"
                    matInput
                    [mask]="INTEGER_ONLY_MASK"
                    [placeholder]="'dssSharedLibFlightZone.activeRestrictionsFilters.unlimitedHeightPlaceholder' | transloco"
                />
                <div class="field-suffix">{{ "dssSharedLibFlightZone.activeRestrictionsFilters.heightLimitUnit" | transloco }}</div>
                <div class="field-error">
                    <ng-container
                        [ngTemplateOutlet]="heightErrorsTemplate"
                        [ngTemplateOutletContext]="{ formControl: restrictionsFiltersForm.controls.upperLimit }"
                    ></ng-container>
                </div>
            </dtm-ui-input-field>

            <dtm-ui-input-field>
                <label>{{ "dssSharedLibFlightZone.activeRestrictionsFilters.lowerHeightLimitLabel" | transloco }}</label>
                <input
                    formControlName="lowerLimit"
                    matInput
                    [mask]="INTEGER_ONLY_MASK"
                    [placeholder]="'dssSharedLibFlightZone.activeRestrictionsFilters.groundHeightPlaceholder' | transloco"
                />
                <div class="field-suffix">{{ "dssSharedLibFlightZone.activeRestrictionsFilters.heightLimitUnit" | transloco }}</div>
                <div class="field-error">
                    <ng-container
                        [ngTemplateOutlet]="heightErrorsTemplate"
                        [ngTemplateOutletContext]="{ formControl: restrictionsFiltersForm.controls.lowerLimit }"
                    ></ng-container>
                </div>
            </dtm-ui-input-field>
        </div>
    </dtm-ui-expandable-panel>
    <button type="button" class="button-secondary reset-button" (click)="resetFilters()">
        {{ "dssSharedLibFlightZone.activeRestrictionsFilters.resetButtonLabel" | transloco }}
    </button>
</form>

<ng-template #toggleContent let-type>
    <div class="toggle-content">
        <dtm-ui-icon name="complex-cylinder-fill" class="{{ type }}"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.activeRestrictionsFilters.typeControlLabel" | transloco : { value: type } }}
    </div>
</ng-template>

<ng-template #heightErrorsTemplate let-formControl="formControl">
    <div *ngIf="formControl.errors?.min">
        {{
            "dssSharedLibFlightZone.activeRestrictionsFilters.minHeightErrorHint"
                | transloco : { min: formControl.errors?.min.min | localizeNumber }
        }}
    </div>
    <div *ngIf="formControl.errors?.max">
        {{
            "dssSharedLibFlightZone.activeRestrictionsFilters.maxHeightErrorHint"
                | transloco : { max: formControl.errors?.max.max | localizeNumber }
        }}
    </div>
</ng-template>
