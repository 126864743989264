<div class="context-menu">
    <div *ngIf="data.isMinimumVertexNumber" (click)="data.onFinishCreate()" class="item">
        {{ "dssSharedLibFlightZone.mapContextMenu.finishCreateAction" | transloco }}
    </div>
    <div *ngIf="data.positionsCount > 1" (click)="data.onRemoveLastPoint()" class="item">
        {{ "dssSharedLibFlightZone.mapContextMenu.removeLastPointAction" | transloco }}
    </div>
    <div *ngIf="data.positionsCount > 0" (click)="data.onRemoveArea()" class="item">
        {{ "dssSharedLibFlightZone.mapContextMenu.removeAreaAction" | transloco }}
    </div>
</div>
