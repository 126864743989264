import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ApplicationType, FlightZoneListFilters, FlightZonesManagementListItem } from "@dtm-frontend/dss-shared-lib";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { distinctUntilChanged } from "rxjs";
import { FlightZonesManagementTableColumns, TableActionButton } from "../../models/flight-zone-management.models";
import { ApplicationKmlExportService } from "../../services/application-kml-export.service";
import { FlightZoneListsActions } from "../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../state/flight-zone-lists.state";
import { FlightZoneManagementState } from "../../state/flight-zone-management.state";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-accepted-applications-list-container",
    templateUrl: "./accepted-applications-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptedApplicationsListContainerComponent implements OnInit {
    public readonly displayedColumns: FlightZonesManagementTableColumns = [
        "expandHandle",
        "date",
        "startAt",
        "endAt",
        "applicationPurpose",
        "title",
        "actions",
    ];
    protected readonly actionButtons: TableActionButton[] = [TableActionButton.PreviewAction, TableActionButton.KmlDownload];
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly basePreviewRoutePath = `/${this.route.snapshot?.url[0].path}`;
    protected readonly isProcessing$ = this.store.select(FlightZoneManagementState.isProcessing);
    protected readonly acceptedApplicationsList$ = this.store.select(FlightZoneListsState.acceptedApplicationsList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly listFiltersCapabilities$ = this.store.select(FlightZoneListsState.listFiltersCapabilities);
    protected readonly institutionsFilterOptions$ = this.store.select(FlightZoneListsState.institutionsFilterOptions);

    private get applicationType(): ApplicationType {
        return this.route.snapshot.data.applicationType;
    }

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly applicationKmlExportService: ApplicationKmlExportService
    ) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
        this.store.dispatch(new FlightZoneListsActions.GetInstitutionsFilterOptions());
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected findInstitutionByText(searchValue?: string): void {
        this.store.dispatch(new FlightZoneListsActions.GetInstitutionsFilterOptions(searchValue));
    }

    protected clearFilterLists(): void {
        this.store.dispatch(new FlightZoneListsActions.ClearFilterLists());
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };
        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new FlightZoneListsActions.GetAcceptedApplicationsList(this.route.snapshot.queryParams, this.applicationType));
    }

    protected downloadKml(flightZone: FlightZonesManagementListItem): void {
        this.applicationKmlExportService.downloadKml(flightZone, this.applicationType);
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new FlightZoneListsActions.GetAcceptedApplicationsList(filterParams, this.applicationType));
        });
    }
}
