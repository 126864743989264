import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DssUserRoles } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { AnspTeamsListContainerComponent } from "./components/ansp-teams-list-container/ansp-teams-list-container.component";
import { InstitutionDetailContainerComponent } from "./components/institution-detail-container/institution-detail-container.component";
import { InstitutionsListContainerComponent } from "./components/institutions-list-container/institutions-list-container.component";
import { TeamWorkCalendarContainerComponent } from "./components/team-work-calendar-container/team-work-calendar-container.component";
import { UserDetailContainerComponent } from "./components/user-detail-container/user-detail-container.component";
import { UsersListContainerComponent } from "./components/users-list-container/users-list-container.component";
import { AdministrationCapabilitiesResolver } from "./services/administration-capabilities.resolver";
import { AdministrationClearInstitutionDetailsResolver } from "./services/administration-clear-institution-details.resolver";
import { AdministrationClearUserDetailsResolver } from "./services/administration-clear-user-details.resolver";
import {
    ADMINISTRATION_INSTITUTION_ID_ROUTE_PARAM_NAME,
    AdministrationInstitutionDetailsResolver,
} from "./services/administration-institution-details.resolver";
import {
    ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME,
    AdministrationUserDetailsResolver,
} from "./services/administration-user-details.resolver";
import {
    ADMINISTRATION_TEAM_ID_ROUTE_PARAM_NAME,
    AdministrationWorkCalendarResolver,
} from "./services/administration-work-calendar.resolver";

const routes: Routes = [
    {
        path: "admin/users",
        component: UsersListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.users",
            roles: [DssUserRoles.Administrator],
        },
    },
    {
        path: "admin/users/new",
        component: UserDetailContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.newUser",
            roles: [DssUserRoles.Administrator],
        },
        resolve: {
            initCapabilities: AdministrationCapabilitiesResolver,
            clearData: AdministrationClearUserDetailsResolver,
        },
    },
    {
        path: `admin/users/:${ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME}`,
        component: UserDetailContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.userDetails",
            roles: [DssUserRoles.Administrator],
        },
        resolve: {
            initCapabilities: AdministrationCapabilitiesResolver,
            initData: AdministrationUserDetailsResolver,
        },
    },
    {
        path: "admin/institutions",
        component: InstitutionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.institutions",
            roles: [DssUserRoles.Administrator],
        },
    },
    {
        path: "admin/institutions/new",
        component: InstitutionDetailContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.newInstitution",
            roles: [DssUserRoles.Administrator],
        },
        resolve: {
            clearData: AdministrationClearInstitutionDetailsResolver,
        },
    },
    {
        path: `admin/institutions/:${ADMINISTRATION_INSTITUTION_ID_ROUTE_PARAM_NAME}`,
        component: InstitutionDetailContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.institutionDetails",
            roles: [DssUserRoles.Administrator],
        },
        resolve: {
            initData: AdministrationInstitutionDetailsResolver,
        },
    },
    {
        path: "admin/ansp-teams",
        component: AnspTeamsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.anspTeams",
            roles: [DssUserRoles.Administrator],
        },
    },
    {
        path: `admin/ansp-teams/:${ADMINISTRATION_TEAM_ID_ROUTE_PARAM_NAME}/work-calendar`,
        component: TeamWorkCalendarContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibAdministration.routeTitles.workCalendar",
            roles: [DssUserRoles.Administrator],
        },
        resolve: {
            initData: AdministrationWorkCalendarResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [
        AdministrationCapabilitiesResolver,
        AdministrationUserDetailsResolver,
        AdministrationClearUserDetailsResolver,
        AdministrationInstitutionDetailsResolver,
        AdministrationClearInstitutionDetailsResolver,
        AdministrationWorkCalendarResolver,
    ],
    exports: [RouterModule],
})
export class AdministrationRoutingModule {}
