<dss-admin-lib-applications-management-table
    *ngrxLet="unassignedApplicationsList$ as unassignedApplicationsList"
    [actionButtons]="actionButtons$ | ngrxPush"
    [displayedColumns]="displayedColumns"
    [flightZoneApplications]="unassignedApplicationsList?.content"
    [page]="unassignedApplicationsList?.page"
    [isProcessing]="isProcessing$ | ngrxPush"
    [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
    [availableAssignees]="availableApplicationAssignees$ | ngrxPush"
    [basePreviewRoutePath]="basePreviewRoutePath"
    [currentUserId]="currentUserId$ | ngrxPush"
    (pageChange)="changePage($event)"
    (listRefresh)="refreshList()"
    (assigneeChange)="assigneeChange($event)"
    (kmlDownload)="downloadKml($event)"
></dss-admin-lib-applications-management-table>
