<div class="dialog-header">
    <h4 mat-dialog-title>{{ data.title }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
</div>

<div mat-dialog-content>
    <div class="editor-container">
        <ng-container *ngIf="textFormControl.enabled; else readOnlyNoteTemplate">
            <dtm-ui-quill-editor
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="textFormControl"
                [hasError]="textFormControl.invalid"
                [placeholder]="'dssSharedLibFlightZone.textEditorSectionDialog.placeholderMessage' | transloco"
            >
                <ng-template #customToolbarTemplate>
                    <span class="ql-formats" quill-editor-toolbar>
                        <button type="button" class="ql-bold"></button>
                        <button type="button" class="ql-italic"></button>
                        <button type="button" class="ql-underline"></button>
                        <button type="button" class="ql-strike"></button>

                        <button type="button" class="ql-list" value="ordered"></button>
                        <button type="button" class="ql-list" value="bullet"></button>
                    </span>
                </ng-template>

                <div class="editor-hint">{{ textFormControl.value?.length ?? 0 }} / {{ data.maxLength }}</div>

                <div class="field-error" *ngIf="textFormControl.errors?.maxlength as error">
                    {{ "dssSharedLibFlightZone.textEditorSectionDialog.maxLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-quill-editor>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{
            (data.isReadonly
                ? "dssSharedLibFlightZone.textEditorSectionDialog.closePreviewButtonLabel"
                : "dssSharedLibFlightZone.textEditorSectionDialog.cancelButtonLabel"
            ) | transloco
        }}
    </button>
    <button *ngIf="textFormControl.enabled" type="button" class="button-primary" (click)="confirm()">
        <dtm-ui-icon name="save"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.textEditorSectionDialog.confirmButtonLabel" | transloco }}
    </button>
</div>

<ng-template #readOnlyNoteTemplate>
    <div class="read-only-text">
        <div class="ql-editor" [innerHTML]="textFormControl.value"></div>
    </div>
</ng-template>
