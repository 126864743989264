import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { ConsultationData, FlightZoneApplicationReviewStatus } from "../../models/flight-zone-shared.models";

interface ConsultationsReviewComponentState {
    consultations: ConsultationData[];
    expandedConsultation: ConsultationData | null;
}

const MAX_TRUNCATED_COMMENT_LENGTH = 30;

@Component({
    selector: "dss-shared-lib-consultations-review[consultations]",
    templateUrl: "./consultations-review.component.html",
    styleUrls: ["./consultations-review.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConsultationsReviewComponent {
    protected readonly MAX_TRUNCATED_COMMENT_LENGTH = MAX_TRUNCATED_COMMENT_LENGTH;
    protected readonly FlightZoneApplicationReviewStatus = FlightZoneApplicationReviewStatus;
    protected readonly consultations$ = this.localStore.selectByKey("consultations");
    protected readonly expandedConsultation$ = this.localStore.selectByKey("expandedConsultation");

    @Input()
    public set consultations(value: ConsultationData[] | undefined) {
        this.localStore.patchState({ consultations: value ?? [] });
    }

    constructor(private readonly localStore: LocalComponentStore<ConsultationsReviewComponentState>) {
        this.localStore.setState({
            consultations: [],
            expandedConsultation: null,
        });
    }

    protected tryTruncateComment(comment: string, isExpanded: boolean): string {
        return isExpanded ? comment : StringUtils.ellipsifyText(comment, MAX_TRUNCATED_COMMENT_LENGTH);
    }

    protected toggleConsultationComment(consultation: ConsultationData): void {
        const currentlyExpandedConsultation = this.localStore.selectSnapshotByKey("expandedConsultation");

        this.localStore.patchState({ expandedConsultation: currentlyExpandedConsultation === consultation ? null : consultation });
    }
}
