import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ApplicationsForConsultationTableColumns } from "../../../models/flight-zone-consultation.models";
import { FlightZoneConsultationActions } from "../../../state/flight-zone-consultation.actions";
import { FlightZoneConsultationState } from "../../../state/flight-zone-consultation.state";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-consultations-in-progress-container",
    templateUrl: "./consultations-in-progress-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationsInProgressContainerComponent {
    protected readonly displayedColumns: ApplicationsForConsultationTableColumns = [
        "expandHandle",
        "date",
        "startAt",
        "endAt",
        "applicationPurpose",
        "title",
        "actions",
    ];

    protected readonly isProcessing$ = this.store.select(FlightZoneConsultationState.isProcessing);
    protected readonly consultationsInProgressList$ = this.store.select(FlightZoneConsultationState.consultationsInProgressList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneConsultationState.hasListDataRetrievalError);

    constructor(private readonly store: Store, private readonly route: ActivatedRoute, private readonly router: Router) {}

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    protected refreshList(): void {
        this.store.dispatch(new FlightZoneConsultationActions.GetConsultationsInProgressList(this.route.snapshot.queryParams));
    }
}
