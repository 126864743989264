import { FlightZoneApplicationPurpose } from "@dtm-frontend/dss-shared-lib";
import { Address, Page, PhoneNumber } from "@dtm-frontend/shared/ui";

export enum AdministrationErrorType {
    Unknown = "Unknown",
    InvalidApplicationVersion = "InvalidApplicationVersion",
}

export interface AdministrationError {
    type: AdministrationErrorType;
}

export type UsersListTableColumns = Array<keyof UsersListItem | "actions">;

export interface UsersList {
    content: UsersListItem[];
    page: Page;
}

export interface InstitutionsList {
    content: InstitutionsListItem[];
    page: Page;
}

export interface InstitutionsListItem {
    id: string;
    name: string;
    phoneNumber: PhoneNumber;
    address: Address;
    email: string;
    members?: InstitutionMember[];
    unitName?: string;
}

export interface InstitutionDetails extends NewInstitutionDetails {
    id: string;
    members: InstitutionMember[];
    version: number;
}

export interface NewInstitutionDetails {
    name: string;
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
    purposes: InstitutionPurpose[];
    unitName?: string;
}

export interface InstitutionPurpose {
    purpose: FlightZoneApplicationPurpose;
    hasStateSecurityRestriction: boolean;
}

export interface InstitutionMember {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    isSupervisor: boolean;
}

export interface UsersListItem {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    userType: DssUserType;
    isFlightsDirector: boolean;
    isAirOperationsCoordinator: boolean;
}

export interface UserDetails extends NewUserDetails {
    id: string;
    version: number;
}

export interface NewUserDetails {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    type: DssUserType;
    isFlightsDirector: boolean;
    isAirOperationsCoordinator: boolean;
}

export interface AdministrationCapabilities {
    types: DssUserType[];
}

export interface AnspTeamsList {
    content: AnspTeamsListItem[];
    page: Page;
}

export interface AnspTeamsListItem {
    id: string;
    name: string;
    members?: InstitutionMember[];
    restrictionPurposes?: FlightZoneApplicationPurpose[];
}

export interface AnspTeamDetails {
    id: string;
    name: string;
    members: InstitutionMember[];
    restrictionPurposes: FlightZoneApplicationPurpose[];
    version: number;
}

export interface AnspStandardTeamWorkCalendar {
    id: string;
    teamName: string;
    teamId: string;
    days: WorkCalendarDays[];
    startAt: Date;
    endAt: Date;
}

export interface AnspTeamDayOff {
    id: string;
    date: Date;
}

export interface AnspTeamWorkCalendar {
    standardTeamCalendar: AnspStandardTeamWorkCalendar;
    daysOff: AnspTeamDayOff[];
}

export enum WorkCalendarDays {
    Monday = "MONDAY",
    Tuesday = "TUESDAY",
    Wednesday = "WEDNESDAY",
    Thursday = "THURSDAY",
    Friday = "FRIDAY",
    Saturday = "SATURDAY",
    Sunday = "SUNDAY",
}

export enum DssUserType {
    InstitutionSubordinate = "INSTITUTION_SUBORDINATE",
    InstitutionSupervisor = "INSTITUTION_SUPERVISOR",
    AnspSubordinate = "ANSP_SUBORDINATE",
    AnspSupervisor = "ANSP_SUPERVISOR",
    NotamOffice = "NOTAM_OFFICE",
    Admin = "ADMIN",
}

export interface RemoveInstitutionMemberData {
    institution: InstitutionsListItem;
    member: InstitutionMember;
}

export interface RemoveAnspTeamMemberData {
    team: AnspTeamsListItem;
    member: InstitutionMember;
}
