import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { FlightZoneConsultationActions } from "../state/flight-zone-consultation.actions";

export const FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME = "id";

@Injectable()
export class FlightZoneConsultationDataResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const consultationId: string = route.paramMap.get(FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME) ?? "";

        return this.store.dispatch(new FlightZoneConsultationActions.GetApplicationForConsultationData(consultationId));
    }
}
