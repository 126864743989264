import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { multipleEmailsValidator } from "@dtm-frontend/shared/ui";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AdditionalReceiversComponentState {
    emails: string | undefined;
    isFormExpanded: boolean;
    isProcessing: boolean;
    isReadOnly: boolean;
}

const EMAIL_SEPARATOR = ";";
const EMAIL_SPLIT_REG_EXP = new RegExp(`[\\s,${EMAIL_SEPARATOR}]+`);

@Component({
    selector: "dss-admin-lib-additional-receivers[emails]",
    templateUrl: "./additional-receivers.component.html",
    styleUrls: ["./additional-receivers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AdditionalReceiversComponent {
    protected readonly additionalReceiversForm = new FormGroup({
        emails: new FormControl<string>("", { validators: multipleEmailsValidator(EMAIL_SPLIT_REG_EXP) }),
    });
    protected readonly isFormExpanded$ = this.localStore.selectByKey("isFormExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly emails$ = this.localStore.selectByKey("emails");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set emails(value: string[] | undefined) {
        if (!Array.isArray(value)) {
            return;
        }

        const joinedValue = value.join(`${EMAIL_SEPARATOR} `);

        this.localStore.patchState({ emails: joinedValue });
        this.additionalReceiversForm.controls.emails.patchValue(joinedValue);
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Output() public receiversEmailsChange = new EventEmitter<string[]>();

    constructor(private readonly localStore: LocalComponentStore<AdditionalReceiversComponentState>) {
        this.localStore.setState({
            emails: undefined,
            isFormExpanded: false,
            isProcessing: false,
            isReadOnly: false,
        });
    }

    protected changeExpansionState(value: boolean): void {
        this.localStore.patchState({ isFormExpanded: value });
    }

    protected cancelChanges(): void {
        const lastSavedEmails = this.localStore.selectSnapshotByKey("emails");

        this.changeExpansionState(false);
        this.additionalReceiversForm.controls.emails.reset(lastSavedEmails);
    }

    protected submitForm(): void {
        this.additionalReceiversForm.markAllAsTouched();

        if (!this.additionalReceiversForm.valid) {
            return;
        }

        const value = this.additionalReceiversForm.controls.emails.value?.split(EMAIL_SPLIT_REG_EXP) ?? [];

        this.receiversEmailsChange.emit(value.filter(FunctionUtils.isTruthy));
        this.changeExpansionState(false);
    }
}
