import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface DetailedDurationComponentState {
    detailedDuration: string | undefined;
    isFormExpanded: boolean;
    isProcessing: boolean;
    isReadOnly: boolean;
}

const MAX_DETAILED_DURATION_LENGTH = 1000;

@Component({
    selector: "dss-admin-lib-detailed-duration[detailedDuration]",
    templateUrl: "./detailed-duration.component.html",
    styleUrls: ["./detailed-duration.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class DetailedDurationComponent {
    protected readonly MAX_DETAILED_DURATION_LENGTH = MAX_DETAILED_DURATION_LENGTH;
    protected readonly detailedDurationForm = new FormGroup({
        detailedDuration: new FormControl<string>("", {
            validators: Validators.maxLength(MAX_DETAILED_DURATION_LENGTH),
            nonNullable: true,
        }),
    });
    protected readonly isFormExpanded$ = this.localStore.selectByKey("isFormExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly detailedDuration$ = this.localStore.selectByKey("detailedDuration");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set detailedDuration(value: string | undefined) {
        this.localStore.patchState({ detailedDuration: value });
        this.detailedDurationForm.controls.detailedDuration.patchValue(value ?? "");
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Output() public detailedDurationChange = new EventEmitter<string>();

    constructor(private readonly localStore: LocalComponentStore<DetailedDurationComponentState>) {
        this.localStore.setState({
            detailedDuration: undefined,
            isFormExpanded: false,
            isProcessing: false,
            isReadOnly: false,
        });
    }

    protected changeExpansionState(value: boolean): void {
        this.localStore.patchState({ isFormExpanded: value });
    }

    protected cancelChanges(): void {
        const lastSavedDetailedDuration = this.localStore.selectSnapshotByKey("detailedDuration");

        this.changeExpansionState(false);
        this.detailedDurationForm.reset({ detailedDuration: lastSavedDetailedDuration });
    }

    protected submitForm(): void {
        if (!this.detailedDurationForm.valid) {
            return;
        }

        this.detailedDurationChange.emit(this.detailedDurationForm.controls.detailedDuration.value);
        this.changeExpansionState(false);
    }
}
