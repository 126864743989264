import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AnspTeamsListItem, InstitutionMember, RemoveAnspTeamMemberData } from "../../models/administration.models";

interface AnspTeamsListComponentState {
    teams: AnspTeamsListItem[];
    isProcessing: boolean;
    page: Page | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-ansp-teams-list[teams]",
    templateUrl: "./ansp-teams-list.component.html",
    styleUrls: ["./ansp-teams-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AnspTeamsListComponent {
    protected readonly teams$ = this.localStore.selectByKey("teams");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly page$ = this.localStore.selectByKey("page");

    @Input()
    public set teams(value: AnspTeamsListItem[] | undefined) {
        this.localStore.patchState({ teams: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ page: value });
    }

    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly moreDetailsLoad = new EventEmitter<string>();
    @Output() public readonly memberAdd = new EventEmitter<AnspTeamsListItem>();
    @Output() public readonly memberRemove = new EventEmitter<RemoveAnspTeamMemberData>();

    constructor(
        private readonly localStore: LocalComponentStore<AnspTeamsListComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            teams: [],
            isProcessing: false,
            page: undefined,
        });
    }

    protected tryRemoveMember(member: InstitutionMember, team: AnspTeamsListItem): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.removeMemberDialogTitle", {
                        userName: `${member.firstName} ${member.lastName}`,
                    }),
                    confirmationText: this.translocoService.translate(
                        "dssAdminLibAdministration.anspTeamsList.removeMemberDialogConfirmationText"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "dssAdminLibAdministration.anspTeamsList.confirmMemberRemovalLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.memberRemove.emit({ member, team }));
    }
}
