import { SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { GeoJSON, Page, PhoneNumber } from "@dtm-frontend/shared/ui";

export interface FlightZoneError {
    type: FlightZoneErrorType;
    messageCode?: ErrorMessageCode;
    messageKey?: string;
    args?: Record<string, unknown>;
}

export enum FlightZoneErrorType {
    Unknown = "FlightZoneErrorUnknown",
    CannotGetCapabilities = "FlightZoneCannotGetCapabilities",
    NotAuthorized = "FlightZoneNotAuthorized",
    NotFound = "FlightZoneNotFound",
    InvalidApplicationVersion = "FlightZoneInvalidApplicationVersion",
    InvalidApplicationStartDate = "FlightZoneInvalidApplicationStartDate",
    CannotSubmitApplicationDraft = "FlightZoneCannotSubmitApplicationDraft",
    CannotSubmitApplication = "FlightZoneCannotSubmitApplication",
    CannotReviewApplication = "FlightZoneCannotReviewApplication",
    CannotUpdateApplicationNote = "CannotUpdateApplicationNote",
    CannotGetApplication = "FlightZoneCannotGetApplication",
    CannotDeleteApplication = "FlightZoneCannotDeleteApplication",
    CannotConfirmCorrections = "FlightZoneCannotConfirmCorrections",
    CannotToggleLockedStatus = "CannotToggleLockedStatus",
    CannotRejectReview = "CannotRejectReview",
    CannotGetApplicationList = "CannotGetApplicationList",
    CannotRejectApplication = "FlightZoneCannotRejectApplication",
    CannotChangeAssignee = "FlightZoneCannotChangeAssignee",
    CannotGetNotam = "FlightZoneCannotGetNotam",
    CannotAssignNotamNumber = "FlightZoneCannotAssignNotamNumber",
    CannotGetChatMessages = "FlightZoneCannotGetChatMessages",
    CannotPostChatMessage = "FlightZoneCannotPostChatMessage",
    CannotUpdateLocation = "CannotUpdateLocation",
    CannotChangeZoneDuration = "CannotChangeZoneDuration",
    CannotAssignConsultant = "CannotAssignConsultant",
    CannotUpdateAnspCaseNumber = "CannotUpdateAnspCaseNumber",
    ConsultantAlreadyAssigned = "ConsultantAlreadyAssigned",
    CannotChangeRestrictionType = "CannotChangeRestrictionType",
    CannotChangeAnspTeam = "CannotChangeAnspTeam",
    CannotChangeDetailedDuration = "CannotChangeDetailedDuration",
    CannotChangeAdditionalReceivers = "CannotChangeAdditionalReceivers",
    CannotChangeAirspaceClassification = "CannotChangeAirspaceClassification",
    CannotDownloadRestrictionPdf = "CannotDownloadRestrictionPdf",
    CannotDownloadConfirmationOfApplicationSubmissionPdf = "CannotDownloadConfirmationOfApplicationSubmissionPdf",
    CannotSetCustomDesignator = "CannotSetCustomDesignator",
    CannotRemoveCustomDesignator = "CannotRemoveCustomDesignator",
    CannotGetZoneGeoJson = "CannotGetZoneGeoJson",
    GeoZonesSystemError = "GeoZonesSystemError",
    InvalidZoneBoundaryError = "InvalidZoneBoundaryError",
}

export enum ErrorMessageCode {
    AlreadyExistRestrictionModificationApplication = "ALREADY_EXIST_RESTRICTION_MODIFICATION_APPLICATION",
}

export interface FlightZoneApplicationDraftListItem {
    id: string;
    status: FlightZoneApplicationStatus;
    applicationPurpose: FlightZoneApplicationPurpose;
    title: string;
    author: ApplicationAuthor;
    stateSecurityRestriction: boolean;
    startAt: Date;
    endAt: Date;
    isLocked: boolean;
    caseNumber?: string;
}

export interface FlightZoneApplicationsListWithExpandableRowComponentState<T> extends FlightZoneApplicationsListComponentState<T> {
    expandedElement: T | null;
}

export interface FlightZoneApplicationsListComponentState<T> {
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    flightZoneApplications: T[];
}

export interface ApplicationAssignee {
    firstName: string;
    lastName: string;
    id: string;
    email: string;
}

export interface ApplicationAuthor {
    id: string;
    fullName: string;
    email: string;
    phoneNumber: PhoneNumber;
}

export interface Institution {
    id: string;
    name: string;
}

export interface FlightZoneCapabilities {
    preferences: FlightZonePreferences;
    geometryConstraints: FlightZoneGeometryConstraints;
    exclusionsConstraints: FlightZoneExclusionsConstraints;
    availableEditors: FlightZoneEditorType[];
    flightZonePurposes: PurposeCapability[];
    eLearningCourses: FlightZoneSelectOption<string>[];
    operatorPermissions: string[];
    defaultInstitutionContact: InstitutionContact;
    maxZoneDurations: MaxZoneDuration[];
}

export interface PurposeCapability {
    id: string;
    purpose: FlightZoneApplicationPurpose;
    hasStateSecurityRestriction: boolean;
}

export interface MaxZoneDuration {
    periodInDays: number;
    purpose: FlightZoneApplicationPurpose;
}

export interface InstitutionContact {
    email: string;
    phoneNumber: PhoneNumber;
}

export interface FlightZonePreferences {
    initialViewbox: GeoJSON;
}

export interface FlightZoneExclusionsConstraints {
    uavMtomExclusion: {
        minValue: number;
        maxValue: number;
    };
    otherExclusionMaxFieldLength: number;
}

export interface FlightZoneApplication {
    basicDataForm: FlightZoneApplicationBasicData | undefined;
    restrictionAreaGeometry: RestrictionArea;
    restrictionExclusions: RestrictionExclusions | undefined;
    analysisStatus: FlightZoneAnalysisStatus | undefined;
    flightZoneId: string | undefined;
    status: FlightZoneApplicationStatus | undefined;
    note: string | undefined;
    location: NotamLocation | undefined;
    anspCaseNumber: string | undefined;
    restrictionModification: RestrictionModificationData | undefined;
    applicationType: ApplicationType;
    additionalReceivers: string[] | undefined;
    detailedDuration: string | undefined;
    airspaceClassification: string | undefined;
    consultations?: ConsultationData[];
    notamPreviewId?: string;
}

export interface FlightZoneRestriction {
    basicData: FlightZoneApplicationBasicData;
    restrictionAreaGeometry: RestrictionArea;
    restrictionExclusions: RestrictionExclusions;
    analysisStatus: FlightZoneAnalysisStatus;
    id: string;
    applicationId: string;
    lastApplicationId: string;
    note: string | undefined;
}

export interface RestrictionModificationData {
    startAt: Date;
    endAt: Date;
}

export interface ConsultationData {
    id: string;
    createdDate: Date;
    consultant: ApplicationAssignee;
    status?: FlightZoneApplicationReviewStatus;
    comment?: string;
}

export interface FlightZoneApplicationBasicData {
    flightZonePurpose: FlightZoneApplicationPurpose;
    caseNumber: string;
    title: string;
    startTime: Date;
    endTime: Date;
    hasStateSecurityRestriction?: boolean;
    isPreTacticalApproval: boolean;
    customDesignator?: number;
    isBeingCancelled?: boolean;
    isBeingModified?: boolean;
    description?: string;
}

export type RestrictionArea = CylinderRestrictionArea | PrismRestrictionArea;

export interface CylinderRestrictionArea {
    areaValues: RestrictionAreaCylinderValues | undefined;
    areaUnits: RestrictionAreaCylinderUnits;
    editorType: FlightZoneEditorType.Cylinder | undefined;
}

export interface PrismRestrictionArea {
    areaValues: RestrictionAreaPrismValues | undefined;
    areaUnits: RestrictionAreaPrismUnits;
    editorType: FlightZoneEditorType.Prism | undefined;
}

export interface RestrictionAreaCylinderValues {
    centerPointLongitude: number;
    centerPointLatitude: number;
    radius: number;
    lowerHeight: number;
    upperHeight: number;
}

export interface RestrictionAreaPrismValues {
    lowerHeight: number;
    upperHeight: number;
    positions: SerializableCartographic[];
}

export type RestrictionAreaUnits = RestrictionAreaCylinderUnits | RestrictionAreaPrismUnits;

export interface RestrictionAreaCylinderUnits extends RestrictionAreaPrismUnits {
    radiusUnit: HorizontalMeasureUnits;
}

export interface RestrictionAreaPrismUnits {
    lowerHeightUnit: VerticalMeasureUnits;
    lowerHeightReference: HeightReferences;
    upperHeightUnit: VerticalMeasureUnits;
    upperHeightReference: HeightReferences;
}

interface BaseRestrictionExclusions {
    isManagedByGovernment: boolean;
    agreedExclusion: {
        phoneNumber: PhoneNumber;
        email: string;
    } | null;
    areOwnedFlights: boolean;
    uavMtomExclusion: UavMtomExclusion | null;
    courses: FlightZoneSelectOption<string>[];
    otherExclusion: string;
}

export interface UavMtomExclusion {
    from: number;
    to: number;
}

export interface UavRestrictionExclusions extends BaseRestrictionExclusions {
    purpose: FlightZoneApplicationPurpose.ForbiddingUAVFlights;
    operatorAuthorizations: string[];
}

export interface GeneralAviationRestrictionExclusions extends BaseRestrictionExclusions {
    purpose: FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights;
    isWithoutEngine: boolean;
    isWithInstrumentalProcedures: boolean;
    isSpecialStatusFlights: boolean;
}

export interface SecuringOwnFlightsRestrictionExclusions extends BaseRestrictionExclusions {
    purpose: FlightZoneApplicationPurpose.SecuringOwnFlights;
    isWithoutEngine: boolean;
    isWithInstrumentalProcedures: boolean;
}

export type RestrictionExclusions =
    | UavRestrictionExclusions
    | GeneralAviationRestrictionExclusions
    | SecuringOwnFlightsRestrictionExclusions;

export interface FlightZoneAnalysisStatus {
    id: string;
    version: number;
    isLocked: boolean;
    isRestrictionTypeAssociatedWithNotam: boolean;
    suggestedRestrictionType?: RestrictionType;
    assignment?: ApplicationAssignment;
    designator?: string;
    notamNumber?: string;
    altitudesConvertedToAMSLFeet: AltitudesConvertedToAMSLFeet;
}

export interface AltitudesConvertedToAMSLFeet {
    lowerAltitude: number | undefined;
    upperAltitude: number | undefined;
}

export interface ApplicationAssignment {
    id: string;
    reviewer: ApplicationAssignee;
    version: number;
    reviewStatus?: FlightZoneApplicationReviewStatus;
}

export interface FlightZoneMapBaseGeometry {
    constraints: FlightZoneGeometryConstraints | undefined;
    horizontalMeasureUnits: HorizontalMeasureUnits[];
    verticalMeasureUnits: VerticalMeasureUnits[];
    heightReferences: HeightReferences[];
}

export enum HorizontalMeasureUnits {
    NauticalMiles = "NM",
    Kilometers = "km",
}

export enum VerticalMeasureUnits {
    Feet = "ft",
    Meters = "m",
}

export type MapUnitsOfMeasure = HorizontalMeasureUnits | VerticalMeasureUnits;

export enum HeightReferences {
    AboveGroundLevel = "AGL",
    AboveMeanSeaLevel = "AMSL",
}

export interface FlightZoneSelectOption<T> {
    codeName: T;
    title: string;
}

export interface FlightZoneGeometryConstraints {
    default: EditorConstraints;
    min: EditorConstraints;
    max: EditorConstraints;
    maximumNumberOfPoints?: number;
}

export interface EditorConstraints {
    upperHeight: number;
    lowerHeight: number;
    size: number;
}

export interface FlightZoneApplicationListItem extends FlightZoneApplicationDraftListItem {
    isBeingCancelled: boolean;
    date?: Date;
    zoneNumber?: string;
    comment?: string;
    notam?: string;
}

export interface FlightZonesManagementListItem extends FlightZoneApplicationDraftListItem {
    date: Date;
    institution: string;
    assignee: string | null;
    isBeingCancelled: boolean;
    assigneeId?: string;
    zoneNumber?: string;
    notam?: string;
    comment?: string;
    anspCaseNumber?: string;
}

export interface FlightZonesConsultationListItem {
    id: string;
    applicationPurpose: FlightZoneApplicationPurpose;
    applicationType: ApplicationType;
    applicationId: string;
    title: string;
    author: ApplicationAuthor;
    date: Date;
    startAt: Date;
    endAt: Date;
    institution: string;
    assignee: string;
    caseNumber?: string;
}

export interface RestrictionsListItem {
    id: string;
    applicationPurpose: FlightZoneApplicationPurpose;
    title: string;
    author: ApplicationAuthor;
    isStateSecurityRestriction: boolean;
    startAt: Date;
    endAt: Date;
    institution: string;
    zoneNumber: string;
    isBeingModified: boolean;
    isBeingCancelled: boolean;
    applicationId: string;
    lastApplicationId: string;
    modifyingStartAt?: Date;
    modifyingEndAt?: Date;
    caseNumber?: string;
    notam?: string;
    anspCaseNumber?: string;
}

export interface NotamListItem {
    id: string;
    applicationId: string;
    title: string;
    type: ApplicationType;
    startAt: Date;
    endAt: Date;
    reviewer: ApplicationAssignee;
    designator: string;
    notamType: NotamType;
    notamNumber?: string;
}

export interface FlightZonesList<T> {
    content: T[];
    page: Page;
}

export type FlightZonesManagementList = FlightZonesList<FlightZonesManagementListItem>;

export type RestrictionsList = FlightZonesList<RestrictionsListItem>;

export type NotamList = FlightZonesList<NotamListItem>;

export type FlightZoneApplicationsList = FlightZonesList<FlightZoneApplicationListItem>;

export type FlightZoneApplicationsForConsultationList = FlightZonesList<FlightZonesConsultationListItem>;

export interface FlightZoneManagementListFiltersCapabilities {
    institutions: Institution[];
    anspEmployees: ApplicationAssignee[];
    flightZonePurposes: FlightZoneApplicationPurpose[];
}

export interface FlightZoneListFilters {
    search?: string | null;
    sendDate?: Date | null;
    startDate?: Date | null;
    endDate?: Date | null;
    institutionId?: string | null;
    author?: string | null;
    reviewerId?: string | null;
    status?: string | null;
    applicationPurpose?: FlightZoneApplicationPurpose | null;
    userId?: string | null;
}

export enum ApplicationListItemStatus {
    WaitingForPublication = "WaitingForPublication",
    Published = "Published",
    Rejected = "Rejected",
    Correction = "Correction",
    ForApproval = "ForApproval",
}

export enum ApplicationManagementListItemStatus {
    Analysis = "Analysis",
    Correction = "Correction",
    ForApproval = "ForApproval",
    Accepted = "Accepted",
}

export interface AssignmentCapabilities {
    restrictionApplicationType: string;
    modificationActivityApplicationType: string;
}

export interface NotamCapabilities {
    notamNumberSeries: string[];
}

export interface NotamData {
    notamId: string;
    nativeNotam: Notam;
    internationalNotam: Notam;
    centroid: {
        latitude: number;
        longitude: number;
    };
    centroidRadius: number;
    type: string;
}

export interface Notam {
    notamNumber: NotamNumber;
    value: string;
    language: string;
    previousNotamNumber?: string;
}

export interface NotamNumber {
    notamSeries: string;
    number: string;
}

export enum FlightZoneEditorType {
    Cylinder = "CYLINDER",
    Prism = "PRISM",
}

export interface FlightZoneApplicationChatMessage {
    id: string;
    flightZoneId: string;
    content: string;
    author: ApplicationAssignee;
    createdAt: Date;
}

export interface NotamLocation {
    code: string;
    name: string;
}

export interface AnspTeam {
    id: string;
    name: string;
    applicationPurposes: FlightZoneApplicationPurpose[];
    members: ApplicationAssignee[];
}

export interface AutocompleteListFilterElement {
    id: string;
    name: string;
}

export interface DesignatorRange {
    prefix: string;
    from: number;
    to: number;
}

export enum FlightZoneApplicationStatus {
    Draft = "DRAFT",
    New = "NEW",
    SentToANSP = "SENT_TO_ANSP",
    Rejected = "REJECTED",
    Assigned = "ASSIGNED",
    Reviewed = "REVIEWED",
    Accepted = "ACCEPTED",
    Published = "PUBLISHED",
}

export enum FlightZoneApplicationPurpose {
    ForbiddingUAVFlights = "FORBIDDING_UAV_FLIGHTS",
    ForbiddingUAVAndGeneralAviationFlights = "FORBIDDING_GA_FLIGHTS",
    SecuringOwnFlights = "SECURING_OWN_FLIGHTS",
    PriorityToImplicitOperation = "GIVING_PRIORITY_TO_IMPLICIT_OPERATION",
}

export enum RestrictionType {
    TSA = "TSA",
    TRA = "TRA",
    R = "R",
    DRAR = "DRAR",
    DRAP = "DRAP",
}

export enum DssUserRoles {
    AnspSupervisor = "ansp-accept-restriction-application",
    AnspEmployee = "ansp-check-restriction-application",
    InstitutionSupervisor = "institution-accept-restriction-application",
    ManageNotam = "manage-notam",
    Administrator = "represent-admin",
}

export enum FlightZoneApplicationReviewStatus {
    Approve = "APPROVE",
    Disapprove = "DISAPPROVE",
}

export enum ApplicationType {
    RestrictionApplication = "RESTRICTION_APPLICATION",
    RestrictionModificationApplication = "RESTRICTION_MODIFICATION_APPLICATION",
}

export enum NotamType {
    CreateRestriction = "CREATE_RESTRICTION",
    ModifyRestriction = "MODIFY_RESTRICTION",
    EndRestriction = "END_RESTRICTION",
}
