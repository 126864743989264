<ng-container *ngrxLet="{ isReadOnly: isReadOnly$, note: note$ } as vm">
    <section class="note">
        <dtm-ui-presentation-tile-with-action
            [titleTile]="'dssSharedLibFlightZone.applicationNote.header' | transloco"
            [class.read-only]="vm.isReadOnly"
        >
            <p *ngIf="vm.isReadOnly && !vm.note?.length" class="note-preview">
                {{ "dssSharedLibFlightZone.applicationNote.sectionNotFilledMessage" | transloco }}
            </p>
            <button
                *ngIf="vm.note?.length || !vm.isReadOnly"
                actionButtonSlot
                class="button-secondary"
                type="button"
                (click)="openNoteDialog()"
            >
                <ng-container [ngSwitch]="vm.isReadOnly">
                    <ng-container *ngSwitchCase="true">
                        <dtm-ui-icon name="eye"></dtm-ui-icon>
                        {{ "dssSharedLibFlightZone.applicationNote.previewNoteButtonLabel" | transloco }}
                    </ng-container>

                    <ng-container *ngSwitchCase="false" [ngSwitch]="!!vm.note?.length">
                        <ng-container *ngSwitchCase="true">
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationNote.editNoteButtonLabel" | transloco }}
                        </ng-container>

                        <ng-container *ngSwitchCase="false">
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationNote.addNoteButtonLabel" | transloco }}
                        </ng-container>
                    </ng-container>
                </ng-container>
            </button>
            <div class="note-preview" *ngIf="vm.note">
                {{ vm.note | invoke : formatNoteForPreview }}
            </div>
        </dtm-ui-presentation-tile-with-action>
    </section>
</ng-container>
