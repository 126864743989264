import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogService, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, first, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { AnspTeamsListItem, RemoveAnspTeamMemberData, UsersListItem } from "../../models/administration.models";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";
import { AddMemberDialogComponent } from "../add-member-dialog/add-member-dialog.component";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-ansp-teams-list-container",
    templateUrl: "./ansp-teams-list-container.component.html",
    styleUrls: ["./ansp-teams-list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnspTeamsListContainerComponent implements OnInit {
    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly anspTeamsList$ = this.store.select(AdministrationState.anspTeamsList);
    protected readonly hasListDataRetrievalError$ = this.store.select(AdministrationState.listDataRetrievalError).pipe(map(Boolean));

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {}

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };
        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new AdministrationActions.GetAnspTeamsList(this.route.snapshot.queryParams));
    }

    protected loadMoreDetails(institutionId: string): void {
        this.store.dispatch(new AdministrationActions.GetAnspTeamDetails(institutionId));
    }

    protected addMember(team: AnspTeamsListItem): void {
        this.store
            .dispatch(AdministrationActions.GetAvailableAnspTeamMembers)
            .pipe(
                tap(() => {
                    if (this.store.selectSnapshot(AdministrationState.getAvailableMembersError)) {
                        this.toastrService.error(
                            this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.getAvailableMembersErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(AdministrationState.availableAnspTeamMembers)),
                first(),
                switchMap((availableMembers) =>
                    this.dialogService.open(AddMemberDialogComponent, { data: { availableMembers } }).afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((member: UsersListItem) => this.store.dispatch(new AdministrationActions.AddAnspTeamMember(team, member.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.addAnspTeamMemberError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.addMemberErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.addMemberSuccessMessage")
                );
            });
    }

    protected removeMember(data: RemoveAnspTeamMemberData): void {
        this.store
            .dispatch(new AdministrationActions.RemoveAnspTeamMember(data.team, data.member.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.removeAnspTeamMemberError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.removeMemberErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dssAdminLibAdministration.anspTeamsList.removeMemberSuccessMessage")
                );
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new AdministrationActions.GetAnspTeamsList(filterParams));
        });
    }
}
