<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dssSharedLibFlightZone.applicationNote.applicationNoteDialog.header" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
</div>
<div mat-dialog-content>
    <div class="dialog-description" *ngIf="data.dialogDescription">
        {{ data.dialogDescription }}
    </div>
    <div class="editor-container">
        <ng-container *ngIf="applicationNoteControl.enabled; else readOnlyNoteTemplate">
            <dtm-ui-quill-editor
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="applicationNoteControl"
                [hasError]="applicationNoteControl.invalid"
                [placeholder]="'dssSharedLibFlightZone.applicationNote.applicationNoteDialog.newNotePlaceholder' | transloco"
            >
                <ng-template #customToolbarTemplate>
                    <span class="ql-formats" quill-editor-toolbar>
                        <button type="button" class="ql-bold"></button>
                        <button type="button" class="ql-italic"></button>
                        <button type="button" class="ql-underline"></button>
                        <button type="button" class="ql-strike"></button>

                        <button type="button" class="ql-list" value="ordered"></button>
                        <button type="button" class="ql-list" value="bullet"></button>
                    </span>
                </ng-template>

                <div class="editor-hint">{{ applicationNoteControl.value?.length ?? 0 }} / {{ MAX_NOTE_LENGTH }}</div>

                <div class="field-error" *ngIf="applicationNoteControl.errors?.required">
                    {{ "dssSharedLibFlightZone.applicationNote.applicationNoteDialog.emptyNoteError" | transloco }}
                </div>
                <div class="field-error" *ngIf="applicationNoteControl.errors?.maxlength as error">
                    {{
                        "dssSharedLibFlightZone.applicationNote.applicationNoteDialog.maxLengthErrorHint"
                            | transloco : { max: error.requiredLength }
                    }}
                </div>
            </dtm-ui-quill-editor>

            <div class="suggestions-description">
                {{ "dssSharedLibFlightZone.applicationNote.autoSuggestions.suggestionsDescription" | transloco }}
            </div>
            <div class="auto-suggest-buttons">
                <button
                    type="button"
                    class="button-secondary"
                    *ngFor="let autoSuggestButton of autoSuggestButtons"
                    [matTooltip]="autoSuggestButton.suggestionText | invoke : formatTooltip"
                    (click)="addAutoSuggestion(autoSuggestButton.suggestionText)"
                >
                    {{ autoSuggestButton.label }}
                </button>
            </div>
        </ng-container>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssSharedLibFlightZone.applicationNote.applicationNoteDialog.cancelButtonLabel" | transloco }}
    </button>
    <button
        type="button"
        class="button-primary"
        [disabled]="data.isProcessing$ | ngrxPush"
        (click)="onConfirmClick()"
        *ngIf="applicationNoteControl.enabled"
    >
        <dtm-ui-icon name="save"></dtm-ui-icon>
        {{ data.confirmButtonLabel ?? "dssSharedLibFlightZone.applicationNote.applicationNoteDialog.confirmButtonLabel" | transloco }}
    </button>
</div>

<ng-template #readOnlyNoteTemplate>
    <div class="read-only-note">
        <div class="ql-editor" [innerHTML]="applicationNoteControl.value"></div>
    </div>
</ng-template>
