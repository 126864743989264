import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FlightZoneCapabilitiesResolver } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { DashboardComponent } from "./components/dashboard/dashboard.component";

const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [DtmRoleGuard],
        data: { titleTranslationKey: "dssAdminLibDashboard.routeTitles.dashboard" },
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [FlightZoneCapabilitiesResolver],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
