<dtm-ui-loader-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        getInstitutionDetailsError: getInstitutionDetailsError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <dtm-ui-error
        *ngIf="vm.getInstitutionDetailsError; else institutionDetailTemplate"
        class="grid"
        (reload)="refreshData()"
        [errorMessage]="'dssAdminLibAdministration.institutionDetail.getDataError' | transloco"
    ></dtm-ui-error>

    <ng-template #institutionDetailTemplate>
        <dss-admin-lib-institution-detail
            class="grid"
            [institutionDetails]="institutionDetails$ | ngrxPush"
            (cancel)="cancel()"
            (institutionDetailSave)="saveInstitution($event)"
        >
        </dss-admin-lib-institution-detail>
    </ng-template>
</dtm-ui-loader-container>
