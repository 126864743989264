import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserProfileComponent, UserProfileResolver } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";

const routes: Routes = [
    {
        path: "user",
        component: UserProfileComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: UserProfileResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibUserProfile.routeTitles.userProfile",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [UserProfileResolver],
    exports: [RouterModule],
})
export class UserProfileRoutingModule {}
