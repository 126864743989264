import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FlightZoneError, FlightZoneErrorType, NotamNumber } from "@dtm-frontend/dss-shared-lib";
import { DialogService } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, lastValueFrom } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME } from "../../services/flight-zone-management.resolvers";
import { FlightZoneManagementActions } from "../../state/flight-zone-management.actions";
import { FlightZoneManagementState } from "../../state/flight-zone-management.state";
import { NotamNumberConfirmDialogComponent } from "./notam-number-confirm-dialog/notam-number-confirm-dialog.component";

interface NotamEditComponentState {
    flightZoneId: string | null;
}

interface NotamValuesForm {
    nativeNotamValue: FormControl<string | null>;
    internationalNotamValue: FormControl<string | null>;
}

const MAX_NOTAM_VALUE_LENGTH = 1000;
const ERROR_TOAST_TIMEOUT_MS = 10000;

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-notam-edit",
    templateUrl: "./notam-edit.component.html",
    styleUrls: ["./notam-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NotamEditComponent {
    protected readonly FlightZoneErrorType = FlightZoneErrorType;
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly MAX_NOTAM_VALUE_LENGTH = MAX_NOTAM_VALUE_LENGTH;
    protected readonly notamValuesForm = new FormGroup<NotamValuesForm>({
        nativeNotamValue: new FormControl("", { validators: Validators.required }),
        internationalNotamValue: new FormControl("", { validators: Validators.required }),
    });

    protected readonly isProcessing$ = this.store.select(FlightZoneManagementState.isProcessing);
    protected readonly notamData$ = this.store.select(FlightZoneManagementState.notamData);
    protected readonly error$ = this.store.select(FlightZoneManagementState.error);

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<NotamEditComponentState>,
        private readonly dialogService: DialogService,
        private readonly toastService: ToastrService,
        private readonly translationHelper: TranslationHelperService,
        private readonly transloco: TranslocoService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.localStore.setState({
            flightZoneId: this.route.snapshot.paramMap.get(FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME),
        });

        this.updateFormOnNotamChanges();
        this.updateNotamDataOnFormChanges();
    }

    protected async assignNotamNumber(notamId: string | undefined): Promise<void> {
        const notamNumber = await this.getNotamNumberConfirmation();

        if (!notamNumber || !notamId) {
            return;
        }

        this.store
            .dispatch(new FlightZoneManagementActions.AssignNotamNumber(notamId, notamNumber))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneManagementState.error);

                if (error) {
                    this.displayErrorMessage(error);
                } else {
                    this.toastService.success(this.transloco.translate("dssAdminLibFlightZoneManagement.assignNotamNumberSuccessMessage"));
                    this.router.navigate(["flight-zone", "applications-waiting-for-notam"]);
                }
            });
    }

    private updateFormOnNotamChanges(): void {
        this.notamData$.pipe(RxjsUtils.filterFalsy(), distinctUntilChanged(equal), untilDestroyed(this)).subscribe((notamData) => {
            this.notamValuesForm.patchValue({
                nativeNotamValue: notamData.nativeNotam.value,
                internationalNotamValue: notamData.internationalNotam.value,
            });
        });
    }

    private updateNotamDataOnFormChanges(): void {
        this.notamValuesForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                withLatestFrom(this.store.select(FlightZoneManagementState.notamData).pipe(RxjsUtils.filterFalsy())),
                untilDestroyed(this)
            )
            .subscribe(([formValue, notamData]) => {
                this.store.dispatch(
                    new FlightZoneManagementActions.UpdateNotamData({
                        ...notamData,
                        nativeNotam: {
                            ...notamData.nativeNotam,
                            value: formValue.nativeNotamValue ?? "",
                        },
                        internationalNotam: {
                            ...notamData.internationalNotam,
                            value: formValue.internationalNotamValue ?? "",
                        },
                    })
                );
            });
    }

    protected formatDecimalCoordinatesForDisplay(decimalDegrees: number | undefined, coordinatesType: GeographicCoordinatesType): string {
        if (!decimalDegrees) {
            return "-";
        }

        const dms = DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(decimalDegrees, coordinatesType);

        return DmsCoordinatesUtils.convertDmsWithDirectionToStringWithoutSeconds(dms);
    }

    private async getNotamNumberConfirmation(): Promise<NotamNumber | null> {
        const dialogRef = this.dialogService.open(NotamNumberConfirmDialogComponent, {
            data: {
                notamSeries$: this.store.select(FlightZoneManagementState.notamNumberSeries),
            },
        });

        return lastValueFrom(dialogRef.afterClosed().pipe(untilDestroyed(this)));
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotAssignNotamNumber:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotAssignNotamNumberErrorMessage");
                break;
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.GeoZonesSystemError:
                errorMessage = error.messageKey && this.translationHelper.selectSystemTranslation(error.messageKey, error.args);
                break;
            default:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.genericErrorMessage");
        }

        this.toastService.error(errorMessage, undefined, { timeOut: ERROR_TOAST_TIMEOUT_MS });
    }
}
