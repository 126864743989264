<dss-admin-lib-applications-management-table
    *ngrxLet="applicationsAssignedToUserList$ as applicationsAssignedToUserList"
    [actionButtons]="actionButtons$ | ngrxPush"
    [displayedColumns]="displayedColumns"
    [flightZoneApplications]="applicationsAssignedToUserList?.content"
    [page]="applicationsAssignedToUserList?.page"
    [isProcessing]="isProcessing$ | ngrxPush"
    [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
    [availableAssignees]="availableApplicationAssignees$ | ngrxPush"
    [hasSupervisorPermissions]="hasSupervisorPermissions$ | ngrxPush"
    [basePreviewRoutePath]="basePreviewRoutePath"
    (pageChange)="changePage($event)"
    (listRefresh)="refreshList()"
    (assigneeChange)="changeAssignee($event)"
    (kmlDownload)="downloadKml($event)"
></dss-admin-lib-applications-management-table>
