<dtm-ui-loader-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        users: users$,
        page: page$,
        displayedColumns: displayedColumns$,
        hasDataRetrievalError: hasDataRetrievalError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <table mat-table multiTemplateDataRows [dataSource]="vm.users" class="dtm-table">
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.firstNameHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.lastNameHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.emailHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.phoneNumberHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.phoneNumber | formatPhoneNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="userType">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.userTypeHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="user-type-value">
                    <dtm-ui-content-popover
                        *ngIf="element.isAirOperationsCoordinator"
                        shouldOpenOnHover
                        [popoverText]="'dssAdminLibAdministration.usersList.airOperationsCoordinatorLabel' | transloco"
                    >
                        <dtm-ui-icon name="nurse"></dtm-ui-icon>
                    </dtm-ui-content-popover>
                    <dtm-ui-content-popover
                        *ngIf="element.isFlightsDirector"
                        shouldOpenOnHover
                        [popoverText]="'dssAdminLibAdministration.usersList.flightsDirectorLabel' | transloco"
                    >
                        <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
                    </dtm-ui-content-popover>
                    <span>{{ element.userType }}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssAdminLibAdministration.usersList.actionsHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                <button
                    type="button"
                    [matMenuTriggerFor]="rowActionsMenu"
                    [matMenuTriggerData]="{ $implicit: element }"
                    (click)="$event.stopPropagation()"
                    class="button-icon"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="vm.displayedColumns.length">
                <dtm-ui-error *ngIf="vm.hasDataRetrievalError; else emptyListTemplate" (reload)="listRefresh.emit()"> </dtm-ui-error>

                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="vm.displayedColumns.length">
                <dtm-ui-pagination *ngIf="vm.page as page" [pagination]="page" (page)="pageChange.emit($event)"> </dtm-ui-pagination>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="vm.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: vm.displayedColumns"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="vm.users.length || vm.isProcessing"
            class="no-results-row"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.users.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <a [routerLink]="element.id" mat-menu-item>
            <dtm-ui-icon name="user-settings"></dtm-ui-icon>
            {{ "dssAdminLibAdministration.usersList.editActionLabel" | transloco }}
        </a>
        <button type="button" mat-menu-item (click)="tryDeleteUser(element)">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            {{ "dssAdminLibAdministration.usersList.deleteActionLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>
