import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { AdministrationActions } from "../state/administration.actions";

export const ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME = "id";

@Injectable({
    providedIn: "root",
})
export class AdministrationUserDetailsResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const userId: string = route.paramMap.get(ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME) ?? "";

        return this.store.dispatch(new AdministrationActions.GetUserDetails(userId));
    }
}
