import { Injectable } from "@angular/core";
import {
    CameraHelperService,
    CylinderEditorLabelProviders,
    EntityEditorConstraints,
    MapEntitiesEditorContent,
    MapEntitiesEditorService,
    MapUtils,
    PrismEditorLabelProviders,
} from "@dtm-frontend/shared/map/cesium";
import { RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, Subscription, lastValueFrom } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { MapContextMenuComponent } from "../components/map-container/map-context-menu/map-context-menu.component";
import {
    CylinderRestrictionArea,
    FlightZoneEditorType,
    FlightZoneGeometryConstraints,
    PrismRestrictionArea,
    RestrictionArea,
} from "../models/flight-zone-shared.models";
import { otherUnitsOfMeasureToMeters } from "../utils/other-units-of-measure-to-meters";

export const FORBIDDING_UAV_FLIGHTS_PRISM_POINTS_LIMIT = 8;
export const DEFAULT_PRISM_POINTS_LIMIT = 20;
export const GEOMETRY_READ_ONLY_CONSTRAINTS: FlightZoneGeometryConstraints = {
    default: { upperHeight: 0, lowerHeight: 0, size: 0 },
    min: { upperHeight: 0, lowerHeight: 0, size: 0 },
    max: { upperHeight: 0, lowerHeight: 0, size: 0 },
    maximumNumberOfPoints: DEFAULT_PRISM_POINTS_LIMIT,
};

@Injectable()
export abstract class FlightZoneGeometryTranslationProvider {
    public abstract upperHeightLabelProvider(height: number): string | undefined;
    public abstract lowerHeightLabelProvider(height: number): string | undefined;
    public abstract cylinderEditorRadiusLabelProvider(radius: number): string | undefined;
}

@Injectable()
@UntilDestroy()
export class FlightZoneGeometryService {
    public readonly geometryContent$ = this.entitiesEditorService.editorContent$;
    public readonly mapReady$ = this.cameraHelperService.mapEntitiesReady$;
    private readonly editorTypeChangedSubject = new Subject<void>();
    private watchForFirstGeometryContentSubscription: Subscription | undefined;

    constructor(
        private readonly entitiesEditorService: MapEntitiesEditorService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly flightZoneGeometryTranslationProvider: FlightZoneGeometryTranslationProvider
    ) {}

    public async startOrEnableEditor(editorToStart: FlightZoneEditorType, constraints: FlightZoneGeometryConstraints): Promise<void> {
        if (this.entitiesEditorService.areEditorsDisabled && (await this.isGeometryContentDefined())) {
            return this.entitiesEditorService.enableEditors();
        }

        this.editorTypeChangedSubject.next();
        const entityEditorConstraints = this.transformConstraints(constraints);

        switch (editorToStart) {
            case FlightZoneEditorType.Cylinder:
                this.entitiesEditorService.startCylinderEditor(
                    StringUtils.generateId(),
                    entityEditorConstraints,
                    this.createCylinderLabelProviders(),
                    {
                        radius: entityEditorConstraints.default.radius,
                        cylinderProps: {
                            areHeightsDraggable: true,
                        },
                    }
                );
                break;

            case FlightZoneEditorType.Prism:
                this.entitiesEditorService.startPrismEditor(
                    StringUtils.generateId(),
                    entityEditorConstraints,
                    this.createPrismLabelProviders(),
                    {
                        prismProps: {
                            areHeightsDraggable: true,
                        },
                        contextMenu: MapContextMenuComponent,
                    }
                );
                break;

            default:
                return;
        }

        this.entitiesEditorService.enableEditors();
        this.showEntireContentAfterFirstDraw();
    }

    public initMapWithEntity(restrictionAreaGeometry: RestrictionArea, constraints: FlightZoneGeometryConstraints): void {
        this.mapReady$.pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)).subscribe(async () => {
            this.createEntity(restrictionAreaGeometry, constraints);
            // NOTE: We need to wait for editor to draw geometry and then disable editors in next cycle
            await this.waitForNextCycle();
            this.disableEditors();
        });
    }

    public createEntity(restrictionAreaGeometry: RestrictionArea, constraints: FlightZoneGeometryConstraints): void {
        const transformedConstraints = this.transformConstraints(constraints);
        const editorType = restrictionAreaGeometry.editorType;

        switch (editorType) {
            case FlightZoneEditorType.Cylinder:
                this.createCylinderEntity(restrictionAreaGeometry, transformedConstraints);
                break;
            case FlightZoneEditorType.Prism:
                this.createPrismEntity(restrictionAreaGeometry, transformedConstraints);
                break;
            default:
                return;
        }

        this.entitiesEditorService.cancelActiveEntityDrawing();
        this.showEntireContentAfterFirstDraw();
    }

    public clearGeometry(): void {
        this.entitiesEditorService.stopEditors();
    }

    public async disableEditors(): Promise<void> {
        if (!(await this.isGeometryContentDefined())) {
            this.entitiesEditorService.stopEditors();
        }

        this.entitiesEditorService.disableEditors();
    }

    public update(content: MapEntitiesEditorContent): void {
        content.forEach((entity) => this.entitiesEditorService.update(entity));
    }

    public async showEntireContent(): Promise<void> {
        const content = await lastValueFrom(this.geometryContent$.pipe(first(), untilDestroyed(this)));

        this.cameraHelperService.flyToContent(content);
    }

    private showEntireContentAfterFirstDraw(): void {
        if (this.watchForFirstGeometryContentSubscription?.closed === false) {
            return;
        }

        this.watchForFirstGeometryContentSubscription = this.geometryContent$
            .pipe(
                first((content) => content.length > 0),
                untilDestroyed(this),
                takeUntil(this.editorTypeChangedSubject)
            )
            .subscribe(() => this.showEntireContent());
    }

    private createCylinderEntity(area: CylinderRestrictionArea, constraints: EntityEditorConstraints): void {
        const areaValues = area.areaValues;
        const areaUnits = area.areaUnits;

        if (!areaValues) {
            return;
        }

        const cylinderCenter = MapUtils.convertSerializableCartographicToCartesian3({
            latitude: areaValues.centerPointLatitude,
            longitude: areaValues.centerPointLongitude,
            height: otherUnitsOfMeasureToMeters(areaValues.lowerHeight, areaUnits.lowerHeightUnit),
        });

        constraints.default = {
            radius: otherUnitsOfMeasureToMeters(areaValues.radius, areaUnits.radiusUnit),
            topHeight: otherUnitsOfMeasureToMeters(areaValues.upperHeight, areaUnits.upperHeightUnit),
            bottomHeight: otherUnitsOfMeasureToMeters(areaValues.lowerHeight, areaUnits.lowerHeightUnit),
            startDelay: 0,
            horizontalNavigationAccuracy: 0,
            verticalNavigationAccuracy: 0,
        };

        this.entitiesEditorService.createEditableCylinder(
            cylinderCenter,
            StringUtils.generateId(),
            constraints,
            this.createCylinderLabelProviders(),
            constraints.default.radius,
            constraints.default.topHeight,
            {
                cylinderProps: {
                    areHeightsDraggable: true,
                },
            }
        );
    }

    private createPrismEntity(area: PrismRestrictionArea, constraints: EntityEditorConstraints): void {
        const areaValues = area.areaValues;
        const areaUnits = area.areaUnits;

        if (!areaValues) {
            return;
        }

        const positions = areaValues.positions.map((position) =>
            MapUtils.convertSerializableCartographicToCartesian3({
                latitude: position.latitude,
                longitude: position.longitude,
                height: otherUnitsOfMeasureToMeters(areaValues.lowerHeight, areaUnits.lowerHeightUnit),
            })
        );

        constraints.default = {
            topHeight: otherUnitsOfMeasureToMeters(areaValues.upperHeight, areaUnits.upperHeightUnit),
            bottomHeight: otherUnitsOfMeasureToMeters(areaValues.lowerHeight, areaUnits.lowerHeightUnit),
            radius: 0,
            startDelay: 0,
            horizontalNavigationAccuracy: 0,
            verticalNavigationAccuracy: 0,
        };

        this.entitiesEditorService.createEditablePrism(positions, StringUtils.generateId(), constraints, this.createPrismLabelProviders(), {
            prismProps: {
                areHeightsDraggable: true,
            },
        });
    }

    private createCylinderLabelProviders(): CylinderEditorLabelProviders {
        return {
            radius: this.flightZoneGeometryTranslationProvider.cylinderEditorRadiusLabelProvider.bind(
                this.flightZoneGeometryTranslationProvider
            ),
            topHeight: this.flightZoneGeometryTranslationProvider.upperHeightLabelProvider.bind(this.flightZoneGeometryTranslationProvider),
            bottomHeight: this.flightZoneGeometryTranslationProvider.lowerHeightLabelProvider.bind(
                this.flightZoneGeometryTranslationProvider
            ),
        };
    }

    private createPrismLabelProviders(): PrismEditorLabelProviders {
        return {
            topHeight: this.flightZoneGeometryTranslationProvider.upperHeightLabelProvider.bind(this.flightZoneGeometryTranslationProvider),
            bottomHeight: this.flightZoneGeometryTranslationProvider.lowerHeightLabelProvider.bind(
                this.flightZoneGeometryTranslationProvider
            ),
        };
    }

    private transformConstraints(constraints: FlightZoneGeometryConstraints): EntityEditorConstraints {
        return {
            default: {
                topHeight: constraints.default.upperHeight,
                bottomHeight: constraints.default.lowerHeight,
                radius: constraints.default.size,
                startDelay: 0,
                horizontalNavigationAccuracy: 0,
                verticalNavigationAccuracy: 0,
            },
            min: {
                topHeight: constraints.min.upperHeight,
                bottomHeight: constraints.min.lowerHeight,
                radius: constraints.min.size,
                startDelay: 0,
                horizontalNavigationAccuracy: 0,
                verticalNavigationAccuracy: 0,
            },
            max: {
                topHeight: constraints.max.upperHeight,
                bottomHeight: constraints.max.lowerHeight,
                radius: constraints.max.size,
                startDelay: 0,
                horizontalNavigationAccuracy: 0,
                verticalNavigationAccuracy: 0,
            },
            maximumNumberOfPoints: constraints.maximumNumberOfPoints ?? DEFAULT_PRISM_POINTS_LIMIT,
        };
    }

    private async waitForNextCycle(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve);
        });
    }

    private async isGeometryContentDefined(): Promise<boolean> {
        return (await lastValueFrom(this.geometryContent$.pipe(first(), untilDestroyed(this)))).length !== 0;
    }
}
