import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ApplicationAssignee } from "@dtm-frontend/dss-shared-lib";
import { TranslocoService } from "@jsverse/transloco";

@Component({
    selector: "dss-admin-lib-assignee-confirm-dialog",
    templateUrl: "./assignee-confirm-dialog.component.html",
    styleUrls: ["./assignee-confirm-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneeConfirmDialogComponent {
    protected readonly assigneeFormControl = new FormControl<ApplicationAssignee | null>(null, [Validators.required]);
    protected selectLabel = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { availableAssignees: ApplicationAssignee[]; label?: string },
        private readonly dialogRef: MatDialogRef<AssigneeConfirmDialogComponent>,
        private readonly transloco: TranslocoService
    ) {
        this.selectLabel =
            data.label || this.transloco.translate("dssAdminLibFlightZoneManagement.assigneeConfirmDialog.selectAssigneeLabel");
    }

    public onConfirmClick(): void {
        this.assigneeFormControl.markAllAsTouched();

        if (this.assigneeFormControl.invalid) {
            return;
        }

        this.dialogRef.close(this.assigneeFormControl.value);
    }
}
