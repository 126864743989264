import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import {
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    FlightZoneRestriction,
    FlightZoneRestrictionActions,
    FlightZoneRestrictionState,
    GEOMETRY_READ_ONLY_CONSTRAINTS,
} from "@dtm-frontend/dss-shared-lib";
import { CameraHelperService } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { finalize, switchMap } from "rxjs";
import { NotamModalService } from "../../services/notam-modal.service";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-restriction-preview",
    templateUrl: "./restriction-preview.component.html",
    styleUrls: ["./restriction-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class RestrictionPreviewComponent implements AfterViewInit, OnDestroy {
    protected readonly FlightZoneErrorType = FlightZoneErrorType;

    protected readonly error$ = this.store.select(FlightZoneRestrictionState.error);
    protected readonly flightZoneRestrictionData$ = this.store.select(FlightZoneRestrictionState.restrictionData);
    protected readonly isProcessing$ = this.store.select(FlightZoneRestrictionState.isProcessing);
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);

    constructor(
        private readonly store: Store,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly notamModalService: NotamModalService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly cameraHelperService: CameraHelperService
    ) {}

    public ngAfterViewInit(): void {
        this.initMapWithEntity();
    }

    public ngOnDestroy(): void {
        this.flightZoneGeometryService.clearGeometry();
    }

    protected zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    protected openNotamPreview(restrictionId: string): void {
        this.notamModalService.openNotamPreview(restrictionId);
    }

    protected downloadPdf(restriction: FlightZoneRestriction): void {
        const cameraViewSettingsCache = this.cameraHelperService.freezeView();

        this.cameraHelperService
            .takeScreenshotWhenStable()
            .pipe(
                switchMap((file) => this.store.dispatch(new FlightZoneRestrictionActions.DownloadPdf(restriction, file))),
                finalize(() => this.cameraHelperService.restoreView(cameraViewSettingsCache)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);
                }
            });
    }

    protected downloadConfirmationOfApplicationSubmissionPdf(restriction: FlightZoneRestriction): void {
        this.store
            .dispatch(
                new FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf(
                    restriction.applicationId,
                    restriction.basicData.title
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }
            });
    }

    private initMapWithEntity(): void {
        const restrictionAreaGeometry = this.store.selectSnapshot(FlightZoneRestrictionState.restrictionData)?.restrictionAreaGeometry;

        if (!restrictionAreaGeometry) {
            return;
        }

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, GEOMETRY_READ_ONLY_CONSTRAINTS);
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotDownloadRestrictionPdf:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotDownloadRestrictionPdfErrorMessage"
                );
                break;
            default:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
