<ng-container *ngIf="notam$ | ngrxPush as notam">
    <ng-container *ngrxLet="isEditModeEnabled$ as isEditModeEnabled">
        <div class="notam-content-header">
            {{ notam.language }}

            <div class="buttons-container">
                <button
                    *ngIf="!isEditModeEnabled"
                    class="button-tertiary"
                    type="button"
                    [matTooltip]="'dssAdminLibFlightZoneManagement.notamPreview.editValueHint' | transloco"
                    (click)="enableEdit()"
                >
                    <dtm-ui-icon name="file-edit"></dtm-ui-icon>
                </button>
                <button
                    class="button-tertiary"
                    type="button"
                    [cdkCopyToClipboard]="notam.value"
                    [matTooltip]="'dssAdminLibFlightZoneManagement.notamPreview.copyToClipboardHint' | transloco"
                >
                    <dtm-ui-icon name="file-copy"></dtm-ui-icon>
                </button>
            </div>
        </div>

        <ng-container *ngIf="isEditModeEnabled; else readOnlyTemplate">
            <ng-content></ng-content>
        </ng-container>

        <ng-template #readOnlyTemplate>
            <div class="notam-value" [innerHTML]="notam.value"></div>
        </ng-template>
    </ng-container>
</ng-container>
