<ng-container *ngrxLet="data.notamSeries$; let notamSeries">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.title" | transloco }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content *ngIf="notamSeries.length > 0; else errorTemplate" dtmUiInvalidFormScrollable>
        <div class="dialog-description">
            {{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.description" | transloco }}
        </div>
        <div class="form-wrapper" [formGroup]="notamNumberForm">
            <dtm-ui-select-field id="notamSeriesControl" formControlName="notamSeries">
                <label for="notamSeriesControl">
                    {{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.seriesLabel" | transloco }}
                </label>

                <dtm-ui-select-option *ngFor="let series of notamSeries" [value]="series">
                    {{ series }}
                </dtm-ui-select-option>

                <div class="field-error" *dtmUiFieldHasError="notamSeriesFormControl; name: 'required'">
                    {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-select-field>

            <dtm-ui-input-field>
                <label for="numberControl">
                    {{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.inputLabel" | transloco }}
                </label>
                <input id="numberControl" matInput type="text" required formControlName="number" />
                <div class="field-error" *dtmUiFieldHasError="notamNumberFormControl; name: 'required'">
                    {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
            {{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.cancelButtonLabel" | transloco }}
        </button>
        <button *ngIf="notamSeries.length > 0" type="button" class="button-primary" (click)="confirm()">
            {{ "dssAdminLibFlightZoneManagement.notamPreview.notamNumberConfirmDialog.confirmButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>

<ng-template #errorTemplate>
    <div class="series-error">
        {{ "dssAdminLibFlightZoneManagement.cannotGetNotamSeriesErrorMessage" | transloco }}
    </div>
</ng-template>
