import { Injectable } from "@angular/core";

import { Store } from "@ngxs/store";
import { FlightZoneCapabilitiesActions } from "../state/flight-zone-capabilities.actions";

@Injectable()
export class FlightZoneCapabilitiesResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store.dispatch(new FlightZoneCapabilitiesActions.GetCapabilities());
    }
}
