<dtm-ui-filters-container *ngrxLet="appliedFiltersCount$; let appliedFiltersCount" [isExpanded]="true" [filtersCount]="appliedFiltersCount">
    <form
        class="filters-container"
        autocomplete="off"
        [formGroup]="filtersFormGroup"
        *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
    >
        <div class="search-row">
            <dtm-ui-input-field class="search">
                <input
                    matInput
                    type="text"
                    formControlName="search"
                    *ngrxLet="searchInputPlaceholder$ as searchInputPlaceholder"
                    [placeholder]="searchInputPlaceholder ?? 'dssSharedLibFlightZone.applicationListFilters.defaultSearchLabel' | transloco"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>

            <dtm-ui-date-field *ngIf="filtersFormGroup.controls.sendDate.enabled" class="date-field">
                <label for="sendDateControl">
                    {{ "dssSharedLibFlightZone.applicationListFilters.sendDateLabel" | transloco }}
                </label>
                <input
                    id="sendDateControl"
                    matInput
                    formControlName="sendDate"
                    [matDatepicker]="sendDatePicker"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #sendDatePicker></mat-datepicker>
            </dtm-ui-date-field>
        </div>
        <div class="row">
            <dtm-ui-date-field [isClearable]="false" class="range">
                <label>
                    {{ "dssSharedLibFlightZone.applicationListFilters.datesRangeLabel" | transloco }}
                </label>
                <mat-date-range-input [rangePicker]="datesRangePicker">
                    <input matStartDate formControlName="startDate" [placeholder]="datePickerPlaceholder" />
                    <input matEndDate formControlName="endDate" [placeholder]="datePickerPlaceholder" />
                </mat-date-range-input>
                <mat-date-range-picker #datesRangePicker></mat-date-range-picker>
            </dtm-ui-date-field>

            <dtm-ui-select-field
                *ngIf="filtersFormGroup.controls.applicationPurpose.enabled"
                class="purpose"
                formControlName="applicationPurpose"
                [placeholder]="'dssSharedLibFlightZone.applicationListFilters.selectPlaceholder' | transloco"
            >
                <label>{{ "dssSharedLibFlightZone.applicationListFilters.applicationPurposeLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let purpose of flightZonePurposes$ | ngrxPush" [value]="purpose">
                    {{ "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel" | transloco : { value: purpose } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dss-shared-lib-institutions-filter
                *ngIf="filtersFormGroup.controls.institutionId.enabled"
                class="institution"
                formControlName="institutionId"
                [institutions]="institutions$ | ngrxPush"
                (institutionSearchTextChange)="institutionSearchTextChange.emit($event)"
            ></dss-shared-lib-institutions-filter>

            <dtm-ui-input-field class="author" *ngIf="filtersFormGroup.controls.author.enabled">
                <label>{{ "dssSharedLibFlightZone.applicationListFilters.authorLabel" | transloco }}</label>
                <input
                    matInput
                    type="text"
                    formControlName="author"
                    [placeholder]="'dssSharedLibFlightZone.applicationListFilters.textInputPlaceholder' | transloco"
                />
            </dtm-ui-input-field>

            <dtm-ui-select-field
                *ngIf="filtersFormGroup.controls.reviewerId.enabled"
                class="reviewer"
                formControlName="reviewerId"
                [placeholder]="'dssSharedLibFlightZone.applicationListFilters.selectPlaceholder' | transloco"
            >
                <label>{{ "dssSharedLibFlightZone.applicationListFilters.reviewerLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let employee of anspEmployees$ | ngrxPush" [value]="employee.id">
                    {{ employee.firstName }} {{ employee.lastName }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-select-field
                *ngIf="filtersFormGroup.controls.status.enabled"
                class="status"
                formControlName="status"
                [placeholder]="'dssSharedLibFlightZone.applicationListFilters.selectPlaceholder' | transloco"
            >
                <label>{{ "dssSharedLibFlightZone.applicationListFilters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let status of statuses$ | ngrxPush" [value]="status">
                    {{ (status | invoke : getStatusTranslationsMap.bind(this))[status] | transloco }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>
    </form>

    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [class.chips-margin]="!!appliedFiltersCount"
        [formGroup]="filtersFormGroup"
        [filtersValue]="filtersFormGroup.value"
        [filtersMap]="FILTERS_MAP"
        [valueMap]="labelsValueMap$ | ngrxPush"
        [filtersCount]="appliedFiltersCount"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
<div class="list-total-count">
    <ng-content select="[totalCount]"></ng-content>
</div>
