import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { InstitutionMember } from "../../models/administration.models";

interface InstitutionMembersComponentState {
    members: InstitutionMember[];
}

@Component({
    selector: "dss-admin-lib-members-list[members]",
    templateUrl: "./members-list.component.html",
    styleUrls: ["./members-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembersListComponent {
    protected readonly displayedColumns: Array<keyof InstitutionMember | "actions"> = [
        "firstName",
        "lastName",
        "email",
        "isSupervisor",
        "actions",
    ];
    protected readonly members$ = this.localStore.selectByKey("members");

    @Input()
    public set members(value: InstitutionMember[] | undefined) {
        this.localStore.patchState({ members: value ?? [] });
    }

    @Output() public readonly memberRemove = new EventEmitter<InstitutionMember>();

    constructor(private readonly localStore: LocalComponentStore<InstitutionMembersComponentState>) {
        this.localStore.setState({
            members: [],
        });
    }
}
