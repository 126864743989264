<ng-container
    *ngrxLet="{
        isFormExpanded: isFormExpanded$,
        emails: emails$,
        isProcessing: isProcessing$,
        isReadOnly: isReadOnly$
    } as vm"
>
    <dtm-ui-presentation-tile-with-action
        [titleTile]="'dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.header' | transloco"
        [class.read-only]="vm.isReadOnly"
    >
        <button
            *ngIf="!vm.isReadOnly && !vm.isFormExpanded"
            actionButtonSlot
            class="button-secondary"
            type="button"
            (click)="changeExpansionState(true)"
        >
            <ng-container *ngIf="vm.emails?.length; else emptyEmailsTemplate">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.editButtonLabel" | transloco }}
            </ng-container>

            <ng-template #emptyEmailsTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.addButtonLabel" | transloco }}
            </ng-template>
        </button>

        <button
            *ngIf="!vm.isReadOnly && vm.isFormExpanded"
            actionButtonSlot
            class="button-icon"
            type="button"
            (click)="changeExpansionState(false)"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <form *ngIf="!vm.isReadOnly && vm.isFormExpanded" [formGroup]="additionalReceiversForm" (submit)="submitForm()" @slideIn>
            <div class="description">
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.description" | transloco }}
            </div>
            <dtm-ui-textarea-field>
                <label>{{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.emailsLabel" | transloco }}</label>
                <textarea formControlName="emails" matInput [rows]="3"></textarea>
                <div class="field-error" *dtmUiFieldHasError="additionalReceiversForm.controls.emails; name: 'email'">
                    {{ "dssAdminLibFlightZoneManagement.invalidMultipleEmailsErrorHint" | transloco }}
                </div>
            </dtm-ui-textarea-field>

            <div class="buttons-container">
                <button class="button-secondary" type="button" (click)="cancelChanges()">
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.cancelButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary"
                    type="submit"
                    [disabled]="(additionalReceiversForm.dirty && additionalReceiversForm.invalid) || vm.isProcessing"
                >
                    <dtm-ui-icon name="save"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.saveButtonLabel" | transloco }}
                </button>
            </div>
        </form>

        <div *ngIf="!vm.isFormExpanded && vm.emails?.length">
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.emailsLabel' | transloco"
                [value]="vm.emails"
            ></dtm-ui-label-value>
        </div>

        <dtm-ui-label-value
            *ngIf="vm.isReadOnly && !vm.emails?.length"
            [label]="'dssAdminLibFlightZoneManagement.applicationPreview.additionalReceivers.emailsLabel' | transloco"
            [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
        ></dtm-ui-label-value>
    </dtm-ui-presentation-tile-with-action>
</ng-container>
