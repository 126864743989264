<dtm-ui-loader-container
    *ngrxLet="{
        institutionsList: institutionsList$,
        isProcessing: isProcessing$,
        hasListDataRetrievalError: hasListDataRetrievalError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <div class="grid">
        <dtm-ui-error
            *ngIf="vm.hasListDataRetrievalError; else institutionsListTemplate"
            (reload)="refreshList()"
            [errorMessage]="'dssAdminLibAdministration.userDetail.capabilitiesDownloadError' | transloco"
        ></dtm-ui-error>

        <ng-template #institutionsListTemplate>
            <div class="action-buttons">
                <button type="button" class="button-primary" routerLink="new">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dssAdminLibAdministration.institutionsList.addInstitutionButtonLabel" | transloco }}
                </button>
            </div>

            <dss-admin-lib-institutions-list
                [institutions]="vm.institutionsList?.content"
                [page]="vm.institutionsList?.page"
                [isProcessing]="vm.isProcessing"
                (pageChange)="changePage($event)"
                (loadMoreDetails)="loadMoreDetails($event)"
                (memberAdd)="addInstitutionMember($event)"
                (memberRemove)="removeInstitutionMember($event)"
                (institutionRemove)="tryRemoveInstitution($event)"
            ></dss-admin-lib-institutions-list>
        </ng-template>
    </div>
</dtm-ui-loader-container>
