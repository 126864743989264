/* eslint-disable max-len */

import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { DssAdminEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<DssAdminEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, sharedMapEndpoints }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const webSocketApiUrl = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: [],
            webSocketEndpoint: webSocketApiUrl,
            keycloakConfig,
            flightZoneEndpoints: {
                getCapabilities: `${apiUrl}/capabilities/restriction-application`,
                getFiltersCapabilities: `${apiUrl}/capabilities/filters`,
                getInstitutionsFilterOptions: `${apiUrl}/dss/institutions`,
                applicationsEntity: `${apiUrl}/applications/{{flightZoneId}}`,
                restrictionApplicationsEntity: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}`,
                restrictionApplications: `${apiUrl}/applications/restriction-applications`,
                getRestrictions: `${apiUrl}/restrictions`,
                restrictionsEntity: `${apiUrl}/restrictions/{{restrictionId}}`,
                subordinates: `${apiUrl}/dss/users/subordinates`,
                anspAcceptance: `${apiUrl}/applications/{{flightZoneId}}/ansp-acceptance`,
                institutionAcceptance: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/institution-acceptance`,
                institutionReAcceptance: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/institution-re-acceptance`,
                institutionAcceptanceRequest: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/institution-acceptance-request`,
                institutionRejection: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/acceptance/institution-rejection`,
                anspRejection: `${apiUrl}/applications/{{flightZoneId}}/acceptance/ansp-rejection`,
                applicationReview: `${apiUrl}/assignment/{{assignmentId}}/review`,
                assignmentManagement: `${apiUrl}/assignment`,
                assignmentUpdate: `${apiUrl}/assignment/{{assignmentId}}`,
                editAssignmentEmployee: `${apiUrl}/assignment/edit-assigned-employee`,
                getNotamCapabilities: `${apiUrl}/capabilities/notam`,
                getNotams: `${apiUrl}/notams`,
                getNotamsNotice: `${apiUrl}/notams/notice`,
                publishNotams: `${apiUrl}/notams/{{notamId}}/publication`,
                getAssignmentCapabilities: `${apiUrl}/capabilities/assignment`,
                commentsManagement: `${apiUrl}/comments`,
                lockApplication: `${apiUrl}/applications/{{flightZoneId}}/lock`,
                unlockApplication: `${apiUrl}/applications/{{flightZoneId}}/unlock`,
                updateApplicationNote: `${apiUrl}/applications/{{flightZoneId}}/note`,
                restrictionApplicationsModification: `${apiUrl}/applications/restriction-modification-applications`,
                cancelZoneDuration: `${apiUrl}/applications/cancel-restriction`,
                restrictionModificationEntity: `${apiUrl}/applications/restriction-modification-applications/{{flightZoneId}}`,
                notamLocationUpdate: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/location`,
                getConsultants: `${apiUrl}/dss/users/consultants`,
                consultationsManagement: `${apiUrl}/consultations`,
                consultationReview: `${apiUrl}/consultations/{{id}}/review`,
                applications: `${apiUrl}/applications`,
                anspCaseNumberModification: `${apiUrl}/applications/{{id}}/ansp-case-number`,
                getRestrictionTypeCapabilities: `${apiUrl}/capabilities/restriction-type`,
                changeRestrictionType: `${apiUrl}/applications/restriction-applications/{{flightZoneId}}/restriction-type`,
                anspTeamsManagement: `${apiUrl}/dss/teams`,
                detailedDurationManagement: `${apiUrl}/applications/{{flightZoneId}}/detailed-duration`,
                additionalReceiversManagement: `${apiUrl}/applications/{{flightZoneId}}/additional-receivers`,
                airspaceClassificationCapabilities: `${apiUrl}/capabilities/airspace-classification`,
                airspaceClassificationManagement: `${apiUrl}/applications/{{flightZoneId}}/airspace-classification`,
                downloadRestrictionPdf: `${apiUrl}/dss/documents/restriction/{{restrictionId}}`,
                downloadConfirmationOfApplicationSubmissionPdf: `${apiUrl}/dss/documents/institution-confirmation/{{flightZoneId}}`,
                getApplicationGeoJson: `${apiUrl}/applications/{{id}}/geo-json`,
                getRestrictionGeoJson: `${apiUrl}/restrictions/{{id}}/geo-json`,
                getForbiddenDesignatorRange: `${apiUrl}/restrictions/system-only-designators`,
                setCustomDesignator: `${apiUrl}/applications/{{flightZoneId}}/custom-designator`,
                removeCustomDesignator: `${apiUrl}/applications/{{flightZoneId}}/custom-designator`,
            },
            notificationEndpoints: {
                notificationsList: `${apiUrl}/notifications`,
                notificationsCount: `${apiUrl}/notifications/counter`,
                getNotifications: "/websocket/direct/{{userId}}/notifications",
                markNotificationAsRead: `${apiUrl}/notifications/{{id}}/read`,
                markAllAsRead: `${apiUrl}/notifications/read`,
            },
            userProfileEndpoints: {
                userDataManagement: `${apiUrl}/dss/users/{{userId}}`,
                requestUserAccountDelete: `${apiUrl}/dss/users/{{userId}}/remove-request`,
                getNotifications: `${apiUrl}/dss-notifications`,
                updateNotification: `${apiUrl}/dss-notifications/{{id}}`,
            },
            administrationEndpoints: {
                getUsersList: `${apiUrl}/dss/users`,
                getInstitutionsList: `${apiUrl}/dss/institutions`,
                saveNewInstitution: `${apiUrl}/dss/institutions`,
                institutionDetailsManagement: `${apiUrl}/dss/institutions/{{institutionId}}`,
                assignInstitutionMembers: `${apiUrl}/dss/institutions/{{institutionId}}/assign-users`,
                removeInstitutionMember: `${apiUrl}/dss/institutions/{{institutionId}}/unassign/{{userId}}`,
                getCapabilities: `${apiUrl}/capabilities/user`,
                saveNewUser: `${apiUrl}/dss/users`,
                userDetailsManagement: `${apiUrl}/dss/users/{{userId}}`,
                getAnspTeamsList: `${apiUrl}/dss/teams`,
                anspTeamDetailsManagement: `${apiUrl}/dss/teams/{{teamId}}`,
                assignAnspTeamMembers: `${apiUrl}/dss/teams/{{teamId}}/assign-users`,
                removeAnspTeamMember: `${apiUrl}/dss/teams/{{teamId}}/unassign/{{userId}}`,
                anspTeamWorkCalendarManagement: `${apiUrl}/dss/calendars/{{id}}`,
                addTeamDaysOff: `${apiUrl}/dss/calendars/days-off`,
                removeTeamDayOff: `${apiUrl}/dss/calendars/{{id}}`,
            },
            versionDataEndpoints: {
                getVersionDetails: `${apiUrl}/system/service-info`,
            },
            translationEndpoints: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            sharedMapEndpoints,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
        };
    }
);
