<div class="dialog-header">
    <div mat-dialog-title>{{ "dssAdminLibAdministration.addMemberDialog.dialogHeader" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-select-field
        [formControl]="memberFormControl"
        id="memberControl"
        *ngIf="data.availableMembers?.length; else emptyMembersListTemplate"
    >
        <label for="memberControl">
            {{ "dssAdminLibAdministration.addMemberDialog.selectMemberLabel" | transloco }}
        </label>

        <dtm-ui-select-option *ngFor="let member of data.availableMembers" [value]="member">
            {{ member.firstName }} {{ member.lastName }}
        </dtm-ui-select-option>

        <div class="field-error" *dtmUiFieldHasError="memberFormControl; name: 'required'">
            {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssAdminLibAdministration.addMemberDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" *ngIf="data.availableMembers?.length" (click)="onConfirmClick()">
        {{ "dssAdminLibAdministration.addMemberDialog.confirmButtonLabel" | transloco }}
    </button>
</div>

<ng-template #emptyMembersListTemplate>
    {{ "dssAdminLibAdministration.addMemberDialog.emptyMembersList" | transloco }}
</ng-template>
