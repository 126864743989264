<ng-container *ngrxLet="{ isProcessing: isProcessing$, isDisabled: isDisabled$ } as vm">
    <ng-container *ngrxLet="assignment$; let assignment">
        <ng-container *ngIf="assignment?.reviewStatus; let reviewStatus; else: unreviewedApplicationTemplate">
            <button class="button-primary" [disabled]="vm.isProcessing || vm.isDisabled" type="button" (click)="acceptReview(reviewStatus)">
                {{ "dssAdminLibFlightZoneManagement.applicationPreview.acceptReviewButtonLabel" | transloco : { value: reviewStatus } }}
            </button>
        </ng-container>
    </ng-container>

    <ng-template #unreviewedApplicationTemplate>
        <button class="button-primary" [disabled]="vm.isProcessing || vm.isDisabled" type="button" (click)="acceptApplication.emit()">
            {{ "dssAdminLibFlightZoneManagement.applicationPreview.submitButtonLabel" | transloco }}
        </button>
    </ng-template>
</ng-container>
