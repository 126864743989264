import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { AutocompleteListFilterElement } from "../../models/flight-zone-shared.models";

interface AutocompleteListFilterComponentState {
    elements: AutocompleteListFilterElement[] | undefined;
    searchPlaceholder: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-autocomplete-list-filter[elements]",
    templateUrl: "./autocomplete-list-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteListFilterComponent), multi: true },
    ],
})
export class AutocompleteListFilterComponent implements ControlValueAccessor {
    protected readonly searchControl = new FormControl<AutocompleteListFilterElement | string>("", {
        nonNullable: true,
    });
    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AutocompleteListFilterElement | null) => void = FunctionUtils.noop;

    protected readonly elements$ = this.localStore.selectByKey("elements");
    protected readonly searchPlaceholder$ = this.localStore.selectByKey("searchPlaceholder");

    @Input() public set elements(value: AutocompleteListFilterElement[] | undefined) {
        this.localStore.patchState({ elements: value });
    }

    @Input() public set searchPlaceholder(value: string | undefined) {
        this.localStore.patchState({ searchPlaceholder: value });
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();

    constructor(private readonly localStore: LocalComponentStore<AutocompleteListFilterComponentState>) {
        this.localStore.setState({
            elements: undefined,
            searchPlaceholder: undefined,
        });

        this.searchControl.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (typeof value !== "string") {
                    return;
                }

                this.searchTextChange.emit(value);

                if (!value) {
                    this.propagateChange(null);
                }
            });
    }

    public registerOnChange(fn: (value: AutocompleteListFilterElement | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: AutocompleteListFilterElement | null): void {
        if (value) {
            this.searchControl.reset(value, { emitEvent: false });
        } else {
            this.searchControl.reset("");
        }
    }

    protected elementSelected({ option }: MatAutocompleteSelectedEvent): void {
        this.propagateChange(option.value);
        this.searchTextChange.emit(option.value.name);
    }

    protected displayAutocompleteValueFn(element: AutocompleteListFilterElement | string): string {
        return typeof element === "string" ? element : element.name;
    }
}
