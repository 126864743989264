import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
} from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, first, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { InstitutionsListItem, RemoveInstitutionMemberData, UsersListItem } from "../../models/administration.models";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";
import { AddMemberDialogComponent } from "../add-member-dialog/add-member-dialog.component";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-institutions-list-container",
    templateUrl: "./institutions-list-container.component.html",
    styleUrls: ["./institutions-list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstitutionsListContainerComponent implements OnInit {
    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly institutionsList$ = this.store.select(AdministrationState.institutionsList);
    protected readonly hasListDataRetrievalError$ = this.store.select(AdministrationState.listDataRetrievalError).pipe(map(Boolean));

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {}

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };
        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new AdministrationActions.GetInstitutionsList(this.route.snapshot.queryParams));
    }

    protected loadMoreDetails(institutionId: string): void {
        this.store.dispatch(new AdministrationActions.GetInstitutionDetails(institutionId));
    }

    protected addInstitutionMember(institution: InstitutionsListItem): void {
        this.store
            .dispatch(AdministrationActions.GetAvailableInstitutionMembers)
            .pipe(
                tap(() => {
                    if (this.store.selectSnapshot(AdministrationState.getAvailableMembersError)) {
                        this.toastrService.error(
                            this.translocoService.translate("dssAdminLibAdministration.institutionsList.getAvailableMembersErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(AdministrationState.availableInstitutionMembers)),
                first(),
                switchMap((availableMembers) =>
                    this.dialogService.open(AddMemberDialogComponent, { data: { availableMembers } }).afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((member: UsersListItem) =>
                    this.store.dispatch(new AdministrationActions.AddInstitutionMember(institution, member.id))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.addInstitutionMemberError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssAdminLibAdministration.institutionsList.addMemberErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dssAdminLibAdministration.institutionsList.addMemberSuccessMessage")
                );
            });
    }

    protected removeInstitutionMember(data: RemoveInstitutionMemberData): void {
        this.store
            .dispatch(new AdministrationActions.RemoveInstitutionMember(data.institution, data.member.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.removeInstitutionMemberError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeMemberErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeMemberSuccessMessage")
                );
            });
    }

    protected tryRemoveInstitution(institution: InstitutionsListItem): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeInstitutionDialogTitle", {
                        name: `${institution.name}`,
                    }),
                    confirmationText: this.translocoService.translate(
                        "dssAdminLibAdministration.institutionsList.removeInstitutionDialogConfirmationText"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "dssAdminLibAdministration.institutionsList.confirmInstitutionRemovalLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new AdministrationActions.RemoveInstitution(institution))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.removeInstitutionError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeInstitutionErrorMessage")
                    );

                    return;
                }

                this.refreshList();
                this.toastrService.success(
                    this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeInstitutionSuccessMessage")
                );
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new AdministrationActions.GetInstitutionsList(filterParams));
        });
    }
}
