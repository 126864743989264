import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { UserProfileContactComponent } from "./components/user-profile-contact/user-profile-contact.component";
import { UserProfileNotificationsComponent } from "./components/user-profile-notifications/user-profile-notifications.component";
import { UserProfilePersonalDataComponent } from "./components/user-profile-personal-data/user-profile-personal-data.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserProfileApiService } from "./services/user-profile-api.service";
import { UserProfileState } from "./state/user-profile.state";

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatTableModule,
        MatSlideToggleModule,
        NgxsModule.forFeature([UserProfileState]),
        PushModule,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        ReactiveFormsModule,
    ],
    declarations: [UserProfileComponent, UserProfileContactComponent, UserProfilePersonalDataComponent, UserProfileNotificationsComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssSharedLibUserProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [UserProfileContactComponent, UserProfilePersonalDataComponent, UserProfileNotificationsComponent],
})
export class SharedUserProfileModule {
    public static forRoot(): ModuleWithProviders<SharedUserProfileModule> {
        return {
            ngModule: SharedUserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useClass: UserProfileApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SharedUserProfileModule> {
        return {
            ngModule: SharedUserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useValue: null,
                },
            ],
        };
    }
}
