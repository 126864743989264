import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MapLayerProviderOptions } from "@pansa/ngx-cesium";
import { ActiveRestrictionsMapFilters } from "../../../models/active-restrictions-map-layer.models";
import { VerticalMeasureUnits } from "../../../models/flight-zone-shared.models";
import { FlightZoneUtils } from "../../../utils/index";
import { ActiveRestrictionsLayerFeaturePickerService } from "../active-restrictions-layer-feature-picker.service";

const FEATURE_COUNT = 50;

interface ActiveRestrictionsMapLayerComponentState {
    areFiltersOpened: boolean;
    hasFiltersChanged: boolean;
    queryFilter: string;
    showLayer: boolean;
}

@Component({
    selector: "dss-shared-lib-active-restrictions-map-layer",
    templateUrl: "./active-restrictions-map-layer.component.html",
    styleUrls: ["./active-restrictions-map-layer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ActiveRestrictionsLayerFeaturePickerService],
})
export class ActiveRestrictionsMapLayerComponent {
    protected readonly FEATURE_COUNT = FEATURE_COUNT;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;

    protected readonly areFiltersOpened$ = this.localStore.selectByKey("areFiltersOpened");
    protected readonly hasFiltersChanged$ = this.localStore.selectByKey("hasFiltersChanged");
    protected readonly queryFilter$ = this.localStore.selectByKey("queryFilter");
    protected readonly showLayer$ = this.localStore.selectByKey("showLayer");
    protected readonly pickedFeaturesData$ = this.activeRestrictionsLayerFeaturePickerService.pickedFeaturesData$;
    protected readonly isFeaturesDataLoading$ = this.activeRestrictionsLayerFeaturePickerService.isFeaturesDataLoading$;

    constructor(
        @Inject(SHARED_MAP_ENDPOINTS) protected readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly localStore: LocalComponentStore<ActiveRestrictionsMapLayerComponentState>,
        private readonly cdRef: ChangeDetectorRef,
        private readonly activeRestrictionsLayerFeaturePickerService: ActiveRestrictionsLayerFeaturePickerService
    ) {
        localStore.setState({
            areFiltersOpened: false,
            hasFiltersChanged: false,
            showLayer: true,
            queryFilter: "",
        });
    }

    protected toggleFilters() {
        this.localStore.patchState(({ areFiltersOpened }) => ({ areFiltersOpened: !areFiltersOpened }));
    }

    protected updateFilters(filters: ActiveRestrictionsMapFilters): void {
        this.localStore.patchState({ queryFilter: this.createCQLFilterQuery(filters) });
        this.rerenderLayer();
    }

    protected filtersInitialStateChange(value: boolean): void {
        this.localStore.patchState({ hasFiltersChanged: value });
    }

    private createCQLFilterQuery(filters: ActiveRestrictionsMapFilters): string {
        const typeFiltersQuery =
            filters.type.length > 0 ? filters.type.map((filterItem) => `type='${filterItem}'`).join(" OR ") : "type='NONE'";
        const geoZoneFiltersQuery =
            filters.geoZone.length > 0 ? filters.geoZone.map((filterItem) => `geozone='${filterItem}'`).join(" OR ") : "geozone='NONE'";
        const combinedFiltersQuery = `(${typeFiltersQuery} OR ${geoZoneFiltersQuery})`;

        const lowerLimitQuery = filters.lowerLimit ? `upper_limit >= ${this.convertFeetToMeters(filters.lowerLimit)}` : "";
        const upperLimitQuery = filters.upperLimit ? `lower_limit <= ${this.convertFeetToMeters(filters.upperLimit)}` : "";

        return [combinedFiltersQuery, lowerLimitQuery, upperLimitQuery].filter(FunctionUtils.isTruthy).join(" AND ");
    }

    private rerenderLayer(): void {
        this.localStore.patchState({ showLayer: false });
        this.cdRef.detectChanges();
        this.localStore.patchState({ showLayer: true });
    }

    private convertFeetToMeters(value: number): number {
        return FlightZoneUtils.otherUnitsOfMeasureToMeters(value, VerticalMeasureUnits.Feet);
    }
}
