import { METERS_IN_FOOT, METERS_IN_KILOMETER, METERS_IN_NAUTICAL_MILE } from "@dtm-frontend/shared/utils";
import { HorizontalMeasureUnits, MapUnitsOfMeasure, VerticalMeasureUnits } from "../models/flight-zone-shared.models";

export function metersToOtherUnitsOfMeasure(valueInMeters: number, unit: MapUnitsOfMeasure): number {
    switch (unit) {
        case VerticalMeasureUnits.Feet:
            return valueInMeters / METERS_IN_FOOT;
        case HorizontalMeasureUnits.NauticalMiles:
            return valueInMeters / METERS_IN_NAUTICAL_MILE;
        case HorizontalMeasureUnits.Kilometers:
            return valueInMeters / METERS_IN_KILOMETER;
        default:
            return valueInMeters;
    }
}

/**
 * Converts meters to selected units and rounds value
 * @param valueInMeters - original value in meters
 * @param unit - new unit to convert value
 * @param decimalPlaces - number of decimal places
 * @returns rounded value in converted unit
 */
export function metersToOtherUnitsOfMeasureRounded(valueInMeters: number, unit: MapUnitsOfMeasure, decimalPlaces: number): number {
    // eslint-disable-next-line no-magic-numbers
    const roundingScale = Math.pow(10, decimalPlaces);

    return Math.round((metersToOtherUnitsOfMeasure(valueInMeters, unit) + Number.EPSILON) * roundingScale) / roundingScale;
}
