import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ApplicationType, NotamListItem, NotamType } from "@dtm-frontend/dss-shared-lib";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { NotamsTableColumns } from "../../../models/flight-zone-management.models";
import { NotamModalService } from "../../../services/notam-modal.service";

interface NotamListComponentState {
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    notams: NotamListItem[];
    expandedElement: NotamListItem | null;
    displayedColumns: NotamsTableColumns;
    applicationsPage: Page | undefined;
}

@Component({
    selector: "dss-admin-lib-notam-list[notams][displayedColumns]",
    templateUrl: "./notam-list.component.html",
    styleUrls: ["./notam-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class NotamListComponent {
    protected readonly ApplicationType = ApplicationType;
    protected readonly NotamType = NotamType;
    protected readonly notams$ = this.localStore.selectByKey("notams");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");

    @Input()
    public set notams(value: NotamListItem[] | undefined) {
        this.localStore.patchState({ notams: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set hasDataRetrievalError(value: BooleanInput) {
        this.localStore.patchState({ hasDataRetrievalError: coerceBooleanProperty(value) });
    }

    @Input()
    public set displayedColumns(value: NotamsTableColumns | undefined) {
        this.localStore.patchState({ displayedColumns: value ?? [] });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }

    @Output() protected readonly listRefresh = new EventEmitter<void>();
    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();

    constructor(
        private readonly localStore: LocalComponentStore<NotamListComponentState>,
        private readonly notamModalService: NotamModalService
    ) {
        this.localStore.setState({
            notams: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            displayedColumns: [],
            applicationsPage: undefined,
        });
    }

    protected toggleExpandableRow(row: NotamListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");

        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }

    protected openNotamPreview(flightZoneId: string): void {
        this.notamModalService.openNotamPreview(flightZoneId);
    }
}
