<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dssAdminLibFlightZoneManagement.assigneeConfirmDialog.dialogHeader" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-select-field
        [formControl]="assigneeFormControl"
        id="assigneeControl"
        *ngIf="!!data.availableAssignees.length; else emptyAssigneesListTemplate"
    >
        <label for="assigneeControl">
            {{ selectLabel }}
        </label>

        <dtm-ui-select-option *ngFor="let assignee of data.availableAssignees" [value]="assignee">
            {{ assignee.firstName }} {{ assignee.lastName }}
        </dtm-ui-select-option>

        <div class="field-error" *dtmUiFieldHasError="assigneeFormControl; name: 'required'">
            {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssAdminLibFlightZoneManagement.assigneeConfirmDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" *ngIf="!!data.availableAssignees.length" (click)="onConfirmClick()">
        {{ "dssAdminLibFlightZoneManagement.assigneeConfirmDialog.confirmButtonLabel" | transloco }}
    </button>
</div>

<ng-template #emptyAssigneesListTemplate>
    {{ "dssAdminLibFlightZoneManagement.assigneeConfirmDialog.emptyAssigneeList" | transloco }}
</ng-template>
