import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationType, DssUserRoles, FlightZonesManagementListItem } from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { FlightZonesManagementTableColumns, TableActionButton } from "../../models/flight-zone-management.models";
import { ApplicationKmlExportService } from "../../services/application-kml-export.service";
import { FlightZoneListsActions } from "../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../state/flight-zone-lists.state";
import { FlightZoneManagementState } from "../../state/flight-zone-management.state";

@Component({
    selector: "dss-admin-lib-applications-for-approval-list-container",
    templateUrl: "./applications-for-approval-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationsForApprovalListContainerComponent {
    protected readonly displayedColumns: FlightZonesManagementTableColumns = [
        "expandHandle",
        "date",
        "startAt",
        "endAt",
        "applicationPurpose",
        "title",
        "actions",
    ];
    protected readonly basePreviewRoutePath = `/${this.route.snapshot.parent?.url[0].path}`;
    protected readonly isProcessing$ = this.store.select(FlightZoneManagementState.isProcessing);
    protected readonly applicationsForApprovalList$ = this.store.select(FlightZoneListsState.applicationsForApprovalList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly actionButtons = [TableActionButton.PreviewAndDecideAction, TableActionButton.KmlDownload];
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.AnspSupervisor)));

    private get applicationType(): ApplicationType {
        return this.route.snapshot.parent?.data.applicationType;
    }

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly applicationKmlExportService: ApplicationKmlExportService
    ) {}

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    protected refreshList(): void {
        this.store.dispatch(
            new FlightZoneListsActions.GetApplicationsForApprovalList(this.route.snapshot.queryParams, this.applicationType)
        );
    }

    protected downloadKml(flightZone: FlightZonesManagementListItem): void {
        this.applicationKmlExportService.downloadKml(flightZone, this.applicationType);
    }
}
