import { Page, PhoneNumber } from "@dtm-frontend/shared/ui";

export enum UserProfileErrorType {
    Unknown = "Unknown",
    InvalidApplicationVersion = "InvalidApplicationVersion",
}

export interface UserProfileError {
    type: UserProfileErrorType;
}

export interface UserData {
    email: string;
    phoneNumber: PhoneNumber;
    id: string;
    lastName: string;
    firstName: string;
    version: number;
}

export interface UserNotificationsList {
    content: UserNotification[];
    page: Page;
}

export interface UserNotification {
    isEnabled: boolean;
    eventName: string;
    id: string;
    userId: string;
}

export interface NotificationStateChange {
    id: string;
    isEnabled: boolean;
}
