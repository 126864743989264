import {
    CylinderRestrictionArea,
    PrismRestrictionArea,
    RestrictionArea,
    RestrictionAreaCylinderUnits,
    RestrictionAreaUnits,
} from "./flight-zone-shared.models";

export function isCylinderRestrictionArea(area: RestrictionArea): area is CylinderRestrictionArea {
    return (area as CylinderRestrictionArea).areaValues?.radius !== undefined;
}

export function isPrismRestrictionArea(area: RestrictionArea): area is PrismRestrictionArea {
    return (area as PrismRestrictionArea).areaValues?.positions !== undefined;
}

export function areRestrictionAreaCylinderUnits(units: RestrictionAreaUnits): units is RestrictionAreaCylinderUnits {
    return (units as RestrictionAreaCylinderUnits).radiusUnit !== undefined;
}
