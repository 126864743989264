<dtm-ui-loader-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        anspStandardTeamWorkCalendar: anspStandardTeamWorkCalendar$,
        getAnspTeamWorkCalendarError: getAnspTeamWorkCalendarError$,
        daysOff: daysOff$,
    } as vm"
    [isShown]="vm.isProcessing"
>
    <dtm-ui-error
        *ngIf="vm.getAnspTeamWorkCalendarError; else workCalendarTemplate"
        class="grid"
        (reload)="refreshData()"
        [errorMessage]="'dssAdminLibAdministration.anspTeamWorkCalendar.getDataError' | transloco"
    ></dtm-ui-error>

    <ng-template #workCalendarTemplate>
        <dss-admin-lib-standard-work-calendar
            [anspTeamWorkCalendar]="vm.anspStandardTeamWorkCalendar"
            (standardWorkCalendarSave)="saveStandardWorkCalendar($event)"
        ></dss-admin-lib-standard-work-calendar>
        <dss-admin-lib-team-days-off
            [daysOff]="vm.daysOff"
            (daysOffAdd)="addDaysOff($event)"
            (dayOffRemove)="removeDayOff($event)"
        ></dss-admin-lib-team-days-off>
    </ng-template>
</dtm-ui-loader-container>
