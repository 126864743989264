<dtm-ui-header [routeTitle]="routeTitle$ | ngrxPush" [componentPortalItem]="componentPortalItem$ | ngrxPush">
    <dtm-notifications-notifications
        notificationsSlot
        *ngIf="userId$ | ngrxPush as userId"
        [userId]="userId"
        (notificationsListChange)="changeNotificationsList($event)"
        [allowedNotificationTypes]="allowedNotificationTypes"
    >
        <dss-shared-lib-notifications
            *ngIf="notificationsList$ | ngrxPush as notificationsList"
            [notificationsList]="notificationsList"
        ></dss-shared-lib-notifications>
    </dtm-notifications-notifications>

    <dtm-ui-header-user-button
        userButtonSlot
        [isUserLoggedIn]="isLoggedIn$ | ngrxPush"
        [isShortMode]="isHeaderUserButtonShortMode$ | ngrxPush"
        [userName]="userName$ | ngrxPush"
        (logout)="logout()"
        (goToUserProfilePage)="goToUserProfilePage()"
    ></dtm-ui-header-user-button>
</dtm-ui-header>
