import { Injectable } from "@angular/core";
import {
    ApplicationType,
    BaseNotificationEventsService,
    DssAssignmentNotificationType,
    DssNotification,
    DssNotificationsService,
} from "@dtm-frontend/dss-shared-lib";
import { Notification, NotificationDisplay, SharedNotificationDisplayProps } from "@dtm-frontend/shared/notifications";
import { IconName } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

@Injectable({
    providedIn: "root",
})
export class AnspAssignmentNotificationEventsService implements BaseNotificationEventsService {
    constructor(private readonly translocoService: TranslocoService, private readonly dssNotificationsService: DssNotificationsService) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return notificationsToFormat
            .filter((notification) =>
                this.dssNotificationsService.getDssAssignmentNotificationTypes().some((type) => notification.payload.type === type)
            )
            .map((notification) => {
                const sharedProps = this.getSharedProps(notification, "user");

                switch (notification.payload.type) {
                    case DssAssignmentNotificationType.AssignedReviewerForRestrictionApplicationEvent:
                        return this.getAssignedReviewerEvent(sharedProps, notification, ApplicationType.RestrictionApplication);

                    case DssAssignmentNotificationType.AssignedReviewerForModificationApplicationEvent:
                        return this.getAssignedReviewerEvent(sharedProps, notification, ApplicationType.RestrictionModificationApplication);

                    case DssAssignmentNotificationType.ChangedReviewerForRestrictionApplicationEvent:
                        return this.getChangedReviewerEvent(sharedProps, notification, ApplicationType.RestrictionApplication);

                    case DssAssignmentNotificationType.ChangedReviewerForRestrictionModificationApplicationEvent:
                        return this.getChangedReviewerEvent(sharedProps, notification, ApplicationType.RestrictionModificationApplication);

                    case DssAssignmentNotificationType.ReviewedRestrictionApplicationEvent:
                        return this.getReviewedApplicationEvent(sharedProps, notification, ApplicationType.RestrictionApplication);

                    case DssAssignmentNotificationType.ReviewedRestrictionModificationApplicationEvent:
                        return this.getReviewedApplicationEvent(
                            sharedProps,
                            notification,
                            ApplicationType.RestrictionModificationApplication
                        );

                    case DssAssignmentNotificationType.RejectedReviewerOpinionForRestrictionApplicationEvent:
                        return this.getRejectedReviewerOpinionEvent(sharedProps, notification, ApplicationType.RestrictionApplication);

                    case DssAssignmentNotificationType.RejectedReviewerOpinionForRestrictionModificationApplicationEvent:
                        return this.getRejectedReviewerOpinionEvent(
                            sharedProps,
                            notification,
                            ApplicationType.RestrictionModificationApplication
                        );

                    default:
                        return null;
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getAssignedReviewerEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification,
        applicationType: ApplicationType
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssAdmin.notifications.assignmentLabel"),
            text: this.translocoService.translate("dssAdmin.notifications.assignedReviewerText"),
            redirectUrl: this.getRedirectUrl(notification, applicationType),
        };
    }

    private getChangedReviewerEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification,
        applicationType: ApplicationType
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssAdmin.notifications.assignmentLabel"),
            text: this.translocoService.translate("dssAdmin.notifications.changedReviewerText"),
            redirectUrl: this.getRedirectUrl(notification, applicationType),
        };
    }

    private getReviewedApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification,
        applicationType: ApplicationType
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssAdmin.notifications.applicationReviewedLabel"),
            text: this.translocoService.translate("dssAdmin.notifications.applicationReviewedText"),
            redirectUrl: this.getRedirectUrl(notification, applicationType),
        };
    }

    private getRejectedReviewerOpinionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification,
        applicationType: ApplicationType
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssAdmin.notifications.applicationReviewedLabel"),
            text: this.translocoService.translate("dssAdmin.notifications.rejectedReviewerOpinionText"),
            redirectUrl: this.getRedirectUrl(notification, applicationType),
        };
    }

    private getRedirectUrl(notification: DssNotification, applicationType: ApplicationType): string {
        switch (applicationType) {
            case ApplicationType.RestrictionApplication:
                return `flight-zone/${notification.payload.id}`;
            case ApplicationType.RestrictionModificationApplication:
                return `modifications/${notification.payload.id}`;
        }
    }

    private getSharedProps(notification: Notification, icon: IconName): SharedNotificationDisplayProps {
        return { icon, date: notification.createdAt, id: notification.id, isRead: notification.read };
    }
}
