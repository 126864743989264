import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { AuthState } from "@dtm-frontend/shared/auth";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { NotificationStateChange, UserData, UserProfileError, UserProfileErrorType } from "../../models/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
    protected readonly isProcessing$ = this.store.select(UserProfileState.isProcessing);
    protected readonly userData$ = this.store.select(UserProfileState.userData);
    protected readonly userNotificationsList$ = this.store.select(UserProfileState.userNotificationsList);
    protected readonly getNotificationsError$ = this.store.select(UserProfileState.getNotificationsError);
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);

    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    protected resetPassword() {
        this.store
            .dispatch(new UserProfileActions.ResetUserPassword())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.resetUserPasswordError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("dssSharedLibUserProfile.cannotResetUserPasswordError"));
                }
            });
    }

    protected reload() {
        this.store.dispatch(new UserProfileActions.GetUserData(this.store.selectSnapshot(AuthState.userId)));
    }

    protected tryDeleteAccount(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dssSharedLibUserProfile.deleteAccount.dialogConfirmationTitleText"),
                    confirmationText: this.translocoService.translate(
                        "dssSharedLibUserProfile.deleteAccount.dialogConfirmationMessageText"
                    ),
                    theme: ButtonTheme.Warn,
                    confirmButtonLabel: this.translocoService.translate(
                        "dssSharedLibUserProfile.deleteAccount.dialogConfirmationButtonLabel"
                    ),
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(UserProfileActions.RequestUserAccountDelete)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.requestUserAccountDeleteError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dssSharedLibUserProfile.cannotRequestUserAccountDeleteError")
                    );
                }
            });
    }

    protected updateUserData(updatedData: Partial<UserData>): void {
        this.store
            .dispatch(new UserProfileActions.UpdateUserData(updatedData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.updateUserError);

                if (error) {
                    this.displayErrorMessage(error, "dssSharedLibUserProfile.updateUserErrorMessage");

                    return;
                }

                this.toastrService.success(this.translocoService.translate("dssSharedLibUserProfile.updateUserSuccessMessage"));
            });
    }

    protected changeNotificationsPage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.store.dispatch(new UserProfileActions.GetNotificationsList(queryParams));
    }

    protected refreshNotificationsList(): void {
        this.store.dispatch(UserProfileActions.GetNotificationsList);
    }

    protected updateNotificationState(data: NotificationStateChange): void {
        this.store
            .dispatch(new UserProfileActions.UpdateNotification(data.id, data.isEnabled))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.updateNotificationStateError);

                if (error) {
                    this.displayErrorMessage(error, "dssSharedLibUserProfile.notifications.updateNotificationErrorMessage");

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dssSharedLibUserProfile.notifications.updateNotificationSuccessMessage")
                );
            });
    }

    protected updateLanguage(language: LanguageCode): void {
        this.store.dispatch(new UIActions.SetActiveLanguage(language));
    }

    private displayErrorMessage(error: UserProfileError, defaultErrorMessage: string): void {
        switch (error.type) {
            case UserProfileErrorType.InvalidApplicationVersion:
                this.toastrService.error(this.translocoService.translate("dssSharedLibUserProfile.invalidVersionErrorMessage"));
                break;
            default:
                this.toastrService.error(this.translocoService.translate(defaultErrorMessage));
        }
    }
}
