import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ApplicationAssignee, FlightZonesManagementListItem } from "@dtm-frontend/dss-shared-lib";
import { ConfirmationDialogComponent, DialogService, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { AssigneeChangeParams, FlightZonesManagementTableColumns, TableActionButton } from "../../models/flight-zone-management.models";
import { NotamModalService } from "../../services/notam-modal.service";
import { AssigneeConfirmDialogComponent } from "../assignee-confirm-dialog/assignee-confirm-dialog.component";

interface ApplicationsManagementTableComponentState {
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    flightZoneApplications: FlightZonesManagementListItem[];
    expandedElement: FlightZonesManagementListItem | null;
    actionButtons: TableActionButton[];
    displayedColumns: FlightZonesManagementTableColumns;
    availableAssignees: ApplicationAssignee[];
    applicationsPage: Page | undefined;
    hasSupervisorPermissions: boolean;
    basePreviewRoutePath: string;
    currentUserId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-applications-management-table[flightZoneApplications][displayedColumns][actionButtons]",
    templateUrl: "./applications-management-table.component.html",
    styleUrls: ["./applications-management-table.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ApplicationsManagementTableComponent {
    protected readonly TableActionButton = TableActionButton;
    protected readonly flightZoneApplications$ = this.localStore.selectByKey("flightZoneApplications");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly actionButtons$ = this.localStore.selectByKey("actionButtons");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");
    protected readonly hasSupervisorPermissions$ = this.localStore.selectByKey("hasSupervisorPermissions");
    protected readonly basePreviewRoutePath$ = this.localStore.selectByKey("basePreviewRoutePath");

    @Input()
    public set flightZoneApplications(value: FlightZonesManagementListItem[] | undefined) {
        this.localStore.patchState({ flightZoneApplications: value ?? [] });
    }

    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }

    @Input()
    public set hasDataRetrievalError(value: boolean | undefined) {
        this.localStore.patchState({ hasDataRetrievalError: !!value });
    }

    @Input()
    public set displayedColumns(value: FlightZonesManagementTableColumns) {
        this.localStore.patchState({ displayedColumns: value });
    }

    @Input()
    public set actionButtons(value: TableActionButton[] | undefined) {
        this.localStore.patchState({ actionButtons: value ?? [] });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }

    @Input()
    public set availableAssignees(value: ApplicationAssignee[] | undefined) {
        this.localStore.patchState({ availableAssignees: value ?? [] });
    }

    @Input()
    public set currentUserId(value: string | undefined) {
        this.localStore.patchState({ currentUserId: value });
    }

    @Input()
    public set hasSupervisorPermissions(value: boolean | undefined) {
        this.localStore.patchState({ hasSupervisorPermissions: !!value });
    }

    @Input()
    public set basePreviewRoutePath(value: string) {
        this.localStore.patchState({ basePreviewRoutePath: value });
    }

    @Output() protected readonly listRefresh = new EventEmitter<void>();
    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly assigneeChange = new EventEmitter<AssigneeChangeParams>();
    @Output() protected readonly kmlDownload = new EventEmitter<FlightZonesManagementListItem>();

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationsManagementTableComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly notamModalService: NotamModalService
    ) {
        this.localStore.setState({
            flightZoneApplications: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            actionButtons: [],
            displayedColumns: [],
            availableAssignees: [],
            applicationsPage: undefined,
            hasSupervisorPermissions: false,
            basePreviewRoutePath: "/flight-zone",
            currentUserId: undefined,
        });
    }

    protected toggleExpandableRow(row: FlightZonesManagementListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");

        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }

    protected shouldDisplayActionButton(actionButtons: TableActionButton[], requiredButton: TableActionButton): boolean {
        return actionButtons.includes(requiredButton);
    }

    protected changeAssignee(flightZoneApplication: FlightZonesManagementListItem, isAlreadyAssigned: boolean): void {
        let availableAssignees = this.localStore.selectSnapshotByKey("availableAssignees");

        if (isAlreadyAssigned) {
            // NOTE: filter out assignee who is already assigned to this application
            availableAssignees = availableAssignees.filter((assignee) => assignee.id !== flightZoneApplication.assigneeId);
        }

        this.dialogService
            .open(AssigneeConfirmDialogComponent, { data: { availableAssignees } })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((assignee: ApplicationAssignee) => {
                this.assigneeChange.emit({ assigneeId: assignee.id, flightZoneApplication, isAlreadyAssigned });
            });
    }

    protected assignToMe(flightZoneApplication: FlightZonesManagementListItem): void {
        this.getAssignToMeConfirmationDialog()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.localStore.selectByKey("currentUserId")),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((currentUserId) => {
                this.assigneeChange.emit({ assigneeId: currentUserId, flightZoneApplication, isAlreadyAssigned: false });
            });
    }

    protected openNotamPreview(flightZoneId: string): void {
        this.notamModalService.openNotamPreview(flightZoneId);
    }

    private getAssignToMeConfirmationDialog(): Observable<boolean> {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.translocoService.translate(
                    "dssAdminLibFlightZoneManagement.applicationListShared.assignToMeConfirmDialog.dialogText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dssAdminLibFlightZoneManagement.applicationListShared.assignToMeConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dssAdminLibFlightZoneManagement.applicationListShared.assignToMeConfirmDialog.confirmButtonLabel"
                ),
            },
        });

        return dialogRef.afterClosed().pipe(map(Boolean));
    }
}
