<div class="layers-filters" [class.opened]="areFiltersOpened" *ngrxLet="areFiltersOpened$ as areFiltersOpened">
    <div>
        <button type="button" class="toggle-button" [class.opened]="areFiltersOpened" (click)="toggleFilters()">
            <dtm-ui-icon name="route"></dtm-ui-icon>
            {{ "dssSharedLibFlightZone.activeRestrictionsFilters.toggleButtonLabel" | transloco }}
            <dtm-ui-icon
                *ngIf="hasFiltersChanged$ | ngrxPush"
                name="error-warning"
                class="filters-changed-icon"
                [matTooltip]="'dssSharedLibFlightZone.activeRestrictionsFilters.filtersChangedInfoLabel' | transloco"
            ></dtm-ui-icon>
            <dtm-ui-icon [name]="areFiltersOpened ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
        </button>
    </div>
    <div class="filters-container" [class.visible]="areFiltersOpened">
        <dss-shared-lib-active-restrictions-filters
            (filtersChange)="updateFilters($event)"
            (filtersInitialStateChange)="filtersInitialStateChange($event)"
        ></dss-shared-lib-active-restrictions-filters>
    </div>
</div>

<ng-container *ngIf="showLayer$ | ngrxPush">
    <ac-map-layer-provider
        [provider]="MapLayerProviderOptions.WebMapService"
        *ngrxLet="queryFilter$ as queryFilter"
        [options]="
            $any({
                url: sharedMapEndpoints.geoServerEndpoint,
                layers: sharedMapEndpoints.layersPrefix,
                enablePickFeatures: true,
                parameters: {
                    transparent: true,
                    format: 'image/png',
                    CQL_FILTER: queryFilter
                },
                getFeatureInfoParameters: {
                    feature_count: FEATURE_COUNT,
                    CQL_FILTER: queryFilter
                }
            })
        "
    ></ac-map-layer-provider>

    <dss-shared-lib-active-restrictions-features-list
        [featuresData]="pickedFeaturesData$ | ngrxPush"
        [isProcessing]="isFeaturesDataLoading$ | ngrxPush"
    ></dss-shared-lib-active-restrictions-features-list>
</ng-container>
