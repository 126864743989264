<dtm-ui-loader-container
    *ngrxLet="{
        anspTeamsList: anspTeamsList$,
        isProcessing: isProcessing$,
        hasListDataRetrievalError: hasListDataRetrievalError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <div class="grid">
        <dtm-ui-error
            *ngIf="vm.hasListDataRetrievalError; else anspTeamsListTemplate"
            (reload)="refreshList()"
            [errorMessage]="'dssAdminLibAdministration.anspTeamsList.capabilitiesDownloadError' | transloco"
        ></dtm-ui-error>

        <ng-template #anspTeamsListTemplate>
            <dss-admin-lib-ansp-teams-list
                [teams]="vm.anspTeamsList?.content"
                [page]="vm.anspTeamsList?.page"
                [isProcessing]="vm.isProcessing"
                (pageChange)="changePage($event)"
                (moreDetailsLoad)="loadMoreDetails($event)"
                (memberAdd)="addMember($event)"
                (memberRemove)="removeMember($event)"
            ></dss-admin-lib-ansp-teams-list>
        </ng-template>
    </div>
</dtm-ui-loader-container>
