import {
    ApplicationAssignee,
    ApplicationAssignment,
    ApplicationType,
    FlightZoneApplicationReviewStatus,
    NotamLocation,
    RestrictionType,
} from "@dtm-frontend/dss-shared-lib";

export namespace FlightZoneApplicationActions {
    export class GetApplicationData {
        public static readonly type = "[Flight Zone Application] Get flight zone application data";

        constructor(public flightZoneId: string, public applicationType: ApplicationType) {}
    }

    export class RejectApplication {
        public static readonly type = "[Flight Zone Application] Reject application";

        constructor(public flightZoneId: string, public comment: string) {}
    }

    export class AcceptApplication {
        public static readonly type = "[Flight Zone Application] Accept application";

        constructor(public flightZoneId: string) {}
    }

    export class ToggleLockedStatus {
        public static readonly type = "[Flight Zone Application] Toggle application locked status";

        constructor(public flightZoneId: string, public shouldBeLocked: boolean) {}
    }

    export class UpdateNotamLocation {
        public static readonly type = "[Flight Zone Application] Update NOTAM location data";

        constructor(public flightZoneId: string, public location: NotamLocation) {}
    }

    export class ReviewApplication {
        public static readonly type = "[Flight Zone Application] Review application";

        constructor(public assignmentId: string, public reviewStatus: FlightZoneApplicationReviewStatus) {}
    }

    export class UpdateApplicationNote {
        public static readonly type = "[Flight Zone Application] Update application note";

        constructor(public flightZoneId: string, public note: string) {}
    }

    export class RejectApplicationReview {
        public static readonly type = "[Flight Zone Application] Reject application review";

        constructor(public assignment: ApplicationAssignment) {}
    }

    export class GetChatMessagesByFlightZoneId {
        public static readonly type = "[Flight Zone Application] Get chat messages for application changes";

        constructor(public flightZoneId: string) {}
    }

    export class PostChatMessage {
        public static readonly type = "[Flight Zone Application] Post chat message to application changes";

        constructor(public flightZoneId: string, public comment: string) {}
    }

    export class GetAvailableConsultants {
        public static readonly type = "[Flight Zone Application] Get available application consultants";
    }

    export class AssignConsultant {
        public static readonly type = "[Flight Zone Application] Assign consultant to application";

        constructor(public assignee: ApplicationAssignee, public flightZoneId: string) {}
    }

    export class GetAnspTeams {
        public static readonly type = "[Flight Zone Application] Get available ansp teams";
    }

    export class ChangeAnspTeam {
        public static readonly type = "[Flight Zone Application] Change ansp team assigned to application";

        constructor(public teamId: string, public flightZoneId: string) {}
    }

    export class UpdateAnspCaseNumber {
        public static readonly type = "[Flight Zone Application] Update ANSP case number";

        constructor(public anspCaseNumber: string) {}
    }

    export class GetAvailableRestrictionTypes {
        public static readonly type = "[Flight Zone Application] Get available restriction types";
    }

    export class ChangeSuggestedRestrictionType {
        public static readonly type = "[Flight Zone Application] Change suggested restriction type";

        constructor(public restrictionType: RestrictionType) {}
    }

    export class ChangeAdditionalReceivers {
        public static readonly type = "[Flight Zone Application] Update additional receivers emails";

        constructor(public emails: string[]) {}
    }

    export class ChangeDetailedDuration {
        public static readonly type = "[Flight Zone Application] Update detailed duration";

        constructor(public detailedDuration: string) {}
    }

    export class GetAirspaceClassificationCapabilities {
        public static readonly type = "[Flight Zone Application] Get airspace classification capabilities";
    }

    export class ChangeAirspaceClassification {
        public static readonly type = "[Flight Zone Application] Change airspace classification";

        constructor(public airspaceClassification: string) {}
    }

    export class GetForbiddenDesignatorRange {
        public static readonly type = "[Flight Zone Application] Get forbidden designator range";

        constructor(public restrictionType: RestrictionType) {}
    }

    export class SetCustomDesignator {
        public static readonly type = "[Flight Zone Application] Set custom designator";

        constructor(public designator: number) {}
    }

    export class RemoveCustomDesignator {
        public static readonly type = "[Flight Zone Application] Remove custom designator";
    }
}
