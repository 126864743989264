import { Params } from "@angular/router";
import { UserData } from "../models/user-profile.models";

export namespace UserProfileActions {
    export class GetUserData {
        public static readonly type = "[User profile] Get user data";
        constructor(public userId: string) {}
    }

    export class UpdateUserData {
        public static readonly type = "[User profile] Update user data";
        constructor(public updatedData: Partial<UserData>) {}
    }

    export class RequestUserAccountDelete {
        public static readonly type = "[User profile] Request user account delete";
    }

    export class ResetUserPassword {
        public static readonly type = "[User Profile] Reset user password";
    }

    export class GetNotificationsList {
        public static readonly type = "[User profile] Get  notifications list";

        constructor(public filterParams: Params) {}
    }

    export class UpdateNotification {
        public static readonly type = "[User profile] Update notification";

        constructor(public id: string, public isEnabled: boolean) {}
    }
}
