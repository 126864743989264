import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    FlightZoneApplicationPurpose,
    FlightZoneSelectOption,
    RestrictionExclusions,
    UavRestrictionExclusions,
} from "../../../models/flight-zone-shared.models";

interface UavExclusionsSummaryComponentState {
    restrictionExclusions: UavRestrictionExclusions | undefined;
}

@Component({
    selector: "dss-shared-lib-uav-exclusions-summary[restrictionExclusions]",
    templateUrl: "./uav-exclusions-summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavExclusionsSummaryComponent {
    public readonly restrictionExclusions$ = this.localStore.selectByKey("restrictionExclusions");

    @Input() public set restrictionExclusions(value: RestrictionExclusions | undefined) {
        if (value?.purpose !== FlightZoneApplicationPurpose.ForbiddingUAVFlights) {
            return;
        }

        this.localStore.patchState({ restrictionExclusions: value });
    }

    constructor(private readonly localStore: LocalComponentStore<UavExclusionsSummaryComponentState>) {
        this.localStore.setState({
            restrictionExclusions: undefined,
        });
    }

    public formatCoursesToDisplay(courses: FlightZoneSelectOption<string>[]): string {
        return courses.map((course) => course.title).join(", ");
    }
}
