import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { FlightZoneApplicationPurpose } from "@dtm-frontend/dss-shared-lib";
import { BasicAddress, PhoneNumber, phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { InstitutionDetails, InstitutionPurpose, NewInstitutionDetails } from "../../models/administration.models";

interface InstitutionDetailForm {
    name: FormControl<string | null>;
    unitName: FormControl<string | null>;
    email: FormControl<string | null>;
    phoneNumber: FormControl<PhoneNumber | null>;
    country: FormControl<string | null>;
    address: FormControl<BasicAddress | null>;
    purposes: FormArray<FormGroup<InstitutionPurposeForm>>;
}

interface InstitutionPurposeForm {
    purpose: FormControl<FlightZoneApplicationPurpose>;
    isSelected: FormControl<boolean>;
    hasStateSecurityRestriction: FormControl<boolean>;
}

@Component({
    selector: "dss-admin-lib-institution-detail",
    templateUrl: "./institution-detail.component.html",
    styleUrls: ["./institution-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstitutionDetailComponent {
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly institutionDetailForm = new FormGroup<InstitutionDetailForm>({
        name: new FormControl("", [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)]),
        unitName: new FormControl(""),
        email: new FormControl("", [Validators.email]),
        phoneNumber: new FormControl(null, {
            validators: [phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode],
        }),
        country: new FormControl("", [Validators.required]),
        address: new FormControl(null, [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)]),
        purposes: this.getPurposesArray(),
    });

    @Input() public set institutionDetails(value: InstitutionDetails | undefined) {
        if (!value) {
            return;
        }

        this.institutionDetailForm.patchValue({
            ...value,
            country: value.address?.country,
        });
        this.institutionDetailForm.controls.purposes = this.getPurposesArray(value.purposes);
    }

    @Output() public readonly cancel = new EventEmitter<void>();
    @Output() public readonly institutionDetailSave = new EventEmitter<NewInstitutionDetails>();

    protected saveForm(): void {
        this.institutionDetailForm.markAllAsTouched();

        if (this.institutionDetailForm.invalid) {
            return;
        }

        const formValues = this.institutionDetailForm.value;
        const fullAddress = { ...formValues.address, country: formValues.country };

        const value = {
            ...formValues,
            address: fullAddress,
            purposes: this.getSelectedPurposes(),
        };

        this.institutionDetailSave.emit(value as NewInstitutionDetails);
    }

    protected changeStateSecurityRestrictionDisabledState({ checked }: MatCheckboxChange, purposeIndex: number): void {
        const controlToUpdate = this.institutionDetailForm.controls.purposes.at(purposeIndex).controls.hasStateSecurityRestriction;

        if (checked) {
            controlToUpdate.enable();
        } else {
            controlToUpdate.disable();
        }
    }

    private getPurposesArray(savedPurposes?: InstitutionPurpose[]): FormArray<FormGroup<InstitutionPurposeForm>> {
        const allPurposes = Object.values(FlightZoneApplicationPurpose);

        return new FormArray(
            allPurposes.map((purpose) => {
                const matchingPurpose = savedPurposes?.find((savedPurpose) => savedPurpose.purpose === purpose);

                return new FormGroup<InstitutionPurposeForm>({
                    purpose: new FormControl(purpose, { nonNullable: true }),
                    isSelected: new FormControl(!!matchingPurpose, { nonNullable: true }),
                    hasStateSecurityRestriction: new FormControl(
                        { value: matchingPurpose?.hasStateSecurityRestriction ?? false, disabled: !matchingPurpose },
                        {
                            nonNullable: true,
                        }
                    ),
                });
            })
        );
    }

    private getSelectedPurposes(): InstitutionPurpose[] {
        return this.institutionDetailForm
            .getRawValue()
            .purposes.reduce((result: InstitutionPurpose[], { purpose, hasStateSecurityRestriction, isSelected }) => {
                if (isSelected) {
                    result.push({
                        purpose,
                        hasStateSecurityRestriction,
                    });
                }

                return result;
            }, []);
    }
}
