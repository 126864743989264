import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    EditableBoxComponent,
    PhoneNumber,
    phoneNumberAndCountryCodeRequired,
    validPhoneNumberWithCountryCode,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UserData } from "../../models/user-profile.models";

interface UserProfileContactComponentState {
    email: string | undefined;
    phoneNumber: PhoneNumber | undefined;
}

@Component({
    selector: "dss-shared-lib-user-profile-contact[email][phoneNumber]",
    templateUrl: "./user-profile-contact.component.html",
    styleUrls: ["./user-profile-contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserProfileContactComponent {
    protected readonly phoneNumberControl = new FormControl<PhoneNumber | undefined>(undefined, {
        validators: [phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode],
    });

    protected readonly email$ = this.localStore.selectByKey("email");
    protected readonly phoneNumber$ = this.localStore.selectByKey("phoneNumber").pipe(RxjsUtils.filterFalsy());

    @Input()
    public set email(value: string | undefined) {
        this.localStore.patchState({ email: value });
    }

    @Input()
    public set phoneNumber(value: PhoneNumber | undefined) {
        this.localStore.patchState({ phoneNumber: value });

        if (value) {
            this.phoneNumberControl.setValue(value);
        }
    }

    @Output() public readonly userDataUpdate = new EventEmitter<Partial<UserData>>();

    constructor(private readonly localStore: LocalComponentStore<UserProfileContactComponentState>) {
        this.localStore.setState({
            email: undefined,
            phoneNumber: undefined,
        });
    }

    protected save(editableBox: EditableBoxComponent, updatedData: Partial<UserData>): void {
        this.phoneNumberControl.markAsTouched();

        if (this.phoneNumberControl.valid) {
            this.userDataUpdate.emit(updatedData);
            editableBox.setEditMode(false);
        } else {
            this.phoneNumberControl.updateValueAndValidity();
        }
    }

    protected close(): void {
        this.phoneNumberControl.setValue(this.localStore.selectSnapshotByKey("phoneNumber"));
    }
}
