<div
    *ngrxLet="{
        isProcessing: isProcessing$,
        teams: teams$,
        page: page$,
    } as vm"
    class="teams-list"
>
    <mat-card *ngFor="let team of vm.teams">
        <div class="card-header">
            {{ team.name }}

            <button type="button" class="button-secondary" [routerLink]="team.id + '/work-calendar'">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dssAdminLibAdministration.anspTeamsList.editCalendarButtonLabel" | transloco }}
            </button>
        </div>

        <div *ngIf="team.members || team.restrictionPurposes; else loadMoreTemplate">
            <div *ngIf="team.restrictionPurposes as restrictionPurposes" class="purposes">
                <div class="purposes-header">{{ "dssAdminLibAdministration.anspTeamsList.purposesLabel" | transloco }}</div>
                <span *ngFor="let purpose of restrictionPurposes" class="purpose">
                    {{ "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel" | transloco : { value: purpose } }}
                </span>
            </div>

            <dss-admin-lib-members-list
                [members]="team.members"
                (memberRemove)="tryRemoveMember($event, team)"
            ></dss-admin-lib-members-list>

            <div class="list-footer">
                <button type="button" class="button-primary" (click)="memberAdd.emit(team)">
                    <dtm-ui-icon name="add-circle"></dtm-ui-icon>
                    {{ "dssAdminLibAdministration.anspTeamsList.addMemberButtonLabel" | transloco }}
                </button>
            </div>
        </div>

        <ng-template #loadMoreTemplate>
            <button type="button" class="button-secondary load-more" (click)="moreDetailsLoad.emit(team.id)">
                <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                {{ "dssAdminLibAdministration.anspTeamsList.loadMoreButtonLabel" | transloco }}
            </button>
        </ng-template>
    </mat-card>

    <dtm-ui-no-results *ngIf="!vm.teams.length && !vm.isProcessing"></dtm-ui-no-results>

    <dtm-ui-pagination *ngIf="vm.teams.length && vm.page as page" [pagination]="page" (page)="pageChange.emit($event)"> </dtm-ui-pagination>
</div>
