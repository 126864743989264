import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, of, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import {
    AdministrationCapabilities,
    AdministrationError,
    AnspStandardTeamWorkCalendar,
    AnspTeamDayOff,
    AnspTeamDetails,
    AnspTeamsList,
    DssUserType,
    InstitutionDetails,
    InstitutionsList,
    UserDetails,
    UsersList,
    UsersListItem,
} from "../models/administration.models";
import { AdministrationApiService } from "../services/administration-api.service";
import { AdministrationActions } from "./administration.actions";

interface AdministrationStateModel {
    listDataRetrievalError: AdministrationError | undefined;
    userDetailSaveError: AdministrationError | undefined;
    getCapabilitiesError: AdministrationError | undefined;
    capabilities: AdministrationCapabilities | undefined;
    isProcessing: boolean;
    usersList: UsersList | undefined;
    institutionsList: InstitutionsList | undefined;
    userDetails: UserDetails | undefined;
    getUserDetailsError: AdministrationError | undefined;
    userDeleteError: AdministrationError | undefined;
    availableInstitutionMembers: UsersListItem[];
    addInstitutionMemberError: AdministrationError | undefined;
    removeInstitutionMemberError: AdministrationError | undefined;
    institutionDetails: InstitutionDetails | undefined;
    getInstitutionDetailsError: AdministrationError | undefined;
    institutionDetailSaveError: AdministrationError | undefined;
    removeInstitutionError: AdministrationError | undefined;
    anspTeamsList: AnspTeamsList | undefined;
    anspTeamDetails: AnspTeamDetails | undefined;
    availableAnspTeamMembers: UsersListItem[];
    getAvailableMembersError: AdministrationError | undefined;
    addAnspTeamMemberError: AdministrationError | undefined;
    removeAnspTeamMemberError: AdministrationError | undefined;
    anspStandardTeamWorkCalendar: AnspStandardTeamWorkCalendar | undefined;
    getAnspTeamWorkCalendarError: AdministrationError | undefined;
    updateStandardTeamWorkCalendarError: AdministrationError | undefined;
    daysOff: AnspTeamDayOff[];
    addTeamDaysOffError: AdministrationError | undefined;
    removeTeamDayOffError: AdministrationError | undefined;
}

const defaultState: AdministrationStateModel = {
    listDataRetrievalError: undefined,
    userDetailSaveError: undefined,
    getCapabilitiesError: undefined,
    capabilities: undefined,
    isProcessing: false,
    usersList: undefined,
    institutionsList: undefined,
    userDetails: undefined,
    getUserDetailsError: undefined,
    userDeleteError: undefined,
    availableInstitutionMembers: [],
    addInstitutionMemberError: undefined,
    removeInstitutionMemberError: undefined,
    institutionDetails: undefined,
    getInstitutionDetailsError: undefined,
    institutionDetailSaveError: undefined,
    removeInstitutionError: undefined,
    anspTeamsList: undefined,
    anspTeamDetails: undefined,
    availableAnspTeamMembers: [],
    getAvailableMembersError: undefined,
    addAnspTeamMemberError: undefined,
    removeAnspTeamMemberError: undefined,
    anspStandardTeamWorkCalendar: undefined,
    getAnspTeamWorkCalendarError: undefined,
    updateStandardTeamWorkCalendarError: undefined,
    daysOff: [],
    addTeamDaysOffError: undefined,
    removeTeamDayOffError: undefined,
};

@State<AdministrationStateModel>({
    name: "administration",
    defaults: defaultState,
})
@Injectable()
export class AdministrationState {
    @Selector()
    public static listDataRetrievalError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.listDataRetrievalError;
    }

    @Selector()
    public static userDetailSaveError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.userDetailSaveError;
    }

    @Selector()
    public static getCapabilitiesError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.getCapabilitiesError;
    }

    @Selector()
    public static capabilities(state: AdministrationStateModel): AdministrationCapabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static isProcessing(state: AdministrationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static usersList(state: AdministrationStateModel): UsersList | undefined {
        return state.usersList;
    }

    @Selector()
    public static institutionsList(state: AdministrationStateModel): InstitutionsList | undefined {
        return state.institutionsList;
    }

    @Selector()
    public static userDetails(state: AdministrationStateModel): UserDetails | undefined {
        return state.userDetails;
    }

    @Selector()
    public static getUserDetailsError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.getUserDetailsError;
    }

    @Selector()
    public static userDeleteError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.userDeleteError;
    }

    @Selector()
    public static availableInstitutionMembers(state: AdministrationStateModel): UsersListItem[] {
        return state.availableInstitutionMembers;
    }

    @Selector()
    public static addInstitutionMemberError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.addInstitutionMemberError;
    }

    @Selector()
    public static removeInstitutionMemberError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.removeInstitutionMemberError;
    }

    @Selector()
    public static institutionDetails(state: AdministrationStateModel): InstitutionDetails | undefined {
        return state.institutionDetails;
    }

    @Selector()
    public static getInstitutionDetailsError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.getInstitutionDetailsError;
    }

    @Selector()
    public static institutionDetailSaveError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.institutionDetailSaveError;
    }

    @Selector()
    public static removeInstitutionError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.removeInstitutionError;
    }

    @Selector()
    public static anspTeamsList(state: AdministrationStateModel): AnspTeamsList | undefined {
        return state.anspTeamsList;
    }

    @Selector()
    public static anspTeamDetails(state: AdministrationStateModel): AnspTeamDetails | undefined {
        return state.anspTeamDetails;
    }

    @Selector()
    public static availableAnspTeamMembers(state: AdministrationStateModel): UsersListItem[] {
        return state.availableAnspTeamMembers;
    }

    @Selector()
    public static getAvailableMembersError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.getAvailableMembersError;
    }

    @Selector()
    public static addAnspTeamMemberError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.addAnspTeamMemberError;
    }

    @Selector()
    public static removeAnspTeamMemberError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.removeAnspTeamMemberError;
    }

    @Selector()
    public static anspStandardTeamWorkCalendar(state: AdministrationStateModel): AnspStandardTeamWorkCalendar | undefined {
        return state.anspStandardTeamWorkCalendar;
    }

    @Selector()
    public static getAnspTeamWorkCalendarError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.getAnspTeamWorkCalendarError;
    }

    @Selector()
    public static updateStandardTeamWorkCalendarError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.updateStandardTeamWorkCalendarError;
    }

    @Selector()
    public static daysOff(state: AdministrationStateModel): AnspTeamDayOff[] {
        return state.daysOff;
    }

    @Selector()
    public static addTeamDaysOffError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.addTeamDaysOffError;
    }

    @Selector()
    public static removeTeamDayOffError(state: AdministrationStateModel): AdministrationError | undefined {
        return state.removeTeamDayOffError;
    }

    constructor(private readonly administrationApiService: AdministrationApiService) {}

    @Action(AdministrationActions.GetUsersList, { cancelUncompleted: true })
    public getUsersList(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetUsersList) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.getUsersList(action.filterParams).pipe(
            tap((usersList) => {
                context.patchState({
                    usersList,
                    listDataRetrievalError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((listDataRetrievalError) => {
                context.patchState({
                    listDataRetrievalError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.GetInstitutionsList, { cancelUncompleted: true })
    public getInstitutionsList(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetInstitutionsList) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.getInstitutionsList(action.filterParams).pipe(
            tap((institutionsList) => {
                context.patchState({
                    institutionsList,
                    listDataRetrievalError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((listDataRetrievalError) => {
                context.patchState({
                    listDataRetrievalError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.AddInstitutionMembers)
    public addInstitutionMembers(context: StateContext<AdministrationStateModel>, action: AdministrationActions.AddInstitutionMembers) {
        const currentInstitutionsList = context.getState().institutionsList;
        const listWithMembers = currentInstitutionsList
            ? {
                  ...currentInstitutionsList,
                  content:
                      currentInstitutionsList.content.map((institution) =>
                          institution.id === action.institutionId
                              ? {
                                    ...institution,
                                    members: action.members,
                                }
                              : institution
                      ) ?? [],
              }
            : undefined;

        context.patchState({
            institutionsList: listWithMembers,
        });
    }

    @Action(AdministrationActions.GetInstitutionDetails)
    public getInstitutionDetails(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetInstitutionDetails) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.getInstitutionDetails(action.institutionId).pipe(
            tap((institutionDetails) => {
                context.dispatch(new AdministrationActions.AddInstitutionMembers(action.institutionId, institutionDetails.members));

                context.patchState({
                    institutionDetails,
                    listDataRetrievalError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((listDataRetrievalError) => {
                context.patchState({
                    listDataRetrievalError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.GetAvailableInstitutionMembers)
    public getAvailableInstitutionMembers(context: StateContext<AdministrationStateModel>) {
        context.patchState({ isProcessing: true, getAvailableMembersError: undefined });

        return this.administrationApiService
            .getUsersList({ userType: [DssUserType.InstitutionSubordinate, DssUserType.InstitutionSupervisor], unassigned: true })
            .pipe(
                tap((usersList) => {
                    context.patchState({
                        availableInstitutionMembers: usersList.content,
                        isProcessing: false,
                    });
                }),
                catchError((getAvailableMembersError) => {
                    context.patchState({
                        getAvailableMembersError,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(AdministrationActions.AddInstitutionMember)
    public addInstitutionMember(context: StateContext<AdministrationStateModel>, action: AdministrationActions.AddInstitutionMember) {
        context.patchState({ isProcessing: true, addInstitutionMemberError: undefined });

        const institutionMemberIds = [...(action.institution.members?.map(({ id }) => id) ?? []), action.memberId];

        return this.administrationApiService.assignInstitutionMembers(action.institution.id, institutionMemberIds).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetInstitutionDetails(action.institution.id));
            }),
            catchError((addInstitutionMemberError) => {
                context.patchState({ addInstitutionMemberError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.RemoveInstitutionMember)
    public removeInstitutionMember(context: StateContext<AdministrationStateModel>, action: AdministrationActions.RemoveInstitutionMember) {
        context.patchState({ isProcessing: true, removeInstitutionMemberError: undefined });

        return this.administrationApiService.removeInstitutionMember(action.institution.id, action.memberId).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetInstitutionDetails(action.institution.id));
            }),
            catchError((removeInstitutionMemberError) => {
                context.patchState({ removeInstitutionMemberError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.GetCapabilities)
    public getCapabilities(context: StateContext<AdministrationStateModel>) {
        const currentState = context.getState();

        if (currentState.capabilities) {
            return of(currentState);
        }

        context.patchState({ isProcessing: true });

        return this.administrationApiService.getCapabilities().pipe(
            tap((capabilities) => {
                context.patchState({
                    capabilities,
                    getCapabilitiesError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((getCapabilitiesError) => {
                context.patchState({
                    getCapabilitiesError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.SaveNewUser)
    public saveNewUser(context: StateContext<AdministrationStateModel>, action: AdministrationActions.SaveNewUser) {
        context.patchState({ isProcessing: true, userDetailSaveError: undefined });

        return this.administrationApiService.createUser(action.data).pipe(
            catchError((userDetailSaveError) => {
                context.patchState({ userDetailSaveError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.GetUserDetails)
    public getUserDetails(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetUserDetails) {
        context.patchState({ isProcessing: true, userDetails: undefined });

        return this.administrationApiService.getUserDetails(action.userId).pipe(
            tap((userDetails) => {
                context.patchState({
                    userDetails,
                    getUserDetailsError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((getUserDetailsError) => {
                context.patchState({
                    getUserDetailsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.ClearUserDetails)
    public clearUserDetails(context: StateContext<AdministrationStateModel>) {
        context.patchState({ userDetails: undefined, getUserDetailsError: undefined });
    }

    @Action(AdministrationActions.UpdateUserDetails)
    public updateUserDetails(context: StateContext<AdministrationStateModel>, action: AdministrationActions.UpdateUserDetails) {
        const currentUserDetails = context.getState().userDetails;

        const userId = currentUserDetails?.id;
        const version = currentUserDetails?.version ?? 0;

        if (!userId) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.administrationApiService.updateUser({ ...action.data, id: userId, version }).pipe(
            tap((userDetails) => {
                context.patchState({
                    userDetails,
                    userDetailSaveError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((userDetailSaveError) => {
                context.patchState({
                    userDetailSaveError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.DeleteUser)
    public deleteUser(context: StateContext<AdministrationStateModel>, action: AdministrationActions.DeleteUser) {
        context.patchState({ isProcessing: true, userDeleteError: undefined });

        return this.administrationApiService.deleteUser(action.userId).pipe(
            catchError((userDeleteError) => {
                context.patchState({ userDeleteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(AdministrationActions.SaveNewInstitution)
    public saveNewInstitution(context: StateContext<AdministrationStateModel>, action: AdministrationActions.SaveNewInstitution) {
        context.patchState({ isProcessing: true, institutionDetailSaveError: undefined });

        return this.administrationApiService.saveNewInstitution(action.data).pipe(
            catchError((institutionDetailSaveError) => {
                context.patchState({ institutionDetailSaveError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.UpdateInstitutionDetails)
    public updateInstitutionDetails(
        context: StateContext<AdministrationStateModel>,
        action: AdministrationActions.UpdateInstitutionDetails
    ) {
        const currentInstitutionDetails = context.getState().institutionDetails;

        if (!currentInstitutionDetails) {
            return;
        }

        const institutionId = currentInstitutionDetails.id;
        const version = currentInstitutionDetails.version ?? 0;

        context.patchState({ isProcessing: true });

        return this.administrationApiService.updateInstitutionDetails(institutionId, action.data, version).pipe(
            tap((institutionDetails) => {
                context.patchState({
                    institutionDetails,
                    institutionDetailSaveError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((institutionDetailSaveError) => {
                context.patchState({
                    institutionDetailSaveError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.ClearInstitutionDetails)
    public clearInstitutionDetails(context: StateContext<AdministrationStateModel>) {
        context.patchState({ institutionDetails: undefined, getInstitutionDetailsError: undefined });
    }

    @Action(AdministrationActions.RemoveInstitution)
    public removeInstitution(context: StateContext<AdministrationStateModel>, action: AdministrationActions.RemoveInstitution) {
        context.patchState({ isProcessing: true, removeInstitutionError: undefined });

        return this.administrationApiService.removeInstitution(action.institution.id).pipe(
            catchError((removeInstitutionError) => {
                context.patchState({ removeInstitutionError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.GetAnspTeamsList, { cancelUncompleted: true })
    public getAnspTeamsList(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetAnspTeamsList) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.getAnspTeamsList(action.filterParams).pipe(
            tap((anspTeamsList) => {
                context.patchState({
                    anspTeamsList,
                    listDataRetrievalError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((listDataRetrievalError) => {
                context.patchState({
                    listDataRetrievalError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.GetAnspTeamDetails)
    public getAnspTeamDetails(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetAnspTeamDetails) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.getAnspTeamDetails(action.teamId).pipe(
            tap((institutionDetails) => {
                const currentAnspTeamsList = context.getState().anspTeamsList;
                const listWithDetails = currentAnspTeamsList
                    ? {
                          ...currentAnspTeamsList,
                          content:
                              currentAnspTeamsList.content.map((team) =>
                                  team.id === action.teamId
                                      ? {
                                            ...team,
                                            restrictionPurposes: institutionDetails.restrictionPurposes,
                                            members: institutionDetails.members,
                                        }
                                      : team
                              ) ?? [],
                      }
                    : undefined;

                context.patchState({
                    anspTeamsList: listWithDetails,
                    listDataRetrievalError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((listDataRetrievalError) => {
                context.patchState({
                    listDataRetrievalError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.GetAnspTeamWorkCalendar)
    public getAnspTeamWorkCalendar(context: StateContext<AdministrationStateModel>, action: AdministrationActions.GetAnspTeamWorkCalendar) {
        context.patchState({ isProcessing: true, anspStandardTeamWorkCalendar: undefined });

        return this.administrationApiService.getTeamWorkCalendar(action.teamId).pipe(
            tap((anspTeamWorkCalendar) => {
                context.patchState({
                    anspStandardTeamWorkCalendar: anspTeamWorkCalendar.standardTeamCalendar,
                    daysOff: anspTeamWorkCalendar.daysOff,
                    getAnspTeamWorkCalendarError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((getAnspTeamWorkCalendarError) => {
                context.patchState({
                    getAnspTeamWorkCalendarError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.UpdateStandardTeamWorkCalendar)
    public updateStandardTeamWorkCalendar(
        context: StateContext<AdministrationStateModel>,
        action: AdministrationActions.UpdateStandardTeamWorkCalendar
    ) {
        context.patchState({ isProcessing: true });

        return this.administrationApiService.updateTeamWorkCalendar(action.calendar).pipe(
            tap((anspStandardTeamWorkCalendar) => {
                context.patchState({
                    anspStandardTeamWorkCalendar,
                    updateStandardTeamWorkCalendarError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((updateStandardTeamWorkCalendarError) => {
                context.patchState({
                    updateStandardTeamWorkCalendarError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.AddDaysOffToTeamCalendar)
    public addDaysOffToTeamCalendar(
        context: StateContext<AdministrationStateModel>,
        action: AdministrationActions.AddDaysOffToTeamCalendar
    ) {
        const currentAnspTeamWorkCalendar = context.getState().anspStandardTeamWorkCalendar;

        if (!currentAnspTeamWorkCalendar) {
            return;
        }

        context.patchState({ isProcessing: true, addTeamDaysOffError: undefined });

        return this.administrationApiService.addTeamDaysOff(action.daysOff, currentAnspTeamWorkCalendar.id).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetAnspTeamWorkCalendar(currentAnspTeamWorkCalendar.teamId));
            }),
            catchError((addTeamDaysOffError) => {
                context.patchState({
                    addTeamDaysOffError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.RemoveDayOffFromTeamCalendar)
    public removeDayOffFromTeamCalendar(
        context: StateContext<AdministrationStateModel>,
        action: AdministrationActions.RemoveDayOffFromTeamCalendar
    ) {
        const currentAnspTeamWorkCalendar = context.getState().anspStandardTeamWorkCalendar;

        if (!currentAnspTeamWorkCalendar) {
            return;
        }

        context.patchState({ isProcessing: true, removeTeamDayOffError: undefined });

        return this.administrationApiService.removeTeamDayOff(action.dayOffId).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetAnspTeamWorkCalendar(currentAnspTeamWorkCalendar.teamId));
            }),
            catchError((removeTeamDayOffError) => {
                context.patchState({
                    removeTeamDayOffError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(AdministrationActions.GetAvailableAnspTeamMembers)
    public getAvailableAnspTeamMembers(context: StateContext<AdministrationStateModel>) {
        context.patchState({ isProcessing: true, getAvailableMembersError: undefined });

        return this.administrationApiService
            .getUsersList({ userType: [DssUserType.AnspSubordinate, DssUserType.AnspSupervisor], unassigned: true })
            .pipe(
                tap((usersList) => {
                    context.patchState({
                        availableAnspTeamMembers: usersList.content,
                        isProcessing: false,
                    });
                }),
                catchError((getAvailableMembersError) => {
                    context.patchState({
                        getAvailableMembersError,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(AdministrationActions.AddAnspTeamMember)
    public addAnspTeamMember(context: StateContext<AdministrationStateModel>, action: AdministrationActions.AddAnspTeamMember) {
        context.patchState({ isProcessing: true, addAnspTeamMemberError: undefined });

        const teamMemberIds = [...(action.team.members?.map(({ id }) => id) ?? []), action.memberId];

        return this.administrationApiService.assignAnspTeamMembers(action.team.id, teamMemberIds).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetAnspTeamDetails(action.team.id));
            }),
            catchError((addAnspTeamMemberError) => {
                context.patchState({ addAnspTeamMemberError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(AdministrationActions.RemoveAnspTeamMember)
    public removeAnspTeamMember(context: StateContext<AdministrationStateModel>, action: AdministrationActions.RemoveAnspTeamMember) {
        context.patchState({ isProcessing: true, removeAnspTeamMemberError: undefined });

        return this.administrationApiService.removeAnspTeamMember(action.team.id, action.memberId).pipe(
            tap(() => {
                context.dispatch(new AdministrationActions.GetAnspTeamDetails(action.team.id));
            }),
            catchError((removeAnspTeamMemberError) => {
                context.patchState({ removeAnspTeamMemberError });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }
}
