import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { ApplicationType, FlightZoneApplicationStatus } from "../../models/flight-zone-shared.models";

interface ApplicationPreviewHeaderComponentState {
    isApplicationLocked: boolean;
    previewTitle: string;
    areEditActionsEnabled: boolean;
    hasSupervisorPermissions: boolean;
    status: FlightZoneApplicationStatus | undefined;
    applicationType: ApplicationType | undefined;
}

@Component({
    selector: "dss-shared-lib-application-preview-header[previewTitle][isApplicationLocked][applicationStatus]",
    templateUrl: "./application-preview-header.component.html",
    styleUrls: ["./application-preview-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationPreviewHeaderComponent {
    @Input() public set previewTitle(value: string) {
        this.localStore.patchState({ previewTitle: value });
    }

    @Input() public set isApplicationLocked(value: BooleanInput) {
        this.localStore.patchState({ isApplicationLocked: coerceBooleanProperty(value) });
    }

    @Input() public set areEditActionsEnabled(value: BooleanInput) {
        this.localStore.patchState({ areEditActionsEnabled: coerceBooleanProperty(value) });
    }

    @Input() public set hasSupervisorPermissions(value: BooleanInput) {
        this.localStore.patchState({ hasSupervisorPermissions: coerceBooleanProperty(value) });
    }

    @Input() public set applicationStatus(value: FlightZoneApplicationStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    @Input() public set applicationType(value: ApplicationType | undefined) {
        this.localStore.patchState({ applicationType: value });
    }

    @Output() protected readonly changeLockedStatus = new EventEmitter<boolean>();
    @Output() protected readonly enableChat = new EventEmitter<void>();
    @Output() protected readonly assignConsultant = new EventEmitter<void>();
    @Output() protected readonly changeTeam = new EventEmitter<void>();
    @Output() protected readonly cannotChangeTeam = new EventEmitter<void>();
    @Output() protected readonly confirmationOfApplicationSubmissionPdfDownload = new EventEmitter<void>();

    protected readonly FlightZoneApplicationStatus = FlightZoneApplicationStatus;
    protected readonly areEditActionsEnabled$ = this.localStore.selectByKey("areEditActionsEnabled");
    protected readonly isApplicationLocked$ = this.localStore.selectByKey("isApplicationLocked");
    protected readonly previewTitle$ = this.localStore.selectByKey("previewTitle");
    protected readonly hasSupervisorPermissions$ = this.localStore.selectByKey("hasSupervisorPermissions");
    protected readonly status$ = this.localStore.selectByKey("status");
    protected readonly isRestrictionModification$ = this.localStore
        .selectByKey("applicationType")
        .pipe(map((applicationType) => applicationType === ApplicationType.RestrictionModificationApplication));
    protected readonly isConfirmationDownloadEnabled$ = this.isRestrictionModification$.pipe(
        map((isRestrictionModification) => !isRestrictionModification)
    );

    constructor(private readonly localStore: LocalComponentStore<ApplicationPreviewHeaderComponentState>) {
        this.localStore.setState({
            isApplicationLocked: true,
            previewTitle: "",
            areEditActionsEnabled: false,
            hasSupervisorPermissions: false,
            status: undefined,
            applicationType: undefined,
        });
    }
}
