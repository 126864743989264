import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    AnspTeam,
    ApplicationAssignee,
    DesignatorRange,
    FlightZoneApiService,
    FlightZoneApplication,
    FlightZoneApplicationChatMessage,
    FlightZoneError,
    FlightZoneErrorType,
    RestrictionType,
} from "@dtm-frontend/dss-shared-lib";
import { TranslocoService } from "@jsverse/transloco";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { EMPTY, of, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { FlightZoneApplicationActions } from "./flight-zone-application.actions";

interface FlightZoneApplicationStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    applicationData: FlightZoneApplication | undefined;
    chatMessages: FlightZoneApplicationChatMessage[];
    availableConsultants: ApplicationAssignee[];
    availableRestrictionTypes: RestrictionType[];
    availableAirspaceClassifications: string[];
    anspTeams: AnspTeam[];
    forbiddenDesignatorRange: DesignatorRange | undefined;
}

const defaultState: FlightZoneApplicationStateModel = {
    error: undefined,
    isProcessing: false,
    applicationData: undefined,
    chatMessages: [],
    availableConsultants: [],
    availableRestrictionTypes: [],
    availableAirspaceClassifications: [],
    anspTeams: [],
    forbiddenDesignatorRange: undefined,
};

@State<FlightZoneApplicationStateModel>({
    name: "flightZoneApplication",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneApplicationState {
    @Selector()
    public static error(state: FlightZoneApplicationStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static isProcessing(state: FlightZoneApplicationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static applicationData(state: FlightZoneApplicationStateModel): FlightZoneApplication | undefined {
        return state.applicationData;
    }

    @Selector()
    public static flightZoneId(state: FlightZoneApplicationStateModel): string | undefined {
        return state.applicationData?.flightZoneId;
    }

    @Selector()
    public static chatMessages(state: FlightZoneApplicationStateModel): FlightZoneApplicationChatMessage[] {
        return state.chatMessages;
    }

    @Selector()
    public static availableConsultants(state: FlightZoneApplicationStateModel): ApplicationAssignee[] {
        return state.availableConsultants;
    }

    @Selector()
    public static availableRestrictionTypes(state: FlightZoneApplicationStateModel): string[] {
        return state.availableRestrictionTypes;
    }

    @Selector()
    public static availableAirspaceClassifications(state: FlightZoneApplicationStateModel): string[] {
        return state.availableAirspaceClassifications;
    }

    @Selector()
    public static anspTeams(state: FlightZoneApplicationStateModel): AnspTeam[] {
        return state.anspTeams;
    }

    @Selector()
    public static forbiddenDesignatorRange(state: FlightZoneApplicationStateModel): DesignatorRange | undefined {
        return state.forbiddenDesignatorRange;
    }

    constructor(
        private readonly flightZoneApi: FlightZoneApiService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router
    ) {}

    @Action(FlightZoneApplicationActions.GetApplicationData)
    public getFlightZoneApplicationData(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.GetApplicationData
    ) {
        context.patchState({ isProcessing: true, applicationData: undefined });

        return this.flightZoneApi.getFlightZoneApplicationData(action.flightZoneId, action.applicationType, true).pipe(
            tap((applicationData: FlightZoneApplication) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                if (error.type === FlightZoneErrorType.NotAuthorized) {
                    this.toastService.error(
                        this.transloco.translate("dssAdminLibFlightZoneManagement.applicationNotAuthorizedErrorMessage")
                    );

                    this.router.navigateByUrl("/");
                }

                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.RejectApplication)
    public rejectFlightZoneApplication(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.RejectApplication
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        const version = currentApplicationDataState.analysisStatus.version;
        const applicationType = currentApplicationDataState.applicationType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.rejectFlightZoneManagementApplication(action.flightZoneId, action.comment, version, applicationType).pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.AcceptApplication)
    public sendFlightZoneApplication(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.AcceptApplication
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus?.suggestedRestrictionType) {
            return;
        }

        const version = currentApplicationDataState.analysisStatus.version;
        const applicationType = currentApplicationDataState.applicationType;
        const restrictionType = currentApplicationDataState.analysisStatus.suggestedRestrictionType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.acceptFlightZoneApplication(action.flightZoneId, version, applicationType, restrictionType).pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ToggleLockedStatus)
    public toggleLockedStatus(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ToggleLockedStatus
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        let version = currentApplicationDataState.analysisStatus.version;
        const applicationType = currentApplicationDataState.applicationType;

        const apiMethod$ = action.shouldBeLocked
            ? this.flightZoneApi.lockFlightZoneApplication(action.flightZoneId, version, applicationType)
            : this.flightZoneApi.unlockFlightZoneApplication(action.flightZoneId, version, applicationType);

        const updatedState: FlightZoneApplication = {
            ...currentApplicationDataState,
            analysisStatus: {
                ...currentApplicationDataState.analysisStatus,
                isLocked: action.shouldBeLocked,
                version: ++version,
            },
        };

        context.patchState({ isProcessing: true });

        return apiMethod$.pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                    applicationData: updatedState,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ReviewApplication)
    public reviewApplication(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ReviewApplication
    ) {
        const currentApplicationDataState = context.getState().applicationData;
        const version = currentApplicationDataState?.analysisStatus?.version;

        if (!currentApplicationDataState) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.reviewFlightZoneApplication(action.assignmentId, action.reviewStatus, version).pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.RejectApplicationReview)
    public rejectApplicationReview(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.RejectApplicationReview
    ) {
        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.rejectApplicationReview(action.assignment.id, action.assignment.version).pipe(
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(FlightZoneApplicationActions.UpdateApplicationNote)
    public updateApplicationNote(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.UpdateApplicationNote
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus?.version) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .updateApplicationNote(
                action.flightZoneId,
                action.note,
                currentApplicationDataState.applicationType,
                currentApplicationDataState.analysisStatus.version
            )
            .pipe(
                tap((applicationData) => {
                    context.patchState({
                        applicationData,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneApplicationActions.UpdateNotamLocation)
    public updateNotamLocation(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.UpdateNotamLocation
    ) {
        const version = context.getState().applicationData?.analysisStatus?.version;

        if (!version) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.updateNotamLocation(action.flightZoneId, action.location, version).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.GetChatMessagesByFlightZoneId)
    public getChatMessagesByFlightZoneId(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.GetChatMessagesByFlightZoneId
    ) {
        context.patchState({ isProcessing: true, chatMessages: [] });

        return this.flightZoneApi.getChatMessages(action.flightZoneId).pipe(
            tap((chatMessages) => {
                context.patchState({
                    chatMessages,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.PostChatMessage)
    public postChatMessage(context: StateContext<FlightZoneApplicationStateModel>, action: FlightZoneApplicationActions.PostChatMessage) {
        const applicationType = context.getState().applicationData?.applicationType;

        if (!applicationType) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.postChatMessage(action.flightZoneId, applicationType, action.comment).pipe(
            tap((newMessage) => {
                context.patchState({
                    chatMessages: [...context.getState().chatMessages, newMessage],
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.GetAvailableConsultants)
    public getAvailableConsultants(context: StateContext<FlightZoneApplicationStateModel>) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getConsultants().pipe(
            tap((availableConsultants) => {
                context.patchState({
                    availableConsultants,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.AssignConsultant)
    public assignConsultant(context: StateContext<FlightZoneApplicationStateModel>, action: FlightZoneApplicationActions.AssignConsultant) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState) {
            return;
        }

        const isUserAlreadyAssigned = currentApplicationDataState.consultations?.some(
            (consultation) => consultation.consultant.id === action.assignee.id
        );
        if (isUserAlreadyAssigned) {
            context.patchState({ error: { type: FlightZoneErrorType.ConsultantAlreadyAssigned } });

            return;
        }

        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi
            .assignConsultant(action.assignee.id, action.flightZoneId, currentApplicationDataState.applicationType)
            .pipe(
                tap((consultation) => {
                    context.patchState({
                        applicationData: {
                            ...currentApplicationDataState,
                            consultations: [...(currentApplicationDataState.consultations ?? []), consultation],
                        },
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneApplicationActions.GetAnspTeams)
    public getAnspTeams(context: StateContext<FlightZoneApplicationStateModel>) {
        const currentState = context.getState();

        if (currentState.anspTeams.length) {
            return of(currentState);
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getAnspTeams().pipe(
            tap((anspTeams) => {
                context.patchState({
                    anspTeams,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ChangeAnspTeam)
    public changeAnspTeam(context: StateContext<FlightZoneApplicationStateModel>, action: FlightZoneApplicationActions.ChangeAnspTeam) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.changeAnspTeam(action.teamId, action.flightZoneId).pipe(
            tap(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.UpdateAnspCaseNumber)
    public updateAnspCaseNumber(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.UpdateAnspCaseNumber
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        const id = currentApplicationDataState.analysisStatus.id;
        const version = currentApplicationDataState.analysisStatus.version;
        const applicationType = currentApplicationDataState.applicationType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.updateAnspCaseNumber(id, version, applicationType, action.anspCaseNumber).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ChangeAdditionalReceivers)
    public changeAdditionalReceivers(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ChangeAdditionalReceivers
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        const id = currentApplicationDataState.analysisStatus.id;
        const version = currentApplicationDataState.analysisStatus.version;
        const applicationType = currentApplicationDataState.applicationType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.changeAdditionalReceivers(id, version, applicationType, action.emails).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ChangeDetailedDuration)
    public changeDetailedDuration(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ChangeDetailedDuration
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        const { id, version } = currentApplicationDataState.analysisStatus;
        const applicationType = currentApplicationDataState.applicationType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.changeDetailedDuration(applicationType, id, version, action.detailedDuration).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.GetAirspaceClassificationCapabilities)
    public getAirspaceClassificationCapabilities(context: StateContext<FlightZoneApplicationStateModel>) {
        const currentState = context.getState();

        if (currentState.availableAirspaceClassifications.length) {
            return of(currentState);
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getAirspaceClassificationCapabilities().pipe(
            tap((availableAirspaceClassifications) => {
                context.patchState({
                    availableAirspaceClassifications,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ChangeAirspaceClassification)
    public changeAirspaceClassification(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ChangeAirspaceClassification
    ) {
        const currentApplicationDataState = context.getState().applicationData;

        if (!currentApplicationDataState?.analysisStatus) {
            return;
        }

        const { id, version } = currentApplicationDataState.analysisStatus;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.changeAirspaceClassification(id, version, action.airspaceClassification).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.GetAvailableRestrictionTypes)
    public getAvailableRestrictionTypes(context: StateContext<FlightZoneApplicationStateModel>) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getAvailableRestrictionTypes().pipe(
            tap((availableRestrictionTypes) => {
                context.patchState({
                    availableRestrictionTypes,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.ChangeSuggestedRestrictionType)
    public changeSuggestedRestrictionType(
        context: StateContext<FlightZoneApplicationStateModel>,
        action: FlightZoneApplicationActions.ChangeSuggestedRestrictionType
    ) {
        const currentApplicationDataState = context.getState().applicationData;
        const flightZoneId = currentApplicationDataState?.flightZoneId;
        const version = currentApplicationDataState?.analysisStatus?.version ?? 0;

        if (!flightZoneId) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.changeSuggestedRestrictionType(action.restrictionType, flightZoneId, version).pipe(
            tap((applicationData) => {
                context.patchState({
                    applicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneApplicationActions.GetForbiddenDesignatorRange)
    public getForbiddenDesignatorRange(
        context: StateContext<FlightZoneApplicationStateModel>,
        { restrictionType }: FlightZoneApplicationActions.GetForbiddenDesignatorRange
    ) {
        context.patchState({ isProcessing: true, forbiddenDesignatorRange: undefined });

        return this.flightZoneApi.getForbiddenDesignatorRange(restrictionType).pipe(
            tap((range) => context.patchState({ forbiddenDesignatorRange: range })),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(FlightZoneApplicationActions.SetCustomDesignator)
    public setCustomDesignator(
        context: StateContext<FlightZoneApplicationStateModel>,
        { designator }: FlightZoneApplicationActions.SetCustomDesignator
    ) {
        context.patchState({ isProcessing: true, error: undefined });

        const currentApplicationDataState = context.getState().applicationData;
        const flightZoneId = currentApplicationDataState?.flightZoneId;
        const version = currentApplicationDataState?.analysisStatus?.version ?? 0;

        if (!flightZoneId) {
            return;
        }

        return this.flightZoneApi.setCustomDesignator(designator, flightZoneId, version).pipe(
            tap((applicationData) => {
                context.patchState({ applicationData });
            }),
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(FlightZoneApplicationActions.RemoveCustomDesignator)
    public removeCustomDesignator(context: StateContext<FlightZoneApplicationStateModel>) {
        context.patchState({ isProcessing: true, error: undefined });

        const currentApplicationDataState = context.getState().applicationData;
        const flightZoneId = currentApplicationDataState?.flightZoneId;
        const version = currentApplicationDataState?.analysisStatus?.version ?? 0;

        if (!flightZoneId) {
            return;
        }

        return this.flightZoneApi.removeCustomDesignator(flightZoneId, version).pipe(
            tap((applicationData) => {
                context.patchState({ applicationData });
            }),
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
