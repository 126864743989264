import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { NotificationStateChange, UserNotification } from "../../models/user-profile.models";

interface UserProfileNotificationsComponentState {
    notifications: UserNotification[];
    page: Page | undefined;
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
}

@Component({
    selector: "dss-shared-lib-user-profile-notifications[notifications]",
    templateUrl: "./user-profile-notifications.component.html",
    styleUrls: ["./user-profile-notifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserProfileNotificationsComponent {
    protected readonly displayedColumns: Array<keyof UserNotification> = ["eventName", "isEnabled"];

    protected readonly notifications$ = this.localStore.selectByKey("notifications");
    protected readonly page$ = this.localStore.selectByKey("page");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");

    @Input() public set notifications(value: UserNotification[] | undefined) {
        this.localStore.patchState({ notifications: value ?? [] });
    }

    @Input() public set page(value: Page | undefined) {
        this.localStore.patchState({ page: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set hasDataRetrievalError(value: BooleanInput) {
        this.localStore.patchState({ hasDataRetrievalError: coerceBooleanProperty(value) });
    }

    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly listRefresh = new EventEmitter<void>();
    @Output() public readonly notificationStateChange = new EventEmitter<NotificationStateChange>();

    constructor(private readonly localStore: LocalComponentStore<UserProfileNotificationsComponentState>) {
        this.localStore.setState({
            notifications: [],
            page: undefined,
            isProcessing: false,
            hasDataRetrievalError: false,
        });
    }
}
