import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LetModule, PushModule } from "@ngrx/component";

@NgModule({
    imports: [CommonModule, LetModule, PushModule, ReactiveFormsModule, HttpClientModule, SharedI18nModule],
})
export class DssSharedLibModule {}
