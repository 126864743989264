import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DateFormatOptions } from "@jsverse/transloco-locale";

interface UtcDateDisplayComponentState {
    date: Date | undefined;
}

@Component({
    selector: "dss-shared-lib-utc-date-display[date]",
    template: "{{ date$ | ngrxPush | localizeDate: localizeDateConfig }}",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtcDateDisplayComponent {
    public readonly localizeDateConfig: DateFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
    };

    public readonly date$ = this.localStore.selectByKey("date");

    @Input() public set date(value: Date | undefined) {
        this.localStore.patchState({ date: value });
    }

    constructor(private readonly localStore: LocalComponentStore<UtcDateDisplayComponentState>) {
        this.localStore.setState({
            date: undefined,
        });
    }
}
