import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
    DeviceSize,
    DeviceSizeService,
    RouteDataService,
    SwipeDirection,
    TouchScreenService,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { filter } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "dss-admin-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    protected readonly isSidebarCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;

    constructor(
        private readonly store: Store,
        private readonly touchScreenService: TouchScreenService,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly routeDataService: RouteDataService
    ) {}

    public ngOnInit(): void {
        this.observeOnDeviceResize();
        this.observeOnSwipe();
    }

    public toggleSidebar() {
        this.setMenuCollapsedState(!this.store.selectSnapshot(UIState.isMenuCollapsed));
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.setMenuCollapsedState(true);
        }
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(untilDestroyed(this))
            .subscribe((isDesktopDevice) => {
                this.setMenuCollapsedState(!isDesktopDevice);
            });
    }

    private observeOnSwipe() {
        this.touchScreenService.swipe$
            .pipe(
                filter(() => this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)),
                untilDestroyed(this)
            )
            .subscribe((direction: SwipeDirection) => {
                if (direction === SwipeDirection.LEFT) {
                    this.setMenuCollapsedState(true);
                } else if (direction === SwipeDirection.RIGHT) {
                    this.setMenuCollapsedState(false);
                }
            });
    }

    private setMenuCollapsedState(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }
}
