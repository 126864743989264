import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxMaskModule } from "ngx-mask";
import { QuillModule } from "ngx-quill";
import { ApplicationChangesChatComponent } from "./components/application-changes-chat/application-changes-chat.component";
import { ApplicationNoteDialogComponent } from "./components/application-note/application-note-dialog/application-note-dialog.component";
import { ApplicationNoteComponent } from "./components/application-note/application-note.component";
import { ApplicationPreviewHeaderComponent } from "./components/application-preview-header/application-preview-header.component";
import { ApplicationSummaryComponent } from "./components/application-summary/application-summary.component";
import { GeneralAviationExclusionsSummaryComponent } from "./components/application-summary/general-aviation-exclusions-summary/general-aviation-exclusions-summary.component";
import { SecuringOwnFlightsExclusionsSummaryComponent } from "./components/application-summary/securing-own-flights-exclusions-summary/securing-own-flights-exclusions-summary.component";
import { UavExclusionsSummaryComponent } from "./components/application-summary/uav-exclusions-summary/uav-exclusions-summary.component";
import { ApplicationsListFiltersComponent } from "./components/applications-list-filters/applications-list-filters.component";
import { InstitutionsFilterComponent } from "./components/applications-list-filters/institutions-filter/institutions-filter.component";
import { AutocompleteListFilterComponent } from "./components/autocomplete-list-filter/autocomplete-list-filter.component";
import { ConsultationsReviewComponent } from "./components/consultations-review/consultations-review.component";
import { ActiveRestrictionsFeaturesListComponent } from "./components/map-container/active-restrictions-features-list/active-restrictions-features-list.component";
import { ActiveRestrictionsFiltersComponent } from "./components/map-container/active-restrictions-filters/active-restrictions-filters.component";
import { ActiveRestrictionsMapLayerComponent } from "./components/map-container/active-restrictions-map-layer/active-restrictions-map-layer.component";
import { MapContainerComponent } from "./components/map-container/map-container.component";
import { MapContextMenuComponent } from "./components/map-container/map-context-menu/map-context-menu.component";
import { NotamPreviewComponent } from "./components/notam-preview/notam-preview.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { RestrictionPdfDownloadConfirmationComponent } from "./components/restriction-pdf-download-confirmation/restriction-pdf-download-confirmation.component";
import { RestrictionPreviewHeaderComponent } from "./components/restriction-preview-header/restriction-preview-header.component";
import { TextEditorSectionDialogComponent } from "./components/text-editor-section/text-editor-section-dialog/text-editor-section-dialog.component";
import { TextEditorSectionComponent } from "./components/text-editor-section/text-editor-section.component";
import { UtcDateDisplayComponent } from "./components/utc-date-display/utc-date-display.component";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        MatInputModule,
        SharedUiModule,
        MatCardModule,
        MatDialogModule,
        MatMenuModule,
        MatSlideToggleModule,
        CesiumMapModule,
        MatTooltipModule,
        ClipboardModule,
        DmsCoordinatesModule,
        QuillModule,
        MatDatepickerModule,
        SharedNotificationsModule,
        NgxMaskModule,
        MatAutocompleteModule,
    ],
    declarations: [
        ApplicationChangesChatComponent,
        ApplicationSummaryComponent,
        GeneralAviationExclusionsSummaryComponent,
        UavExclusionsSummaryComponent,
        NotamPreviewComponent,
        UtcDateDisplayComponent,
        ApplicationPreviewHeaderComponent,
        ApplicationNoteComponent,
        ApplicationNoteDialogComponent,
        ApplicationsListFiltersComponent,
        ConsultationsReviewComponent,
        MapContainerComponent,
        MapContextMenuComponent,
        NotificationsComponent,
        SecuringOwnFlightsExclusionsSummaryComponent,
        RestrictionPreviewHeaderComponent,
        RestrictionPdfDownloadConfirmationComponent,
        ActiveRestrictionsMapLayerComponent,
        ActiveRestrictionsFiltersComponent,
        ActiveRestrictionsFeaturesListComponent,
        AutocompleteListFilterComponent,
        InstitutionsFilterComponent,
        TextEditorSectionComponent,
        TextEditorSectionDialogComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssSharedLibFlightZone",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        TranslationHelperService,
    ],
    exports: [
        ApplicationChangesChatComponent,
        ApplicationSummaryComponent,
        GeneralAviationExclusionsSummaryComponent,
        UavExclusionsSummaryComponent,
        NotamPreviewComponent,
        UtcDateDisplayComponent,
        ApplicationPreviewHeaderComponent,
        ApplicationNoteComponent,
        ApplicationsListFiltersComponent,
        ConsultationsReviewComponent,
        MapContainerComponent,
        NotificationsComponent,
        RestrictionPreviewHeaderComponent,
        ActiveRestrictionsFiltersComponent,
        ActiveRestrictionsFeaturesListComponent,
        AutocompleteListFilterComponent,
        InstitutionsFilterComponent,
        ActiveRestrictionsMapLayerComponent,
        TextEditorSectionComponent,
        TextEditorSectionDialogComponent,
    ],
})
export class FlightZoneSharedModule {}
