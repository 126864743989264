<ng-container *ngrxLet="flightZoneApplications$ as flightZoneApplications">
    <ng-container *ngrxLet="displayedColumns$ as displayedColumns">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <table
                mat-table
                multiTemplateDataRows
                [dataSource]="flightZoneApplications"
                *ngrxLet="expandedElement$ as expandedElement"
                class="dtm-table"
            >
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.titleHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="applicationPurpose">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.purposeHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel"
                                | transloco : { value: element.applicationPurpose }
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.dateHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.date | localizeDate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.startAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.startAt; else restrictionCancelledDateTemplate"
                            [date]="element.startAt"
                        ></dss-shared-lib-utc-date-display>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.endAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.endAt; else restrictionCancelledDateTemplate"
                            [date]="element.endAt"
                        ></dss-shared-lib-utc-date-display>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="element.caseNumber || element.author || element.institution || element.assignee"
                            type="button"
                            class="button-icon"
                            (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                        >
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.caseNumber"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.caseNumberHeader' | transloco"
                                [value]="element.caseNumber"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.author.fullName"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.authorHeader' | transloco"
                                [value]="element.author.fullName"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.email"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.authorEmailHeader' | transloco"
                                [value]="element.author.email"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.phoneNumber"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.authorPhoneHeader' | transloco"
                                [value]="element.author.phoneNumber | formatPhoneNumber"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.institution"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.institutionHeader' | transloco"
                                [value]="element.institution"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.assignee"
                                [label]="'dssAdminLibFlightZoneConsultation.applicationsForConsultationList.contactHeader' | transloco"
                                [value]="element.assignee"
                            >
                            </dtm-ui-label-value>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.actionsHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-error *ngIf="hasDataRetrievalError$ | ngrxPush; else emptyListTemplate" (reload)="listRefresh.emit()">
                        </dtm-ui-error>

                        <ng-template #emptyListTemplate>
                            <dtm-ui-no-results></dtm-ui-no-results>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-pagination
                            *ngIf="applicationsPage$ | ngrxPush as page"
                            [pagination]="page"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="(row.caseNumber || row.author || row.institution || row.assignee) && toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="flightZoneApplications.length || isProcessing"
                    class="no-results-row"
                ></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['pagination']"
                    [class.hide-footer]="!flightZoneApplications.length"
                    class="pagination-row"
                ></tr>
            </table>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <a [routerLink]="['/consultation', element.id]" mat-menu-item>
            <dtm-ui-icon name="file-text"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.previewActionLabel" | transloco }}</span>
        </a>
    </ng-template>
</mat-menu>

<ng-template #restrictionCancelledDateTemplate>
    {{ "dssAdminLibFlightZoneConsultation.applicationsForConsultationList.restrictionCancelledDateLabel" | transloco }}
</ng-template>
