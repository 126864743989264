<dss-admin-lib-applications-for-consultation-list
    *ngrxLet="consultationsCompletedList$ as consultationsCompletedList"
    [displayedColumns]="displayedColumns"
    [flightZoneApplications]="consultationsCompletedList?.content"
    [page]="consultationsCompletedList?.page"
    [isProcessing]="isProcessing$ | ngrxPush"
    [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
    (pageChange)="changePage($event)"
    (listRefresh)="refreshList()"
></dss-admin-lib-applications-for-consultation-list>
