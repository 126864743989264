import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { UserNotificationsList, UserProfileError, UserProfileErrorType } from "../models/user-profile.models";

export type UserNotificationsListResponseBody = PageResponseBody<{
    enable: boolean;
    eventName: string;
    id: string;
    userId: string;
}>;

export function transformUserProfileErrorResponseToUserProfileError(errorResponse: HttpErrorResponse): UserProfileError {
    switch (errorResponse.status) {
        case HttpStatusCode.Conflict:
            return { type: UserProfileErrorType.InvalidApplicationVersion };
        default: {
            return { type: UserProfileErrorType.Unknown };
        }
    }
}

export function transformUserNotificationsListResponseBodyToUserNotificationsList(
    response: UserNotificationsListResponseBody
): UserNotificationsList {
    return {
        content: response.content.map((content) => ({
            isEnabled: content.enable,
            eventName: content.eventName,
            id: content.id,
            userId: content.userId,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
