import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    CylinderRestrictionArea,
    FlightZoneAnalysisStatus,
    FlightZoneApplicationBasicData,
    FlightZoneApplicationPurpose,
    FlightZoneCapabilities,
    HeightReferences,
    PrismRestrictionArea,
    RestrictionArea,
    RestrictionExclusions,
    RestrictionModificationData,
    VerticalMeasureUnits,
} from "../../models/flight-zone-shared.models";
import { isCylinderRestrictionArea, isPrismRestrictionArea } from "../../models/flight-zone-shared.typeguards";
import { TextEditorSectionDialogComponent } from "../text-editor-section/text-editor-section-dialog/text-editor-section-dialog.component";

interface ApplicationSummaryComponentState {
    basicDataForm: FlightZoneApplicationBasicData | undefined;
    restrictionAreaGeometry: RestrictionArea | undefined;
    restrictionExclusions: RestrictionExclusions | undefined;
    analysisStatus: FlightZoneAnalysisStatus | undefined;
    restrictionModificationData: RestrictionModificationData | undefined;
    isEditBasicDataButtonVisible: boolean;
    isEditGeometryButtonVisible: boolean;
    isEditExclusionsButtonVisible: boolean;
    isEditSuggestedRestrictionTypeButtonVisible: boolean;
    shouldShowModificationEditButton: boolean;
    capabilities: FlightZoneCapabilities | undefined;
    isSetCustomDesignatorButtonVisible: boolean;
    isRemoveCustomDesignatorButtonVisible: boolean;
}

@UntilDestroy()
@Component({
    selector:
        "dss-shared-lib-application-summary[basicDataForm][restrictionAreaGeometry][restrictionExclusions][analysisStatus][capabilities]",
    templateUrl: "./application-summary.component.html",
    styleUrls: ["./application-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationSummaryComponent implements OnInit, OnChanges {
    protected readonly VerticalMeasureUnits = VerticalMeasureUnits;
    protected readonly HeightReferences = HeightReferences;
    protected readonly FlightZoneApplicationPurpose = FlightZoneApplicationPurpose;
    protected readonly basicDataForm$ = this.localStore.selectByKey("basicDataForm");
    protected readonly restrictionAreaGeometry$ = this.localStore.selectByKey("restrictionAreaGeometry");
    protected readonly restrictionExclusions$ = this.localStore.selectByKey("restrictionExclusions");
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly restrictionModificationData$ = this.localStore.selectByKey("restrictionModificationData");
    protected readonly isEditBasicDataButtonVisible$ = this.localStore.selectByKey("isEditBasicDataButtonVisible");
    protected readonly isEditGeometryButtonVisible$ = this.localStore.selectByKey("isEditGeometryButtonVisible");
    protected readonly isEditExclusionsButtonVisible$ = this.localStore.selectByKey("isEditExclusionsButtonVisible");
    protected readonly isEditSuggestedRestrictionTypeButtonVisible$ = this.localStore.selectByKey(
        "isEditSuggestedRestrictionTypeButtonVisible"
    );
    protected readonly shouldShowModificationEditButton$ = this.localStore.selectByKey("shouldShowModificationEditButton");
    protected readonly isSetCustomDesignatorButtonVisible$ = this.localStore.selectByKey("isSetCustomDesignatorButtonVisible");
    protected readonly isRemoveCustomDesignatorButtonVisible$ = this.localStore.selectByKey("isRemoveCustomDesignatorButtonVisible");

    @Input() public set basicDataForm(value: FlightZoneApplicationBasicData | undefined) {
        this.localStore.patchState({ basicDataForm: value });
    }
    @Input() public set restrictionAreaGeometry(value: RestrictionArea | undefined) {
        this.localStore.patchState({ restrictionAreaGeometry: value });
    }
    @Input() public set restrictionExclusions(value: RestrictionExclusions | undefined) {
        this.localStore.patchState({ restrictionExclusions: value });
    }
    @Input() public set capabilities(value: FlightZoneCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input() public set analysisStatus(value: FlightZoneAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }
    @Input() public set restrictionModificationData(value: RestrictionModificationData | undefined) {
        this.localStore.patchState({ restrictionModificationData: value });
    }
    @Input() public set shouldShowModificationEditButton(value: BooleanInput) {
        this.localStore.patchState({ shouldShowModificationEditButton: coerceBooleanProperty(value) });
    }
    @Input() public set isEditSuggestedRestrictionTypeButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isEditSuggestedRestrictionTypeButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isSetCustomDesignatorButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isSetCustomDesignatorButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isRemoveCustomDesignatorButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isRemoveCustomDesignatorButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly editBasicDataSection = new EventEmitter<void>();
    @Output() public readonly editGeometrySection = new EventEmitter<void>();
    @Output() public readonly editExclusionsSection = new EventEmitter<void>();
    @Output() public readonly editRestrictionModification = new EventEmitter<void>();
    @Output() protected readonly editSuggestedRestrictionType = new EventEmitter<void>();
    @Output() protected readonly customDesignatorSet = new EventEmitter<void>();
    @Output() protected readonly customDesignatorRemove = new EventEmitter<void>();

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<ApplicationSummaryComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            basicDataForm: undefined,
            restrictionAreaGeometry: undefined,
            restrictionExclusions: undefined,
            analysisStatus: undefined,
            restrictionModificationData: undefined,
            isEditBasicDataButtonVisible: false,
            isEditGeometryButtonVisible: false,
            isEditExclusionsButtonVisible: false,
            isEditSuggestedRestrictionTypeButtonVisible: false,
            shouldShowModificationEditButton: false,
            capabilities: undefined,
            isSetCustomDesignatorButtonVisible: false,
            isRemoveCustomDesignatorButtonVisible: false,
        });
    }

    public ngOnInit() {
        this.localStore.patchState({
            isEditBasicDataButtonVisible: this.editBasicDataSection.observed,
            isEditGeometryButtonVisible: this.editGeometrySection.observed,
            isEditExclusionsButtonVisible: this.editExclusionsSection.observed,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        const capabilitiesChanges = changes.capabilities;
        const restrictionExclusionsChanges = changes.restrictionExclusions;

        if (!capabilitiesChanges || !restrictionExclusionsChanges) {
            return;
        }

        this.localStore.patchState({
            restrictionExclusions: this.updateExclusionsWithCapabilitiesData(
                restrictionExclusionsChanges.currentValue,
                capabilitiesChanges.currentValue
            ),
        });
    }

    public areRestrictionExclusionsDefined(exclusions: RestrictionExclusions): boolean {
        return Object.entries(exclusions)
            .reduce(
                (
                    accumulator: RestrictionExclusions[keyof RestrictionExclusions][],
                    [exclusionKey, exclusionValue]: [string, RestrictionExclusions[keyof RestrictionExclusions]]
                ) => {
                    // NOTE: Purpose will always be defined by default, that's why it's filtered out here
                    if (exclusionKey !== "purpose") {
                        accumulator.push(exclusionValue);
                    }

                    return accumulator;
                },
                []
            )
            .some((exclusionValue) => (Array.isArray(exclusionValue) ? exclusionValue.length : !!exclusionValue));
    }

    public getCylinderGeometry(geometry: RestrictionArea): CylinderRestrictionArea | null {
        return isCylinderRestrictionArea(geometry) ? geometry : null;
    }

    public getPrismGeometry(geometry: RestrictionArea): PrismRestrictionArea | null {
        return isPrismRestrictionArea(geometry) ? geometry : null;
    }

    protected openDescriptionPreviewDialog(description: string): void {
        this.dialogService.open(TextEditorSectionDialogComponent, {
            data: {
                text: description,
                title: this.translocoService.translate("dssSharedLibFlightZone.applicationSummary.additionalInfoHeader"),
                isReadonly: true,
            },
        });
    }

    protected hasModificationDateChanged(modification: Date | undefined, original: Date): boolean {
        if (!modification) {
            return false;
        }

        return modification.getTime() !== original.getTime();
    }

    private updateExclusionsWithCapabilitiesData(
        restrictionExclusions: RestrictionExclusions | undefined,
        capabilities: FlightZoneCapabilities | undefined
    ): RestrictionExclusions | undefined {
        if (!restrictionExclusions || !capabilities) {
            return restrictionExclusions;
        }

        const courses = capabilities.eLearningCourses.filter(({ codeName: capabilitiesCourseCode }) =>
            restrictionExclusions.courses.some(({ codeName: valueCourseCode }) => capabilitiesCourseCode === valueCourseCode)
        );

        return {
            ...restrictionExclusions,
            courses,
        };
    }
}
