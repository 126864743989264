<ng-container *ngrxLet="error$ as error">
    <ng-container [ngSwitch]="error?.type">
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetApplication" class="error">
            {{ "dssAdminLibFlightZoneConsultation.consultationPreview.cannotGetDataErrorMessage" | transloco }}
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noErrorTemplate>
    <div class="application-preview" *ngIf="applicationForConsultation$ | ngrxPush as applicationForConsultation">
        <div class="card-box" *ngrxLet="activeConsultation$ as activeConsultation">
            <div class="overflow-container">
                <dss-shared-lib-consultations-review
                    [consultations]="applicationForConsultation.consultations"
                ></dss-shared-lib-consultations-review>
                <dss-shared-lib-application-summary
                    [basicDataForm]="applicationForConsultation.basicDataForm"
                    [restrictionAreaGeometry]="applicationForConsultation.restrictionAreaGeometry"
                    [restrictionExclusions]="applicationForConsultation.restrictionExclusions"
                    [analysisStatus]="applicationForConsultation.analysisStatus"
                    [capabilities]="capabilities$ | ngrxPush"
                >
                </dss-shared-lib-application-summary>
                <dss-shared-lib-application-note isReadOnly [note]="applicationForConsultation.note"></dss-shared-lib-application-note>
                <dss-admin-lib-additional-receivers
                    isReadOnly
                    [emails]="applicationForConsultation.additionalReceivers"
                ></dss-admin-lib-additional-receivers>
                <dss-admin-lib-detailed-duration
                    isReadOnly
                    [detailedDuration]="applicationForConsultation.detailedDuration"
                ></dss-admin-lib-detailed-duration>
                <dss-admin-lib-ansp-case-number
                    isReadOnly
                    [anspCaseNumber]="applicationForConsultation.anspCaseNumber"
                ></dss-admin-lib-ansp-case-number>
                <dss-admin-lib-airspace-classification
                    *ngIf="applicationForConsultation.analysisStatus?.isRestrictionTypeAssociatedWithNotam"
                    isReadOnly
                    [airspaceClassification]="applicationForConsultation.airspaceClassification"
                ></dss-admin-lib-airspace-classification>
                <dss-admin-lib-notam-location-form
                    *ngIf="applicationForConsultation.analysisStatus?.isRestrictionTypeAssociatedWithNotam"
                    isReadOnly
                    [location]="applicationForConsultation.location"
                ></dss-admin-lib-notam-location-form>
            </div>
            <div class="actions" *ngrxLet="isProcessing$ as isProcessing">
                <button
                    *ngIf="!activeConsultation?.status"
                    class="button-primary"
                    [disabled]="isProcessing"
                    type="button"
                    (click)="reviewConsultation(FlightZoneApplicationReviewStatus.Approve)"
                >
                    {{ "dssAdminLibFlightZoneConsultation.consultationPreview.approveButtonLabel" | transloco }}
                </button>

                <button
                    *ngIf="!activeConsultation?.status"
                    class="button-warn"
                    [disabled]="isProcessing"
                    type="button"
                    (click)="reviewConsultation(FlightZoneApplicationReviewStatus.Disapprove)"
                >
                    {{ "dssAdminLibFlightZoneConsultation.consultationPreview.disapproveButtonLabel" | transloco }}
                </button>
                <button class="button-secondary" (click)="zoomToGeometry()" type="button">
                    <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneConsultation.consultationPreview.zoomToZoneButtonLabel" | transloco }}
                </button>
            </div>
        </div>
        <dss-shared-lib-map-container [shouldShowActiveRestrictionsLayer]="true"></dss-shared-lib-map-container>
    </div>
</ng-template>
