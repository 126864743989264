import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    AnspTeam,
    ApplicationAssignee,
    ApplicationChangesChatComponent,
    ApplicationNoteDialogComponent,
    ApplicationType,
    DesignatorRange,
    DssUserRoles,
    FlightZoneApplication,
    FlightZoneApplicationReviewStatus,
    FlightZoneApplicationStatus,
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    FlightZoneRestrictionActions,
    FlightZoneRestrictionState,
    GEOMETRY_READ_ONLY_CONSTRAINTS,
    NotamLocation,
    RestrictionType,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatestWith, concatMap, first, of, share, switchMap, take } from "rxjs";
import { map } from "rxjs/operators";
import { FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME } from "../../services/flight-zone-management.resolvers";
import { NotamModalService } from "../../services/notam-modal.service";
import { FlightZoneApplicationActions } from "../../state/flight-zone-application.actions";
import { FlightZoneApplicationState } from "../../state/flight-zone-application.state";
import { FlightZoneManagementActions } from "../../state/flight-zone-management.actions";
import { FlightZoneManagementState } from "../../state/flight-zone-management.state";
import { AssigneeConfirmDialogComponent } from "../assignee-confirm-dialog/assignee-confirm-dialog.component";
import { AirspaceClassificationComponent } from "./airspace-classification/airspace-classification.component";
import { EditSuggestedRestrictionTypeDialogComponent } from "./edit-suggested-restriction-type-dialog/edit-suggested-restriction-type-dialog.component";
import { SetCustomDesignatorDialogComponent } from "./set-custom-designator-dialog/set-custom-designator-dialog.component";
import { TeamChangeDialogComponent } from "./team-change-dialog/team-change-dialog.component";

interface ApplicationManagementPreviewComponentState {
    isChatEnabled: boolean;
    hasLocationMissingError: boolean;
}

const ERROR_TOAST_TIMEOUT_MS = 10000;

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-application-management-preview",
    templateUrl: "./application-management-preview.component.html",
    styleUrls: ["./application-management-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class ApplicationManagementPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(ApplicationChangesChatComponent) public applicationChangesChatComponent: ApplicationChangesChatComponent | undefined;

    protected readonly FlightZoneErrorType = FlightZoneErrorType;
    protected readonly FlightZoneApplicationStatus = FlightZoneApplicationStatus;
    protected readonly FlightZoneApplicationReviewStatus = FlightZoneApplicationReviewStatus;
    protected readonly ApplicationType = ApplicationType;

    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly error$ = this.store.select(FlightZoneApplicationState.error);
    protected readonly flightZoneApplicationData$ = this.store.select(FlightZoneApplicationState.applicationData);
    protected readonly isProcessing$ = this.store.select(FlightZoneManagementState.isProcessing);
    protected readonly enableActions$: Observable<boolean> = this.route.data.pipe(map((data) => data.enableActions));
    protected readonly isChatEnabled$ = this.localStore.selectByKey("isChatEnabled");
    protected readonly hasLocationMissingError$ = this.localStore.selectByKey("hasLocationMissingError");
    protected readonly chatMessages$ = this.store.select(FlightZoneApplicationState.chatMessages).pipe(share());
    protected readonly hasAnspSupervisorRole$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => !!roles?.includes(DssUserRoles.AnspSupervisor)));
    protected readonly availableAirspaceClassifications$ = this.store.select(FlightZoneApplicationState.availableAirspaceClassifications);
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);
    protected readonly applicationType$ = this.route.data.pipe(map((data) => data.applicationType));
    protected readonly isEditDisabled$ = this.flightZoneApplicationData$.pipe(
        combineLatestWith(this.hasAnspSupervisorRole$),
        map(([applicationData, hasAnspSupervisorRole]) =>
            this.isEditDisabledForApplicationStatus(applicationData?.status, hasAnspSupervisorRole)
        )
    );
    protected readonly isCustomDesignatorSet$ = this.flightZoneApplicationData$.pipe(
        map((applicationData) => !FunctionUtils.isNullOrUndefined(applicationData?.basicDataForm?.customDesignator))
    );

    private get applicationType(): ApplicationType {
        return this.route.snapshot.data.applicationType;
    }

    constructor(
        private readonly store: Store,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<ApplicationManagementPreviewComponentState>,
        private readonly notamModalService: NotamModalService,
        private readonly translationHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            isChatEnabled: false,
            hasLocationMissingError: false,
        });
    }

    public ngOnInit(): void {
        this.getChatMessages();
        this.toggleChatBasedOnMessagesLength();
        this.store.dispatch([
            FlightZoneApplicationActions.GetAvailableConsultants,
            FlightZoneApplicationActions.GetAvailableRestrictionTypes,
        ]);
    }

    public ngAfterViewInit(): void {
        this.initMapWithEntity();
    }

    public ngOnDestroy(): void {
        this.flightZoneGeometryService.clearGeometry();
    }

    public zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    public rejectApplication(flightZoneId: string): void {
        const dialogRef = this.getNoteConfirmationDialogRef(
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogDescriptionForRejection"),
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogConfirmButtonLabelForRejection"),
            true
        );

        dialogRef.componentInstance.updateNote$
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((note) => this.store.dispatch(new FlightZoneApplicationActions.RejectApplication(flightZoneId, note))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.flightZoneRejectionSuccessMessage")
                );
                dialogRef.close();
                this.router.navigate(["/dashboard"]);
            });
    }

    public rejectReview(application: FlightZoneApplication): void {
        const assignment = application.analysisStatus?.assignment;

        if (!assignment) {
            return;
        }

        this.store
            .dispatch(new FlightZoneApplicationActions.RejectApplicationReview(assignment))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.reviewRejectionSuccessMessage")
                );
                this.router.navigate(["/dashboard"]);
            });
    }

    public updateApplicationNote(dialogRef: MatDialogRef<ApplicationNoteDialogComponent>, flightZoneId: string): void {
        dialogRef.componentInstance.updateNote$
            .pipe(
                switchMap((note) => this.store.dispatch(new FlightZoneApplicationActions.UpdateApplicationNote(flightZoneId, note))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                dialogRef.close();
            });
    }

    public enableChat(): void {
        this.localStore.patchState({ isChatEnabled: true });

        if (!this.applicationChangesChatComponent) {
            return;
        }

        this.applicationChangesChatComponent.isChatCollapsed = false;
    }

    public submitMessage(flightZoneId: string, comment: string, chatComponent: ApplicationChangesChatComponent): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.PostChatMessage(flightZoneId, comment))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dssAdminLibFlightZoneManagement.chatMessageSubmissionSuccessMessage"));
                chatComponent.resetMessageInput();
            });
    }

    public addAssignee(flightZoneId: string): void {
        this.store
            .select(FlightZoneManagementState.availableApplicationAssignees)
            .pipe(
                switchMap<ApplicationAssignee[], Observable<ApplicationAssignee | null>>((availableAssignees) =>
                    this.dialogService.open(AssigneeConfirmDialogComponent, { data: { availableAssignees } }).afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((assignee) =>
                    this.store.dispatch(
                        new FlightZoneManagementActions.AddApplicationAssignee(assignee.id, flightZoneId, this.applicationType)
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneManagementState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dssAdminLibFlightZoneManagement.changeAssigneeSuccessMessage"));
                this.router.navigate(["/dashboard"]);
            });
    }

    protected assignConsultant(flightZoneId: string): void {
        this.store
            .select(FlightZoneApplicationState.availableConsultants)
            .pipe(
                switchMap((availableConsultants) =>
                    this.dialogService
                        .open(AssigneeConfirmDialogComponent, {
                            data: {
                                availableAssignees: availableConsultants,
                                label: this.transloco.translate(
                                    "dssAdminLibFlightZoneManagement.assigneeConfirmDialog.selectConsultantLabel"
                                ),
                            },
                        })
                        .afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((assignee) => this.store.dispatch(new FlightZoneApplicationActions.AssignConsultant(assignee, flightZoneId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.assignConsultantSuccessMessage")
                );
            });
    }

    protected changeTeam(flightZoneId: string): void {
        this.store
            .dispatch(FlightZoneApplicationActions.GetAnspTeams)
            .pipe(
                switchMap(() => this.store.select(FlightZoneApplicationState.anspTeams)),
                take(1),
                switchMap((anspTeams) =>
                    this.dialogService
                        .open(TeamChangeDialogComponent, {
                            data: { anspTeams },
                        })
                        .afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((team: AnspTeam) => this.store.dispatch(new FlightZoneApplicationActions.ChangeAnspTeam(team.id, flightZoneId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.teamChangeSuccessMessage")
                );
                this.router.navigate(["/dashboard"]);
            });
    }

    protected openTeamChangeDisabledDialog(flightZoneId: string): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dssAdminLibFlightZoneManagement.teamChangeDisabledDialog.dialogHeader"),
                    confirmationText: this.transloco.translate(
                        "dssAdminLibFlightZoneManagement.teamChangeDisabledDialog.dialogDescription"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dssAdminLibFlightZoneManagement.teamChangeDisabledDialog.confirmApplicationLockButtonLabel"
                    ),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.toggleLockedStatus(flightZoneId, true);
            });
    }

    public approveApplication(assignmentId: string): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    confirmationText: this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.approveDialogText"),
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() =>
                    this.store.dispatch(
                        new FlightZoneApplicationActions.ReviewApplication(assignmentId, FlightZoneApplicationReviewStatus.Approve)
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.flightZoneReviewSuccessMessage")
                );
                this.router.navigate(["/dashboard"]);
            });
    }

    public disapproveApplication(assignmentId: string, flightZoneId: string): void {
        const dialogRef = this.getNoteConfirmationDialogRef(
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogDescriptionForRejection"),
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogConfirmButtonLabelForRejectionApproval"),
            true
        );

        dialogRef.componentInstance.updateNote$
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((note) => this.store.dispatch(new FlightZoneApplicationActions.UpdateApplicationNote(flightZoneId, note))),
                concatMap((response) => {
                    const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                    if (error) {
                        return of(response);
                    }

                    return this.store.dispatch(
                        new FlightZoneApplicationActions.ReviewApplication(assignmentId, FlightZoneApplicationReviewStatus.Disapprove)
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.flightZoneReviewSuccessMessage")
                );
                dialogRef.close();
                this.router.navigate(["/dashboard"]);
            });
    }

    protected acceptApplication(application: FlightZoneApplication): void {
        const flightZoneId = application.flightZoneId;

        if (!flightZoneId) {
            return;
        }

        if (application.analysisStatus?.isRestrictionTypeAssociatedWithNotam && !application.location) {
            this.localStore.patchState({ hasLocationMissingError: true });

            return;
        }

        const dialogRef = this.getNoteConfirmationDialogRef(
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogDescriptionForAcceptation"),
            this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.noteDialogConfirmButtonLabelForAcceptation"),
            false
        );

        dialogRef.componentInstance.updateNote$
            .pipe(
                switchMap((note) => this.store.dispatch(new FlightZoneApplicationActions.UpdateApplicationNote(flightZoneId, note))),
                concatMap((response) => {
                    const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                    if (error) {
                        return of(response);
                    }

                    return this.store.dispatch(new FlightZoneApplicationActions.AcceptApplication(flightZoneId));
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.flightZoneSubmissionSuccessMessage")
                );
                dialogRef.close();
                this.router.navigate(["/dashboard"]);
            });
    }

    public openNotamPreview(flightZoneId: string): void {
        this.notamModalService.openNotamPreview(flightZoneId);
    }

    public toggleLockedStatus(flightZoneId: string, shouldBeLocked: boolean): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.ToggleLockedStatus(flightZoneId, shouldBeLocked))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate(
                        shouldBeLocked
                            ? "dssAdminLibFlightZoneManagement.applicationLockSuccessMessage"
                            : "dssAdminLibFlightZoneManagement.applicationUnlockSuccessMessage"
                    )
                );
            });
    }

    protected notamLocationChange(flightZoneId: string, location: NotamLocation): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.UpdateNotamLocation(flightZoneId, location))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.localStore.patchState({ hasLocationMissingError: false });
                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.notamLocationSubmissionSuccessMessage")
                );
            });
    }

    protected anspCaseNumberChange(anspCaseNumber: string): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.UpdateAnspCaseNumber(anspCaseNumber))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.anspCaseNumberChangeSuccessMessage")
                );
            });
    }

    protected editSuggestedRestrictionType(applicationData: FlightZoneApplication): void {
        this.store
            .select(FlightZoneApplicationState.availableRestrictionTypes)
            .pipe(
                first(),
                switchMap((availableRestrictionTypes) =>
                    this.dialogService
                        .open(EditSuggestedRestrictionTypeDialogComponent, {
                            data: {
                                availableRestrictionTypes,
                                suggestedRestrictionType: applicationData.analysisStatus?.suggestedRestrictionType,
                                isCustomDesignatorSet: !!applicationData.basicDataForm?.customDesignator,
                            },
                        })
                        .afterClosed()
                ),
                RxjsUtils.filterFalsy(),
                switchMap((updatedRestrictionType: RestrictionType) =>
                    this.store.dispatch(new FlightZoneApplicationActions.ChangeSuggestedRestrictionType(updatedRestrictionType))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.restrictionTypeChangeSuccessMessage")
                );
            });
    }

    protected setCustomDesignator(restrictionType: RestrictionType | undefined): void {
        if (!restrictionType) {
            return;
        }

        this.store
            .dispatch(new FlightZoneApplicationActions.GetForbiddenDesignatorRange(restrictionType))
            .pipe(
                switchMap(() =>
                    this.openSetCustomDesignatorDialog(this.store.selectSnapshot(FlightZoneApplicationState.forbiddenDesignatorRange))
                ),
                RxjsUtils.filterFalsy(),
                switchMap((designator: number) => this.store.dispatch(new FlightZoneApplicationActions.SetCustomDesignator(designator)))
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.setCustomDesignatorSuccessMessage")
                );
            });
    }

    protected removeCustomDesignator(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dssAdminLibFlightZoneManagement.removeCustomDesignatorDialog.dialogHeader"),
                    confirmationText: this.transloco.translate(
                        "dssAdminLibFlightZoneManagement.removeCustomDesignatorDialog.dialogInfoMessage"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(FlightZoneApplicationActions.RemoveCustomDesignator))
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.removeCustomDesignatorSuccessMessage")
                );
            });
    }

    protected receiversEmailsChange(emails: string[]): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.ChangeAdditionalReceivers(emails))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.additionalReceiversChangeSuccessMessage")
                );
            });
    }

    protected detailedDurationChange(detailedDuration: string): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.ChangeDetailedDuration(detailedDuration))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.detailedDurationChangeSuccessMessage")
                );
            });
    }

    protected fetchAvailableClassifications(): void {
        this.store.dispatch(new FlightZoneApplicationActions.GetAirspaceClassificationCapabilities());
    }

    protected airspaceClassificationChange(
        airspaceClassification: string,
        airspaceClassificationComponent: AirspaceClassificationComponent
    ): void {
        this.store
            .dispatch(new FlightZoneApplicationActions.ChangeAirspaceClassification(airspaceClassification))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneApplicationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                airspaceClassificationComponent.changeExpansionState(false);

                this.toastService.success(
                    this.transloco.translate(
                        "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassificationChangeSuccessMessage"
                    )
                );
            });
    }

    protected isEditDisabledForApplicationStatus(
        status: FlightZoneApplicationStatus | undefined,
        hasSupervisorPermissions: boolean
    ): boolean {
        return status
            ? [
                  FlightZoneApplicationStatus.Accepted,
                  FlightZoneApplicationStatus.Published,
                  FlightZoneApplicationStatus.Rejected,
                  hasSupervisorPermissions ? null : FlightZoneApplicationStatus.Reviewed,
              ].includes(status)
            : false;
    }

    protected isApplicationAcceptanceAllowed(application: FlightZoneApplication): boolean {
        const endTime = application.basicDataForm?.endTime;

        return !!endTime && endTime > new Date();
    }

    protected downloadConfirmationOfApplicationSubmissionPdf(application: FlightZoneApplication): void {
        const fileName = application.basicDataForm?.title;
        const flightZoneId = application.flightZoneId;

        if (!fileName || !flightZoneId) {
            return;
        }

        this.store
            .dispatch(new FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf(flightZoneId, fileName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }
            });
    }

    private getChatMessages(): void {
        const flightZoneId: string = this.route.snapshot.paramMap.get(FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME) ?? "";

        this.store.dispatch(new FlightZoneApplicationActions.GetChatMessagesByFlightZoneId(flightZoneId));
    }

    private toggleChatBasedOnMessagesLength(): void {
        this.chatMessages$.pipe(untilDestroyed(this)).subscribe((messages) => {
            this.localStore.patchState({ isChatEnabled: messages.length > 0 });
        });
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotSubmitApplication:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotSubmitApplicationErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotRejectApplication:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotRejectApplicationErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotReviewApplication:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotReviewApplicationErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotRejectReview:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotRejectReviewErrorMessage"
                );
                break;
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotChangeAssignee:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotChangeAssigneeErrorMessage");
                break;
            case FlightZoneErrorType.CannotPostChatMessage:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotPostChatMessageErrorMessage");
                break;
            case FlightZoneErrorType.CannotToggleLockedStatus:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.applicationLockToggleErrorMessage");
                break;
            case FlightZoneErrorType.CannotUpdateApplicationNote:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotUpdateApplicationNoteErrorMessage");
                break;
            case FlightZoneErrorType.CannotUpdateLocation:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotUpdateLocationErrorMessage");
                break;
            case FlightZoneErrorType.CannotAssignConsultant:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.cannotAssignConsultantErrorMessage");
                break;
            case FlightZoneErrorType.CannotUpdateAnspCaseNumber:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotUpdateAnspCaseNumberErrorMessage"
                );
                break;
            case FlightZoneErrorType.ConsultantAlreadyAssigned:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.consultantAlreadyAssignedErrorMessage");
                break;
            case FlightZoneErrorType.CannotChangeRestrictionType:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotChangeRestrictionTypeErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotChangeAnspTeam:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.applicationPreview.cannotChangeTeamErrorMessage");
                break;
            case FlightZoneErrorType.CannotChangeAdditionalReceivers:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.additionalReceiversChangeErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotChangeDetailedDuration:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.detailedDurationChangeErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotChangeAirspaceClassification:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.airspaceClassificationChangeErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotSetCustomDesignator:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotSetCustomDesignatorErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotRemoveCustomDesignator:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneManagement.applicationPreview.cannotRemoveCustomDesignatorErrorMessage"
                );
                break;
            case FlightZoneErrorType.GeoZonesSystemError:
                errorMessage = error.messageKey && this.translationHelper.selectSystemTranslation(error.messageKey, error.args);
                break;
            default:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneManagement.genericErrorMessage");
        }

        this.toastService.error(errorMessage, undefined, { timeOut: ERROR_TOAST_TIMEOUT_MS });
    }

    private getNoteConfirmationDialogRef(
        dialogDescription: string,
        confirmButtonLabel: string,
        isNoteRequired: boolean
    ): MatDialogRef<ApplicationNoteDialogComponent> {
        return this.dialogService.open(ApplicationNoteDialogComponent, {
            data: {
                note: this.store.selectSnapshot(FlightZoneApplicationState.applicationData)?.note,
                isProcessing$: this.store.select(FlightZoneManagementState.isProcessing),
                isReadOnly: false,
                isNoteRequired,
                dialogDescription,
                confirmButtonLabel,
            },
        });
    }

    private initMapWithEntity(): void {
        const restrictionAreaGeometry = this.store.selectSnapshot(FlightZoneApplicationState.applicationData)?.restrictionAreaGeometry;

        if (!restrictionAreaGeometry) {
            return;
        }

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, GEOMETRY_READ_ONLY_CONSTRAINTS);
    }

    private openSetCustomDesignatorDialog(forbiddenDesignatorRange: DesignatorRange | undefined) {
        return this.dialogService
            .open(SetCustomDesignatorDialogComponent, {
                data: {
                    forbiddenDesignatorRange,
                },
            })
            .afterClosed();
    }
}
