<div class="map-container">
    <ac-map dtmMapPointerManager dtmMapCesiumCleanupOnDestroy>
        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
        <dss-shared-lib-active-restrictions-map-layer
            *ngIf="shouldShowActiveRestrictionsLayer$ | ngrxPush"
        ></dss-shared-lib-active-restrictions-map-layer>
        <dtm-map-cylinders-editor [labelsTemplate]="labelsTemplate" [show]="true"></dtm-map-cylinders-editor>
        <dtm-map-prisms-editor
            [labelsTemplate]="labelsTemplate"
            [show]="true"
            (pointLimitReached)="pointLimitReached.emit($event)"
        ></dtm-map-prisms-editor>
    </ac-map>
</div>

<ng-template #labelsTemplate let-label>
    <div class="default-label" *ngIf="label.show && label.text">
        {{ label.text }}
    </div>
</ng-template>
