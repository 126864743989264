<div class="grid">
    <mat-card *ngrxLet="notamData$ as notamData">
        <ng-container *ngIf="error$ | ngrxPush as error; else noErrorTemplate">
            <ng-container [ngSwitch]="error.type">
                <div *ngSwitchCase="FlightZoneErrorType.CannotGetNotam" class="error">
                    {{ "dssAdminLibFlightZoneManagement.notamPreview.cannotGetNotamErrorMessage" | transloco }}
                </div>
                <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-template #noErrorTemplate>
            <mat-card-content *ngrxLet="notamData$ as notamData">
                <form [formGroup]="notamValuesForm">
                    <ng-container *ngIf="notamData?.nativeNotam?.previousNotamNumber as previousNotamNumber">
                        <div class="title">
                            {{
                                "dssAdminLibFlightZoneManagement.notamPreview.previousNotamNumberLabel"
                                    | transloco : { value: notamData?.type }
                            }}
                        </div>
                        <div class="value-container">{{ previousNotamNumber }}</div>
                    </ng-container>

                    <dss-admin-lib-notam-content
                        *ngIf="notamData?.nativeNotam as nativeNotam"
                        [notam]="nativeNotam"
                        class="value-container"
                    >
                        <dtm-ui-textarea-field [maxLength]="MAX_NOTAM_VALUE_LENGTH">
                            <textarea formControlName="nativeNotamValue" [rows]="12" matInput></textarea>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="errorsTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: notamValuesForm.controls.nativeNotamValue }"
                                ></ng-container>
                            </div>
                        </dtm-ui-textarea-field>
                    </dss-admin-lib-notam-content>

                    <dss-admin-lib-notam-content
                        *ngIf="notamData?.internationalNotam as internationalNotam"
                        [notam]="internationalNotam"
                        class="value-container"
                    >
                        <dtm-ui-textarea-field [maxLength]="MAX_NOTAM_VALUE_LENGTH">
                            <textarea formControlName="internationalNotamValue" [rows]="12" matInput></textarea>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="errorsTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: notamValuesForm.controls.internationalNotamValue }"
                                ></ng-container>
                            </div>
                        </dtm-ui-textarea-field>
                    </dss-admin-lib-notam-content>

                    <div class="title">{{ "dssAdminLibFlightZoneManagement.notamPreview.centerCoordinates" | transloco }}</div>
                    <div class="value-container">
                        {{
                            notamData?.centroid?.latitude | invoke : formatDecimalCoordinatesForDisplay : GeographicCoordinatesType.Latitude
                        }}
                        {{
                            notamData?.centroid?.longitude
                                | invoke : formatDecimalCoordinatesForDisplay : GeographicCoordinatesType.Longitude
                        }}
                    </div>

                    <div class="title">{{ "dssAdminLibFlightZoneManagement.notamPreview.radius" | transloco }}</div>
                    <div class="value-container">{{ notamData?.centroidRadius }}</div>
                </form>
            </mat-card-content>

            <mat-card-actions align="end">
                <a class="button-secondary" routerLink="../">
                    {{ "dssAdminLibFlightZoneManagement.notamPreview.goToApplicationPreviewButtonLabel" | transloco }}
                </a>
                <button
                    *ngrxLet="isProcessing$ as isProcessing"
                    class="button-primary"
                    type="button"
                    [disabled]="isProcessing || notamValuesForm.invalid"
                    (click)="assignNotamNumber(notamData?.notamId)"
                >
                    {{ "dssAdminLibFlightZoneManagement.notamPreview.notamIssuedButtonLabel" | transloco }}
                </button>
            </mat-card-actions>
        </ng-template>
    </mat-card>
</div>

<ng-template #errorsTemplate let-control>
    <ng-container *dtmUiFieldHasError="control; name: 'maxlength'; error as error">
        {{ "dssAdminLibFlightZoneManagement.maxLengthErrorHint" | transloco : { maxLength: error.requiredLength } }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="control; name: 'requiredTouched'">
        {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
    </ng-container>
</ng-template>
