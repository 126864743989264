<dtm-ui-loader-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        userData: userData$,
        userNotificationsList: userNotificationsList$,
        getNotificationsError: getNotificationsError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <div class="grid cards-container" *ngIf="vm.userData; else errorTemplate">
        <div class="grid-col-6 column">
            <dss-shared-lib-user-profile-personal-data
                [firstName]="vm.userData.firstName"
                [lastName]="vm.userData.lastName"
                (userDataUpdate)="updateUserData($event)"
            ></dss-shared-lib-user-profile-personal-data>

            <dss-shared-lib-user-profile-contact
                [email]="vm.userData.email"
                [phoneNumber]="vm.userData.phoneNumber"
                (userDataUpdate)="updateUserData($event)"
            ></dss-shared-lib-user-profile-contact>
        </div>
        <div class="grid-col-6 column">
            <dtm-ui-profile-language
                [currentLanguage]="activeLanguage$ | ngrxPush"
                (updateLanguage)="updateLanguage($event)"
            ></dtm-ui-profile-language>

            <dtm-ui-reset-password (resetPassword)="resetPassword()"></dtm-ui-reset-password>

            <mat-card>
                <dtm-ui-card-header>
                    {{ "dssSharedLibUserProfile.deleteAccount.sectionTitle" | transloco }}
                </dtm-ui-card-header>

                <div class="remove-account-description">
                    {{ "dssSharedLibUserProfile.deleteAccount.deleteAccountDescription" | transloco }}
                </div>
                <button type="button" class="button-warn" (click)="tryDeleteAccount()">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dssSharedLibUserProfile.deleteAccount.deleteAccountButtonLabel" | transloco }}</span>
                </button>
            </mat-card>
        </div>
        <dss-shared-lib-user-profile-notifications
            [notifications]="vm.userNotificationsList?.content"
            [isProcessing]="vm.isProcessing"
            [page]="vm.userNotificationsList?.page"
            [hasDataRetrievalError]="!!vm.getNotificationsError"
            (notificationStateChange)="updateNotificationState($event)"
            (pageChange)="changeNotificationsPage($event)"
            (listRefresh)="refreshNotificationsList()"
        ></dss-shared-lib-user-profile-notifications>
    </div>

    <ng-template #errorTemplate>
        <dtm-ui-error (reload)="reload()"></dtm-ui-error>
    </ng-template>
</dtm-ui-loader-container>
