import { InjectionToken } from "@angular/core";

export interface AdministrationEndpoints {
    getUsersList: string;
    getCapabilities: string;
    saveNewUser: string;
    getInstitutionsList: string;
    userDetailsManagement: string;
    institutionDetailsManagement: string;
    saveNewInstitution: string;
    assignInstitutionMembers: string;
    removeInstitutionMember: string;
    getAnspTeamsList: string;
    anspTeamDetailsManagement: string;
    assignAnspTeamMembers: string;
    removeAnspTeamMember: string;
    anspTeamWorkCalendarManagement: string;
    addTeamDaysOff: string;
    removeTeamDayOff: string;
}

export const ADMINISTRATION_ENDPOINTS = new InjectionToken<AdministrationEndpoints>("Administration endpoints");
