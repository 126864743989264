<ng-container *ngIf="assignment$ | ngrxPush as assignment">
    <ng-container [ngSwitch]="assignment.reviewStatus">
        <span class="chip success" *ngSwitchCase="FlightZoneApplicationReviewStatus.Approve">
            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
            {{
                "dssAdminLibFlightZoneManagement.reviewStatusBadge.approvedLabel"
                    | transloco
                        : { firstLetterOfFirstName: assignment.reviewer.firstName.slice(0, 1), lastName: assignment.reviewer.lastName }
            }}
        </span>
        <span class="chip error" *ngSwitchCase="FlightZoneApplicationReviewStatus.Disapprove">
            <dtm-ui-icon name="error-warning"></dtm-ui-icon>
            {{
                "dssAdminLibFlightZoneManagement.reviewStatusBadge.disapprovedLabel"
                    | transloco
                        : { firstLetterOfFirstName: assignment.reviewer.firstName.slice(0, 1), lastName: assignment.reviewer.lastName }
            }}
        </span>
    </ng-container>
</ng-container>
