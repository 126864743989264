import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DesignatorRange } from "@dtm-frontend/dss-shared-lib";
import { FunctionUtils } from "@dtm-frontend/shared/utils";

const DESIGNATOR_MAX_VALUE = 9999;
const DESIGNATOR_PATTERN = "[0-9]{1,4}";

@Component({
    selector: "dss-admin-lib-set-custom-designator-dialog",
    templateUrl: "set-custom-designator-dialog.component.html",
    styleUrls: ["./set-custom-designator-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetCustomDesignatorDialogComponent {
    protected readonly designatorControl = new FormControl<number | null>(null, [
        Validators.required,
        Validators.max(DESIGNATOR_MAX_VALUE),
        Validators.pattern(DESIGNATOR_PATTERN),
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: { forbiddenDesignatorRange?: DesignatorRange },
        protected readonly dialogRef: MatDialogRef<SetCustomDesignatorDialogComponent>
    ) {
        if (data.forbiddenDesignatorRange) {
            this.designatorControl.addValidators(this.validateDesignatorValue(data.forbiddenDesignatorRange));
        }
    }

    protected confirm(): void {
        if (this.designatorControl.invalid) {
            this.designatorControl.markAsTouched();

            return;
        }

        this.dialogRef.close(this.designatorControl.value);
    }

    private validateDesignatorValue(forbiddenValueRange: { from: number; to: number }): ValidatorFn {
        return (control: AbstractControl<number | null>): ValidationErrors | null => {
            const value = control.value;
            if (FunctionUtils.isNullOrUndefined(value) || value < forbiddenValueRange.from || value > forbiddenValueRange.to) {
                return null;
            }

            return { invalidValue: { from: forbiddenValueRange.from, to: forbiddenValueRange.to } };
        };
    }
}
