import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { ApplicationNoteDialogComponent } from "./application-note-dialog/application-note-dialog.component";

interface ApplicationNoteComponentState {
    note: string | undefined;
    isReadOnly: boolean;
    isProcessing: boolean;
}

const MAX_NOTE_PREVIEW_LENGTH = 300;

@Component({
    selector: "dss-shared-lib-application-note[note]",
    templateUrl: "./application-note.component.html",
    styleUrls: ["./application-note.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationNoteComponent {
    protected readonly note$ = this.localStore.selectByKey("note");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input() public set note(value: string | undefined) {
        this.localStore.patchState({ note: value });
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public dialogOpened = new EventEmitter<MatDialogRef<ApplicationNoteDialogComponent>>();

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationNoteComponentState>,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            note: undefined,
            isReadOnly: false,
            isProcessing: false,
        });
    }

    public openNoteDialog(): void {
        const note = this.localStore.selectSnapshotByKey("note");
        const isReadOnly = this.localStore.selectSnapshotByKey("isReadOnly");
        const isProcessing$ = this.localStore.selectByKey("isProcessing");
        const dialogRef = this.dialogService.open(ApplicationNoteDialogComponent, {
            data: { note, isReadOnly, isProcessing$ },
        });

        this.dialogOpened.emit(dialogRef);
    }

    protected formatNoteForPreview(htmlString: string): string {
        const noteWithoutHMTL = htmlString.replace(/(<([^>]+)>)/gi, " ");

        return StringUtils.ellipsifyText(noteWithoutHMTL, MAX_NOTE_PREVIEW_LENGTH);
    }
}
