import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { RouterModule } from "@angular/router";
import { FlightZoneSharedModule } from "@dtm-frontend/dss-shared-lib";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AddMemberDialogComponent } from "./components/add-member-dialog/add-member-dialog.component";
import { AnspTeamsListContainerComponent } from "./components/ansp-teams-list-container/ansp-teams-list-container.component";
import { AnspTeamsListComponent } from "./components/ansp-teams-list/ansp-teams-list.component";
import { InstitutionDetailContainerComponent } from "./components/institution-detail-container/institution-detail-container.component";
import { InstitutionDetailComponent } from "./components/institution-detail/institution-detail.component";
import { InstitutionsListContainerComponent } from "./components/institutions-list-container/institutions-list-container.component";
import { InstitutionsListComponent } from "./components/institutions-list/institutions-list.component";
import { MembersListComponent } from "./components/members-list/members-list.component";
import { StandardWorkCalendarComponent } from "./components/standard-work-calendar/standard-work-calendar.component";
import { TeamDaysOffComponent } from "./components/team-days-off/team-days-off.component";
import { TeamWorkCalendarContainerComponent } from "./components/team-work-calendar-container/team-work-calendar-container.component";
import { UserDetailContainerComponent } from "./components/user-detail-container/user-detail-container.component";
import { UserDetailComponent } from "./components/user-detail/user-detail.component";
import { UsersListContainerComponent } from "./components/users-list-container/users-list-container.component";
import { UsersListComponent } from "./components/users-list/users-list.component";
import { AdministrationApiService } from "./services/administration-api.service";
import { AdministrationState } from "./state/administration.state";

@NgModule({
    declarations: [
        UsersListContainerComponent,
        UsersListComponent,
        UserDetailContainerComponent,
        UserDetailComponent,
        InstitutionsListContainerComponent,
        InstitutionsListComponent,
        MembersListComponent,
        AddMemberDialogComponent,
        InstitutionDetailContainerComponent,
        InstitutionDetailComponent,
        AnspTeamsListContainerComponent,
        AnspTeamsListComponent,
        TeamWorkCalendarContainerComponent,
        StandardWorkCalendarComponent,
        TeamDaysOffComponent,
    ],
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        RouterModule,
        SharedUiModule,
        SharedI18nModule,
        MatTableModule,
        MatMenuModule,
        NgxsModule.forFeature([AdministrationState]),
        MatInputModule,
        ReactiveFormsModule,
        MatCardModule,
        MatDialogModule,
        FlightZoneSharedModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatChipsModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssAdminLibAdministration",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        UserDetailComponent,
        InstitutionsListComponent,
        InstitutionDetailComponent,
        AnspTeamsListComponent,
        StandardWorkCalendarComponent,
        TeamDaysOffComponent,
    ],
})
export class AdministrationModule {
    public static forRoot(): ModuleWithProviders<AdministrationModule> {
        return {
            ngModule: AdministrationModule,
            providers: [
                {
                    provide: AdministrationApiService,
                    useClass: AdministrationApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<AdministrationModule> {
        return {
            ngModule: AdministrationModule,
            providers: [
                {
                    provide: AdministrationApiService,
                    useValue: null,
                },
            ],
        };
    }
}
