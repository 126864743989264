import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PhoneNumber, phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { AdministrationCapabilities, NewUserDetails, UserDetails } from "../../models/administration.models";

interface UserDetailForm {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    email: FormControl<string | null>;
    phoneNumber: FormControl<PhoneNumber | null>;
    type: FormControl<string | null>;
    isAirOperationsCoordinator: FormControl<boolean | null>;
    isFlightsDirector: FormControl<boolean | null>;
}

interface UserDetailComponentState {
    capabilities: AdministrationCapabilities | undefined;
}

@Component({
    selector: "dss-admin-lib-user-detail[capabilities]",
    templateUrl: "./user-detail.component.html",
    styleUrls: ["./user-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserDetailComponent {
    protected readonly userDetailForm = new FormGroup<UserDetailForm>({
        firstName: new FormControl("", [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)]),
        lastName: new FormControl("", [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)]),
        email: new FormControl("", [Validators.required, Validators.email, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)]),
        phoneNumber: new FormControl(null, {
            validators: [phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode],
        }),
        type: new FormControl("", [Validators.required]),
        isAirOperationsCoordinator: new FormControl(null),
        isFlightsDirector: new FormControl(null),
    });

    protected readonly capabilities$ = this.localStore.selectByKey("capabilities");

    @Input() public set capabilities(value: AdministrationCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }

    @Input() public set userDetails(value: UserDetails | undefined) {
        if (!value) {
            return;
        }

        this.userDetailForm.patchValue(value);
    }

    @Output() public readonly cancel = new EventEmitter<void>();
    @Output() public readonly userDetailSave = new EventEmitter<NewUserDetails>();

    constructor(private readonly localStore: LocalComponentStore<UserDetailComponentState>) {
        localStore.setState({ capabilities: undefined });
    }

    protected saveForm(): void {
        this.userDetailForm.markAllAsTouched();

        if (this.userDetailForm.invalid) {
            return;
        }

        this.userDetailSave.emit(this.userDetailForm.value as NewUserDetails);
    }
}
