import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Notam } from "@dtm-frontend/dss-shared-lib";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface NotamContentComponentState {
    isEditModeEnabled: boolean;
    notam: Notam | undefined;
}

@Component({
    selector: "dss-admin-lib-notam-content[notam]",
    templateUrl: "./notam-content.component.html",
    styleUrls: ["./notam-content.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotamContentComponent {
    protected readonly isEditModeEnabled$ = this.localStore.selectByKey("isEditModeEnabled");
    protected readonly notam$ = this.localStore.selectByKey("notam");

    @Input() public set notam(value: Notam | undefined) {
        this.localStore.patchState({ notam: value });
    }

    constructor(private readonly localStore: LocalComponentStore<NotamContentComponentState>) {
        this.localStore.setState({
            isEditModeEnabled: false,
            notam: undefined,
        });
    }

    protected enableEdit(): void {
        this.localStore.patchState({ isEditModeEnabled: true });
    }
}
