import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ApplicationAssignment, FlightZoneApplicationReviewStatus } from "@dtm-frontend/dss-shared-lib";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AnspSupervisorRejectButtonComponentState {
    isProcessing: boolean;
    assignment: ApplicationAssignment | undefined;
}

@Component({
    selector: "dss-admin-lib-ansp-supervisor-reject-button[assignment]",
    templateUrl: "./ansp-supervisor-reject-button.component.html",
    styleUrls: ["./ansp-supervisor-reject-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AnspSupervisorRejectButtonComponent {
    public readonly assignment$ = this.localStore.selectByKey("assignment");
    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    @Input()
    public set assignment(value: ApplicationAssignment | undefined) {
        this.localStore.patchState({ assignment: value });
    }

    @Input()
    public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Output() public rejectApplication = new EventEmitter<void>();
    @Output() public rejectReview = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<AnspSupervisorRejectButtonComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            assignment: undefined,
        });
    }

    public reject(reviewStatus: FlightZoneApplicationReviewStatus | undefined): void {
        if (reviewStatus) {
            this.rejectReview.emit();
        } else {
            this.rejectApplication.emit();
        }
    }
}
