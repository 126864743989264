import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Subject } from "rxjs";

interface TextEditorSectionDialogConfig {
    title: string;
    text: string;
    isReadonly: boolean;
    maxLength?: number;
}

const DEFAULT_TEXT_MAX_LENGTH = 5000;

@Component({
    selector: "dss-shared-lib-text-editor-section-dialog",
    templateUrl: "text-editor-section-dialog.component.html",
    styleUrls: ["text-editor-section-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorSectionDialogComponent {
    private readonly updateTextSubject = new Subject<string | null>();

    protected readonly textFormControl = new FormControl<string | null>(null);

    public readonly textUpdate$ = this.updateTextSubject.asObservable();

    constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: TextEditorSectionDialogConfig) {
        data.maxLength = data.maxLength ?? DEFAULT_TEXT_MAX_LENGTH;
        this.textFormControl.addValidators(Validators.maxLength(data.maxLength));

        if (data.text) {
            this.textFormControl.setValue(data.text);
        }

        if (data.isReadonly) {
            this.textFormControl.disable();
        }
    }

    protected confirm(): void {
        this.textFormControl.markAsTouched();

        if (this.textFormControl.invalid) {
            return;
        }

        this.updateTextSubject.next(this.textFormControl.value);
    }
}
