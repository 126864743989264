import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AnspTeam } from "@dtm-frontend/dss-shared-lib";

@Component({
    selector: "dss-admin-lib-team-change-dialog",
    templateUrl: "./team-change-dialog.component.html",
    styleUrls: ["./team-change-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamChangeDialogComponent {
    protected readonly selectedTeamFormControl = new FormControl<AnspTeam | null>(null, [Validators.required]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { anspTeams: AnspTeam[] },
        private readonly dialogRef: MatDialogRef<TeamChangeDialogComponent>
    ) {}

    public onConfirmClick(): void {
        this.selectedTeamFormControl.markAllAsTouched();

        if (this.selectedTeamFormControl.invalid) {
            return;
        }

        this.dialogRef.close(this.selectedTeamFormControl.value);
    }
}
