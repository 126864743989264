<dtm-ui-editable-card
    class="editable-card"
    [label]="'dssSharedLibUserProfile.personalData.sectionTitle' | transloco"
    editButtonIcon="edit"
    [canLeaveEditModeOnSave]="personalDataForm.valid"
    (actionSave)="save()"
    (editModeChange)="changeEditMode($event)"
>
    <div class="preview-data-info-container" previewContent *ngrxLet="{ firstName: firstName$, lastName: lastName$ } as vm">
        <dtm-ui-label-value
            [label]="'dssSharedLibUserProfile.personalData.nameLabel' | transloco"
            [value]="vm.firstName + ' ' + vm.lastName"
        ></dtm-ui-label-value>
    </div>
    <ng-container editableContent>
        <dtm-ui-input-field>
            <input [formControl]="personalDataForm.controls.firstName" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="personalDataForm.controls.firstName; name: ['required', 'pattern']">
                {{ "dssSharedLibUserProfile.validationErrors.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="personalDataForm.controls.firstName; name: 'maxlength'; error as error">
                {{ "dssSharedLibUserProfile.validationErrors.maxLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <input [formControl]="personalDataForm.controls.lastName" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="personalDataForm.controls.lastName; name: ['required', 'pattern']">
                {{ "dssSharedLibUserProfile.validationErrors.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="personalDataForm.controls.lastName; name: 'maxlength'; error as error">
                {{ "dssSharedLibUserProfile.validationErrors.maxLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
    </ng-container></dtm-ui-editable-card
>
