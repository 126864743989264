<ng-container
    *ngrxLet="{
        isApplicationLocked: isApplicationLocked$,
        status: status$,
        areEditActionsEnabled: areEditActionsEnabled$,
        isRestrictionModification: isRestrictionModification$,
        isConfirmationDownloadEnabled: isConfirmationDownloadEnabled$
    } as vm"
>
    <h3 class="title-with-icon">
        {{ previewTitle$ | ngrxPush }}
        <dtm-ui-icon *ngIf="!vm.isApplicationLocked" name="lock-unlock"></dtm-ui-icon>
    </h3>

    <button
        *ngIf="vm.areEditActionsEnabled || vm.isConfirmationDownloadEnabled"
        type="button"
        class="button-icon"
        [matMenuTriggerFor]="lockActionsMenu"
    >
        <dtm-ui-icon name="more"></dtm-ui-icon>
    </button>

    <mat-menu #lockActionsMenu="matMenu">
        <ng-template matMenuContent>
            <ng-container *ngIf="vm.areEditActionsEnabled">
                <ng-container
                    *ngIf="vm.status === FlightZoneApplicationStatus.Assigned || vm.status === FlightZoneApplicationStatus.Reviewed"
                >
                    <ng-container *ngIf="!vm.isRestrictionModification">
                        <button
                            mat-menu-item
                            type="button"
                            *ngIf="vm.isApplicationLocked; else applicationUnlockedTemplate"
                            (click)="changeLockedStatus.emit(false)"
                        >
                            <dtm-ui-icon name="lock-unlock"></dtm-ui-icon>
                            <span>{{ "dssSharedLibFlightZone.previewHeader.unlockButtonLabel" | transloco }}</span>
                        </button>

                        <ng-template #applicationUnlockedTemplate>
                            <button mat-menu-item type="button" (click)="changeLockedStatus.emit(true)">
                                <dtm-ui-icon name="lock"></dtm-ui-icon>
                                <span>{{ "dssSharedLibFlightZone.previewHeader.lockButtonLabel" | transloco }}</span>
                            </button>
                        </ng-template>
                    </ng-container>

                    <button mat-menu-item type="button" (click)="assignConsultant.emit()">
                        <dtm-ui-icon name="user-follow"></dtm-ui-icon>
                        <span>{{ "dssSharedLibFlightZone.previewHeader.assignConsultantButtonLabel" | transloco }}</span>
                    </button>
                </ng-container>

                <button
                    mat-menu-item
                    type="button"
                    *ngIf="enableChat.observed && !vm.isRestrictionModification"
                    (click)="enableChat.emit()"
                >
                    <dtm-ui-icon name="mail"></dtm-ui-icon>
                    <span>{{ "dssSharedLibFlightZone.previewHeader.enableChatButtonLabel" | transloco }}</span>
                </button>

                <button
                    *ngIf="hasSupervisorPermissions$ | ngrxPush"
                    mat-menu-item
                    type="button"
                    (click)="vm.isApplicationLocked ? changeTeam.emit() : cannotChangeTeam.emit()"
                >
                    <dtm-ui-icon name="group"></dtm-ui-icon>
                    <span>{{ "dssSharedLibFlightZone.previewHeader.changeTeamButtonLabel" | transloco }}</span>
                </button>
            </ng-container>

            <button
                *ngIf="vm.isConfirmationDownloadEnabled && confirmationOfApplicationSubmissionPdfDownload.observed"
                mat-menu-item
                type="button"
                (click)="confirmationOfApplicationSubmissionPdfDownload.emit()"
            >
                <dtm-ui-icon name="download"></dtm-ui-icon>
                <span>{{
                    "dssSharedLibFlightZone.previewHeader.downloadConfirmationOfApplicationSubmissionPdfButtonLabel" | transloco
                }}</span>
            </button>
        </ng-template>
    </mat-menu>
</ng-container>
