import { HttpParams } from "@angular/common/http";
import { Params } from "@angular/router";
import { FlightZoneApplicationPurpose } from "@dtm-frontend/dss-shared-lib";
import { Address, PageResponseBody, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils, ObjectUtils } from "@dtm-frontend/shared/utils";
import {
    AnspStandardTeamWorkCalendar,
    AnspTeamDayOff,
    AnspTeamDetails,
    AnspTeamWorkCalendar,
    AnspTeamsList,
    AnspTeamsListItem,
    DssUserType,
    InstitutionDetails,
    InstitutionMember,
    InstitutionsList,
    InstitutionsListItem,
    NewInstitutionDetails,
    NewUserDetails,
    UserDetails,
    UsersList,
    WorkCalendarDays,
} from "../models/administration.models";

export type UsersListResponseBody = PageResponseBody<UserListItemEntity>;
export type InstitutionsListResponseBody = PageResponseBody<InstitutionsListItem>;
export type AnspTeamsListResponseBody = PageResponseBody<AnspTeamsListItem>;

interface UserListItemEntity {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    userType: DssUserType;
    flightsDirector: boolean;
    airOperationsCoordinator: boolean;
}

export interface InstitutionsDetailsResponseBody {
    id: string;
    generalInfo: {
        name: string;
        unitName: string;
    };
    members: InstitutionMemberResponseBody[];
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
    version: number;
    purposes: {
        id: string;
        purpose: FlightZoneApplicationPurpose;
        stateSecurityRestriction: true;
    }[];
}

interface InstitutionMemberResponseBody {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    supervisor: boolean;
}

interface SaveInstitutionRequestPayload {
    name: string;
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
    unitName?: string;
    institutionPurposes: {
        purpose: FlightZoneApplicationPurpose;
        stateSecurityRestriction: boolean;
    }[];
}

export interface AnspTeamDetailsResponseBody {
    id: string;
    name: string;
    members: InstitutionMemberResponseBody[];
    restrictionPurposes: FlightZoneApplicationPurpose[];
    version: number;
}

export interface AnspTeamWorkCalendarResponseBody {
    workingCalendar: WorkingCalendarResponseBody;
    daysOff: DaysOffResponseBody;
}

export interface WorkingCalendarResponseBody {
    id: string;
    days: WorkCalendarDays[];
    team: AnspTeamDetailsResponseBody;
    startAt: string;
    endAt: string;
}

export interface DaysOffResponseBody {
    daysOff: {
        id: string;
        at: string;
    }[];
}

interface AddDaysOffRequestPayload {
    daysOff: string[];
    calendarId: string;
}

interface UserDetailsEntity {
    id: string;
    version: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    type: DssUserType;
    airOperationsCoordinator: boolean;
    flightsDirector: boolean;
}

export type GetUserDetailsResponseBody = UserDetailsEntity;
export type CreateUserRequestPayload = Omit<UserDetailsEntity, "id" | "version">;
export type UpdateUserRequestPayload = UserDetailsEntity;

export function convertFilterParamsToHttpParams(params: Params): HttpParams {
    return new HttpParams({
        fromObject: ObjectUtils.removeNullishProperties({
            ...params,
        }),
    });
}

export function convertUsersListResponseBodyToUsersList(response: UsersListResponseBody): UsersList {
    return {
        content: response.content.map((user) => {
            const { airOperationsCoordinator, flightsDirector, ...remainingUserDetails } = user;

            return {
                ...remainingUserDetails,
                isAirOperationsCoordinator: airOperationsCoordinator,
                isFlightsDirector: flightsDirector,
            };
        }),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertInstitutionsListResponseBodyToInstitutionsList(response: InstitutionsListResponseBody): InstitutionsList {
    return {
        content: response.content,
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

function convertInstitutionMemberResponseBodyToInstitutionMember(member: InstitutionMemberResponseBody): InstitutionMember {
    return {
        id: member.id,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        phoneNumber: member.phoneNumber,
        isSupervisor: member.supervisor,
    };
}

export function convertInstitutionsDetailsResponseBodyToInstitutionDetails(response: InstitutionsDetailsResponseBody): InstitutionDetails {
    return {
        id: response.id,
        name: response.generalInfo.name,
        phoneNumber: response.phoneNumber,
        email: response.email,
        address: response.address,
        unitName: response.generalInfo.unitName,
        members: response.members.map((member) => convertInstitutionMemberResponseBodyToInstitutionMember(member)),
        purposes: response.purposes.map(({ purpose, stateSecurityRestriction }) => ({
            purpose,
            hasStateSecurityRestriction: stateSecurityRestriction,
        })),
        version: response.version,
    };
}

export function convertNewInstitutionDetailsToThreadRequestPayload(institution: NewInstitutionDetails): SaveInstitutionRequestPayload {
    return {
        address: institution.address,
        unitName: institution.unitName,
        email: institution.email,
        name: institution.name,
        phoneNumber: institution.phoneNumber,
        institutionPurposes: institution.purposes.map(({ purpose, hasStateSecurityRestriction }) => ({
            purpose,
            stateSecurityRestriction: hasStateSecurityRestriction,
        })),
    };
}

export function convertAnspTeamsListResponseBodyToAnspTeamsList(response: AnspTeamsListResponseBody): AnspTeamsList {
    return {
        content: response.content,
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertAnspTeamDetailsResponseBodyToAnspTeamDetails(response: AnspTeamDetailsResponseBody): AnspTeamDetails {
    return {
        id: response.id,
        name: response.name,
        members: response.members.map((member) => convertInstitutionMemberResponseBodyToInstitutionMember(member)),
        restrictionPurposes: response.restrictionPurposes,
        version: response.version,
    };
}

function convertWorkingCalendarTimeResponseToDate(timeResponse: string): Date {
    const [hours, minutes] = timeResponse.split(":").map(Number);

    return new Date(new Date().setUTCHours(hours, minutes, 0, 0));
}

export function convertWorkingCalendarResponseBodyToAnspStandardTeamWorkCalendar(
    response: WorkingCalendarResponseBody
): AnspStandardTeamWorkCalendar {
    return {
        id: response.id,
        teamName: response.team.name,
        teamId: response.team.id,
        days: response.days,
        startAt: convertWorkingCalendarTimeResponseToDate(response.startAt),
        endAt: convertWorkingCalendarTimeResponseToDate(response.endAt),
    };
}

export function convertDaysOffResponseBodyToAnspTeamDayOffList(response: DaysOffResponseBody): AnspTeamDayOff[] {
    return response.daysOff.map((dayOffResponse) => ({
        id: dayOffResponse.id,
        date: new Date(dayOffResponse.at),
    }));
}

export function convertAnspTeamWorkCalendarResponseBodyToAnspTeamWorkCalendar(
    response: AnspTeamWorkCalendarResponseBody
): AnspTeamWorkCalendar {
    return {
        standardTeamCalendar: convertWorkingCalendarResponseBodyToAnspStandardTeamWorkCalendar(response.workingCalendar),
        daysOff: convertDaysOffResponseBodyToAnspTeamDayOffList(response.daysOff),
    };
}

export function getAddDaysOffRequestPayload(daysOff: Date[], calendarId: string): AddDaysOffRequestPayload {
    return {
        daysOff: daysOff.map((dayOff) => DateUtils.getISOStringDate(dayOff.toDateString())),
        calendarId,
    };
}

export function convertUserDetailsToUpdateUserRequestPayload(userDetails: UserDetails): UpdateUserRequestPayload {
    const { isAirOperationsCoordinator, isFlightsDirector, ...remainingUserDetails } = userDetails;

    return {
        ...remainingUserDetails,
        airOperationsCoordinator: isAirOperationsCoordinator,
        flightsDirector: isFlightsDirector,
    };
}

export function convertNewUserDetailsToCreateUserRequestPayload(userDetails: NewUserDetails): CreateUserRequestPayload {
    const { isAirOperationsCoordinator, isFlightsDirector, ...remainingUserDetails } = userDetails;

    return {
        ...remainingUserDetails,
        airOperationsCoordinator: isAirOperationsCoordinator,
        flightsDirector: isFlightsDirector,
    };
}

export function convertGetUserDetailsResponseBodyToUserDetails(getUserDetailsResponse: GetUserDetailsResponseBody): UserDetails {
    const { airOperationsCoordinator, flightsDirector, ...remainingUserDetails } = getUserDetailsResponse;

    return {
        ...remainingUserDetails,
        isAirOperationsCoordinator: airOperationsCoordinator,
        isFlightsDirector: flightsDirector,
    };
}
