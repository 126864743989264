import { Injectable } from "@angular/core";
import {
    ConsultationData,
    FlightZoneApiService,
    FlightZoneApplication,
    FlightZoneApplicationsForConsultationList,
    FlightZoneError,
    FlightZoneErrorType,
} from "@dtm-frontend/dss-shared-lib";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { FlightZoneConsultationStateModel } from "../models/flight-zone-consultation.models";
import { FlightZoneConsultationActions } from "./flight-zone-consultation.actions";

const defaultState: FlightZoneConsultationStateModel = {
    error: undefined,
    isProcessing: false,
    applicationForConsultation: undefined,
    activeConsultation: undefined,
    consultationsInProgressList: undefined,
    consultationsCompletedList: undefined,
};

@State<FlightZoneConsultationStateModel>({
    name: "flightZoneConsultation",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneConsultationState {
    @Selector()
    public static error(state: FlightZoneConsultationStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static hasListDataRetrievalError(state: FlightZoneConsultationStateModel): boolean {
        return state.error?.type === FlightZoneErrorType.CannotGetApplicationList;
    }

    @Selector()
    public static isProcessing(state: FlightZoneConsultationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static applicationForConsultation(state: FlightZoneConsultationStateModel): FlightZoneApplication | undefined {
        return state.applicationForConsultation;
    }

    @Selector()
    public static activeConsultation(state: FlightZoneConsultationStateModel): ConsultationData | undefined {
        return state.activeConsultation;
    }

    @Selector()
    public static consultationsInProgressList(
        state: FlightZoneConsultationStateModel
    ): FlightZoneApplicationsForConsultationList | undefined {
        return state.consultationsInProgressList;
    }

    @Selector()
    public static consultationsCompletedList(
        state: FlightZoneConsultationStateModel
    ): FlightZoneApplicationsForConsultationList | undefined {
        return state.consultationsCompletedList;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {}

    @Action(FlightZoneConsultationActions.GetApplicationForConsultationData)
    public getConsultationData(
        context: StateContext<FlightZoneConsultationStateModel>,
        action: FlightZoneConsultationActions.GetApplicationForConsultationData
    ) {
        context.patchState({ isProcessing: true, applicationForConsultation: undefined, activeConsultation: undefined });

        return this.flightZoneApi.getFlightZoneApplicationConsultationData(action.consultationId).pipe(
            tap((applicationForConsultation) => {
                context.patchState({
                    applicationForConsultation,
                    activeConsultation: applicationForConsultation.consultations?.find(
                        (consultation) => consultation.id === action.consultationId
                    ),
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneConsultationActions.ReviewConsultation)
    public reviewConsultation(
        context: StateContext<FlightZoneConsultationStateModel>,
        action: FlightZoneConsultationActions.ReviewConsultation
    ) {
        const currentApplicationForConsultationDataState = context.getState().applicationForConsultation;
        const activeConsultation = context.getState().activeConsultation;

        if (!currentApplicationForConsultationDataState?.analysisStatus || !activeConsultation) {
            return;
        }

        const updatedConsultation: ConsultationData = {
            ...activeConsultation,
            comment: action.comment,
            status: action.reviewStatus,
        };

        const version = currentApplicationForConsultationDataState.analysisStatus.version;
        const applicationType = currentApplicationForConsultationDataState.applicationType;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .consultationReview(action.consultationId, action.reviewStatus, action.comment, applicationType, version)
            .pipe(
                tap(() => {
                    context.patchState({
                        applicationForConsultation: {
                            ...currentApplicationForConsultationDataState,
                            consultations: currentApplicationForConsultationDataState.consultations?.map((consultation) =>
                                consultation.id === action.consultationId ? updatedConsultation : consultation
                            ),
                        },
                        activeConsultation: updatedConsultation,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error: error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneConsultationActions.GetConsultationsInProgressList, { cancelUncompleted: true })
    public getConsultationsInProgressList(
        context: StateContext<FlightZoneConsultationStateModel>,
        action: FlightZoneConsultationActions.GetConsultationsInProgressList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getApplicationsForConsultationList({
                ...action.filterParams,
                reviewed: false,
            })
            .pipe(
                tap((consultationsInProgressList) => {
                    context.patchState({
                        consultationsInProgressList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneConsultationActions.GetConsultationsCompletedList, { cancelUncompleted: true })
    public GetConsultationsCompletedList(
        context: StateContext<FlightZoneConsultationStateModel>,
        action: FlightZoneConsultationActions.GetConsultationsCompletedList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getApplicationsForConsultationList({
                ...action.filterParams,
                reviewed: true,
            })
            .pipe(
                tap((consultationsCompletedList) => {
                    context.patchState({
                        consultationsCompletedList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }
}
