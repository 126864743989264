<ng-container *ngrxLet="{ featuresData: featuresData$, isProcessing: isProcessing$ } as vm">
    <div class="feature-list" *ngIf="vm.featuresData.length > 0 || vm.isProcessing">
        <button type="button" class="button-icon" (click)="dismissFeaturesDataList()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <dtm-ui-loader-container [isShown]="vm.isProcessing" [diameter]="20">
            <table>
                <thead>
                    <tr>
                        <th>{{ "dssSharedLibFlightZone.activeRestrictionsFeaturesList.designatorColumnLabel" | transloco }}</th>
                        <th>{{ "dssSharedLibFlightZone.activeRestrictionsFeaturesList.typeColumnLabel" | transloco }}</th>
                        <th>{{ "dssSharedLibFlightZone.activeRestrictionsFeaturesList.heightLimitsColumnLabel" | transloco }}</th>
                    </tr>
                </thead>

                <tr *ngFor="let featureData of vm.featuresData">
                    <td>{{ featureData.designator }}</td>
                    <td>{{ featureData.type }} / {{ featureData.geozone }}</td>
                    <td>
                        <ng-container
                            [ngTemplateOutlet]="heightLimitsTemplate"
                            [ngTemplateOutletContext]="{ $implicit: featureData.lowerLimit | invoke : tryGetHeightLimitInFeet }"
                        ></ng-container>
                        -
                        <ng-container
                            [ngTemplateOutlet]="heightLimitsTemplate"
                            [ngTemplateOutletContext]="{ $implicit: featureData.upperLimit | invoke : tryGetHeightLimitInFeet }"
                        ></ng-container>
                    </td>
                </tr>
            </table>
        </dtm-ui-loader-container>
    </div>
</ng-container>

<ng-template #heightLimitsTemplate let-value>
    <ng-container *ngIf="!!value; else groundOrUnlimitedHeightTemplate">
        {{ "dssSharedLibFlightZone.activeRestrictionsFeaturesList.heightLimitLabel" | transloco : { value: value } }}
    </ng-container>

    <ng-template #groundOrUnlimitedHeightTemplate [ngSwitch]="value">
        <ng-container *ngSwitchCase="0">
            {{ "dssSharedLibFlightZone.activeRestrictionsFilters.groundHeightPlaceholder" | transloco }}
        </ng-container>

        <ng-container *ngSwitchDefault>
            {{ "dssSharedLibFlightZone.activeRestrictionsFilters.unlimitedHeightPlaceholder" | transloco }}
        </ng-container>
    </ng-template>
</ng-template>
