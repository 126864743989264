<ng-container *ngrxLet="{ text: text$, isReadonly: isReadonly$, isProcessing: isProcessing$ } as vm">
    <div class="header" [class.read-only]="vm.isReadonly">
        <div class="section-title"><ng-content select="[sectionTitle]"></ng-content></div>
        <div class="section-actions">
            <ng-content select="[sectionActions]"></ng-content>

            <button *ngIf="vm.text?.length || !vm.isReadonly" class="button-secondary" type="button" (click)="openDialog()">
                <ng-container *ngIf="vm.isReadonly">
                    <dtm-ui-icon name="eye"></dtm-ui-icon>
                    {{ "dssSharedLibFlightZone.textEditorSection.previewButtonLabel" | transloco }}
                </ng-container>

                <ng-container *ngIf="!vm.isReadonly">
                    <ng-container *ngIf="!!vm.text?.length; else addButtonTemplate">
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                        {{ "dssSharedLibFlightZone.textEditorSection.editButtonLabel" | transloco }}
                    </ng-container>

                    <ng-template #addButtonTemplate>
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dssSharedLibFlightZone.textEditorSection.addButtonLabel" | transloco }}
                    </ng-template>
                </ng-container>
            </button>
        </div>
    </div>

    <div class="content">
        <p *ngIf="vm.isReadonly && !vm.text?.length" class="text-preview">
            {{ "dssSharedLibFlightZone.textEditorSection.notFilledMessage" | transloco }}
        </p>

        <p *ngIf="vm.text" class="text-preview">
            {{ vm.text | invoke : formatTextForPreview }}
        </p>
    </div>
</ng-container>
