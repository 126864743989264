import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { RestrictionType } from "@dtm-frontend/dss-shared-lib";

@Component({
    selector: "dss-admin-lib-edit-suggested-restriction-type-dialog",
    templateUrl: "./edit-suggested-restriction-type-dialog.component.html",
    styleUrls: ["./edit-suggested-restriction-type-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSuggestedRestrictionTypeDialogComponent {
    protected readonly restrictionTypeControl = new FormControl<RestrictionType | null>(null, {
        validators: [Validators.required],
        nonNullable: true,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        protected readonly data: {
            availableRestrictionTypes: RestrictionType[];
            suggestedRestrictionType: RestrictionType;
            isCustomDesignatorSet: boolean;
        },
        private readonly dialogRef: MatDialogRef<EditSuggestedRestrictionTypeDialogComponent>
    ) {
        this.restrictionTypeControl.setValue(data.suggestedRestrictionType);
    }

    public onConfirmClick(): void {
        if (this.restrictionTypeControl.invalid) {
            return;
        }

        this.dialogRef.close(this.restrictionTypeControl.value);
    }
}
