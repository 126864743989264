import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs";
import { FlightZoneApplicationChatMessage } from "../../models/flight-zone-shared.models";

const MESSAGE_MAX_LENGTH = 500;

interface ApplicationChangesChatComponentState {
    messages: FlightZoneApplicationChatMessage[];
    isProcessing: boolean;
    isReadOnly: boolean;
    isChatCollapsed: boolean;
    userId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-application-changes-chat[messages][userId]",
    templateUrl: "./application-changes-chat.component.html",
    styleUrls: ["./application-changes-chat.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationChangesChatComponent implements AfterViewInit {
    public readonly MESSAGE_MAX_LENGTH = MESSAGE_MAX_LENGTH;
    public readonly commentFormControl = new UntypedFormControl("", [Validators.required]);

    public readonly messages$ = this.localStore.selectByKey("messages");
    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");
    public readonly userId$ = this.localStore.selectByKey("userId");

    @HostBinding("class.chat-collapsed")
    @Input()
    public set isChatCollapsed(value: boolean) {
        this.localStore.patchState({ isChatCollapsed: value });
    }
    public get isChatCollapsed() {
        return this.localStore.selectSnapshotByKey("isChatCollapsed");
    }

    @Input() public set messages(value: FlightZoneApplicationChatMessage[] | undefined) {
        this.localStore.patchState({ messages: value });
    }

    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input() public set userId(value: string | undefined) {
        this.localStore.patchState({ userId: value });
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Output() public readonly messageSubmit = new EventEmitter<string>();

    @ViewChildren("messageElement") private readonly messageElements: QueryList<ElementRef<HTMLElement>> = new QueryList();

    constructor(private readonly localStore: LocalComponentStore<ApplicationChangesChatComponentState>) {
        this.localStore.setState({
            messages: [],
            isProcessing: false,
            isReadOnly: false,
            isChatCollapsed: false,
            userId: undefined,
        });
    }

    public ngAfterViewInit(): void {
        this.scrollToLastMessageOnChanges();
    }

    public toggleChatSidebar(): void {
        this.localStore.patchState({ isChatCollapsed: !this.isChatCollapsed });
    }

    public submitMessage(): void {
        if (this.commentFormControl.valid) {
            this.messageSubmit.emit(this.commentFormControl.value);
        }
    }

    public resetMessageInput(): void {
        this.commentFormControl.reset();
    }

    private scrollToLastMessageOnChanges(): void {
        this.messageElements.changes
            .pipe(startWith(this.messageElements), untilDestroyed(this))
            .subscribe((queryList: QueryList<ElementRef<HTMLElement>>) => {
                if (!queryList.last) {
                    return;
                }

                const lastMessage = queryList.last.nativeElement;

                lastMessage.scrollIntoView({ behavior: "smooth" });
            });
    }
}
