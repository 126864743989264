import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs";
import { AnspStandardTeamWorkCalendar, WorkCalendarDays } from "../../models/administration.models";

interface StandardWorkCalendarComponentState {
    anspTeamWorkCalendar: AnspStandardTeamWorkCalendar | undefined;
    minEndTime: Date | undefined;
}

interface StandardWorkCalendarForm {
    startAt: FormControl<Date | null>;
    endAt: FormControl<Date | null>;
    days: FormControl<WorkCalendarDays[] | null>;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-standard-work-calendar[anspTeamWorkCalendar]",
    templateUrl: "./standard-work-calendar.component.html",
    styleUrls: ["./standard-work-calendar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StandardWorkCalendarComponent {
    protected readonly WorkCalendarDays = Object.values(WorkCalendarDays);
    protected readonly standardWorkCalendarForm = new FormGroup<StandardWorkCalendarForm>({
        startAt: new FormControl(null, [Validators.required]),
        endAt: new FormControl(null, [Validators.required]),
        days: new FormControl(null, [Validators.required]),
    });

    protected readonly anspTeamWorkCalendar$ = this.localStore.selectByKey("anspTeamWorkCalendar");
    protected readonly minEndTime$ = this.localStore.selectByKey("minEndTime");

    @Input() public set anspTeamWorkCalendar(value: AnspStandardTeamWorkCalendar | undefined) {
        this.localStore.patchState({ anspTeamWorkCalendar: value });

        if (value) {
            this.standardWorkCalendarForm.patchValue(value);
        }
    }

    @Output() public readonly standardWorkCalendarSave = new EventEmitter<AnspStandardTeamWorkCalendar>();

    constructor(private readonly localStore: LocalComponentStore<StandardWorkCalendarComponentState>) {
        this.localStore.setState({
            anspTeamWorkCalendar: undefined,
            minEndTime: undefined,
        });

        this.watchStartTimeChanges();
    }

    protected save(): void {
        const anspTeamWorkCalendar = this.localStore.selectSnapshotByKey("anspTeamWorkCalendar");

        this.standardWorkCalendarForm.markAllAsTouched();

        if (this.standardWorkCalendarForm.invalid || !anspTeamWorkCalendar) {
            return;
        }

        const value: AnspStandardTeamWorkCalendar = {
            ...anspTeamWorkCalendar,
            ...(this.standardWorkCalendarForm.value as Partial<AnspStandardTeamWorkCalendar>),
        };

        this.standardWorkCalendarSave.emit(value);
    }

    private watchStartTimeChanges(): void {
        const startTimeControl = this.standardWorkCalendarForm.controls.startAt;

        startTimeControl.valueChanges
            .pipe(startWith(startTimeControl.value), RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((startTimeValue) => {
                this.localStore.patchState({ minEndTime: new Date(startTimeValue) });
                this.standardWorkCalendarForm.controls.endAt.updateValueAndValidity();
            });
    }
}
