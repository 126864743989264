import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, catchError, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { UserData, UserNotificationsList, UserProfileErrorType } from "../models/user-profile.models";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";
import {
    UserNotificationsListResponseBody,
    transformUserNotificationsListResponseBodyToUserNotificationsList,
    transformUserProfileErrorResponseToUserProfileError,
} from "./user-profile-api.converters";

@Injectable()
export class UserProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly userProfileEndpoints: UserProfileEndpoints
    ) {}

    public getUserData(userId: string): Observable<UserData> {
        return this.httpClient
            .get<UserData>(StringUtils.replaceInTemplate(this.userProfileEndpoints.userDataManagement, { userId }))
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown }))));
    }

    public updateUserData(userData: UserData): Observable<UserData> {
        const params = new HttpParams().set("version", userData.version);

        return this.httpClient
            .put<UserData>(StringUtils.replaceInTemplate(this.userProfileEndpoints.userDataManagement, { userId: userData.id }), userData, {
                params,
            })
            .pipe(catchError((error) => throwError(() => transformUserProfileErrorResponseToUserProfileError(error))));
    }

    public requestUserAccountDelete(userId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.userProfileEndpoints.requestUserAccountDelete, { userId }))
            .pipe(catchError((error) => throwError(() => transformUserProfileErrorResponseToUserProfileError(error))));
    }

    public getNotificationsList(params: Params): Observable<UserNotificationsList> {
        return this.httpClient
            .get<UserNotificationsListResponseBody>(this.userProfileEndpoints.getNotifications, {
                params,
            })
            .pipe(
                map((response) => transformUserNotificationsListResponseBodyToUserNotificationsList(response)),
                catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown })))
            );
    }

    public updateNotification(id: string, isEnabled: boolean): Observable<void> {
        const params = new HttpParams().set("enable", isEnabled);

        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(this.userProfileEndpoints.updateNotification, { id }),
                {},
                {
                    params,
                }
            )
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown }))));
    }

    public resetUserPassword(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.userProfileEndpoints.userDataManagement, { userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown }))));
    }
}
