import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { NewUserDetails } from "../../models/administration.models";
import { ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME } from "../../services/administration-user-details.resolver";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";

interface UserDetailContainerComponentState {
    userId: string | null;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-user-detail-container",
    templateUrl: "./user-detail-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserDetailContainerComponent {
    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly capabilities$ = this.store.select(AdministrationState.capabilities);
    protected readonly capabilitiesError$ = this.store.select(AdministrationState.getCapabilitiesError);
    protected readonly getUserDetailsError$ = this.store.select(AdministrationState.getUserDetailsError);
    protected readonly userDetails$ = this.store.select(AdministrationState.userDetails);

    constructor(
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<UserDetailContainerComponentState>,
        private readonly route: ActivatedRoute
    ) {
        this.localStore.setState({
            userId: this.route.snapshot.paramMap.get(ADMINISTRATION_USER_ID_ROUTE_PARAM_NAME),
        });
    }

    protected refreshData(): void {
        this.store.dispatch(new AdministrationActions.GetCapabilities());

        const userId = this.localStore.selectSnapshotByKey("userId");

        if (userId) {
            this.store.dispatch(new AdministrationActions.GetUserDetails(userId));
        }
    }

    protected cancel(): void {
        this.router.navigateByUrl("/admin/users");
    }

    protected saveUser(value: NewUserDetails): void {
        const saveAction = this.localStore.selectSnapshotByKey("userId")
            ? new AdministrationActions.UpdateUserDetails(value)
            : new AdministrationActions.SaveNewUser(value);

        this.store
            .dispatch(saveAction)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.userDetailSaveError);

                if (error) {
                    this.toastrService.error(this.transloco.translate("dssAdminLibAdministration.userDetail.saveUserErrorMessage"));

                    return;
                }

                this.toastrService.success(this.transloco.translate("dssAdminLibAdministration.userDetail.saveUserSuccessMessage"));
                this.router.navigateByUrl("/admin/users");
            });
    }
}
