<ng-container *ngrxLet="flightZoneApplications$ as flightZoneApplications">
    <ng-container *ngrxLet="displayedColumns$ as displayedColumns">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <table
                mat-table
                multiTemplateDataRows
                [dataSource]="flightZoneApplications"
                *ngrxLet="expandedElement$ as expandedElement"
                class="dtm-table"
            >
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.statusHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-admin-lib-application-management-status-badge
                            [listElement]="element"
                            [hasSupervisorPermissions]="!!(hasSupervisorPermissions$ | ngrxPush)"
                        ></dss-admin-lib-application-management-status-badge>
                    </td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.titleHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="notam">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.notamHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.notam }}</td>
                </ng-container>

                <ng-container matColumnDef="zoneNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.zoneNumberHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.zoneNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="applicationPurpose">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.purposeHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dtm-ui-popover
                            *ngIf="element.stateSecurityRestriction"
                            [popoverText]="'dssAdminLibFlightZoneManagement.applicationListShared.stateSecurityRestrictionHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                        {{
                            "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel"
                                | transloco : { value: element.applicationPurpose }
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.dateHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.date | localizeDate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.startAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.startAt; else restrictionCancelledDateTemplate"
                            [date]="element.startAt"
                        ></dss-shared-lib-utc-date-display>
                        <dtm-ui-popover
                            *ngIf="element.isBeingCancelled"
                            popoverIcon="forbid"
                            [popoverText]="'dssAdminLibFlightZoneManagement.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.endAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display
                            *ngIf="element.endAt; else restrictionCancelledDateTemplate"
                            [date]="element.endAt"
                        ></dss-shared-lib-utc-date-display>
                        <dtm-ui-popover
                            *ngIf="element.isBeingCancelled"
                            popoverIcon="forbid"
                            [popoverText]="'dssAdminLibFlightZoneManagement.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="
                                element.caseNumber || element.author || element.institution || element.assignee || element.anspCaseNumber
                            "
                            type="button"
                            class="button-icon"
                            (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                        >
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.caseNumber"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.caseNumberHeader' | transloco"
                                [value]="element.caseNumber"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.anspCaseNumber"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.anspCaseNumberHeader' | transloco"
                                [value]="element.anspCaseNumber"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.author.fullName"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.authorHeader' | transloco"
                                [value]="element.author.fullName"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.email"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.authorEmailHeader' | transloco"
                                [value]="element.author.email"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.phoneNumber"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.authorPhoneHeader' | transloco"
                                [value]="element.author.phoneNumber | formatPhoneNumber"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.institution"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.institutionHeader' | transloco"
                                [value]="element.institution"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.assignee"
                                [label]="'dssAdminLibFlightZoneManagement.applicationListShared.assigneeHeader' | transloco"
                                [value]="element.assignee"
                            >
                            </dtm-ui-label-value>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssAdminLibFlightZoneManagement.applicationListShared.actionsHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-error
                            *ngIf="hasDataRetrievalError$ | ngrxPush; else emptyListTemplate"
                            (reload)="listRefresh.emit()"
                        ></dtm-ui-error>

                        <ng-template #emptyListTemplate>
                            <dtm-ui-no-results></dtm-ui-no-results>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-pagination
                            *ngIf="applicationsPage$ | ngrxPush as page"
                            [pagination]="page"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="
                        (row.caseNumber || row.author || row.institution || row.assignee || row.anspCaseNumber) && toggleExpandableRow(row)
                    "
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="flightZoneApplications.length || isProcessing"
                    class="no-results-row"
                ></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['pagination']"
                    [class.hide-footer]="!flightZoneApplications.length"
                    class="pagination-row"
                ></tr>
            </table>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element *ngrxLet="actionButtons$ as actionButtons">
        <ng-container *ngrxLet="basePreviewRoutePath$ as basePreviewRoutePath">
            <a
                *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.PreviewAction"
                [routerLink]="[basePreviewRoutePath, 'preview', element.id]"
                mat-menu-item
            >
                <dtm-ui-icon name="file-text"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewActionLabel" | transloco }}</span>
            </a>
            <a
                *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.PreviewAndDecideAction"
                [routerLink]="[basePreviewRoutePath, element.id]"
                mat-menu-item
            >
                <dtm-ui-icon name="file-text"></dtm-ui-icon>
                <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewAndDecideActionLabel" | transloco }}</span>
            </a>
        </ng-container>

        <button
            *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.AddAssignee"
            mat-menu-item
            type="button"
            (click)="changeAssignee(element, false)"
        >
            <dtm-ui-icon name="user-add"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.addAssigneeActionLabel" | transloco }}</span>
        </button>

        <button
            *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.ChangeAssignee"
            mat-menu-item
            type="button"
            (click)="changeAssignee(element, true)"
        >
            <dtm-ui-icon name="user"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.changeAssigneeActionLabel" | transloco }}</span>
        </button>

        <button
            *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.AssignToMe"
            mat-menu-item
            type="button"
            (click)="assignToMe(element)"
        >
            <dtm-ui-icon name="user-add"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.assignToMeActionLabel" | transloco }}</span>
        </button>

        <button
            *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.NotamPreview"
            mat-menu-item
            type="button"
            (click)="openNotamPreview(element.id)"
        >
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.previewNotamActionLabel" | transloco }}</span>
        </button>

        <button
            *ngIf="actionButtons | invoke : shouldDisplayActionButton : TableActionButton.KmlDownload"
            mat-menu-item
            type="button"
            (click)="kmlDownload.emit(element)"
        >
            <dtm-ui-icon name="download"></dtm-ui-icon>
            <span>{{ "dssAdminLibFlightZoneManagement.applicationListShared.downloadKmlActionLabel" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>

<ng-template #restrictionCancelledDateTemplate>
    {{ "dssAdminLibFlightZoneManagement.applicationListShared.restrictionCancelledDateLabel" | transloco }}
</ng-template>
