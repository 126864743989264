<ng-container *ngIf="notam; else errorsTemplate">
    <div class="dialog-header" *ngIf="notam.notamNumber; let notamNumber">
        <h4 mat-dialog-title>
            {{ "dssSharedLibFlightZone.notamPreview.header" | transloco : { notamNumber: notamNumber.notamSeries + notamNumber.number } }}
        </h4>
        <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
    </div>

    <div mat-dialog-content>
        <div class="notam-value" [innerHTML]="notam.value"></div>
    </div>
</ng-container>

<ng-template #errorsTemplate>
    <div mat-dialog-content class="no-results-content">
        <dtm-ui-no-results></dtm-ui-no-results>
    </div>
</ng-template>

<div mat-dialog-actions align="end">
    <button
        *ngIf="notam"
        class="button-secondary"
        type="button"
        [cdkCopyToClipboard]="notam.value"
        (cdkCopyToClipboardCopied)="notamCopied()"
    >
        <dtm-ui-icon name="file-copy"></dtm-ui-icon>
        {{ "dssSharedLibFlightZone.notamPreview.copyToClipboardHint" | transloco }}
    </button>

    <button type="button" class="button-primary" [matDialogClose]="null">
        {{ "dssSharedLibFlightZone.notamPreview.closeButtonLabel" | transloco }}
    </button>
</div>
