<div
    *ngrxLet="{
        isProcessing: isProcessing$,
        institutions: institutions$,
        page: page$,
    } as vm"
    class="institutions-list"
>
    <mat-card *ngFor="let institution of vm.institutions" class="institution-card">
        <div class="card-header">
            {{ institution.name }}

            <div class="buttons">
                <button type="button" class="button-secondary" [routerLink]="institution.id">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dssAdminLibAdministration.institutionsList.editButtonLabel" | transloco }}
                </button>
                <button type="button" class="button-warn" (click)="institutionRemove.emit(institution)">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dssAdminLibAdministration.institutionsList.deleteButtonLabel" | transloco }}
                </button>
            </div>
        </div>
        <div class="contact-info">
            <dtm-ui-label-value
                *ngIf="institution.unitName"
                [label]="'dssAdminLibAdministration.institutionsList.unitNameLabel' | transloco"
            >
                {{ institution.unitName }}
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="institution.phoneNumber"
                [label]="'dssAdminLibAdministration.institutionsList.phoneNumberLabel' | transloco"
            >
                {{ institution.phoneNumber | formatPhoneNumber }}
            </dtm-ui-label-value>
            <dtm-ui-label-value *ngIf="institution.email" [label]="'dssAdminLibAdministration.institutionsList.emailLabel' | transloco">
                {{ institution.email }}
            </dtm-ui-label-value>
            <dtm-ui-address-label-value *ngIf="institution.address" [address]="institution.address"></dtm-ui-address-label-value>
        </div>

        <ng-container *ngIf="institution.members; else loadMoreTemplate">
            <dss-admin-lib-members-list
                [members]="institution.members"
                (memberRemove)="tryRemoveMember($event, institution)"
            ></dss-admin-lib-members-list>
            <div class="list-footer">
                <button type="button" class="button-primary" (click)="memberAdd.emit(institution)">
                    <dtm-ui-icon name="add-circle"></dtm-ui-icon>
                    {{ "dssAdminLibAdministration.institutionsList.addMemberButtonLabel" | transloco }}
                </button>
            </div>
        </ng-container>

        <ng-template #loadMoreTemplate>
            <button type="button" class="button-secondary load-more" (click)="loadMoreDetails.emit(institution.id)">
                <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                {{ "dssAdminLibAdministration.institutionsList.loadMoreButtonLabel" | transloco }}
            </button>
        </ng-template>
    </mat-card>

    <dtm-ui-no-results *ngIf="!vm.institutions.length && !vm.isProcessing"></dtm-ui-no-results>

    <dtm-ui-pagination *ngIf="vm.institutions.length && vm.page as page" [pagination]="page" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</div>
