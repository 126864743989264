import { Injectable } from "@angular/core";
import { ApplicationManagementListItemStatus, ListStatusTranslationService } from "@dtm-frontend/dss-shared-lib";

const APPLICATION_MANAGEMENT_STATUS_BADGE_KEYS = {
    [ApplicationManagementListItemStatus.Analysis]: "dssAdminLibFlightZoneManagement.applicationManagementStatusBadge.Analysis",
    [ApplicationManagementListItemStatus.Correction]: "dssAdminLibFlightZoneManagement.applicationManagementStatusBadge.Correction",
    [ApplicationManagementListItemStatus.ForApproval]: "dssAdminLibFlightZoneManagement.applicationManagementStatusBadge.ForApproval",
    [ApplicationManagementListItemStatus.Accepted]: "dssAdminLibFlightZoneManagement.applicationManagementStatusBadge.Accepted",
};

@Injectable({
    providedIn: "root",
})
export class ApplicationManagementStatusTranslationService implements ListStatusTranslationService {
    public getStatusTranslationsMap(hasSupervisorPermissions: boolean): Record<string, string> {
        const statuses = Object.values(ApplicationManagementListItemStatus);

        return statuses.reduce(
            (result, status) => ({
                ...result,
                [status]:
                    status === ApplicationManagementListItemStatus.ForApproval && !hasSupervisorPermissions
                        ? "dssAdminLibFlightZoneManagement.applicationManagementStatusBadge.WaitingForSupervisorApproval"
                        : APPLICATION_MANAGEMENT_STATUS_BADGE_KEYS[status],
            }),
            {}
        );
    }
}
