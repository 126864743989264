import { NgModule } from "@angular/core";
import { Route, RouterModule, Routes } from "@angular/router";
import {
    ApplicationManagementListItemStatus,
    ApplicationType,
    DssUserRoles,
    FlightZoneCapabilitiesResolver,
} from "@dtm-frontend/dss-shared-lib";
import { DtmAuthGuard, DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { AcceptedApplicationsListContainerComponent } from "./components/accepted-applications-list-container/accepted-applications-list-container.component";
import { ApplicationManagementPreviewComponent } from "./components/application-management-preview/application-management-preview.component";
import { ApplicationsAssignedToUserListContainerComponent } from "./components/applications-assigned-to-user-list-container/applications-assigned-to-user-list-container.component";
import { ApplicationsForApprovalListContainerComponent } from "./components/applications-for-approval-list-container/applications-for-approval-list-container.component";
import { AssignedApplicationsListContainerComponent } from "./components/assigned-applications-list-container/assigned-applications-list-container.component";
import { NotamEditComponent } from "./components/notam-edit/notam-edit.component";
import { NotamListContainerComponent } from "./components/notam-list/container/notam-list-container.component";
import { RejectedApplicationsListContainerComponent } from "./components/rejected-applications-list-container/rejected-applications-list-container.component";
import { RestrictionPreviewComponent } from "./components/restriction-preview/restriction-preview.component";
import { RestrictionsListContainerComponent } from "./components/restrictions-list-container/restrictions-list-container.component";
import { SubmittedListsContainerComponent } from "./components/submitted-lists-container/submitted-lists-container.component";
import { UnassignedApplicationsListContainerComponent } from "./components/unassigned-applications-list-container/unassigned-applications-list-container.component";
import { NotamListRoute, RestrictionListRoute } from "./models/flight-zone-management.models";
import {
    ApplicationAssigneesResolver,
    AssignmentCapabilitiesResolver,
    FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT,
    FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME,
    FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT,
    FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT,
    FlightZoneManagementApplicationDataResolver,
    FlightZoneManagementRestrictionDataResolver,
    ListFiltersCapabilitiesResolver,
    NotamCapabilitiesResolver,
    NotamResolver,
} from "./services/flight-zone-management.resolvers";
import { FlightZoneListsActions } from "./state/flight-zone-lists.actions";
import { FlightZoneListsState } from "./state/flight-zone-lists.state";

const submittedListRoute: Route = {
    component: SubmittedListsContainerComponent,
    children: [
        {
            path: "unassigned",
            component: UnassignedApplicationsListContainerComponent,
        },
        {
            path: "assigned-to-me",
            component: ApplicationsAssignedToUserListContainerComponent,
            data: {
                statusFilterOptions: [
                    ApplicationManagementListItemStatus.Analysis,
                    ApplicationManagementListItemStatus.Correction,
                    ApplicationManagementListItemStatus.ForApproval,
                ],
            },
        },
        {
            path: "assigned",
            component: AssignedApplicationsListContainerComponent,
            data: {
                statusFilterOptions: [ApplicationManagementListItemStatus.Analysis, ApplicationManagementListItemStatus.Correction],
                isAnspEmployeesFilterEnabled: true,
            },
        },
        {
            path: "for-approval",
            component: ApplicationsForApprovalListContainerComponent,
            data: {
                isAnspEmployeesFilterEnabled: true,
            },
        },
        {
            path: "",
            redirectTo: "unassigned",
            pathMatch: "full",
        },
    ],
    resolve: {
        initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        initAssignmentCapabilities: AssignmentCapabilitiesResolver,
        initAssigneesList: ApplicationAssigneesResolver,
    },
};

const notamListRoutes: NotamListRoute[] = [
    {
        path: "flight-zone/applications-with-notam",
        component: NotamListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.applicationsWithNotam",
            roles: [DssUserRoles.ManageNotam],
            displayedColumns: ["expandHandle", "notamNumber", "designator", "startAt", "endAt", "title", "actions"],
            selector: FlightZoneListsState.applicationsWithNotam,
            action: FlightZoneListsActions.GetApplicationsWithNotam,
        },
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
    },
    {
        path: "flight-zone/applications-waiting-for-notam",
        component: NotamListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.applicationsWaitingForNotam",
            roles: [DssUserRoles.ManageNotam],
            displayedColumns: ["expandHandle", "designator", "startAt", "endAt", "title", "actions"],
            selector: FlightZoneListsState.applicationsWaitingForNotam,
            action: FlightZoneListsActions.GetApplicationsWaitingForNotam,
        },
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
    },
];

const restrictionListRoutes: RestrictionListRoute[] = [
    {
        path: "flight-zone/published-restrictions-list",
        component: RestrictionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.publishedRestrictionsList",
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
            selector: FlightZoneListsState.publishedRestrictionsList,
            action: FlightZoneListsActions.GetPublishedRestrictionsList,
        },
    },
    {
        path: "flight-zone/ended-restrictions-list",
        component: RestrictionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.endedRestrictionsList",
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
            selector: FlightZoneListsState.endedRestrictionsList,
            action: FlightZoneListsActions.GetEndedRestrictionsList,
        },
    },
];

const routes: Routes = [
    {
        ...submittedListRoute,
        path: "flight-zone/submitted-list",
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.submittedApplicationsList",
            applicationType: ApplicationType.RestrictionApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        ...submittedListRoute,
        path: "modifications/submitted-list",
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.submittedModificationsList",
            applicationType: ApplicationType.RestrictionModificationApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        path: "flight-zone/rejected-applications-list",
        component: RejectedApplicationsListContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.rejectedApplicationsList",
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        path: "flight-zone/accepted-applications-list",
        component: AcceptedApplicationsListContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.acceptedApplicationsList",
            applicationType: ApplicationType.RestrictionApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        path: "modifications/accepted-modifications-list",
        component: AcceptedApplicationsListContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initFiltersCapabilities: ListFiltersCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.acceptedModificationsList",
            applicationType: ApplicationType.RestrictionModificationApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    ...restrictionListRoutes,
    ...notamListRoutes,
    {
        path: `${FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT}/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationManagementPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            initAssignmentCapabilities: AssignmentCapabilitiesResolver,
            initData: FlightZoneManagementApplicationDataResolver,
            initAssigneesList: ApplicationAssigneesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.modificationApplication",
            enableActions: true,
            applicationType: ApplicationType.RestrictionModificationApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        path: `${FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT}/${FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT}/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationManagementPreviewComponent,
        canActivate: [DtmAuthGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            initData: FlightZoneManagementApplicationDataResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.modificationApplication",
            enableActions: false,
            applicationType: ApplicationType.RestrictionModificationApplication,
        },
    },
    {
        path: `${FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT}/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationManagementPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            initAssignmentCapabilities: AssignmentCapabilitiesResolver,
            initData: FlightZoneManagementApplicationDataResolver,
            initAssigneesList: ApplicationAssigneesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.newFlightZone",
            enableActions: true,
            applicationType: ApplicationType.RestrictionApplication,
            roles: [DssUserRoles.AnspEmployee, DssUserRoles.AnspSupervisor],
        },
    },
    {
        path: `${FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT}/${FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT}/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationManagementPreviewComponent,
        canActivate: [DtmAuthGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            initData: FlightZoneManagementApplicationDataResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.newFlightZone",
            enableActions: false,
            applicationType: ApplicationType.RestrictionApplication,
        },
    },
    {
        path: `${FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT}/${FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT}/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}/notam-edit`,
        component: NotamEditComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: NotamResolver,
            initCapabilities: NotamCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.notamPreview",
            roles: [DssUserRoles.ManageNotam],
        },
    },
    {
        path: `restriction/:${FLIGHT_ZONE_MANAGEMENT_ID_ROUTE_PARAM_NAME}`,
        component: RestrictionPreviewComponent,
        canActivate: [DtmAuthGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            initData: FlightZoneManagementRestrictionDataResolver,
        },
        data: {
            titleTranslationKey: "dssAdminLibFlightZoneManagement.routeTitles.restriction",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        ApplicationAssigneesResolver,
        FlightZoneManagementApplicationDataResolver,
        NotamResolver,
        NotamCapabilitiesResolver,
        AssignmentCapabilitiesResolver,
        ListFiltersCapabilitiesResolver,
        FlightZoneManagementRestrictionDataResolver,
        FlightZoneCapabilitiesResolver,
    ],
})
export class FlightZoneManagementRoutingModule {}
