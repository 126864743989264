import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RestrictionPdfDownloadConfirmationComponent } from "../restriction-pdf-download-confirmation/restriction-pdf-download-confirmation.component";

interface RestrictionPreviewHeaderComponentState {
    headerTitle: string;
    isProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-restriction-preview-header[headerTitle]",
    templateUrl: "./restriction-preview-header.component.html",
    styleUrls: ["./restriction-preview-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RestrictionPreviewHeaderComponent {
    protected readonly headerTitle$ = this.localStore.selectByKey("headerTitle");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    @Input() public set headerTitle(value: string) {
        this.localStore.patchState({ headerTitle: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() protected readonly pdfDownload = new EventEmitter<void>();
    @Output() protected readonly confirmationOfApplicationSubmissionPdfDownload = new EventEmitter<void>();

    constructor(
        private readonly localStore: LocalComponentStore<RestrictionPreviewHeaderComponentState>,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            headerTitle: "",
            isProcessing: false,
        });
    }

    protected downloadPdf(): void {
        this.dialogService
            .open(RestrictionPdfDownloadConfirmationComponent)
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.pdfDownload.emit();
            });
    }
}
