import { ApplicationType, NotamData, NotamNumber } from "@dtm-frontend/dss-shared-lib";

export namespace FlightZoneManagementActions {
    export class GetAvailableApplicationAssignees {
        public static readonly type = "[Flight Zone Management] Get available application assignees";
    }

    export class GetAssignmentCapabilities {
        public static readonly type = "[Flight Zone Management] Get assignment capabilities";
    }

    export class AddApplicationAssignee {
        public static readonly type = "[Flight Zone Management] Add application assignee";

        constructor(public assigneeId: string, public flightZoneId: string, public applicationType: ApplicationType) {}
    }

    export class ChangeApplicationAssignee {
        public static readonly type = "[Flight Zone Management] Change application assignee";

        constructor(public assigneeId: string, public flightZoneId: string) {}
    }

    export class GetNotamData {
        public static readonly type = "[Flight Zone Management] Get notam data";

        constructor(public flightZoneId: string) {}
    }

    export class GetNotamCapabilities {
        public static readonly type = "[Flight Zone Management] Get Notam capabilities";
    }

    export class UpdateNotamData {
        public static readonly type = "[Flight Zone Management] Update NotamData value";

        constructor(public notamData: NotamData) {}
    }

    export class AssignNotamNumber {
        public static readonly type = "[Flight Zone Management] Assign Notam number";

        constructor(public notamId: string, public notamNumber: NotamNumber) {}
    }
}
