import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { AnspStandardTeamWorkCalendar } from "../../models/administration.models";
import { ADMINISTRATION_TEAM_ID_ROUTE_PARAM_NAME } from "../../services/administration-work-calendar.resolver";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";

interface TeamWorkCalendarContainerComponentState {
    teamId: string | null;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-team-work-calendar-container",
    templateUrl: "./team-work-calendar-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TeamWorkCalendarContainerComponent {
    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly anspStandardTeamWorkCalendar$ = this.store.select(AdministrationState.anspStandardTeamWorkCalendar);
    protected readonly daysOff$ = this.store.select(AdministrationState.daysOff);
    protected readonly getAnspTeamWorkCalendarError$ = this.store.select(AdministrationState.getAnspTeamWorkCalendarError);

    constructor(
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly localStore: LocalComponentStore<TeamWorkCalendarContainerComponentState>,
        private readonly route: ActivatedRoute
    ) {
        this.localStore.setState({
            teamId: this.route.snapshot.paramMap.get(ADMINISTRATION_TEAM_ID_ROUTE_PARAM_NAME),
        });
    }

    protected refreshData(): void {
        const teamId = this.localStore.selectSnapshotByKey("teamId");

        if (teamId) {
            this.store.dispatch(new AdministrationActions.GetAnspTeamWorkCalendar(teamId));
        }
    }

    protected saveStandardWorkCalendar(calendar: AnspStandardTeamWorkCalendar): void {
        this.store
            .dispatch(new AdministrationActions.UpdateStandardTeamWorkCalendar(calendar))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.updateStandardTeamWorkCalendarError);

                if (error) {
                    this.toastrService.error(
                        this.transloco.translate("dssAdminLibAdministration.anspTeamWorkCalendar.saveStandardWorkCalendarErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.transloco.translate("dssAdminLibAdministration.anspTeamWorkCalendar.saveStandardWorkCalendarSuccessMessage")
                );
            });
    }

    protected addDaysOff(daysOff: Date[]): void {
        this.store
            .dispatch(new AdministrationActions.AddDaysOffToTeamCalendar(daysOff))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.addTeamDaysOffError);

                if (error) {
                    this.toastrService.error(this.transloco.translate("dssAdminLibAdministration.teamDaysOff.addTeamDayOffErrorMessage"));

                    return;
                }

                this.toastrService.success(this.transloco.translate("dssAdminLibAdministration.teamDaysOff.addTeamDayOffSuccessMessage"));
            });
    }

    protected removeDayOff(dayOffId: string): void {
        this.store
            .dispatch(new AdministrationActions.RemoveDayOffFromTeamCalendar(dayOffId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.removeTeamDayOffError);

                if (error) {
                    this.toastrService.error(
                        this.transloco.translate("dssAdminLibAdministration.teamDaysOff.removeTeamDayOffErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.transloco.translate("dssAdminLibAdministration.teamDaysOff.removeTeamDayOffSuccessMessage")
                );
            });
    }
}
