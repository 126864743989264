import { Notification } from "@dtm-frontend/shared/notifications";

export type DssNotification = Notification<string, DssNotificationPayload>;

export enum DssRestrictionApplicationNotificationType {
    AutomaticallyPublishedEvent = "AutomaticallyPublishedEvent",
    AnspAcceptedRestrictionApplicationEvent = "AnspAcceptedRestrictionApplicationEvent",
    AnspPublishedRestrictionApplicationEvent = "AnspPublishedRestrictionApplicationEvent",
    RejectedRestrictionApplicationEvent = "RejectedRestrictionApplicationEvent",
    AppliedForInstitutionAcceptEvent = "AppliedForInstitutionAcceptEvent",
    InstitutionReAcceptedEvent = "InstitutionReAcceptedEvent",
    UnlockedRestrictionApplicationEvent = "UnlockedRestrictionApplicationEvent",
    LockedRestrictionApplicationEvent = "LockedRestrictionApplicationEvent",
}

export enum DssRestrictionModificationNotificationType {
    RejectedRestrictionModificationApplicationEvent = "RejectedRestrictionModificationApplicationEvent",
    InstitutionReAcceptedEvent = "InstitutionReAcceptedEvent",
    AnspAcceptedEndingApplicationEvent = "AnspAcceptedEndingApplicationEvent",
    AnspAcceptedModifingApplicationEvent = "AnspAcceptedModifingApplicationEvent",
    AnspPublishedRestrictionModificationApplicationEvent = "AnspPublishedRestrictionModificationApplicationEvent",
    LockedRestrictionModificationApplicationEvent = "LockedRestrictionModificationApplicationEvent",
    UnlockedRestrictionModificationApplicationEvent = "UnlockedRestrictionModificationApplicationEvent",
}

export enum DssNotamNotificationType {
    PublishedNotamEvent = "PublishedNotamEvent",
    PublishedNotamReplacementEvent = "PublishedNotamReplacementEvent",
}

export enum DssTeamNotificationType {
    AssignedTeamForRestrictionApplicationEvent = "AssignedTeamForRestrictionApplicationEvent",
    AssignedTeamForRestrictionModificationEvent = "AssignedTeamForRestrictionModificationEvent",
    AssignedAnotherTeamForRestrictionApplicationEvent = "AssignedAnotherTeamForRestrictionApplicationEvent",
    AssignedAnotherTeamForRestrictionModificationApplicationEvent = "AssignedAnotherTeamForRestrictionModificationApplicationEvent",
}

export enum DssCommentNotificationType {
    AddedCommentForRestrictionApplicationEvent = "AddedCommentForRestrictionApplicationEvent",
    AddedCommentForRestrictionModificationApplicationEvent = "AddedCommentForRestrictionModificationApplicationEvent",
}

export enum DssAssignmentNotificationType {
    AssignedReviewerForRestrictionApplicationEvent = "AssignedReviewerForRestrictionApplicationEvent",
    AssignedReviewerForModificationApplicationEvent = "AssignedReviewerForModificationApplicationEvent",
    ReviewedRestrictionApplicationEvent = "ReviewedRestrictionApplicationEvent",
    ReviewedRestrictionModificationApplicationEvent = "ReviewedRestrictionModificationApplicationEvent",
    ChangedReviewerForRestrictionApplicationEvent = "ChangedReviewerForRestrictionApplicationEvent",
    ChangedReviewerForRestrictionModificationApplicationEvent = "ChangedReviewerForRestrictionModificationApplicationEvent",
    RejectedReviewerOpinionForRestrictionApplicationEvent = "RejectedReviewerOpinionForRestrictionApplicationEvent",
    RejectedReviewerOpinionForRestrictionModificationApplicationEvent = "RejectedReviewerOpinionForRestrictionModificationApplicationEvent",
}

export enum DssConsultationNotificationType {
    AssignedConsultantForRestrictionApplicationEvent = "AssignedConsultantForRestrictionApplicationEvent",
    AssignedConsultantForRestrictionModificationApplicationEvent = "AssignedConsultantForRestrictionModificationApplicationEvent",
    ConsultedRestrictionApplicationEvent = "ConsultedRestrictionApplicationEvent",
    ConsultedRestrictionModificationApplicationEvent = "ConsultedRestrictionModificationApplicationEvent",
}

type DssNotificationType =
    | DssRestrictionApplicationNotificationType
    | DssRestrictionModificationNotificationType
    | DssNotamNotificationType
    | DssTeamNotificationType
    | DssCommentNotificationType
    | DssAssignmentNotificationType
    | DssConsultationNotificationType;

interface DssNotificationPayload {
    id: string;
    type: DssNotificationType;
}
