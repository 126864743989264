<div class="dialog-header">
    <h2 mat-dialog-title>{{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.dialogHeader" | transloco }}</h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-info-message type="warning">{{
        "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.dialogWarnMessage" | transloco
    }}</dtm-ui-info-message>

    <dtm-ui-input-field>
        <label for="designatorControl">{{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.designatorLabel" | transloco }}</label>
        <div class="field-prefix">{{ data.forbiddenDesignatorRange?.prefix }}</div>
        <input matInput id="designatorControl" autocomplete="off" type="number" [formControl]="designatorControl" />
        <div class="field-error" *dtmUiFieldHasError="designatorControl; name: 'required'">
            {{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.requiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="designatorControl; name: 'max'; error as error">
            {{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.maxValueError" | transloco : { max: error.max } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="designatorControl; name: 'pattern'; error as error">
            {{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.patternError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="designatorControl; name: 'invalidValue'; error as error">
            {{
                "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.invalidValueError"
                    | transloco : { from: error.from, to: error.to }
            }}
        </div>
    </dtm-ui-input-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="confirm()">
        {{ "dssAdminLibFlightZoneManagement.setCustomDesignatorDialog.confirmButtonLabel" | transloco }}
    </button>
</div>
