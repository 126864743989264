import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AirspaceClassificationComponentState {
    airspaceClassification: string | undefined;
    classifications: string[];
    isFormExpanded: boolean;
    isProcessing: boolean;
    isReadOnly: boolean;
}

@Component({
    selector: "dss-admin-lib-airspace-classification[airspaceClassification]",
    templateUrl: "./airspace-classification.component.html",
    styleUrls: ["./airspace-classification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AirspaceClassificationComponent {
    protected readonly airspaceClassificationForm = new FormGroup({
        airspaceClassification: new FormControl<string>("", { nonNullable: true }),
    });
    protected readonly isFormExpanded$ = this.localStore.selectByKey("isFormExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly airspaceClassification$ = this.localStore.selectByKey("airspaceClassification");
    protected readonly classifications$ = this.localStore.selectByKey("classifications");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Input() public set airspaceClassification(value: string | undefined) {
        this.localStore.patchState({ airspaceClassification: value });
        this.airspaceClassificationForm.controls.airspaceClassification.patchValue(value ?? "");
    }

    @Input() public set classifications(value: string[] | undefined) {
        this.localStore.patchState({ classifications: value ?? [] });
    }

    @Output() public readonly availableClassificationsFetch = new EventEmitter<void>();
    @Output() public readonly airspaceClassificationChange = new EventEmitter<string>();

    constructor(private readonly localStore: LocalComponentStore<AirspaceClassificationComponentState>) {
        this.localStore.setState({
            airspaceClassification: undefined,
            classifications: [],
            isFormExpanded: false,
            isProcessing: false,
            isReadOnly: false,
        });
    }

    public changeExpansionState(value: boolean): void {
        this.localStore.patchState({ isFormExpanded: value });

        if (value) {
            this.availableClassificationsFetch.emit();
        }
    }

    protected cancelChanges(): void {
        const lastSavedAirspaceClassification = this.localStore.selectSnapshotByKey("airspaceClassification");

        this.changeExpansionState(false);
        this.airspaceClassificationForm.reset({ airspaceClassification: lastSavedAirspaceClassification });
    }

    protected submitForm(): void {
        if (!this.airspaceClassificationForm.valid) {
            return;
        }

        this.airspaceClassificationChange.emit(this.airspaceClassificationForm.controls.airspaceClassification.value);
    }
}
