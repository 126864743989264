import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { AdministrationError, AdministrationErrorType, NewInstitutionDetails } from "../../models/administration.models";
import { ADMINISTRATION_INSTITUTION_ID_ROUTE_PARAM_NAME } from "../../services/administration-institution-details.resolver";
import { AdministrationActions } from "../../state/administration.actions";
import { AdministrationState } from "../../state/administration.state";

interface InstitutionDetailContainerComponentState {
    institutionId: string | null;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-institution-detail-container",
    templateUrl: "./institution-detail-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstitutionDetailContainerComponent {
    protected readonly isProcessing$ = this.store.select(AdministrationState.isProcessing);
    protected readonly getInstitutionDetailsError$ = this.store.select(AdministrationState.getInstitutionDetailsError);
    protected readonly institutionDetails$ = this.store.select(AdministrationState.institutionDetails);

    constructor(
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<InstitutionDetailContainerComponentState>,
        private readonly route: ActivatedRoute
    ) {
        this.localStore.setState({
            institutionId: this.route.snapshot.paramMap.get(ADMINISTRATION_INSTITUTION_ID_ROUTE_PARAM_NAME),
        });
    }

    protected refreshData(): void {
        const institutionId = this.localStore.selectSnapshotByKey("institutionId");

        if (institutionId) {
            this.store.dispatch(new AdministrationActions.GetInstitutionDetails(institutionId));
        }
    }

    protected cancel(): void {
        this.router.navigateByUrl("/admin/institutions");
    }

    protected saveInstitution(value: NewInstitutionDetails): void {
        const saveAction = this.localStore.selectSnapshotByKey("institutionId")
            ? new AdministrationActions.UpdateInstitutionDetails(value)
            : new AdministrationActions.SaveNewInstitution(value);

        this.store
            .dispatch(saveAction)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdministrationState.institutionDetailSaveError);

                if (error) {
                    this.displayErrorMessage(error, "dssAdminLibAdministration.institutionDetail.saveInstitutionErrorMessage");

                    return;
                }

                this.toastrService.success(
                    this.transloco.translate("dssAdminLibAdministration.institutionDetail.saveInstitutionSuccessMessage")
                );
                this.router.navigateByUrl("/admin/institutions");
            });
    }

    private displayErrorMessage(error: AdministrationError, defaultErrorMessage: string): void {
        switch (error.type) {
            case AdministrationErrorType.InvalidApplicationVersion:
                this.toastrService.error(this.transloco.translate("dssAdminLibAdministration.toastErrors.invalidVersionErrorMessage"));
                break;

            default:
                this.toastrService.error(this.transloco.translate(defaultErrorMessage));
        }
    }
}
