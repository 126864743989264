import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InstitutionMember, InstitutionsListItem, RemoveInstitutionMemberData } from "../../models/administration.models";

interface InstitutionsListComponentState {
    institutions: InstitutionsListItem[];
    isProcessing: boolean;
    page: Page | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-institutions-list[institutions]",
    templateUrl: "./institutions-list.component.html",
    styleUrls: ["./institutions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstitutionsListComponent {
    protected readonly institutions$ = this.localStore.selectByKey("institutions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly page$ = this.localStore.selectByKey("page");

    @Input()
    public set institutions(value: InstitutionsListItem[] | undefined) {
        this.localStore.patchState({ institutions: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ page: value });
    }

    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly loadMoreDetails = new EventEmitter<string>();
    @Output() public readonly memberAdd = new EventEmitter<InstitutionsListItem>();
    @Output() public readonly memberRemove = new EventEmitter<RemoveInstitutionMemberData>();
    @Output() public readonly institutionRemove = new EventEmitter<InstitutionsListItem>();

    constructor(
        private readonly localStore: LocalComponentStore<InstitutionsListComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            institutions: [],
            isProcessing: false,
            page: undefined,
        });
    }

    protected tryRemoveMember(member: InstitutionMember, institution: InstitutionsListItem): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dssAdminLibAdministration.institutionsList.removeMemberDialogTitle", {
                        userName: `${member.firstName} ${member.lastName}`,
                    }),
                    confirmationText: this.translocoService.translate(
                        "dssAdminLibAdministration.institutionsList.removeMemberDialogConfirmationText"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "dssAdminLibAdministration.institutionsList.confirmMemberRemovalLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.memberRemove.emit({ member, institution }));
    }
}
