import { Params } from "@angular/router";
import { ApplicationType, FlightZoneApplicationDraftListItem, RestrictionsListItem } from "@dtm-frontend/dss-shared-lib";

export namespace FlightZoneListsActions {
    export class GetListFiltersCapabilities {
        public static readonly type = "[Flight Zone Lists] Get applications list filters capabilities";
    }

    export class GetUnassignedApplicationsList {
        public static readonly type = "[Flight Zone Lists] Get unassigned applications list";

        constructor(public filterParams: Params, public applicationType: ApplicationType) {}
    }

    export class GetAssignedApplicationsList {
        public static readonly type = "[Flight Zone Lists] Get assigned applications list";

        constructor(public filterParams: Params, public applicationType: ApplicationType) {}
    }

    export class GetAcceptedApplicationsList {
        public static readonly type = "[Flight Zone Lists] Get accepted applications list";

        constructor(public filterParams: Params, public applicationType: ApplicationType) {}
    }

    export class GetRejectedApplicationsList {
        public static readonly type = "[Flight Zone Lists] Get rejected applications list";

        constructor(public filterParams: Params) {}
    }

    export class GetApplicationsForApprovalList {
        public static readonly type = "[Flight Zone Lists] Get applications for approval list";

        constructor(public filterParams: Params, public applicationType: ApplicationType) {}
    }

    export class GetApplicationsAssignedToUserList {
        public static readonly type = "[Flight Zone Lists] Get applications list assigned to current user";

        constructor(public filterParams: Params, public applicationType: ApplicationType) {}
    }

    export class GetPublishedRestrictionsList {
        public static readonly type = "[Flight Zone Lists] Get published restrictions list";

        constructor(public filterParams: Params) {}
    }

    export class GetEndedRestrictionsList {
        public static readonly type = "[Flight Zone Lists] Get ended restrictions list";

        constructor(public filterParams: Params) {}
    }

    export class GetApplicationsWaitingForNotam {
        public static readonly type = "[Flight Zone Lists] Get list of applications waiting for NOTAM";

        constructor(public filterParams: Params) {}
    }

    export class GetApplicationsWithNotam {
        public static readonly type = "[Flight Zone Lists] Get list of applications with NOTAM";

        constructor(public filterParams: Params) {}
    }

    export class GetInstitutionsFilterOptions {
        public static readonly type = "[Flight Zone Lists] Get list of institutions for filter options";

        constructor(public search?: string) {}
    }

    export class ClearFilterLists {
        public static readonly type = "[Flight Zone Lists] Clears filters lists options";
    }

    export class GetApplicationGeoJson {
        public static readonly type = "[Flight Zone Lists] Get application GeoJSON data";

        constructor(public flightZone: FlightZoneApplicationDraftListItem, public applicationType: ApplicationType) {}
    }

    export class GetRestrictionGeoJson {
        public static readonly type = "[Flight Zone Lists] Get flight zone restriction GeoJSON data";

        constructor(public restriction: RestrictionsListItem) {}
    }
}
