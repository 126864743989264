import { NgModule } from "@angular/core";
import { SharedUserProfileModule } from "@dtm-frontend/dss-shared-lib";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";

@NgModule({
    imports: [SharedI18nModule, SharedUserProfileModule.forRoot()],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssAdminLibUserProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class UserProfileModule {}
