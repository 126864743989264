<h2 class="header-title">
    {{ headerTitle$ | ngrxPush }}
</h2>

<ng-container [ngSwitch]="isProcessing$ | ngrxPush">
    <button *ngSwitchCase="false" type="button" class="button-icon" [matMenuTriggerFor]="headerActionsMenu">
        <dtm-ui-icon name="more"></dtm-ui-icon>
    </button>

    <dtm-ui-icon *ngSwitchCase="true" class="loader-icon button-icon" name="loader"></dtm-ui-icon>
</ng-container>

<mat-menu #headerActionsMenu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item type="button" (click)="downloadPdf()">
            <dtm-ui-icon name="file"></dtm-ui-icon>
            <span>{{ "dssSharedLibFlightZone.restrictionPreviewHeader.downloadPdfButtonLabel" | transloco }}</span>
        </button>

        <button
            mat-menu-item
            type="button"
            *ngIf="confirmationOfApplicationSubmissionPdfDownload.observed"
            (click)="confirmationOfApplicationSubmissionPdfDownload.emit()"
        >
            <dtm-ui-icon name="download"></dtm-ui-icon>
            <span>{{
                "dssSharedLibFlightZone.restrictionPreviewHeader.downloadConfirmationOfApplicationSubmissionPdfButtonLabel" | transloco
            }}</span>
        </button>
    </ng-template>
</mat-menu>
