import { HttpParams, HttpStatusCode } from "@angular/common/http";
import { Params } from "@angular/router";
import { SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { PageResponseBody, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils, ObjectUtils } from "@dtm-frontend/shared/utils";
import { Feature, Geometry, Position } from "@turf/helpers/dist/js/lib/geojson";
import {
    AltitudesConvertedToAMSLFeet,
    AnspTeam,
    ApplicationAssignee,
    ApplicationAssignment,
    ApplicationType,
    ConsultationData,
    ErrorMessageCode,
    FlightZoneApplication,
    FlightZoneApplicationBasicData,
    FlightZoneApplicationChatMessage,
    FlightZoneApplicationListItem,
    FlightZoneApplicationPurpose,
    FlightZoneApplicationReviewStatus,
    FlightZoneApplicationStatus,
    FlightZoneApplicationsForConsultationList,
    FlightZoneApplicationsList,
    FlightZoneCapabilities,
    FlightZoneEditorType,
    FlightZoneExclusionsConstraints,
    FlightZoneRestriction,
    FlightZoneSelectOption,
    FlightZonesManagementList,
    FlightZonesManagementListItem,
    HeightReferences,
    HorizontalMeasureUnits,
    Institution,
    InstitutionContact,
    MaxZoneDuration,
    NotamCapabilities,
    NotamData,
    NotamList,
    NotamLocation,
    NotamNumber,
    NotamType,
    RestrictionArea,
    RestrictionExclusions,
    RestrictionType,
    RestrictionsList,
    VerticalMeasureUnits,
} from "../../models/flight-zone-shared.models";
import { isCylinderRestrictionArea } from "../../models/flight-zone-shared.typeguards";

export enum GeoZonesSystemErrorType {
    AlreadyExists = "geozone.alreadyExists.createFailed",
    GenericBadRequest = "geozone.genericBadRequest.createFailed",
    ServerErrorCreateFailed = "geozone.serverError.createFailed",
    ServerErrorUpdateFailed = "geozone.serverError.updateFailed",
    ServerErrorDeleteFailed = "geozone.serverError.deleteFailed",
}

export enum ZoneBoundaryValidationErrorType {
    LowerBoundaryInvalid = "altitude.lower.lowerThanMinElevation",
    UpperBoundaryInvalid = "altitude.upper.lowerThanMinElevation",
}

export interface FlightZoneCapabilitiesResponseBody {
    initialViewBox: InitialViewBoxResponseBody[];
    purposes: PurposeCapabilityResponseBody[];
    operatorPermissions: string[];
    limitations: LimitationsResponseBody;
    availableEditors: FlightZoneEditorType[];
    elearningCourses: FlightZoneSelectOption<string>[];
    applicant: InstitutionContact;
    maxTimeProperties: MaxZoneDuration[];
}

export interface PurposeCapabilityResponseBody {
    id: string;
    purpose: FlightZoneApplicationPurpose;
    stateSecurityRestriction: boolean;
}

export interface FiltersCapabilitiesResponseBody {
    institutions: Institution[];
    anspEmployees: ApplicationAssignee[];
}

export type InstitutionsFilterOptionsResponseBody = PageResponseBody<Institution>;

interface InitialViewBoxResponseBody {
    x: number;
    y: number;
    z: string;
    valid: boolean;
    m: string;
}

interface LimitationsResponseBody {
    radius: MapLimitationResponseBody;
    lowerHeight: MapLimitationResponseBody;
    upperHeight: MapLimitationResponseBody;
    exclusions: FlightZoneExclusionsConstraints;
}

interface MapLimitationResponseBody {
    minValue: number;
    maxValue: number;
    baseValue: number;
}

interface FlightZoneApplicationRequestPayload {
    generalInfo: FlightZoneApplicationBasicDataRequestPayload;
    restrictionArea: RestrictionAreaRequestPayload;
    exclusions: ApiExclusionsRequestPayload;
}

interface FlightZoneApplicationBasicDataRequestPayload {
    startAt: string;
    endAt: string;
    preTacticalApproval: boolean;
    tacticalApproval: boolean;
    restrictionPurpose: FlightZoneApplicationPurpose;
    caseNumber: string;
    title: string;
    stateSecurityRestriction?: boolean;
    description?: string;
}

interface FlightZoneApplicationBasicDataResponseBody extends FlightZoneApplicationBasicDataRequestPayload {
    restrictionType: RestrictionType;
    status: FlightZoneApplicationStatus;
    locked: boolean;
    author: {
        person: ApplicationAssignee;
        supervisor: boolean;
    };
    note: string;
    anspCaseNumber: string;
    detailedDuration: string;
    additionalReceivers: {
        id: string;
        email: string;
    }[];
    airspaceClassification: string;
    customDesignator: number;
}

interface RestrictionAreaRequestPayload {
    upperAltitude: ZoneAltitudeRequestPayload;
    lowerAltitude: ZoneAltitudeRequestPayload;
    area: GeoJsonCylinderAreaRequestPayload | GeoJsonPrismAreaRequestPayload;
}

interface GeoJsonCylinderAreaRequestPayload extends Feature<Geometry> {
    radius: {
        value: number;
        unit: HorizontalMeasureUnits;
    };
    center: Geometry;
}

type GeoJsonPrismAreaRequestPayload = Feature<Geometry>;

interface ZoneAltitudeRequestPayload {
    value: number;
    unit: VerticalMeasureUnits;
    referenceLevel: HeightReferences;
    altitudeFt?: number;
}

interface ExclusionsBaseRequestPayload {
    agreedExclusion: {
        phoneNumber: PhoneNumber;
        email: string;
    } | null;
    mtomExclusion: {
        from: number;
        to: number;
    } | null;
    managedByGovernment: boolean;
    ownedFlights: boolean;
    courseCodeNames: string[];
    otherExclusion: string;
}

interface UavExclusionsRequestPayload extends ExclusionsBaseRequestPayload {
    operatorAuthorizations: string[];
}

interface GeneralAviationExclusionsRequestPayload extends ExclusionsBaseRequestPayload {
    withoutEngine: boolean;
    withInstrumentalProcedures: boolean;
    specialStatusFlights: boolean;
}

interface SecuringOwnFlightsExclusionsRequestPayload extends ExclusionsBaseRequestPayload {
    withoutEngine: boolean;
    withInstrumentalProcedures: boolean;
}

type ApiExclusionsRequestPayload =
    | UavExclusionsRequestPayload
    | GeneralAviationExclusionsRequestPayload
    | SecuringOwnFlightsExclusionsRequestPayload;

export type ApplicationsListResponseBody = PageResponseBody<ListContentResponseBody>;

interface ListContentResponseBody {
    id: string;
    title: string;
    startAt: Date;
    endAt: Date;
    preTacticalApproval: boolean;
    tacticalApproval: boolean;
    stateSecurityRestriction: boolean;
    locked: boolean;
    restrictionPurpose: FlightZoneApplicationPurpose;
    institution: {
        id: string;
        name: string;
    };
    author: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: PhoneNumber;
    };
    status: FlightZoneApplicationStatus;
    sentToAnspAt: Date;
    canceling: boolean;
    caseNumber?: string;
    institutionAccepter?: ApplicationAssignee;
    reviewer?: ApplicationAssignee;
    rejecter?: ApplicationAssignee;
    anspAccepter?: ApplicationAssignee;
    designator?: string;
    notamNumber?: string;
    rejectionReason?: string;
    anspCaseNumber?: string;
}

export type ApplicationsForConsultationListResponseBody = PageResponseBody<{
    id: string;
    applicationId: string;
    applicationType: ApplicationType;
    title: string;
    startAt: string;
    endAt: string;
    sentToAnspAt: string;
    restrictionPurpose: FlightZoneApplicationPurpose;
    institution: {
        id: string;
        name: string;
    };
    author: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: PhoneNumber;
    };
    reviewer: ApplicationAssignee;
    caseNumber?: string;
}>;

export type RestrictionsListResponseBody = PageResponseBody<RestrictionResponseBody>;

interface NotamListApplicationResponse {
    id: string;
    title: string;
    type: ApplicationType;
    startAt: string;
    endAt: string;
}

export type NotamListResponseBody = PageResponseBody<{
    id: string;
    application: NotamListApplicationResponse;
    reviewer: ApplicationAssignee;
    designator: string;
    notamType: NotamType;
    plNotamNumber: string;
    enNotamNumber: string;
}>;

interface RestrictionResponseBody {
    id: string;
    title: string;
    restrictionPurpose: FlightZoneApplicationPurpose;
    startAt: string;
    endAt: string;
    designator: string;
    stateSecurityRestriction: boolean;
    author: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: PhoneNumber;
    };
    institution: {
        id: string;
        name: string;
    };
    modifying: boolean;
    canceling: boolean;
    modifyingStartAt?: string;
    modifyingEndAt?: string;
    applicationId: string;
    lastApplicationId: string;
    caseNumber?: string;
    notamNumber?: string;
    anspCaseNumber?: string;
}

export interface RestrictionApplicationResponseBody {
    generalInfo: FlightZoneApplicationBasicDataResponseBody;
    restrictionArea: RestrictionAreaRequestPayload;
    exclusions: ApiExclusionsRequestPayload;
    id: string;
    version: number;
    acceptance?: {
        institution: {
            person: ApplicationAssignee;
            acceptDate: Date;
        } | null;
        ansp: {
            person: ApplicationAssignee;
            acceptDate: Date;
        } | null;
        rejection: {
            person: ApplicationAssignee;
            rejectDate: Date;
            reason: string;
        } | null;
    };
    designator?: string;
    notamNumber?: string;
    assignment?: ApplicationAssignment;
    location?: NotamLocation;
}

export interface RestrictionConsultationResponseBody {
    id: string;
    applicationId: string;
    createdDate: string;
    consultant: ApplicationAssignee;
    applicationType: ApplicationType;
    reviewStatus?: FlightZoneApplicationReviewStatus;
    comment?: string;
}

interface FlightZoneRestrictionDesignatorResponseBody {
    applicationId: string;
    designator: string;
    end: string;
    id: string;
    reserved: string;
    start: string;
}

interface FlightZoneRestrictionGeneralInfoResponseBody {
    author: ApplicationAssignee;
    automatic: boolean;
    canceling: boolean;
    canceled: boolean;
    caseNumber: string;
    modifying: boolean;
    modified: boolean;
    note: string;
    preTacticalApproval: boolean;
    restrictionPurpose: FlightZoneApplicationPurpose;
    restrictionType: RestrictionType;
    stateSecurityRestriction: boolean;
    tacticalApproval: boolean;
    title: string;
    description: string;
}

export interface FlightZoneRestrictionResponseBody {
    id: string;
    applicationId: string;
    lastApplicationId: string;
    generalInfo: FlightZoneRestrictionGeneralInfoResponseBody;
    restrictionArea: RestrictionAreaRequestPayload;
    exclusions: ApiExclusionsRequestPayload;
    designator: FlightZoneRestrictionDesignatorResponseBody;
    version: number;
    notamNumber?: string;
    location?: NotamLocation;
}

export type FlightZoneApplicationResponseBody =
    | FlightZoneRestrictionApplicationResponseBody
    | FlightZoneRestrictionModificationApplicationResponseBody;

interface FlightZoneRestrictionApplicationResponseBody {
    restrictionApplication: RestrictionApplicationResponseBody;
    consultations?: RestrictionConsultationResponseBody[];
}

interface FlightZoneRestrictionModificationApplicationResponseBody {
    restrictionModificationApplication: RestrictionApplicationResponseBody;
    restriction: FlightZoneRestrictionResponseBody;
    consultations?: RestrictionConsultationResponseBody[];
}

function isFlightZoneRestrictionApplicationResponseBody(
    response: FlightZoneRestrictionApplicationResponseBody | FlightZoneRestrictionModificationApplicationResponseBody
): response is FlightZoneRestrictionApplicationResponseBody {
    return (response as FlightZoneRestrictionApplicationResponseBody).restrictionApplication !== undefined;
}

export interface NotamResponseBody {
    id: string;
    applicationId: string;
    type: string;
    pl: {
        number: string | null;
        value: string;
    };
    en: {
        number: string | null;
        value: string;
    };
    centroid: Geometry;
    radiusOfGyration: number;
    prevPlNotamNumber?: string;
    prevEnNotamNumber?: string;
}

export interface NotamCapabilitiesResponseBody {
    plNotamSeries: string[];
}

interface PublishNotamRequestPayload {
    notamType: string;
    notamNumber: NotamNumber;
    plNotam: string;
    enNotam: string;
}

export interface ChatMessagesListResponseBody {
    content: ChatMessageResponseBody[];
}

export interface ChatMessageResponseBody {
    id: string;
    applicationId: string;
    content: string;
    author: {
        anspEmployee: boolean;
        person: ApplicationAssignee;
    };
    createdAt: Date;
}

export type AnspTeamResponseBody = PageResponseBody<AnspTeam>;

export interface SaveDraftErrorResponseBody {
    error: {
        messageCode: ErrorMessageCode;
        status: HttpStatusCode;
        fieldErrors: {
            fieldName: string;
            code: string;
        }[];
        generalMessage: string;
        args: Record<string, unknown>;
    };
}

export interface GetDesignatorForbiddenRangeResponseBody {
    prefix: string;
    from: number;
    to: number;
}

export function convertFlightZoneCapabilitiesResponseBodyToFlightZoneCapabilities(
    response: FlightZoneCapabilitiesResponseBody
): FlightZoneCapabilities {
    return {
        flightZonePurposes: response.purposes.map(({ purpose, stateSecurityRestriction, id }) => ({
            id,
            purpose,
            hasStateSecurityRestriction: stateSecurityRestriction,
        })),
        availableEditors: response.availableEditors,
        geometryConstraints: {
            default: {
                upperHeight: response.limitations?.upperHeight.baseValue,
                lowerHeight: response.limitations?.lowerHeight.baseValue,
                size: response.limitations?.radius.baseValue,
            },
            min: {
                upperHeight: response.limitations?.upperHeight.minValue,
                lowerHeight: response.limitations?.lowerHeight.minValue,
                size: response.limitations?.radius.minValue,
            },
            max: {
                upperHeight: response.limitations?.upperHeight.maxValue,
                lowerHeight: response.limitations?.lowerHeight.maxValue,
                size: response.limitations?.radius.maxValue,
            },
        },
        exclusionsConstraints: response.limitations?.exclusions,
        preferences: {
            initialViewbox: {
                type: "Polygon",
                coordinates: [response.initialViewBox.map((viewBoxPoint) => [viewBoxPoint.x, viewBoxPoint.y])],
            },
        },
        eLearningCourses: response.elearningCourses,
        operatorPermissions: response.operatorPermissions,
        defaultInstitutionContact: {
            email: response.applicant?.email,
            phoneNumber: {
                countryCode: response.applicant?.phoneNumber.countryCode,
                number: response.applicant?.phoneNumber.number,
            },
        },
        maxZoneDurations: response.maxTimeProperties,
    };
}

function convertRestrictionExclusionsToApiExclusionsRequestPayload(exclusions: RestrictionExclusions): ApiExclusionsRequestPayload {
    const baseExclusions: ExclusionsBaseRequestPayload = {
        courseCodeNames: exclusions.courses.map((course) => course.codeName),
        managedByGovernment: exclusions.isManagedByGovernment,
        otherExclusion: exclusions.otherExclusion,
        ownedFlights: exclusions.areOwnedFlights,
        agreedExclusion: exclusions.agreedExclusion,
        mtomExclusion: exclusions.uavMtomExclusion,
    };

    switch (exclusions.purpose) {
        case FlightZoneApplicationPurpose.ForbiddingUAVFlights: {
            return {
                ...baseExclusions,
                operatorAuthorizations: exclusions.operatorAuthorizations,
            };
        }
        case FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights: {
            return {
                ...baseExclusions,
                withInstrumentalProcedures: exclusions.isWithInstrumentalProcedures,
                withoutEngine: exclusions.isWithoutEngine,
                specialStatusFlights: exclusions.isSpecialStatusFlights,
            };
        }
        case FlightZoneApplicationPurpose.SecuringOwnFlights: {
            return {
                ...baseExclusions,
                withInstrumentalProcedures: exclusions.isWithInstrumentalProcedures,
                withoutEngine: exclusions.isWithoutEngine,
            };
        }
    }
}

export function convertFlightZoneApplicationToFlightZoneApplicationRequestPayload({
    basicDataForm,
    restrictionAreaGeometry,
    restrictionExclusions,
}: FlightZoneApplication): FlightZoneApplicationRequestPayload | null {
    return basicDataForm && restrictionAreaGeometry.areaValues && restrictionExclusions
        ? {
              generalInfo: {
                  restrictionPurpose: basicDataForm.flightZonePurpose,
                  caseNumber: basicDataForm.caseNumber,
                  title: basicDataForm.title,
                  startAt: basicDataForm.startTime.toJSON(),
                  endAt: basicDataForm.endTime.toJSON(),
                  preTacticalApproval: basicDataForm.isPreTacticalApproval,
                  tacticalApproval: false,
                  stateSecurityRestriction: basicDataForm.hasStateSecurityRestriction,
                  description: basicDataForm.description,
              },
              restrictionArea: {
                  upperAltitude: {
                      value: restrictionAreaGeometry.areaValues.upperHeight,
                      unit: restrictionAreaGeometry.areaUnits.upperHeightUnit,
                      referenceLevel: restrictionAreaGeometry.areaUnits.upperHeightReference,
                  },
                  lowerAltitude: {
                      value: restrictionAreaGeometry.areaValues.lowerHeight,
                      unit: restrictionAreaGeometry.areaUnits.lowerHeightUnit,
                      referenceLevel: restrictionAreaGeometry.areaUnits.lowerHeightReference,
                  },
                  area: {
                      ...(isCylinderRestrictionArea(restrictionAreaGeometry)
                          ? {
                                type: "Feature",
                                properties: {},
                                radius: {
                                    value: restrictionAreaGeometry.areaValues.radius,
                                    unit: restrictionAreaGeometry.areaUnits.radiusUnit,
                                },
                                center: {
                                    type: "Point",
                                    coordinates: [
                                        restrictionAreaGeometry.areaValues.centerPointLongitude,
                                        restrictionAreaGeometry.areaValues.centerPointLatitude,
                                    ],
                                },
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [],
                                },
                            }
                          : {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            ...restrictionAreaGeometry.areaValues.positions,
                                            // NOTE: GeoJson format requires last element in coordinates array to be a copy of first
                                            restrictionAreaGeometry.areaValues.positions[0],
                                        ].map((position) => [position.longitude, position.latitude]),
                                    ],
                                },
                            }),
                  },
              },
              exclusions: convertRestrictionExclusionsToApiExclusionsRequestPayload(restrictionExclusions),
          }
        : null;
}

function convertListContentResponseBodyToFlightZoneApplicationListItem(content: ListContentResponseBody): FlightZoneApplicationListItem {
    return {
        id: content.id,
        caseNumber: content.caseNumber,
        title: content.title,
        author: {
            id: content.author.id,
            fullName: `${content.author.lastName} ${content.author.firstName}`,
            email: content.author.email,
            phoneNumber: content.author.phoneNumber,
        },
        status: content.status,
        comment: content.rejectionReason,
        zoneNumber: content.designator,
        date: content.sentToAnspAt,
        startAt: content.startAt,
        endAt: content.endAt,
        applicationPurpose: content.restrictionPurpose,
        stateSecurityRestriction: content.stateSecurityRestriction,
        notam: content.notamNumber,
        isLocked: content.locked,
        isBeingCancelled: content.canceling,
    };
}

export function convertApplicationsListResponseBodyToFlightZoneApplicationsList(
    response: ApplicationsListResponseBody
): FlightZoneApplicationsList {
    return {
        content: response.content.map((content) => convertListContentResponseBodyToFlightZoneApplicationListItem(content)),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

function convertApiExclusionsRequestPayloadToRestrictionExclusions(
    exclusions: ApiExclusionsRequestPayload,
    flightZoneApplicationPurpose: FlightZoneApplicationPurpose
): RestrictionExclusions {
    const baseExclusions = {
        agreedExclusion: exclusions.agreedExclusion,
        otherExclusion: exclusions.otherExclusion,
        isManagedByGovernment: exclusions?.managedByGovernment,
        areOwnedFlights: exclusions?.ownedFlights,
        uavMtomExclusion: exclusions?.mtomExclusion,
        courses: exclusions?.courseCodeNames.map((course) => ({
            codeName: course,
            title: course,
        })),
    };

    switch (flightZoneApplicationPurpose) {
        case FlightZoneApplicationPurpose.ForbiddingUAVFlights: {
            const uavExclusions = exclusions as UavExclusionsRequestPayload;

            return {
                ...baseExclusions,
                operatorAuthorizations: uavExclusions.operatorAuthorizations,
                purpose: flightZoneApplicationPurpose,
            };
        }
        case FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights: {
            const generalAviationExclusions = exclusions as GeneralAviationExclusionsRequestPayload;

            return {
                ...baseExclusions,
                isWithInstrumentalProcedures: generalAviationExclusions.withInstrumentalProcedures,
                isSpecialStatusFlights: generalAviationExclusions.specialStatusFlights,
                isWithoutEngine: generalAviationExclusions.withoutEngine,
                purpose: flightZoneApplicationPurpose,
            };
        }
        case FlightZoneApplicationPurpose.SecuringOwnFlights: {
            const securingOwnFlightsExclusions = exclusions as SecuringOwnFlightsExclusionsRequestPayload;

            return {
                ...baseExclusions,
                isWithInstrumentalProcedures: securingOwnFlightsExclusions.withInstrumentalProcedures,
                isWithoutEngine: securingOwnFlightsExclusions.withoutEngine,
                purpose: flightZoneApplicationPurpose,
            };
        }
        case FlightZoneApplicationPurpose.PriorityToImplicitOperation: {
            throw new Error("Not implemented yet: FlightZoneApplicationPurpose.PriorityToImplicitOperation case");
        }
    }
}

function isGeoJsonCylinderAreaRequestPayload(
    area: GeoJsonCylinderAreaRequestPayload | GeoJsonPrismAreaRequestPayload
): area is GeoJsonCylinderAreaRequestPayload {
    return (area as GeoJsonCylinderAreaRequestPayload).radius !== undefined;
}

function convertFlightZoneApplicationBasicDataResponseBodyToFlightZoneApplicationBasicData(
    generalInfo: FlightZoneApplicationBasicDataResponseBody
): FlightZoneApplicationBasicData {
    return {
        title: generalInfo.title,
        caseNumber: generalInfo.caseNumber,
        startTime: new Date(generalInfo.startAt),
        endTime: new Date(generalInfo.endAt),
        flightZonePurpose: generalInfo.restrictionPurpose,
        hasStateSecurityRestriction: generalInfo.stateSecurityRestriction,
        isPreTacticalApproval: generalInfo.preTacticalApproval,
        customDesignator: generalInfo.customDesignator,
        description: generalInfo.description,
    };
}

function convertGeoJSONGeometryToSerializableCartographic(geoJSONGeometry: Geometry): SerializableCartographic[] {
    const coordinates = geoJSONGeometry.coordinates;

    if (!Array.isArray(coordinates[0])) {
        const [longitude, latitude] = coordinates as Position;

        return [
            {
                latitude,
                longitude,
            },
        ];
    }

    return coordinates[0]
        .map((position) => {
            const [longitude, latitude] = position as Position;

            return {
                latitude,
                longitude,
            };
        })
        .slice(0, -1); // NOTE: GeoJson format returns last element as copy of first. We need to remove it for compatibility with existing components.
}

function convertRestrictionAreaRequestPayloadToRestrictionArea(restrictionArea: RestrictionAreaRequestPayload): RestrictionArea {
    return isGeoJsonCylinderAreaRequestPayload(restrictionArea.area)
        ? {
              areaValues: {
                  lowerHeight: restrictionArea.lowerAltitude.value,
                  upperHeight: restrictionArea.upperAltitude.value,
                  radius: restrictionArea.area.radius.value,
                  centerPointLatitude: convertGeoJSONGeometryToSerializableCartographic(restrictionArea.area.center)[0].latitude,
                  centerPointLongitude: convertGeoJSONGeometryToSerializableCartographic(restrictionArea.area.center)[0].longitude,
              },
              areaUnits: {
                  lowerHeightUnit: restrictionArea.lowerAltitude.unit,
                  lowerHeightReference: restrictionArea.lowerAltitude.referenceLevel,
                  upperHeightUnit: restrictionArea.upperAltitude.unit,
                  upperHeightReference: restrictionArea.upperAltitude.referenceLevel,
                  radiusUnit: restrictionArea.area.radius.unit,
              },
              editorType: FlightZoneEditorType.Cylinder,
          }
        : {
              areaValues: {
                  lowerHeight: restrictionArea.lowerAltitude.value,
                  upperHeight: restrictionArea.upperAltitude.value,
                  positions: convertGeoJSONGeometryToSerializableCartographic(restrictionArea.area.geometry),
              },
              areaUnits: {
                  lowerHeightUnit: restrictionArea.lowerAltitude.unit,
                  lowerHeightReference: restrictionArea.lowerAltitude.referenceLevel,
                  upperHeightUnit: restrictionArea.upperAltitude.unit,
                  upperHeightReference: restrictionArea.upperAltitude.referenceLevel,
              },
              editorType: FlightZoneEditorType.Prism,
          };
}

function isRestrictionTypeAssociatedWithNotam(restrictionType: RestrictionType): boolean {
    switch (restrictionType) {
        case RestrictionType.TSA:
        case RestrictionType.TRA:
        case RestrictionType.R:
            return true;
        default:
            return false;
    }
}

function tryGetAltitudeConvertedToFeetAmsl(zoneAltitude: ZoneAltitudeRequestPayload): number | undefined {
    // NOTE: Omit converted "altitudeFt" value if user entered unit and referenceLevel values are already feet AMSL.
    return zoneAltitude.unit === VerticalMeasureUnits.Feet && zoneAltitude.referenceLevel === HeightReferences.AboveMeanSeaLevel
        ? undefined
        : zoneAltitude.altitudeFt;
}

function convertRestrictionAreaRequestPayloadToAltitudesConvertedToAMSLFeet(
    value: RestrictionAreaRequestPayload
): AltitudesConvertedToAMSLFeet {
    return {
        lowerAltitude: tryGetAltitudeConvertedToFeetAmsl(value.lowerAltitude),
        upperAltitude: tryGetAltitudeConvertedToFeetAmsl(value.upperAltitude),
    };
}

function convertRestrictionApplicationResponseBodyToFlightZoneApplication({
    generalInfo,
    restrictionArea,
    exclusions,
    id,
    assignment,
    designator,
    notamNumber,
    version,
    location,
}: RestrictionApplicationResponseBody): FlightZoneApplication {
    return {
        basicDataForm: convertFlightZoneApplicationBasicDataResponseBodyToFlightZoneApplicationBasicData(generalInfo),
        restrictionAreaGeometry: convertRestrictionAreaRequestPayloadToRestrictionArea(restrictionArea),
        restrictionExclusions: convertApiExclusionsRequestPayloadToRestrictionExclusions(exclusions, generalInfo.restrictionPurpose),
        analysisStatus: {
            suggestedRestrictionType: generalInfo.restrictionType,
            isRestrictionTypeAssociatedWithNotam: isRestrictionTypeAssociatedWithNotam(generalInfo.restrictionType),
            designator,
            assignment,
            isLocked: generalInfo.locked,
            id,
            notamNumber,
            version,
            altitudesConvertedToAMSLFeet: convertRestrictionAreaRequestPayloadToAltitudesConvertedToAMSLFeet(restrictionArea),
        },
        flightZoneId: id,
        status: generalInfo.status,
        note: generalInfo.note,
        location,
        anspCaseNumber: generalInfo.anspCaseNumber,
        restrictionModification: undefined,
        applicationType: ApplicationType.RestrictionApplication,
        notamPreviewId: notamNumber ? id : undefined,
        additionalReceivers: generalInfo.additionalReceivers.map((receiver) => receiver.email),
        detailedDuration: generalInfo.detailedDuration,
        airspaceClassification: generalInfo.airspaceClassification,
    };
}

export function convertRestrictionConsultationResponseBodyToConsultationData(
    consultation: RestrictionConsultationResponseBody
): ConsultationData {
    return {
        id: consultation.id,
        status: consultation.reviewStatus,
        comment: consultation.comment,
        consultant: consultation.consultant,
        createdDate: new Date(consultation.createdDate),
    };
}

function convertFlightZoneRestrictionApplicationResponseBodyToFlightZoneApplication({
    restrictionApplication,
    consultations,
}: FlightZoneRestrictionApplicationResponseBody): FlightZoneApplication {
    return {
        ...convertRestrictionApplicationResponseBodyToFlightZoneApplication(restrictionApplication),
        consultations: consultations?.map((consultation) => convertRestrictionConsultationResponseBodyToConsultationData(consultation)),
    };
}

function convertFlightZoneRestrictionResponseBodyToFlightZoneApplicationBasicData(
    response: FlightZoneRestrictionResponseBody
): FlightZoneApplicationBasicData {
    return {
        title: response.generalInfo.title,
        caseNumber: response.generalInfo.caseNumber,
        startTime: new Date(response.designator.start),
        endTime: new Date(response.designator.end),
        flightZonePurpose: response.generalInfo.restrictionPurpose,
        hasStateSecurityRestriction: response.generalInfo.stateSecurityRestriction,
        isPreTacticalApproval: response.generalInfo.preTacticalApproval,
        isBeingCancelled: response.generalInfo.canceling,
        isBeingModified: response.generalInfo.modifying,
        description: response.generalInfo.description,
    };
}

function convertFlightZoneRestrictionModificationApplicationResponseBodyToFlightZoneApplication({
    restrictionModificationApplication,
    restriction,
    consultations,
}: FlightZoneRestrictionModificationApplicationResponseBody): FlightZoneApplication {
    return {
        basicDataForm: convertFlightZoneRestrictionResponseBodyToFlightZoneApplicationBasicData(restriction),
        restrictionAreaGeometry: convertRestrictionAreaRequestPayloadToRestrictionArea(restriction.restrictionArea),
        restrictionExclusions: convertApiExclusionsRequestPayloadToRestrictionExclusions(
            restriction.exclusions,
            restriction.generalInfo.restrictionPurpose
        ),
        analysisStatus: {
            suggestedRestrictionType: restriction.generalInfo.restrictionType,
            isRestrictionTypeAssociatedWithNotam: isRestrictionTypeAssociatedWithNotam(restriction.generalInfo.restrictionType),
            notamNumber: restriction.notamNumber,
            designator: restriction.designator.designator,
            assignment: restrictionModificationApplication.assignment,
            isLocked: restrictionModificationApplication.generalInfo.locked,
            id: restrictionModificationApplication.id,
            version: restrictionModificationApplication.version,
            altitudesConvertedToAMSLFeet: convertRestrictionAreaRequestPayloadToAltitudesConvertedToAMSLFeet(
                restrictionModificationApplication.restrictionArea
            ),
        },
        flightZoneId: restrictionModificationApplication.id,
        status: restrictionModificationApplication.generalInfo.status,
        note: restrictionModificationApplication.generalInfo.note,
        location: restrictionModificationApplication.location,
        anspCaseNumber: restrictionModificationApplication.generalInfo.anspCaseNumber,
        restrictionModification: {
            startAt: new Date(restrictionModificationApplication.generalInfo.startAt),
            endAt: new Date(restrictionModificationApplication.generalInfo.endAt),
        },
        applicationType: ApplicationType.RestrictionModificationApplication,
        notamPreviewId: restriction.notamNumber ? restriction.applicationId : undefined,
        consultations: consultations?.map((consultation) => convertRestrictionConsultationResponseBodyToConsultationData(consultation)),
        additionalReceivers: restrictionModificationApplication.generalInfo.additionalReceivers.map((receiver) => receiver.email),
        detailedDuration: restrictionModificationApplication.generalInfo.detailedDuration,
        airspaceClassification: restrictionModificationApplication.generalInfo.airspaceClassification,
    };
}

export function convertFlightZoneApplicationResponseBodyToFlightZoneApplication(
    response: FlightZoneApplicationResponseBody
): FlightZoneApplication {
    return isFlightZoneRestrictionApplicationResponseBody(response)
        ? convertFlightZoneRestrictionApplicationResponseBodyToFlightZoneApplication(response)
        : convertFlightZoneRestrictionModificationApplicationResponseBodyToFlightZoneApplication(response);
}

export function convertFlightZoneRestrictionResponseBodyToFlightZoneRestriction(
    response: FlightZoneRestrictionResponseBody
): FlightZoneRestriction {
    return {
        basicData: convertFlightZoneRestrictionResponseBodyToFlightZoneApplicationBasicData(response),
        restrictionAreaGeometry: convertRestrictionAreaRequestPayloadToRestrictionArea(response.restrictionArea),
        restrictionExclusions: convertApiExclusionsRequestPayloadToRestrictionExclusions(
            response.exclusions,
            response.generalInfo.restrictionPurpose
        ),
        analysisStatus: {
            isRestrictionTypeAssociatedWithNotam: isRestrictionTypeAssociatedWithNotam(response.generalInfo.restrictionType),
            designator: response.designator.designator,
            id: response.id,
            notamNumber: response.notamNumber,
            version: response.version,
            isLocked: true,
            altitudesConvertedToAMSLFeet: convertRestrictionAreaRequestPayloadToAltitudesConvertedToAMSLFeet(response.restrictionArea),
        },
        id: response.id,
        applicationId: response.applicationId,
        lastApplicationId: response.lastApplicationId,
        note: response.generalInfo.note,
    };
}

export function convertListContentResponseBodyToFlightZonesManagementListItem(
    content: ListContentResponseBody
): FlightZonesManagementListItem {
    return {
        id: content.id,
        caseNumber: content.caseNumber,
        title: content.title,
        author: {
            id: content.author.id,
            fullName: `${content.author.lastName} ${content.author.firstName}`,
            email: content.author.email,
            phoneNumber: content.author.phoneNumber,
        },
        date: content.sentToAnspAt,
        institution: content.institution.name,
        status: content.status,
        applicationPurpose: content.restrictionPurpose,
        assignee: content.reviewer ? `${content.reviewer.lastName} ${content.reviewer.firstName}` : null,
        assigneeId: content.reviewer?.id,
        zoneNumber: content.designator,
        notam: content.notamNumber,
        stateSecurityRestriction: content.stateSecurityRestriction,
        startAt: content.startAt,
        endAt: content.endAt,
        comment: content.rejectionReason,
        isLocked: content.locked,
        anspCaseNumber: content.anspCaseNumber,
        isBeingCancelled: content.canceling,
    };
}

export function convertApplicationsListResponseBodyToFlightZonesManagementList(
    response: ApplicationsListResponseBody
): FlightZonesManagementList {
    return {
        content: response.content.map((content) => convertListContentResponseBodyToFlightZonesManagementListItem(content)),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
export function convertApplicationsListResponseBodyToFlightZoneApplicationsForConsultationList(
    response: ApplicationsForConsultationListResponseBody
): FlightZoneApplicationsForConsultationList {
    return {
        content: response.content.map((content) => ({
            id: content.id,
            applicationPurpose: content.restrictionPurpose,
            applicationType: content.applicationType,
            applicationId: content.applicationId,
            title: content.title,
            author: {
                id: content.author.id,
                fullName: `${content.author.firstName} ${content.author.lastName}`,
                email: content.author.email,
                phoneNumber: content.author.phoneNumber,
            },
            date: new Date(content.sentToAnspAt),
            startAt: new Date(content.startAt),
            endAt: new Date(content.endAt),
            institution: content.institution.name,
            caseNumber: content.caseNumber,
            assignee: `${content.reviewer.firstName} ${content.reviewer.lastName}`,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertRestrictionsListResponseBodyToRestrictionsList(response: RestrictionsListResponseBody): RestrictionsList {
    return {
        content: response.content.map((element) => ({
            id: element.id,
            applicationPurpose: element.restrictionPurpose,
            title: element.title,
            author: {
                id: element.author.id,
                fullName: `${element.author.firstName} ${element.author.lastName}`,
                email: element.author.email,
                phoneNumber: element.author.phoneNumber,
            },
            isStateSecurityRestriction: element.stateSecurityRestriction,
            startAt: new Date(element.startAt),
            endAt: new Date(element.endAt),
            institution: element.institution.name,
            zoneNumber: element.designator,
            caseNumber: element.caseNumber,
            notam: element.notamNumber,
            isBeingModified: element.modifying,
            isBeingCancelled: element.canceling,
            modifyingStartAt: element.modifyingStartAt ? new Date(element.modifyingStartAt) : undefined,
            modifyingEndAt: element.modifyingEndAt ? new Date(element.modifyingEndAt) : undefined,
            anspCaseNumber: element.anspCaseNumber,
            applicationId: element.applicationId,
            lastApplicationId: element.lastApplicationId,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertInstitutionsFilterOptionsResponseBodyToInstitutions(response: InstitutionsFilterOptionsResponseBody): Institution[] {
    return response.content.map((element) => ({
        id: element.id,
        name: element.name,
    }));
}

export function convertFilterParamsToHttpParams(params: Params): HttpParams {
    return new HttpParams({
        fromObject: ObjectUtils.removeNullishProperties({
            ...params,
            sendDate: params.sendDate ? DateUtils.getISOStringDate(params.sendDate) : null,
            startDate: params.startDate ? DateUtils.getISOStringDate(params.startDate) : null,
            endDate: params.endDate ? DateUtils.getISOStringDate(params.endDate) : null,
        }),
    });
}

export function convertNotamCapabilitiesResponseBodyToNotamCapabilities(response: NotamCapabilitiesResponseBody): NotamCapabilities {
    return { notamNumberSeries: response.plNotamSeries };
}

export function convertNotamsToPublishNotamRequestPayload(
    updatedNotamNumber: NotamNumber,
    notamData: NotamData
): PublishNotamRequestPayload {
    return {
        notamType: notamData.type,
        notamNumber: updatedNotamNumber,
        enNotam: notamData.internationalNotam.value ?? "",
        plNotam: notamData.nativeNotam.value ?? "",
    };
}

function createNotamNumber(number: string | null): NotamNumber {
    return {
        notamSeries: number?.slice(0, 1) ?? "",
        number: number?.slice(1) ?? "",
    };
}

export function convertNotamResponseBodyToNotamData(response: NotamResponseBody): NotamData {
    return {
        notamId: response.id,
        nativeNotam: {
            language: "PL",
            value: response.pl.value,
            notamNumber: createNotamNumber(response.pl.number),
            previousNotamNumber: response.prevPlNotamNumber,
        },
        internationalNotam: {
            language: "EN",
            value: response.en.value,
            notamNumber: createNotamNumber(response.en.number),
            previousNotamNumber: response.prevEnNotamNumber,
        },
        centroid: {
            latitude: convertGeoJSONGeometryToSerializableCartographic(response.centroid)[0].latitude,
            longitude: convertGeoJSONGeometryToSerializableCartographic(response.centroid)[0].longitude,
        },
        centroidRadius: response.radiusOfGyration,
        type: response.type,
    };
}

export function convertChatMessageResponseBodyToFlightZoneApplicationChatMessage(
    messageResponse: ChatMessageResponseBody
): FlightZoneApplicationChatMessage {
    return {
        id: messageResponse.id,
        flightZoneId: messageResponse.applicationId,
        author: messageResponse.author.person,
        content: messageResponse.content,
        createdAt: messageResponse.createdAt,
    };
}

export function convertChatMessagesResponseBodyToFlightZoneApplicationChatMessageList(
    response: ChatMessagesListResponseBody
): FlightZoneApplicationChatMessage[] {
    return response.content.map((chatMessageContent) =>
        convertChatMessageResponseBodyToFlightZoneApplicationChatMessage(chatMessageContent)
    );
}

export function convertNotamListResponseBodyToFlightZonesManagementList(response: NotamListResponseBody): NotamList {
    return {
        content: response.content.map((content) => ({
            id: content.id,
            applicationId: content.application.id,
            title: content.application.title,
            type: content.application.type,
            startAt: new Date(content.application.startAt),
            endAt: new Date(content.application.endAt),
            reviewer: content.reviewer,
            designator: content.designator,
            notamType: content.notamType,
            notamNumber: content.plNotamNumber,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertAnspTeamResponseBodyToAnspTeamList(response: PageResponseBody<AnspTeam>): AnspTeam[] {
    return response.content;
}

const zoneGeoJsonStyles = {
    fill: "#FF3318",
    "fill-opacity": 0.3,
};

export function convertGeoJsonGeometryToFeature(geometry: Geometry): Feature {
    return {
        type: "Feature",
        geometry,
        properties: {
            ...zoneGeoJsonStyles,
        },
    };
}
