import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    FlightZoneApplicationReviewStatus,
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    GEOMETRY_READ_ONLY_CONSTRAINTS,
} from "@dtm-frontend/dss-shared-lib";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME } from "../../services/flight-zone-consultation.resolvers";
import { FlightZoneConsultationActions } from "../../state/flight-zone-consultation.actions";
import { FlightZoneConsultationState } from "../../state/flight-zone-consultation.state";
import { ConsultationReviewConfirmDialogComponent } from "./consultation-review-confirm-dialog/consultation-review-confirm-dialog.component";

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-consultation-preview",
    templateUrl: "./consultation-preview.component.html",
    styleUrls: ["./consultation-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class ConsultationPreviewComponent implements AfterViewInit, OnDestroy {
    protected readonly FlightZoneErrorType = FlightZoneErrorType;
    protected readonly FlightZoneApplicationReviewStatus = FlightZoneApplicationReviewStatus;

    protected readonly error$ = this.store.select(FlightZoneConsultationState.error);
    protected readonly applicationForConsultation$ = this.store.select(FlightZoneConsultationState.applicationForConsultation);
    protected readonly isProcessing$ = this.store.select(FlightZoneConsultationState.isProcessing);
    protected readonly activeConsultation$ = this.store.select(FlightZoneConsultationState.activeConsultation);
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);

    constructor(
        private readonly store: Store,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly toastService: ToastrService,
        private readonly route: ActivatedRoute
    ) {}

    public ngAfterViewInit(): void {
        this.initMapWithEntity();
    }

    public ngOnDestroy(): void {
        this.flightZoneGeometryService.clearGeometry();
    }

    protected zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    protected reviewConsultation(reviewStatus: FlightZoneApplicationReviewStatus): void {
        const consultationId = this.route.snapshot.paramMap.get(FLIGHT_ZONE_CONSULTATION_ID_ROUTE_PARAM_NAME);

        if (!consultationId) {
            return;
        }

        const dialogRef = this.dialogService.open(ConsultationReviewConfirmDialogComponent, {
            data: { reviewStatus },
        });

        dialogRef.componentInstance.confirmReview$
            .pipe(
                switchMap((comment) =>
                    this.store.dispatch(new FlightZoneConsultationActions.ReviewConsultation(consultationId, reviewStatus, comment))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneConsultationState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dssAdminLibFlightZoneConsultation.consultationPreview.reviewConsultationSuccessMessage")
                );
                dialogRef.close();
            });
    }

    private initMapWithEntity(): void {
        const restrictionAreaGeometry = this.store.selectSnapshot(
            FlightZoneConsultationState.applicationForConsultation
        )?.restrictionAreaGeometry;

        if (!restrictionAreaGeometry) {
            return;
        }

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, GEOMETRY_READ_ONLY_CONSTRAINTS);
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotReviewApplication:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneConsultation.consultationPreview.cannotReviewConsultationErrorMessage"
                );
                break;
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneConsultation.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate(
                    "dssAdminLibFlightZoneConsultation.consultationPreview.alreadyReviewedErrorMessage"
                );
                break;
            default:
                errorMessage = this.transloco.translate("dssAdminLibFlightZoneConsultation.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
