<ng-container
    *ngrxLet="{
        isFormExpanded: isFormExpanded$,
        location: location$,
        hasLocationMissingError: hasLocationMissingError$,
        isProcessing: isProcessing$,
        isReadOnly: isReadOnly$
    } as vm"
>
    <dtm-ui-presentation-tile-with-action
        [titleTile]="'dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.header' | transloco"
        [class.error]="vm.hasLocationMissingError"
        [class.read-only]="vm.isReadOnly"
    >
        <button
            *ngIf="!vm.isReadOnly && !vm.isFormExpanded"
            actionButtonSlot
            class="button-secondary"
            type="button"
            (click)="changeExpansionState(true)"
        >
            <dtm-ui-icon name="edit"></dtm-ui-icon>
            {{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.editButtonLabel" | transloco }}
        </button>

        <button
            *ngIf="!vm.isReadOnly && vm.isFormExpanded"
            actionButtonSlot
            class="button-icon"
            type="button"
            (click)="changeExpansionState(false)"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <form *ngIf="!vm.isReadOnly && vm.isFormExpanded" [formGroup]="notamLocationForm" (submit)="submitForm()" @slideIn>
            <dtm-ui-input-field>
                <label>{{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.codeLabel" | transloco }}</label>
                <input matInput type="text" formControlName="code" [mask]="LOCATION_CODE_MASK" placeholder="_ _ _ _" />
                <div class="field-error">
                    <ng-container
                        [ngTemplateOutlet]="formErrorsTemplate"
                        [ngTemplateOutletContext]="{ formControl: notamLocationForm.controls.code }"
                    ></ng-container>
                </div>
            </dtm-ui-input-field>

            <dtm-ui-input-field dtmUiScrollToAfterInit>
                <label>{{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.nameLabel" | transloco }}</label>
                <input matInput type="text" formControlName="name" />
                <div class="field-error">
                    <ng-container
                        [ngTemplateOutlet]="formErrorsTemplate"
                        [ngTemplateOutletContext]="{ formControl: notamLocationForm.controls.name }"
                    ></ng-container>
                </div>
            </dtm-ui-input-field>

            <div class="buttons-container">
                <button class="button-secondary" type="button" (click)="cancelChanges()">
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.cancelButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary"
                    type="submit"
                    [disabled]="(notamLocationForm.dirty && notamLocationForm.invalid) || isProcessing$ | ngrxPush"
                >
                    <dtm-ui-icon name="save"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.saveButtonLabel" | transloco }}
                </button>
            </div>
        </form>

        <div *ngIf="!vm.isFormExpanded && vm.location">
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.codeLabel' | transloco"
                [value]="vm.location.code"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.nameLabel' | transloco"
                [value]="vm.location.name"
            ></dtm-ui-label-value>
        </div>

        <div *ngIf="vm.isReadOnly && !vm.location">
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.codeLabel' | transloco"
                [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.nameLabel' | transloco"
                [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
            ></dtm-ui-label-value>
        </div>
    </dtm-ui-presentation-tile-with-action>
    <dtm-ui-form-errors>
        <div *ngIf="vm.hasLocationMissingError">
            {{ "dssAdminLibFlightZoneManagement.applicationPreview.notamLocationForm.locationMissingError" | transloco }}
        </div>
    </dtm-ui-form-errors>
</ng-container>

<ng-template #formErrorsTemplate let-formControl="formControl">
    <ng-container *dtmUiFieldHasError="formControl; name: 'requiredTouched'">
        {{ "dssAdminLibFlightZoneManagement.requiredFieldErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'mask'; error as error">
        {{ "dssAdminLibFlightZoneManagement.maskLengthErrorHint" | transloco : { value: error.requiredMask.length } }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'maxlength'; error as error">
        {{ "dssAdminLibFlightZoneManagement.maxLengthErrorHint" | transloco : { maxLength: error.requiredLength } }}
    </ng-container>
</ng-template>
