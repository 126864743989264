<mat-card
    *ngrxLet="{
        notifications: notifications$,
        page: page$,
        isProcessing: isProcessing$,
        hasDataRetrievalError: hasDataRetrievalError$
    } as vm"
>
    <dtm-ui-card-header>
        {{ "dssSharedLibUserProfile.notifications.sectionTitle" | transloco }}
    </dtm-ui-card-header>

    <table mat-table [dataSource]="vm.notifications" class="dtm-table">
        <ng-container matColumnDef="eventName">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssSharedLibUserProfile.notifications.eventNameHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.eventName | systemTranslation }}</td>
        </ng-container>

        <ng-container matColumnDef="isEnabled">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dssSharedLibUserProfile.notifications.isEnabledHeader" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element" class="toggle-cell">
                <mat-slide-toggle
                    [checked]="element.isEnabled"
                    (change)="notificationStateChange.emit({ id: element.id, isEnabled: $event.checked })"
                ></mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error *ngIf="vm.hasDataRetrievalError; else emptyListTemplate" (reload)="listRefresh.emit()"></dtm-ui-error>

                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="vm.page as page" [pagination]="page" (page)="pageChange.emit($event)"></dtm-ui-pagination>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="vm.notifications.length || vm.isProcessing"
            class="no-results-row"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.notifications.length" class="pagination-row"></tr>
    </table>
</mat-card>
