import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FlightZoneCapabilities, FlightZoneError } from "../models/flight-zone-shared.models";
import { FlightZoneApiService } from "../services/flight-zone-api/flight-zone-api.service";
import { FlightZoneCapabilitiesActions } from "./flight-zone-capabilities.actions";

interface FlightZoneCapabilitiesStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    capabilities: FlightZoneCapabilities | undefined;
}

const defaultState: FlightZoneCapabilitiesStateModel = {
    error: undefined,
    isProcessing: false,
    capabilities: undefined,
};

@State<FlightZoneCapabilitiesStateModel>({
    name: "flightZoneCapabilities",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneCapabilitiesState {
    @Selector()
    public static error(state: FlightZoneCapabilitiesStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static isProcessing(state: FlightZoneCapabilitiesStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static capabilities(state: FlightZoneCapabilitiesStateModel): FlightZoneCapabilities | undefined {
        return state.capabilities;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {}

    @Action(FlightZoneCapabilitiesActions.GetCapabilities)
    public getFlightZoneCapabilities(context: StateContext<FlightZoneCapabilitiesStateModel>) {
        const currentState = context.getState();

        if (currentState.capabilities) {
            return of(currentState);
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getFlightZoneCapabilities().pipe(
            map((capabilities: FlightZoneCapabilities) => {
                context.patchState({
                    capabilities,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
