import { Injectable } from "@angular/core";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { UserData, UserNotificationsList, UserProfileError } from "../models/user-profile.models";
import { UserProfileApiService } from "../services/user-profile-api.service";
import { UserProfileActions } from "./user-profile.actions";

export interface UserProfileStateModel {
    isProcessing: boolean;
    userData: UserData | undefined;
    userDataError: UserProfileError | undefined;
    updateUserError: UserProfileError | undefined;
    userNotificationsList: UserNotificationsList | undefined;
    getNotificationsError: UserProfileError | undefined;
    updateNotificationStateError: UserProfileError | undefined;
    resetUserPasswordError: UserProfileError | undefined;
    requestUserAccountDeleteError: UserProfileError | undefined;
}

const defaultState: UserProfileStateModel = {
    isProcessing: false,
    userData: undefined,
    userDataError: undefined,
    updateUserError: undefined,
    userNotificationsList: undefined,
    getNotificationsError: undefined,
    updateNotificationStateError: undefined,
    resetUserPasswordError: undefined,
    requestUserAccountDeleteError: undefined,
};

@State<UserProfileStateModel>({
    name: "userProfile",
    defaults: defaultState,
})
@Injectable()
export class UserProfileState {
    @Selector()
    public static isProcessing(state: UserProfileStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static userData(state: UserProfileStateModel): UserData | undefined {
        return state.userData;
    }

    @Selector()
    public static updateUserError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updateUserError;
    }

    @Selector()
    public static userNotificationsList(state: UserProfileStateModel): UserNotificationsList | undefined {
        return state.userNotificationsList;
    }

    @Selector()
    public static getNotificationsError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.getNotificationsError;
    }

    @Selector()
    public static updateNotificationStateError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updateNotificationStateError;
    }

    @Selector()
    public static resetUserPasswordError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.resetUserPasswordError;
    }

    @Selector()
    public static requestUserAccountDeleteError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestUserAccountDeleteError;
    }

    constructor(private readonly userProfileApi: UserProfileApiService) {}

    @Action(UserProfileActions.GetUserData)
    public getUserData(context: StateContext<UserProfileStateModel>, { userId }: UserProfileActions.GetUserData) {
        context.patchState({ isProcessing: true, userDataError: undefined });

        return this.userProfileApi.getUserData(userId).pipe(
            tap((userData) => context.patchState({ userData })),
            catchError((userDataError) => {
                context.patchState({ userDataError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.UpdateUserData)
    public updateUserData(context: StateContext<UserProfileStateModel>, { updatedData }: UserProfileActions.UpdateUserData) {
        const currentData = context.getState().userData;

        if (!currentData) {
            return;
        }

        context.patchState({ isProcessing: true, updateUserError: undefined });

        const updatedUser: UserData = {
            ...currentData,
            ...updatedData,
        };

        return this.userProfileApi.updateUserData(updatedUser).pipe(
            tap((userData) => context.patchState({ userData })),
            catchError((updateUserError) => {
                context.patchState({ updateUserError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.RequestUserAccountDelete)
    public requestUserAccountDelete(context: StateContext<UserProfileStateModel>) {
        const userId = context.getState().userData?.id;

        if (!userId) {
            return;
        }

        context.patchState({ isProcessing: true, requestUserAccountDeleteError: undefined });

        return this.userProfileApi.requestUserAccountDelete(userId).pipe(
            tap(() => context.dispatch(new AuthActions.Logout())),
            catchError((requestUserAccountDeleteError) => {
                context.patchState({ requestUserAccountDeleteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.GetNotificationsList, { cancelUncompleted: true })
    public getNotificationsList(context: StateContext<UserProfileStateModel>, { filterParams }: UserProfileActions.GetNotificationsList) {
        context.patchState({ isProcessing: true, getNotificationsError: undefined });

        return this.userProfileApi.getNotificationsList(filterParams).pipe(
            tap((userNotificationsList) => context.patchState({ userNotificationsList })),
            catchError((getNotificationsError) => {
                context.patchState({ getNotificationsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.UpdateNotification)
    public updateNotification(context: StateContext<UserProfileStateModel>, { id, isEnabled }: UserProfileActions.UpdateNotification) {
        const currentNotificationsState = context.getState().userNotificationsList;

        if (!currentNotificationsState) {
            return;
        }

        context.patchState({ isProcessing: true, updateNotificationStateError: undefined });

        return this.userProfileApi.updateNotification(id, isEnabled).pipe(
            tap(() =>
                context.patchState({
                    userNotificationsList: {
                        ...currentNotificationsState,
                        content: currentNotificationsState.content.map((notification) =>
                            notification.id === id ? { ...notification, isEnabled } : notification
                        ),
                    },
                })
            ),
            catchError((updateNotificationStateError) => {
                context.patchState({
                    userNotificationsList: {
                        ...currentNotificationsState,
                        content: currentNotificationsState.content.map((notification) => ({ ...notification })),
                    },
                    updateNotificationStateError,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.ResetUserPassword)
    public resetUserPassword(context: StateContext<UserProfileStateModel>) {
        const userId = context.getState().userData?.id;

        if (!userId) {
            return;
        }

        context.patchState({ resetUserPasswordError: undefined, isProcessing: true });

        return this.userProfileApi.resetUserPassword(userId).pipe(
            tap(() => context.dispatch(new AuthActions.Logout())),
            catchError((resetUserPasswordError) => {
                context.patchState({
                    resetUserPasswordError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
