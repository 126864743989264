import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, Subject } from "rxjs";

const MAX_NOTE_LENGTH = 5000;
const MAX_TOOLTIP_LENGTH = 300;

interface ApplicationNoteDialogConfig {
    note: string;
    isReadOnly: boolean;
    isProcessing$: Observable<boolean>;
    isNoteRequired?: boolean;
    dialogDescription?: string;
    confirmButtonLabel?: string;
}

interface NoteSuggestionButton {
    label: string;
    suggestionText: string;
}

@Component({
    selector: "dss-shared-lib-application-note-dialog",
    templateUrl: "./application-note-dialog.component.html",
    styleUrls: ["./application-note-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationNoteDialogComponent {
    private readonly updateNoteSubject = new Subject<string>();
    protected readonly autoSuggestButtons = this.getAutoSuggestButtons();
    protected readonly MAX_NOTE_LENGTH = MAX_NOTE_LENGTH;
    protected readonly applicationNoteControl = new UntypedFormControl("", [Validators.maxLength(MAX_NOTE_LENGTH)]);
    public readonly updateNote$ = this.updateNoteSubject.asObservable();

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: ApplicationNoteDialogConfig,
        private readonly transloco: TranslocoService
    ) {
        if (data.note) {
            this.applicationNoteControl.setValue(data.note);
        }

        if (data.isNoteRequired) {
            this.applicationNoteControl.addValidators(Validators.required);
        }

        if (data.isReadOnly) {
            this.applicationNoteControl.disable();
        }
    }

    protected onConfirmClick(): void {
        this.applicationNoteControl.markAllAsTouched();

        if (this.applicationNoteControl.invalid) {
            return;
        }

        this.updateNoteSubject.next(this.applicationNoteControl.value ?? "");
    }

    protected addAutoSuggestion(suggestionText: string): void {
        this.applicationNoteControl.setValue((this.applicationNoteControl.value ?? "") + suggestionText);
    }

    protected formatTooltip(htmlString: string): string {
        const textWithoutHMTL = htmlString.replace(/(<([^>]+)>)/gi, " ");

        return StringUtils.ellipsifyText(textWithoutHMTL, MAX_TOOLTIP_LENGTH);
    }

    private getAutoSuggestButtons(): NoteSuggestionButton[] {
        return [
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.servicesLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.servicesValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.withoutRqaLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.withoutRqaValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.nonCollisionLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.nonCollisionValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.collisionLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.collisionValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.belowKtsLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.belowKtsValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.aboveKtsLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.aboveKtsValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.cityLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.cityValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.fplLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.fplValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.organizerAgreementLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.organizerAgreementValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.uavBuffersLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.uavBuffersValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.uavFailsafeLabel"),
                suggestionText: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.uavFailsafeValue"),
            },
            {
                label: this.transloco.translate("dssSharedLibFlightZone.applicationNote.autoSuggestions.uavEmergencyProcedureLabel"),
                suggestionText: this.transloco.translate(
                    "dssSharedLibFlightZone.applicationNote.autoSuggestions.uavEmergencyProcedureValue"
                ),
            },
        ];
    }
}
