import { Data, Params, Route } from "@angular/router";
import {
    FlightZonesManagementListItem,
    NotamList,
    NotamListItem,
    RestrictionsList,
    RestrictionsListItem,
} from "@dtm-frontend/dss-shared-lib";
import { FlightZoneListsStateModel } from "../state/flight-zone-lists.state";

export enum TableActionButton {
    PreviewAction = "PreviewAction",
    PreviewAndDecideAction = "PreviewAndDecideAction",
    AddAssignee = "AddAssignee",
    ChangeAssignee = "ChangeAssignee",
    AssignToMe = "AssignToMe",
    NotamPreview = "NotamPreview",
    KmlDownload = "KmlDownload",
}

export interface AssigneeChangeParams {
    assigneeId: string;
    flightZoneApplication: FlightZonesManagementListItem;
    isAlreadyAssigned: boolean;
}

export type FlightZonesManagementTableColumns = Array<keyof FlightZonesManagementListItem | "actions" | "expandHandle">;

export type RestrictionsTableColumns = Array<keyof RestrictionsListItem | "actions" | "expandHandle">;

export type NotamsTableColumns = Array<keyof NotamListItem | "actions" | "expandHandle">;

export type NotamListRoute = Route & {
    data?: NotamListRouteData;
};

export interface NotamListRouteData extends Data {
    titleTranslationKey: string;
    displayedColumns: NotamsTableColumns;
    action: new (filterParams: Params) => unknown;
    selector: (state: FlightZoneListsStateModel) => NotamList | undefined;
}

export type RestrictionListRoute = Route & {
    data?: RestrictionListRouteData;
};

export interface RestrictionListRouteData extends Data {
    titleTranslationKey: string;
    action: new (filterParams: Params) => unknown;
    selector: (state: FlightZoneListsStateModel) => RestrictionsList | undefined;
}
