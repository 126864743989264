import { Params } from "@angular/router";
import {
    AnspStandardTeamWorkCalendar,
    AnspTeamsListItem,
    InstitutionMember,
    InstitutionsListItem,
    NewInstitutionDetails,
    NewUserDetails,
} from "../models/administration.models";

export namespace AdministrationActions {
    export class GetUsersList {
        public static readonly type = "[Administration] Get users list";

        constructor(public filterParams: Params) {}
    }

    export class GetInstitutionsList {
        public static readonly type = "[Administration] Get institutions list";

        constructor(public filterParams: Params) {}
    }

    export class GetInstitutionDetails {
        public static readonly type = "[Administration] Get institution details";

        constructor(public institutionId: string) {}
    }

    export class GetAvailableInstitutionMembers {
        public static readonly type = "[Administration] Get available institution members";
    }

    export class AddInstitutionMember {
        public static readonly type = "[Administration] Add institution member";

        constructor(public institution: InstitutionsListItem, public memberId: string) {}
    }

    export class RemoveInstitutionMember {
        public static readonly type = "[Administration] Remove institution member";

        constructor(public institution: InstitutionsListItem, public memberId: string) {}
    }

    export class GetCapabilities {
        public static readonly type = "[Administration] Get capabilities";
    }

    export class SaveNewUser {
        public static readonly type = "[Administration] Save new user";

        constructor(public data: NewUserDetails) {}
    }

    export class ClearUserDetails {
        public static readonly type = "[Administration] Clear user details";
    }

    export class GetUserDetails {
        public static readonly type = "[Administration] Get user details";

        constructor(public userId: string) {}
    }

    export class UpdateUserDetails {
        public static readonly type = "[Administration] Update user details";

        constructor(public data: NewUserDetails) {}
    }

    export class DeleteUser {
        public static readonly type = "[Administration] Delete user";

        constructor(public userId: string) {}
    }

    export class AddInstitutionMembers {
        public static readonly type = "[Administration] Add members to institution list item";

        constructor(public institutionId: string, public members: InstitutionMember[]) {}
    }

    export class SaveNewInstitution {
        public static readonly type = "[Administration] Save new institution";

        constructor(public data: NewInstitutionDetails) {}
    }

    export class UpdateInstitutionDetails {
        public static readonly type = "[Administration] Update institution details";

        constructor(public data: NewInstitutionDetails) {}
    }

    export class ClearInstitutionDetails {
        public static readonly type = "[Administration] Clear institution details";
    }

    export class RemoveInstitution {
        public static readonly type = "[Administration] Remove institution";

        constructor(public institution: InstitutionsListItem) {}
    }

    export class GetAnspTeamsList {
        public static readonly type = "[Administration] Get ansp teams list";

        constructor(public filterParams: Params) {}
    }

    export class GetAnspTeamDetails {
        public static readonly type = "[Administration] Get ansp team details";

        constructor(public teamId: string) {}
    }

    export class GetAvailableAnspTeamMembers {
        public static readonly type = "[Administration] Get available ansp team members";
    }

    export class AddAnspTeamMember {
        public static readonly type = "[Administration] Add ansp team member";

        constructor(public team: AnspTeamsListItem, public memberId: string) {}
    }

    export class RemoveAnspTeamMember {
        public static readonly type = "[Administration] Remove ansp team member";

        constructor(public team: AnspTeamsListItem, public memberId: string) {}
    }

    export class GetAnspTeamWorkCalendar {
        public static readonly type = "[Administration] Get ansp team work calendar";

        constructor(public teamId: string) {}
    }

    export class UpdateStandardTeamWorkCalendar {
        public static readonly type = "[Administration] Update standard team work calendar";

        constructor(public calendar: AnspStandardTeamWorkCalendar) {}
    }

    export class AddDaysOffToTeamCalendar {
        public static readonly type = "[Administration] Add days off to team calendar";

        constructor(public daysOff: Date[]) {}
    }

    export class RemoveDayOffFromTeamCalendar {
        public static readonly type = "[Administration] Remove day off from team calendar";

        constructor(public dayOffId: string) {}
    }
}
