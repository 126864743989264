import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ActiveRestrictionsLayerFeatureData } from "../../../models/active-restrictions-map-layer.models";
import { VerticalMeasureUnits } from "../../../models/flight-zone-shared.models";
import { FlightZoneUtils } from "../../../utils";

interface ActiveRestrictionsFeaturesListComponentState {
    featuresData: ActiveRestrictionsLayerFeatureData[];
    isProcessing: boolean;
}

@Component({
    selector: "dss-shared-lib-active-restrictions-features-list[featuresData]",
    templateUrl: "./active-restrictions-features-list.component.html",
    styleUrls: ["./active-restrictions-features-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActiveRestrictionsFeaturesListComponent {
    protected readonly featuresData$ = this.localStore.selectByKey("featuresData");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    @Input() public set featuresData(value: ActiveRestrictionsLayerFeatureData[] | undefined) {
        this.localStore.patchState({ featuresData: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    constructor(private readonly localStore: LocalComponentStore<ActiveRestrictionsFeaturesListComponentState>) {
        localStore.setState({
            featuresData: [],
            isProcessing: false,
        });
    }

    protected tryGetHeightLimitInFeet(value: number | undefined): number | undefined {
        if (!value) {
            return value;
        }

        return FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(value, VerticalMeasureUnits.Feet, 0);
    }

    protected dismissFeaturesDataList(): void {
        this.localStore.patchState({ featuresData: [] });
    }
}
