import { FlightZoneRestriction } from "../models/flight-zone-shared.models";

export namespace FlightZoneRestrictionActions {
    export class GetRestrictionData {
        public static readonly type = "[Flight Zone Restriction] Get flight zone restriction data";

        constructor(public restrictionId: string) {}
    }

    export class DownloadPdf {
        public static readonly type = "[Flight Zone Restriction] Download PDF file with restriction";

        constructor(public restriction: FlightZoneRestriction, public file: Blob) {}
    }

    export class DownloadConfirmationOfApplicationSubmissionPdf {
        public static readonly type = "[Flight Zone Restriction] Download confirmation of application submission pdf";

        constructor(public flightZoneId: string, public fileName: string) {}
    }
}
