<dtm-ui-loader-container
    *ngrxLet="{
        capabilities: capabilities$,
        isProcessing: isProcessing$,
        capabilitiesError: capabilitiesError$,
        getUserDetailsError: getUserDetailsError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <dtm-ui-error
        *ngIf="vm.capabilitiesError || vm.getUserDetailsError; else userDetailTemplate"
        class="grid"
        (reload)="refreshData()"
        [errorMessage]="'dssAdminLibAdministration.userDetail.capabilitiesDownloadError' | transloco"
    ></dtm-ui-error>

    <ng-template #userDetailTemplate>
        <dss-admin-lib-user-detail
            class="grid"
            [capabilities]="vm.capabilities"
            [userDetails]="userDetails$ | ngrxPush"
            (cancel)="cancel()"
            (userDetailSave)="saveUser($event)"
        ></dss-admin-lib-user-detail>
    </ng-template>
</dtm-ui-loader-container>
