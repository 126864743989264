import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import {
    ApplicationManagementListItemStatus,
    ApplicationType,
    FlightZoneApiService,
    FlightZoneApplicationPurpose,
    FlightZoneApplicationStatus,
    FlightZoneError,
    FlightZoneManagementListFiltersCapabilities,
    FlightZonesManagementList,
    Institution,
    NotamList,
    RestrictionsList,
} from "@dtm-frontend/dss-shared-lib";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Feature } from "@turf/helpers/dist/js/lib/geojson";
import { EMPTY, Observable, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { FlightZoneListsActions } from "./flight-zone-lists.actions";

export interface FlightZoneListsStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    listFiltersCapabilities: FlightZoneManagementListFiltersCapabilities | undefined;
    unassignedApplicationsList: FlightZonesManagementList | undefined;
    assignedApplicationsList: FlightZonesManagementList | undefined;
    rejectedApplicationsList: FlightZonesManagementList | undefined;
    applicationsForApprovalList: FlightZonesManagementList | undefined;
    applicationsAssignedToUserList: FlightZonesManagementList | undefined;
    publishedRestrictionsList: RestrictionsList | undefined;
    endedRestrictionsList: RestrictionsList | undefined;
    acceptedApplicationsList: FlightZonesManagementList | undefined;
    applicationsWaitingForNotam: NotamList | undefined;
    applicationsWithNotam: NotamList | undefined;
    institutionsFilterOptions: Institution[] | undefined;
    zoneGeoJson: Feature | undefined;
}

const defaultState: FlightZoneListsStateModel = {
    error: undefined,
    isProcessing: false,
    listFiltersCapabilities: undefined,
    unassignedApplicationsList: undefined,
    assignedApplicationsList: undefined,
    rejectedApplicationsList: undefined,
    applicationsForApprovalList: undefined,
    acceptedApplicationsList: undefined,
    applicationsAssignedToUserList: undefined,
    publishedRestrictionsList: undefined,
    endedRestrictionsList: undefined,
    applicationsWaitingForNotam: undefined,
    applicationsWithNotam: undefined,
    institutionsFilterOptions: undefined,
    zoneGeoJson: undefined,
};

@State<FlightZoneListsStateModel>({
    name: "flightZoneLists",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneListsState {
    @Selector()
    public static error(state: FlightZoneListsStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static hasListDataRetrievalError(state: FlightZoneListsStateModel): boolean {
        return !!state.error;
    }

    @Selector()
    public static isProcessing(state: FlightZoneListsStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static listFiltersCapabilities(state: FlightZoneListsStateModel): FlightZoneManagementListFiltersCapabilities | undefined {
        return state.listFiltersCapabilities;
    }

    @Selector()
    public static unassignedApplicationsList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.unassignedApplicationsList;
    }

    @Selector()
    public static assignedApplicationsList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.assignedApplicationsList;
    }

    @Selector()
    public static applicationsAssignedToUserList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.applicationsAssignedToUserList;
    }

    @Selector()
    public static publishedRestrictionsList(state: FlightZoneListsStateModel): RestrictionsList | undefined {
        return state.publishedRestrictionsList;
    }

    @Selector()
    public static endedRestrictionsList(state: FlightZoneListsStateModel): RestrictionsList | undefined {
        return state.endedRestrictionsList;
    }

    @Selector()
    public static acceptedApplicationsList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.acceptedApplicationsList;
    }

    @Selector()
    public static rejectedApplicationsList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.rejectedApplicationsList;
    }

    @Selector()
    public static applicationsForApprovalList(state: FlightZoneListsStateModel): FlightZonesManagementList | undefined {
        return state.applicationsForApprovalList;
    }

    @Selector()
    public static applicationsWaitingForNotam(state: FlightZoneListsStateModel): NotamList | undefined {
        return state.applicationsWaitingForNotam;
    }

    @Selector()
    public static applicationsWithNotam(state: FlightZoneListsStateModel): NotamList | undefined {
        return state.applicationsWithNotam;
    }

    @Selector()
    public static institutionsFilterOptions(state: FlightZoneListsStateModel): Institution[] | undefined {
        return state.institutionsFilterOptions;
    }

    @Selector()
    public static zoneGeoJson(state: FlightZoneListsStateModel): Feature | undefined {
        return state.zoneGeoJson;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {
        if (flightZoneApi === undefined) {
            throw new Error("Initialize FlightZoneManagementModule with .forRoot()");
        }
    }

    @Action(FlightZoneListsActions.GetListFiltersCapabilities)
    public getListFiltersCapabilities(context: StateContext<FlightZoneListsStateModel>) {
        context.patchState({ isProcessing: true, listFiltersCapabilities: undefined });

        return this.flightZoneApi.getListFiltersCapabilities().pipe(
            tap(({ institutions, anspEmployees }) => {
                context.patchState({
                    listFiltersCapabilities: {
                        anspEmployees,
                        institutions,
                        flightZonePurposes: Object.values(FlightZoneApplicationPurpose),
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetUnassignedApplicationsList, { cancelUncompleted: true })
    public getUnassignedApplicationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetUnassignedApplicationsList
    ) {
        context.patchState({ isProcessing: true });

        return this.switchListFetchMethod(
            {
                ...action.filterParams,
                ...this.getParamsFromListItemStatus(action.filterParams.status, [], action.applicationType),
                reviewerId: null,
                locked: null,
                unassignedApplications: true,
            },
            action.applicationType
        ).pipe(
            tap((unassignedApplicationsList) => {
                context.patchState({
                    unassignedApplicationsList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetAssignedApplicationsList, { cancelUncompleted: true })
    public getAssignedApplicationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetAssignedApplicationsList
    ) {
        const restrictionModificationApplicationStatuses = [FlightZoneApplicationStatus.New, FlightZoneApplicationStatus.Assigned];
        const restrictionApplicationStatuses = [FlightZoneApplicationStatus.Assigned, FlightZoneApplicationStatus.SentToANSP];

        const defaultStatuses =
            action.applicationType === ApplicationType.RestrictionModificationApplication
                ? restrictionModificationApplicationStatuses
                : restrictionApplicationStatuses;

        context.patchState({ isProcessing: true });

        return this.switchListFetchMethod(
            {
                ...action.filterParams,
                ...this.getParamsFromListItemStatus(action.filterParams.status, defaultStatuses, action.applicationType),
                mySubordinatesApplications: true,
            },
            action.applicationType
        ).pipe(
            tap((assignedApplicationsList) => {
                context.patchState({
                    assignedApplicationsList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetApplicationsAssignedToUserList, { cancelUncompleted: true })
    public getApplicationsAssignedToUserList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetApplicationsAssignedToUserList
    ) {
        const restrictionModificationApplicationStatuses = [
            FlightZoneApplicationStatus.Assigned,
            FlightZoneApplicationStatus.Reviewed,
            FlightZoneApplicationStatus.New,
        ];
        const restrictionApplicationStatuses = [
            FlightZoneApplicationStatus.Assigned,
            FlightZoneApplicationStatus.Reviewed,
            FlightZoneApplicationStatus.SentToANSP,
        ];

        const defaultStatuses =
            action.applicationType === ApplicationType.RestrictionModificationApplication
                ? restrictionModificationApplicationStatuses
                : restrictionApplicationStatuses;

        context.patchState({ isProcessing: true });

        return this.switchListFetchMethod(
            {
                ...action.filterParams,
                ...this.getParamsFromListItemStatus(action.filterParams.status, defaultStatuses, action.applicationType),
                reviewerId: null,
                myApplication: true,
            },
            action.applicationType
        ).pipe(
            tap((applicationsAssignedToUserList) => {
                context.patchState({
                    applicationsAssignedToUserList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetPublishedRestrictionsList, { cancelUncompleted: true })
    public getPublishedRestrictionsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetPublishedRestrictionsList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getRestrictionsList({ ...action.filterParams, canceled: false, ended: false }).pipe(
            tap((publishedRestrictionsList) => {
                context.patchState({
                    publishedRestrictionsList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetEndedRestrictionsList, { cancelUncompleted: true })
    public getEndedRestrictionsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetEndedRestrictionsList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getRestrictionsList({ ...action.filterParams, ended: true }).pipe(
            tap((endedRestrictionsList) =>
                context.patchState({
                    endedRestrictionsList,
                    error: undefined,
                    isProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetAcceptedApplicationsList, { cancelUncompleted: true })
    public getAcceptedApplicationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetAcceptedApplicationsList
    ) {
        context.patchState({ isProcessing: true });

        return this.switchListFetchMethod(
            {
                ...action.filterParams,
                status: [FlightZoneApplicationStatus.Accepted],
            },
            action.applicationType
        ).pipe(
            tap((acceptedApplicationsList) => {
                context.patchState({
                    acceptedApplicationsList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetRejectedApplicationsList, { cancelUncompleted: true })
    public getRejectedApplicationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetRejectedApplicationsList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getApplicationsManagementPaginatedList({
                ...action.filterParams,
                status: [FlightZoneApplicationStatus.Rejected],
            })
            .pipe(
                tap((rejectedApplicationsList) => {
                    context.patchState({
                        rejectedApplicationsList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneListsActions.GetApplicationsForApprovalList, { cancelUncompleted: true })
    public getApplicationsForApprovalList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetApplicationsForApprovalList
    ) {
        const defaultStatuses = [FlightZoneApplicationStatus.Reviewed];

        context.patchState({ isProcessing: true });

        return this.switchListFetchMethod(
            {
                ...action.filterParams,
                ...this.getParamsFromListItemStatus(action.filterParams.status, defaultStatuses, action.applicationType),
                locked: null,
            },
            action.applicationType
        ).pipe(
            tap((applicationsForApprovalList) => {
                context.patchState({
                    applicationsForApprovalList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetApplicationsWaitingForNotam, { cancelUncompleted: true })
    public getApplicationsWaitingForNotam(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetApplicationsWaitingForNotam
    ) {
        const filters = {
            ...action.filterParams,
            published: false,
        };

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getNotamList(filters).pipe(
            tap((applicationsWaitingForNotam) => {
                context.patchState({
                    applicationsWaitingForNotam,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetApplicationsWithNotam, { cancelUncompleted: true })
    public getApplicationsWithNotam(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetApplicationsWithNotam
    ) {
        const filters = {
            ...action.filterParams,
            published: true,
        };

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getNotamList(filters).pipe(
            tap((applicationsWithNotam) => {
                context.patchState({
                    applicationsWithNotam,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetInstitutionsFilterOptions, { cancelUncompleted: true })
    public getInstitutionsFilterOptions(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetInstitutionsFilterOptions
    ) {
        return this.flightZoneApi.getInstitutionsFilterOptions(action.search).pipe(
            tap((institutionsFilterOptions) => {
                context.patchState({ institutionsFilterOptions });
            })
        );
    }

    @Action(FlightZoneListsActions.ClearFilterLists)
    public clearFilterLists(context: StateContext<FlightZoneListsStateModel>) {
        context.patchState({ institutionsFilterOptions: [] });
    }

    private getParamsFromListItemStatus(
        listItemStatus: ApplicationManagementListItemStatus,
        defaultApplicationStatuses: FlightZoneApplicationStatus[],
        applicationType: ApplicationType
    ): Params {
        switch (listItemStatus) {
            case ApplicationManagementListItemStatus.Analysis:
                return {
                    status: [
                        FlightZoneApplicationStatus.Assigned,
                        applicationType === ApplicationType.RestrictionModificationApplication
                            ? [FlightZoneApplicationStatus.New]
                            : [FlightZoneApplicationStatus.SentToANSP],
                    ],
                    locked: true,
                };
            case ApplicationManagementListItemStatus.ForApproval:
                return {
                    status: [FlightZoneApplicationStatus.Reviewed],
                    locked: true,
                };
            case ApplicationManagementListItemStatus.Correction:
                return {
                    status: defaultApplicationStatuses,
                    locked: false,
                };
            default:
                return {
                    status: defaultApplicationStatuses,
                    locked: null,
                };
        }
    }

    @Action(FlightZoneListsActions.GetApplicationGeoJson)
    public getApplicationGeoJson(context: StateContext<FlightZoneListsStateModel>, action: FlightZoneListsActions.GetApplicationGeoJson) {
        context.patchState({ isProcessing: true, zoneGeoJson: undefined });

        return this.flightZoneApi.getApplicationGeoJson(action.flightZone.id, action.applicationType).pipe(
            tap((zoneGeoJson) => {
                context.patchState({
                    zoneGeoJson: {
                        ...zoneGeoJson,
                        properties: {
                            ...zoneGeoJson.properties,
                            name: action.flightZone.title,
                        },
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetRestrictionGeoJson)
    public getRestrictionGeoJson(context: StateContext<FlightZoneListsStateModel>, action: FlightZoneListsActions.GetRestrictionGeoJson) {
        context.patchState({ isProcessing: true, zoneGeoJson: undefined });

        return this.flightZoneApi.getRestrictionGeoJson(action.restriction.id).pipe(
            tap((zoneGeoJson) => {
                context.patchState({
                    zoneGeoJson: {
                        ...zoneGeoJson,
                        properties: {
                            ...zoneGeoJson.properties,
                            name: action.restriction.zoneNumber,
                            description: action.restriction.title,
                        },
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    private switchListFetchMethod(filterParams: Params, applicationType: ApplicationType): Observable<FlightZonesManagementList> {
        return applicationType === ApplicationType.RestrictionModificationApplication
            ? this.flightZoneApi.getRestrictionsModificationsManagementList(filterParams)
            : this.flightZoneApi.getApplicationsManagementPaginatedList(filterParams);
    }
}
