import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { FlightZonesConsultationListItem } from "@dtm-frontend/dss-shared-lib";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ApplicationsForConsultationTableColumns } from "../../../models/flight-zone-consultation.models";

interface ApplicationsForConsultationListComponentState {
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    flightZoneApplications: FlightZonesConsultationListItem[];
    expandedElement: FlightZonesConsultationListItem | null;
    displayedColumns: ApplicationsForConsultationTableColumns;
    applicationsPage: Page | undefined;
}

@Component({
    selector: "dss-admin-lib-applications-for-consultation-list[flightZoneApplications][displayedColumns]",
    templateUrl: "./applications-for-consultation-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ApplicationsForConsultationListComponent {
    protected readonly flightZoneApplications$ = this.localStore.selectByKey("flightZoneApplications");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");

    @Input()
    public set flightZoneApplications(value: FlightZonesConsultationListItem[] | undefined) {
        this.localStore.patchState({ flightZoneApplications: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set hasDataRetrievalError(value: BooleanInput) {
        this.localStore.patchState({ hasDataRetrievalError: coerceBooleanProperty(value) });
    }

    @Input()
    public set displayedColumns(value: ApplicationsForConsultationTableColumns) {
        this.localStore.patchState({ displayedColumns: value });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly listRefresh = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<ApplicationsForConsultationListComponentState>) {
        this.localStore.setState({
            flightZoneApplications: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            displayedColumns: [],
            applicationsPage: undefined,
        });
    }

    protected toggleExpandableRow(row: FlightZonesConsultationListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");

        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }
}
