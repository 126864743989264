<form *ngrxLet="{ capabilities: capabilities$ } as vm" [formGroup]="userDetailForm" dtmUiInvalidFormScrollable (ngSubmit)="saveForm()">
    <mat-card>
        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.userDetail.firstNameLabel" | transloco }}</label>
            <input matInput type="text" formControlName="firstName" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: userDetailForm.controls.firstName }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.userDetail.lastNameLabel" | transloco }}</label>
            <input matInput type="text" formControlName="lastName" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: userDetailForm.controls.lastName }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <label>{{ "dssAdminLibAdministration.userDetail.emailLabel" | transloco }}</label>
            <input matInput type="email" formControlName="email" />
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: userDetailForm.controls.email }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>

        <dtm-ui-phone-number-field formControlName="phoneNumber">
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: userDetailForm.controls.phoneNumber }"
                ></ng-container>
            </div>
        </dtm-ui-phone-number-field>

        <dtm-ui-select-field formControlName="type">
            <label>{{ "dssAdminLibAdministration.userDetail.userTypeLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let userType of vm.capabilities?.types ?? []" [value]="userType">
                {{ userType }}
            </dtm-ui-select-option>

            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: userDetailForm.controls.type }"
                ></ng-container>
            </div>
        </dtm-ui-select-field>

        <section>
            <h2>{{ "dssAdminLibAdministration.userDetail.sahPermissionsLabel" | transloco }}</h2>

            <dtm-ui-checkbox-field formControlName="isAirOperationsCoordinator">
                <div class="checkbox-label">
                    <dtm-ui-icon name="nurse"></dtm-ui-icon>
                    <span>{{ "dssAdminLibAdministration.userDetail.airOperationsCoordinatorLabel" | transloco }}</span>
                </div>
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field formControlName="isFlightsDirector">
                <div class="checkbox-label">
                    <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
                    <span>{{ "dssAdminLibAdministration.userDetail.flightsDirectorLabel" | transloco }}</span>
                </div>
            </dtm-ui-checkbox-field>
        </section>

        <div class="buttons-container">
            <button type="button" class="button-secondary" (click)="cancel.emit()">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dssAdminLibAdministration.userDetail.cancelButtonLabel" | transloco }}
            </button>
            <button type="submit" class="button-primary">
                {{ "dssAdminLibAdministration.userDetail.saveButtonLabel" | transloco }}
            </button>
        </div>
    </mat-card>
</form>

<ng-template #formErrorsTemplate let-formControl="formControl">
    <ng-container *dtmUiFieldHasError="formControl; name: ['required', 'pattern']">
        {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
    </ng-container>

    <ng-container *dtmUiFieldHasError="formControl; name: 'email'">
        {{ "dssAdminLibAdministration.validationErrors.invalidEmailError" | transloco }}
    </ng-container>

    <ng-container *dtmUiFieldHasError="formControl; name: 'invalidNumber'">
        {{ "dssAdminLibAdministration.validationErrors.invalidPhoneNumberError" | transloco }}
    </ng-container>
</ng-template>
