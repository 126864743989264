import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, first } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { AnspTeamDayOff } from "../../models/administration.models";

interface TeamDaysOffComponentState {
    allDaysOff: AnspTeamDayOff[];
    filteredDaysOff: AnspTeamDayOff[];
    yearFilterValues: number[];
}

interface DaysOffForm {
    daysOff: FormArray<FormControl<Date | null>>;
}

const EARLIEST_YEAR_DIFF_FROM_NOW = -1;
const LATEST_YEAR_DIFF_FROM_NOW = 5;

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-team-days-off[daysOff]",
    templateUrl: "./team-days-off.component.html",
    styleUrls: ["./team-days-off.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TeamDaysOffComponent {
    protected readonly yearFilterControl = new FormControl<number | undefined>(undefined, { nonNullable: true });
    protected readonly daysOffForm = new FormGroup<DaysOffForm>({
        daysOff: new FormArray([this.getDayOffControl()]),
    });

    protected readonly filteredDaysOff$ = this.localStore.selectByKey("filteredDaysOff");
    protected readonly yearFilterValues$ = this.localStore.selectByKey("yearFilterValues");
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    @Input() public set daysOff(value: AnspTeamDayOff[] | undefined) {
        this.localStore.patchState({ allDaysOff: value ?? [] });

        this.yearFilterControl.updateValueAndValidity();
        this.daysOffForm.setControl("daysOff", new FormArray([this.getDayOffControl()]));
    }

    @Output() public readonly daysOffAdd = new EventEmitter<Date[]>();
    @Output() public readonly dayOffRemove = new EventEmitter<string>();

    constructor(
        private readonly localStore: LocalComponentStore<TeamDaysOffComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            allDaysOff: [],
            filteredDaysOff: [],
            yearFilterValues: [],
        });
        this.createYearFilterValues();
        this.updateFilteredDaysOffBasedOnYearFilterChanges();
    }

    protected addDayOffControl(): void {
        this.daysOffForm.controls.daysOff.push(this.getDayOffControl());
    }

    protected removeDayOffControl(index: number): void {
        this.daysOffForm.controls.daysOff.removeAt(index);
    }

    protected saveNewDaysOff(): void {
        this.daysOffForm.markAllAsTouched();

        if (this.daysOffForm.invalid) {
            return;
        }

        this.daysOffAdd.emit(this.daysOffForm.value.daysOff as Date[]);
    }

    protected tryRemoveDayOff(dayOffId: string, formattedDate: string): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dssAdminLibAdministration.teamDaysOff.dayOffRemoveDialogHeader"),
                    confirmationText: this.transloco.translate("dssAdminLibAdministration.teamDaysOff.dayOffRemoveDialogDescription", {
                        value: formattedDate,
                    }),
                    confirmButtonLabel: this.transloco.translate("dssAdminLibAdministration.teamDaysOff.confirmDayOffRemoveButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.dayOffRemove.emit(dayOffId);
            });
    }

    protected resetYearFilter(): void {
        this.yearFilterControl.reset();
    }

    private getDayOffControl(value?: Date) {
        return new FormControl<Date | null>(value ?? null, {
            validators: [Validators.required],
            asyncValidators: [this.uniqueDateValidator()],
        });
    }

    private uniqueDateValidator(): AsyncValidatorFn {
        return (control): Observable<ValidationErrors | null> =>
            this.localStore.selectByKey("allDaysOff").pipe(
                first(),
                map((savedDates) => {
                    const savedDateStrings = savedDates.map(({ date }) => date.toDateString());

                    if (savedDateStrings.includes(control.value?.toDateString())) {
                        return { notUnique: control.value };
                    }

                    return null;
                }),
                untilDestroyed(this)
            );
    }

    private updateFilteredDaysOffBasedOnYearFilterChanges(): void {
        this.yearFilterControl.valueChanges
            .pipe(withLatestFrom(this.localStore.selectByKey("allDaysOff")), untilDestroyed(this))
            .subscribe(([filterValue, allDaysOff]) => {
                const sortedDaysOff = [...allDaysOff].sort((left, right) => new Date(left.date).getTime() - new Date(right.date).getTime());
                const filteredDaysOff = filterValue
                    ? sortedDaysOff.filter((dayOff) => dayOff.date.getFullYear() === filterValue)
                    : sortedDaysOff;

                this.localStore.patchState({ filteredDaysOff });
            });
    }

    private createYearFilterValues(): void {
        const yearFilterValues: number[] = [];

        for (let yearValue = EARLIEST_YEAR_DIFF_FROM_NOW; yearValue <= LATEST_YEAR_DIFF_FROM_NOW; yearValue++) {
            yearFilterValues.push(this.getYearFilterValue(yearValue));
        }

        this.localStore.patchState({ yearFilterValues });
    }

    private getYearFilterValue(yearDiff: number): number {
        const currentDate = new Date();

        return new Date(currentDate.setFullYear(currentDate.getFullYear() + yearDiff)).getFullYear();
    }
}
