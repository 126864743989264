<mat-card>
    <div class="days-off-heading">{{ "dssAdminLibAdministration.teamDaysOff.daysOffLabel" | transloco }}</div>
    <mat-button-toggle-group [formControl]="yearFilterControl" class="year-filter-group" *ngrxLet="yearFilterValues$ as yearFilterValues">
        <mat-button-toggle *ngFor="let yearFilter of yearFilterValues" [value]="yearFilter">
            {{ yearFilter }}
        </mat-button-toggle>
        <button type="button" class="button-secondary" (click)="resetYearFilter()">
            {{ "dssAdminLibAdministration.teamDaysOff.clearFiltersLabel" | transloco }}
        </button>
    </mat-button-toggle-group>

    <mat-chip-list *ngrxLet="filteredDaysOff$ as filteredDaysOff">
        <mat-chip *ngFor="let dayOff of filteredDaysOff" class="day-off-chip">
            <ng-container *ngIf="dayOff.date | localizeDate : { dateStyle: 'short' } as localizedDate">
                <span class="chip-label">{{ localizedDate }}</span>
                <button matChipRemove type="button" class="button-icon" (click)="tryRemoveDayOff(dayOff.id, localizedDate)">
                    <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                </button>
            </ng-container>
        </mat-chip>
        <mat-chip *ngIf="!filteredDaysOff.length" class="day-off-chip">
            {{ "dssAdminLibAdministration.teamDaysOff.noResultsLabel" | transloco }}
        </mat-chip>
    </mat-chip-list>

    <form [formGroup]="daysOffForm">
        <div class="days-off" formArrayName="daysOff">
            <div class="days-off-heading">{{ "dssAdminLibAdministration.teamDaysOff.newDaysOffLabel" | transloco }}</div>
            <div class="day-off-control" *ngFor="let dayOff of daysOffForm.controls.daysOff.controls; index as dayOffIndex; last as last">
                <dtm-ui-date-field [isClearable]="false">
                    <input
                        matInput
                        [formControlName]="dayOffIndex"
                        [matDatepicker]="dayOffPicker"
                        *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                        [placeholder]="datePickerPlaceholder"
                    />
                    <mat-datepicker #dayOffPicker></mat-datepicker>
                    <div class="field-error" *dtmUiFieldHasError="dayOff; name: 'requiredTouched'">
                        {{ "dssAdminLibAdministration.validationErrors.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="dayOff; name: 'notUnique'; error as error">
                        {{ "dssAdminLibAdministration.teamDaysOff.notUniqueDateFieldError" | transloco : { value: error | localizeDate } }}
                    </div>
                </dtm-ui-date-field>
                <button
                    *ngIf="daysOffForm.controls.daysOff.controls.length > 1"
                    type="button"
                    class="button-icon remove-icon"
                    (click)="removeDayOffControl(dayOffIndex)"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
                <button *ngIf="last" type="button" class="button-icon" (click)="addDayOffControl()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                </button>
            </div>
        </div>

        <div class="buttons">
            <button type="button" class="button-secondary" routerLink="/admin/ansp-teams">
                {{ "dssAdminLibAdministration.teamDaysOff.cancelButtonLabel" | transloco }}
            </button>
            <button type="submit" class="button-primary" (click)="saveNewDaysOff()">
                {{ "dssAdminLibAdministration.teamDaysOff.saveButtonLabel" | transloco }}
            </button>
        </div>
    </form>
</mat-card>
