import { Injectable } from "@angular/core";
import {
    DssAssignmentNotificationType,
    DssCommentNotificationType,
    DssConsultationNotificationType,
    DssNotamNotificationType,
    DssRestrictionApplicationNotificationType,
    DssRestrictionModificationNotificationType,
    DssTeamNotificationType,
} from "../models/dss-notifications.models";

@Injectable({
    providedIn: "root",
})
export class DssNotificationsService {
    public getAllowedNotifications(): string[] {
        return ["DssEvent"];
    }

    public getDssRestrictionApplicationNotificationTypes(): DssRestrictionApplicationNotificationType[] {
        return Object.values(DssRestrictionApplicationNotificationType);
    }

    public getDssRestrictionModificationNotificationTypes(): DssRestrictionModificationNotificationType[] {
        return Object.values(DssRestrictionModificationNotificationType);
    }

    public getDssTeamNotificationTypes(): DssTeamNotificationType[] {
        return Object.values(DssTeamNotificationType);
    }

    public getDssCommentNotificationTypes(): DssCommentNotificationType[] {
        return Object.values(DssCommentNotificationType);
    }

    public getDssNotamNotificationTypes(): DssNotamNotificationType[] {
        return Object.values(DssNotamNotificationType);
    }

    public getDssAssignmentNotificationTypes(): DssAssignmentNotificationType[] {
        return Object.values(DssAssignmentNotificationType);
    }

    public getDssConsultationNotificationTypes(): DssConsultationNotificationType[] {
        return Object.values(DssConsultationNotificationType);
    }
}
