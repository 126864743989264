<ng-container *ngrxLet="{ anspCaseNumber: anspCaseNumber$, isExpanded: isExpanded$, isReadOnly: isReadOnly$ } as vm">
    <dtm-ui-presentation-tile-with-action
        [titleTile]="'dssAdminLibFlightZoneManagement.anspCaseNumber.header' | transloco"
        [class.read-only]="vm.isReadOnly"
    >
        <button
            *ngIf="!vm.isReadOnly && !vm.isExpanded"
            actionButtonSlot
            class="button-secondary"
            type="button"
            (click)="changeExpansionState(true)"
        >
            <ng-container *ngIf="vm.anspCaseNumber && vm.anspCaseNumber.length > 0; else emptyNumberTemplate">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.anspCaseNumber.editButtonLabel" | transloco }}
            </ng-container>

            <ng-template #emptyNumberTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dssAdminLibFlightZoneManagement.anspCaseNumber.addCaseNumberButtonLabel" | transloco }}
            </ng-template>
        </button>

        <button
            *ngIf="!vm.isReadOnly && vm.isExpanded"
            actionButtonSlot
            class="button-icon"
            type="button"
            (click)="changeExpansionState(false)"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>

        <div *ngIf="!vm.isReadOnly && vm.isExpanded" @slideIn>
            <dtm-ui-input-field dtmUiScrollToAfterInit>
                <label>{{ "dssAdminLibFlightZoneManagement.anspCaseNumber.caseNumberLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="anspCaseNumberControl" />

                <div class="field-error" *dtmUiFieldHasError="anspCaseNumberControl; name: 'maxlength'; error as error">
                    {{ "dssAdminLibFlightZoneManagement.maxLengthErrorHint" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>

            <div class="buttons-container">
                <button class="button-secondary" type="button" (click)="cancelChanges()">
                    {{ "dssAdminLibFlightZoneManagement.anspCaseNumber.cancelButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary"
                    type="submit"
                    (click)="submitCaseNumber()"
                    [disabled]="(anspCaseNumberControl.dirty && anspCaseNumberControl.invalid) || isProcessing$ | ngrxPush"
                >
                    <dtm-ui-icon name="save"></dtm-ui-icon>
                    {{ "dssAdminLibFlightZoneManagement.anspCaseNumber.saveButtonLabel" | transloco }}
                </button>
            </div>
        </div>

        <dtm-ui-label-value
            *ngIf="!vm.isExpanded && vm.anspCaseNumber"
            [label]="'dssAdminLibFlightZoneManagement.anspCaseNumber.caseNumberLabel' | transloco"
            [value]="vm.anspCaseNumber"
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngIf="vm.isReadOnly && !vm.anspCaseNumber"
            [label]="'dssAdminLibFlightZoneManagement.anspCaseNumber.caseNumberLabel' | transloco"
            [value]="'dssAdminLibFlightZoneManagement.applicationPreview.sectionNotFilledMessage' | transloco"
        ></dtm-ui-label-value>
    </dtm-ui-presentation-tile-with-action>
</ng-container>
