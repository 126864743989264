import { InjectionToken } from "@angular/core";

export interface FlightZoneEndpoints {
    getCapabilities: string;
    getFiltersCapabilities: string;
    getInstitutionsFilterOptions: string;
    restrictionApplications: string;
    getRestrictions: string;
    applicationsEntity: string;
    restrictionsEntity: string;
    restrictionApplicationsEntity: string;
    anspAcceptance: string;
    institutionAcceptance: string;
    institutionReAcceptance: string;
    institutionAcceptanceRequest: string;
    institutionRejection: string;
    anspRejection: string;
    applicationReview: string;
    updateApplicationNote: string;
    assignmentManagement: string;
    assignmentUpdate: string;
    editAssignmentEmployee: string;
    getNotams: string;
    getNotamsNotice: string;
    getNotamCapabilities: string;
    getAssignmentCapabilities: string;
    publishNotams: string;
    subordinates: string;
    commentsManagement: string;
    lockApplication: string;
    unlockApplication: string;
    notamLocationUpdate: string;
    restrictionApplicationsModification: string;
    cancelZoneDuration: string;
    getConsultants: string;
    applications: string;
    consultationsManagement: string;
    consultationReview: string;
    restrictionModificationEntity: string;
    anspCaseNumberModification: string;
    getRestrictionTypeCapabilities: string;
    changeRestrictionType: string;
    anspTeamsManagement: string;
    detailedDurationManagement: string;
    additionalReceiversManagement: string;
    airspaceClassificationCapabilities: string;
    airspaceClassificationManagement: string;
    downloadRestrictionPdf: string;
    downloadConfirmationOfApplicationSubmissionPdf: string;
    getApplicationGeoJson: string;
    getRestrictionGeoJson: string;
    getForbiddenDesignatorRange: string;
    setCustomDesignator: string;
    removeCustomDesignator: string;
}

export const FLIGHT_ZONE_ENDPOINTS = new InjectionToken<FlightZoneEndpoints>("Flight Zone endpoints");
