import { Injectable } from "@angular/core";

import { Store } from "@ngxs/store";
import { AdministrationActions } from "../state/administration.actions";

@Injectable({
    providedIn: "root",
})
export class AdministrationCapabilitiesResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store.dispatch(new AdministrationActions.GetCapabilities());
    }
}
