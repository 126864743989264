<ng-container *ngrxLet="consultations$ as consultations">
    <ng-container *ngrxLet="expandedConsultation$ as expandedConsultation">
        <dtm-ui-presentation-tile-with-action
            *ngIf="consultations.length"
            [titleTile]="'dssSharedLibFlightZone.consultationsReview.header' | transloco"
        >
            <div
                *ngFor="let consultation of consultations"
                class="consultation"
                [class.approved]="consultation.status === FlightZoneApplicationReviewStatus.Approve"
                [class.disapproved]="consultation.status === FlightZoneApplicationReviewStatus.Disapprove"
            >
                <div class="consultant">{{ consultation.consultant.firstName }} {{ consultation.consultant.lastName }}</div>

                <ng-container *ngIf="consultation.comment">
                    <p class="comment">
                        {{ consultation.comment | invoke : tryTruncateComment : consultation === expandedConsultation }}
                    </p>
                    <div class="expand-button-container">
                        <button
                            type="button"
                            *ngIf="consultation.comment.length > MAX_TRUNCATED_COMMENT_LENGTH"
                            (click)="toggleConsultationComment(consultation)"
                        >
                            {{
                                "dssSharedLibFlightZone.consultationsReview.expandButtonLabel"
                                    | transloco : { value: consultation === expandedConsultation }
                            }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </dtm-ui-presentation-tile-with-action>
    </ng-container>
</ng-container>
