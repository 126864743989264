import {
    FlightZoneAnalysisStatus,
    FlightZoneApplicationBasicData,
    FlightZoneApplicationPurpose,
    FlightZoneEditorType,
    HeightReferences,
    HorizontalMeasureUnits,
    RestrictionArea,
    RestrictionExclusions,
    RestrictionType,
    VerticalMeasureUnits,
} from "../../models/flight-zone-shared.models";

export const BASIC_DATA: FlightZoneApplicationBasicData = {
    caseNumber: "ABC/123/456/789",
    title: "Rescue operation for cat on the tree",
    startTime: new Date("2022-02-17T03:24Z"),
    endTime: new Date("2022-11-27T11:00Z"),
    flightZonePurpose: FlightZoneApplicationPurpose.ForbiddingUAVFlights,
    hasStateSecurityRestriction: true,
    isPreTacticalApproval: true,
};

export const CYLINDER_GEOMETRY: RestrictionArea = {
    areaValues: {
        centerPointLongitude: 21.178225,
        centerPointLatitude: 52.196217,
        radius: 20,
        lowerHeight: 0,
        upperHeight: 1200,
    },
    areaUnits: {
        radiusUnit: HorizontalMeasureUnits.NauticalMiles,
        lowerHeightUnit: VerticalMeasureUnits.Feet,
        lowerHeightReference: HeightReferences.AboveGroundLevel,
        upperHeightUnit: VerticalMeasureUnits.Meters,
        upperHeightReference: HeightReferences.AboveMeanSeaLevel,
    },
    editorType: FlightZoneEditorType.Cylinder,
};

export const PRISM_GEOMETRY: RestrictionArea = {
    areaValues: {
        positions: [
            { longitude: 21.178225, latitude: 52.196217 },
            { longitude: 25.0, latitude: 60.0 },
            { longitude: -30.10203, latitude: -70.10203 },
        ],
        lowerHeight: 0,
        upperHeight: 1200,
    },
    areaUnits: {
        lowerHeightUnit: VerticalMeasureUnits.Feet,
        lowerHeightReference: HeightReferences.AboveGroundLevel,
        upperHeightUnit: VerticalMeasureUnits.Meters,
        upperHeightReference: HeightReferences.AboveMeanSeaLevel,
    },
    editorType: FlightZoneEditorType.Prism,
};

export const UAV_EXCLUSIONS: RestrictionExclusions = {
    isManagedByGovernment: true,
    agreedExclusion: {
        phoneNumber: {
            countryCode: "PL",
            number: "512345678",
        },
        email: "policja@gov.pl",
    },
    areOwnedFlights: true,
    uavMtomExclusion: {
        from: 0.9,
        to: 12,
    },
    courses: [
        { codeName: "COURSE_1", title: "King BSP" },
        { codeName: "COURSE_2", title: "Uav for dummies" },
    ],
    operatorAuthorizations: ["A1", "A3"],
    otherExclusion: "every third tuesday and every wednesday after the full moon",
    purpose: FlightZoneApplicationPurpose.ForbiddingUAVFlights,
};

export const EMPTY_EXCLUSIONS: RestrictionExclusions = {
    purpose: FlightZoneApplicationPurpose.ForbiddingUAVFlights,
    isManagedByGovernment: false,
    agreedExclusion: null,
    areOwnedFlights: false,
    uavMtomExclusion: null,
    courses: [],
    operatorAuthorizations: [],
    otherExclusion: "",
};

export const ANALYSIS_STATUS: FlightZoneAnalysisStatus = {
    suggestedRestrictionType: RestrictionType.DRAR,
    isRestrictionTypeAssociatedWithNotam: false,
    id: "2389074623874623564",
    version: 1,
    isLocked: true,
    altitudesConvertedToAMSLFeet: {
        upperAltitude: 2000,
        lowerAltitude: 500,
    },
};
