<div class="grid">
    <ng-container *ngrxLet="isAnspEmployeesFilterEnabled$ as isAnspEmployeesFilterEnabled">
        <dss-shared-lib-applications-list-filters
            *ngrxLet="listFiltersCapabilities$ as listFiltersCapabilities"
            [initialFilters]="initialFilters"
            [flightZonePurposes]="listFiltersCapabilities?.flightZonePurposes"
            [anspEmployees]="isAnspEmployeesFilterEnabled ? listFiltersCapabilities?.anspEmployees : []"
            [institutions]="institutionsFilterOptions$ | ngrxPush"
            [statuses]="statuses$ | ngrxPush"
            [hasSupervisorPermissions]="hasSupervisorPermissions$ | ngrxPush"
            (filtersChange)="applyFilters($event)"
            (institutionSearchTextChange)="findInstitutionByText($event)"
            (filtersListsClear)="clearFilterLists()"
        ></dss-shared-lib-applications-list-filters>
    </ng-container>

    <div class="card-box">
        <h4 class="tabs-header">
            <dtm-ui-icon name="treasure-map"></dtm-ui-icon>
            {{ "dssAdminLibFlightZoneManagement.submittedLists.tabsHeader" | transloco }}
        </h4>
        <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="dtm-tabs" color="accent">
            <a
                mat-tab-link
                *ngFor="let link of submittedListsLinks$ | ngrxPush"
                [routerLink]="link.path"
                queryParamsHandling="preserve"
                [state]="{ skipLoading: true }"
                routerLinkActive
                #routerLinkActive="routerLinkActive"
                [active]="routerLinkActive.isActive"
            >
                {{ link.label | transloco }} ({{ link.totalCount$ | ngrxPush }})
            </a>
        </nav>

        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>
    </div>
</div>
