<ng-container *ngrxLet="error$; let error">
    <ng-container [ngSwitch]="error?.type">
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetApplication" class="error">
            {{ "dssAdminLibFlightZoneManagement.cannotGetDataErrorMessage" | transloco }}
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noErrorTemplate>
    <div class="application-preview" *ngIf="flightZoneApplicationData$ | ngrxPush; let flightZoneApplicationData">
        <ng-container
            *ngrxLet="{
                isProcessing: isProcessing$,
                isChatEnabled: isChatEnabled$,
                enableActions: enableActions$,
                hasAnspSupervisorRole: hasAnspSupervisorRole$,
                isEditDisabled: isEditDisabled$,
                isCustomDesignatorSet: isCustomDesignatorSet$
            } as vm"
        >
            <div class="card-box" *ngIf="flightZoneApplicationData.analysisStatus">
                <div class="overflow-container scroll-shadows">
                    <dss-shared-lib-application-preview-header
                        [previewTitle]="'dssAdminLibFlightZoneManagement.applicationPreview.title' | transloco"
                        [isApplicationLocked]="flightZoneApplicationData.analysisStatus.isLocked"
                        [areEditActionsEnabled]="!vm.isEditDisabled"
                        [applicationStatus]="flightZoneApplicationData.status"
                        [applicationType]="applicationType$ | ngrxPush"
                        [hasSupervisorPermissions]="vm.hasAnspSupervisorRole"
                        (changeLockedStatus)="toggleLockedStatus(flightZoneApplicationData.flightZoneId!, $event)"
                        (enableChat)="enableChat()"
                        (assignConsultant)="assignConsultant(flightZoneApplicationData.flightZoneId!)"
                        (changeTeam)="changeTeam(flightZoneApplicationData.flightZoneId!)"
                        (cannotChangeTeam)="openTeamChangeDisabledDialog(flightZoneApplicationData.flightZoneId!)"
                        (confirmationOfApplicationSubmissionPdfDownload)="
                            downloadConfirmationOfApplicationSubmissionPdf(flightZoneApplicationData)
                        "
                    >
                    </dss-shared-lib-application-preview-header>
                    <dss-admin-lib-review-status-badge
                        *ngIf="flightZoneApplicationData.analysisStatus.assignment"
                        [assignment]="flightZoneApplicationData.analysisStatus.assignment"
                    ></dss-admin-lib-review-status-badge>
                    <dss-shared-lib-consultations-review
                        [consultations]="flightZoneApplicationData.consultations"
                    ></dss-shared-lib-consultations-review>
                    <dss-shared-lib-application-summary
                        [basicDataForm]="flightZoneApplicationData.basicDataForm"
                        [restrictionAreaGeometry]="flightZoneApplicationData.restrictionAreaGeometry"
                        [restrictionExclusions]="flightZoneApplicationData.restrictionExclusions"
                        [analysisStatus]="flightZoneApplicationData.analysisStatus"
                        [restrictionModificationData]="flightZoneApplicationData.restrictionModification"
                        [capabilities]="capabilities$ | ngrxPush"
                        [isEditSuggestedRestrictionTypeButtonVisible]="!vm.isEditDisabled"
                        [isSetCustomDesignatorButtonVisible]="!vm.isEditDisabled && !vm.isCustomDesignatorSet"
                        [isRemoveCustomDesignatorButtonVisible]="!vm.isEditDisabled && vm.isCustomDesignatorSet"
                        (editSuggestedRestrictionType)="editSuggestedRestrictionType(flightZoneApplicationData)"
                        (customDesignatorSet)="setCustomDesignator(flightZoneApplicationData.analysisStatus.suggestedRestrictionType)"
                        (customDesignatorRemove)="removeCustomDesignator()"
                    >
                    </dss-shared-lib-application-summary>
                    <dss-shared-lib-application-note
                        [note]="flightZoneApplicationData.note"
                        [isReadOnly]="vm.isEditDisabled"
                        [isProcessing]="vm.isProcessing"
                        (dialogOpened)="updateApplicationNote($event, flightZoneApplicationData.flightZoneId!)"
                    ></dss-shared-lib-application-note>
                    <dss-admin-lib-additional-receivers
                        [isReadOnly]="vm.isEditDisabled"
                        [emails]="flightZoneApplicationData.additionalReceivers"
                        (receiversEmailsChange)="receiversEmailsChange($event)"
                    ></dss-admin-lib-additional-receivers>
                    <dss-admin-lib-detailed-duration
                        [isReadOnly]="vm.isEditDisabled"
                        [detailedDuration]="flightZoneApplicationData.detailedDuration"
                        (detailedDurationChange)="detailedDurationChange($event)"
                    ></dss-admin-lib-detailed-duration>
                    <dss-admin-lib-ansp-case-number
                        [isReadOnly]="vm.isEditDisabled"
                        [anspCaseNumber]="flightZoneApplicationData.anspCaseNumber"
                        [isProcessing]="vm.isProcessing"
                        (anspCaseNumberChange)="anspCaseNumberChange($event)"
                    ></dss-admin-lib-ansp-case-number>
                    <dss-admin-lib-airspace-classification
                        *ngIf="flightZoneApplicationData.analysisStatus.isRestrictionTypeAssociatedWithNotam"
                        [isReadOnly]="vm.isEditDisabled"
                        [isProcessing]="vm.isProcessing"
                        [airspaceClassification]="flightZoneApplicationData.airspaceClassification"
                        [classifications]="availableAirspaceClassifications$ | ngrxPush"
                        (availableClassificationsFetch)="fetchAvailableClassifications()"
                        #airspaceClassificationComponent
                        (airspaceClassificationChange)="airspaceClassificationChange($event, airspaceClassificationComponent)"
                    ></dss-admin-lib-airspace-classification>
                    <dss-admin-lib-notam-location-form
                        *ngIf="flightZoneApplicationData.analysisStatus.isRestrictionTypeAssociatedWithNotam"
                        [isReadOnly]="vm.isEditDisabled"
                        [hasLocationMissingError]="hasLocationMissingError$ | ngrxPush"
                        [location]="flightZoneApplicationData.location"
                        [isProcessing]="vm.isProcessing"
                        (notamLocationChange)="notamLocationChange(flightZoneApplicationData.flightZoneId!, $event)"
                    ></dss-admin-lib-notam-location-form>
                </div>
                <div class="actions" *ngrxLet="flightZoneApplicationData | invoke : isApplicationAcceptanceAllowed as isAcceptanceAllowed">
                    <ng-container *ngIf="vm.enableActions">
                        <ng-container *ngIf="vm.hasAnspSupervisorRole; else anspWorkerActionsTemplate">
                            <dss-admin-lib-ansp-supervisor-accept-button
                                class="full-width-button"
                                [isDisabled]="!isAcceptanceAllowed"
                                [matTooltipDisabled]="isAcceptanceAllowed"
                                [matTooltip]="'dssAdminLibFlightZoneManagement.applicationPreview.acceptButtonDisabledLabel' | transloco"
                                matTooltipPosition="above"
                                [assignment]="flightZoneApplicationData.analysisStatus.assignment"
                                (acceptApplication)="acceptApplication(flightZoneApplicationData)"
                                (rejectApplication)="rejectApplication(flightZoneApplicationData.flightZoneId!)"
                            ></dss-admin-lib-ansp-supervisor-accept-button>
                            <dss-admin-lib-ansp-supervisor-reject-button
                                class="full-width-button"
                                [assignment]="flightZoneApplicationData.analysisStatus.assignment"
                                (rejectApplication)="rejectApplication(flightZoneApplicationData.flightZoneId!)"
                                (rejectReview)="rejectReview(flightZoneApplicationData)"
                            >
                            </dss-admin-lib-ansp-supervisor-reject-button>
                        </ng-container>
                        <ng-template #anspWorkerActionsTemplate>
                            <ng-container *ngIf="flightZoneApplicationData?.analysisStatus?.assignment as assignment">
                                <button
                                    class="button-primary full-width-button"
                                    [matTooltip]="
                                        'dssAdminLibFlightZoneManagement.applicationPreview.acceptButtonDisabledLabel' | transloco
                                    "
                                    matTooltipPosition="above"
                                    [matTooltipDisabled]="isAcceptanceAllowed"
                                    [disabled]="vm.isProcessing || !isAcceptanceAllowed"
                                    type="button"
                                    (click)="approveApplication(assignment.id)"
                                >
                                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.approveButtonLabel" | transloco }}
                                </button>

                                <button
                                    class="button-warn full-width-button"
                                    [disabled]="vm.isProcessing"
                                    type="button"
                                    (click)="disapproveApplication(assignment.id, flightZoneApplicationData.flightZoneId!)"
                                >
                                    {{ "dssAdminLibFlightZoneManagement.applicationPreview.disapproveButtonLabel" | transloco }}
                                </button>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                    <button
                        type="button"
                        *ngIf="flightZoneApplicationData.analysisStatus.notamNumber"
                        class="button-primary full-width-button"
                        (click)="openNotamPreview(flightZoneApplicationData.notamPreviewId!)"
                    >
                        {{ "dssAdminLibFlightZoneManagement.applicationPreview.openNotamButtonLabel" | transloco }}
                    </button>
                    <button
                        *ngIf="vm.enableActions && vm.hasAnspSupervisorRole && !flightZoneApplicationData.analysisStatus.assignment"
                        class="button-secondary"
                        (click)="addAssignee(flightZoneApplicationData.flightZoneId!)"
                        [disabled]="vm.isProcessing"
                        type="button"
                    >
                        {{ "dssAdminLibFlightZoneManagement.applicationPreview.changeAssigneeButtonLabel" | transloco }}
                    </button>
                    <button
                        class="button-secondary"
                        [class.full-width-button]="flightZoneApplicationData.analysisStatus.assignment"
                        (click)="zoomToGeometry()"
                        type="button"
                    >
                        <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                        {{ "dssAdminLibFlightZoneManagement.applicationPreview.zoomToZoneButtonLabel" | transloco }}
                    </button>
                </div>
            </div>
            <dss-shared-lib-map-container [shouldShowActiveRestrictionsLayer]="true"></dss-shared-lib-map-container>

            <ng-container *ngrxLet="chatMessages$; let chatMessages">
                <dss-shared-lib-application-changes-chat
                    *ngIf="flightZoneApplicationData.flightZoneId && vm.isChatEnabled"
                    [messages]="chatMessages"
                    [userId]="userId$ | ngrxPush"
                    [isProcessing]="vm.isProcessing"
                    [isReadOnly]="vm.isEditDisabled"
                    #chatComponent
                    (messageSubmit)="submitMessage(flightZoneApplicationData.flightZoneId, $event, chatComponent)"
                ></dss-shared-lib-application-changes-chat>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
