<dss-admin-lib-applications-for-consultation-list
    *ngrxLet="consultationsInProgressList$ as consultationsInProgressList"
    [displayedColumns]="displayedColumns"
    [flightZoneApplications]="consultationsInProgressList?.content"
    [page]="consultationsInProgressList?.page"
    [isProcessing]="isProcessing$ | ngrxPush"
    [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
    (pageChange)="changePage($event)"
    (listRefresh)="refreshList()"
></dss-admin-lib-applications-for-consultation-list>
