import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore, ObjectUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { debounceTime, distinctUntilChanged, filter, merge, of } from "rxjs";
import { first, map } from "rxjs/operators";
import {
    ApplicationAssignee,
    FlightZoneApplicationPurpose,
    FlightZoneListFilters,
    Institution,
} from "../../models/flight-zone-shared.models";
import { ListStatusTranslationService } from "../../services/list-status-translation.service";

interface ApplicationsManagementListFiltersComponentState {
    flightZonePurposes: FlightZoneApplicationPurpose[];
    institutions: Institution[];
    anspEmployees: ApplicationAssignee[];
    statuses: string[];
    appliedFiltersCount: number;
    hasSupervisorPermissions: boolean;
    searchInputPlaceholder: string | undefined;
    labelsValueMap: Record<string, string> | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "search",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.searchChipLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "sendDate",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.sendDateLabel",
        type: FilterType.Date,
    },
    {
        key: "startDate",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.startDateLabel",
        type: FilterType.Date,
    },
    {
        key: "endDate",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.endDateLabel",
        type: FilterType.Date,
    },
    {
        key: "applicationPurpose",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.applicationPurposeLabel",
        filterValueLabel: "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel",
    },
    {
        key: "institutionId",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.institutionLabel",
        type: FilterType.ValueMap,
    },
    {
        key: "author",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.authorLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "reviewerId",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.reviewerLabel",
        type: FilterType.ValueMap,
    },
    {
        key: "status",
        filterLabel: "dssSharedLibFlightZone.applicationListFilters.statusLabel",
        type: FilterType.TranslatedValueMap,
    },
];

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-applications-list-filters",
    templateUrl: "./applications-list-filters.component.html",
    styleUrls: ["./applications-list-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationsListFiltersComponent implements OnInit, OnDestroy {
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly filtersFormGroup = new FormGroup({
        search: new FormControl<string>(""),
        sendDate: new FormControl<Date | null>(null),
        startDate: new FormControl<Date | null>(null),
        endDate: new FormControl<Date | null>(null),
        applicationPurpose: new FormControl<FlightZoneApplicationPurpose | null>({ value: null, disabled: true }),
        institutionId: new FormControl<string>({ value: "", disabled: true }),
        author: new FormControl<string>(""),
        reviewerId: new FormControl<string | null>({ value: null, disabled: true }),
        status: new FormControl<string>({ value: "", disabled: true }),
    });

    protected readonly flightZonePurposes$ = this.localStore.selectByKey("flightZonePurposes");
    protected readonly institutions$ = this.localStore.selectByKey("institutions");
    protected readonly anspEmployees$ = this.localStore.selectByKey("anspEmployees");
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly appliedFiltersCount$ = this.localStore.selectByKey("appliedFiltersCount");
    protected readonly searchInputPlaceholder$ = this.localStore.selectByKey("searchInputPlaceholder");
    protected readonly labelsValueMap$ = this.localStore.selectByKey("labelsValueMap");

    @Input()
    public set initialFilters(value: FlightZoneListFilters | undefined) {
        if (!value) {
            return;
        }

        this.filtersFormGroup.patchValue(value, { emitEvent: false });
        this.updateAppliedFiltersCount();
    }

    @Input()
    public set flightZonePurposes(value: FlightZoneApplicationPurpose[] | undefined) {
        this.localStore.patchState({ flightZonePurposes: value ?? [] });

        if (value?.length) {
            this.filtersFormGroup.controls.applicationPurpose.enable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.applicationPurpose.disable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set institutions(value: Institution[] | undefined) {
        this.localStore.patchState({ institutions: value ?? [] });

        if (this.institutionSearchTextChange.observed) {
            this.filtersFormGroup.controls.institutionId.enable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.institutionId.disable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set anspEmployees(value: ApplicationAssignee[] | undefined) {
        this.localStore.patchState({ anspEmployees: value ?? [] });

        if (value?.length) {
            this.filtersFormGroup.controls.reviewerId.enable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.reviewerId.disable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set statuses(value: string[] | undefined) {
        this.localStore.patchState({ statuses: value ?? [] });

        if (value?.length) {
            this.filtersFormGroup.controls.status.enable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.status.disable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set hasSupervisorPermissions(value: boolean | undefined) {
        this.localStore.patchState({ hasSupervisorPermissions: !!value });
    }

    @Input()
    public set shouldDisableAuthorFilter(value: boolean) {
        if (value) {
            this.filtersFormGroup.controls.author.disable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.author.enable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set shouldDisableSendDateFilter(value: boolean) {
        if (value) {
            this.filtersFormGroup.controls.sendDate.disable({ emitEvent: false });
        } else {
            this.filtersFormGroup.controls.sendDate.enable({ emitEvent: false });
        }

        this.updateAppliedFiltersCount();
    }

    @Input()
    public set searchInputPlaceholder(value: string | undefined) {
        this.localStore.patchState({ searchInputPlaceholder: value });
    }

    @Output() public readonly filtersChange = new EventEmitter<FlightZoneListFilters>();
    @Output() public readonly institutionSearchTextChange = new EventEmitter<string>();
    @Output() public readonly filtersListsClear = new EventEmitter<void>();

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationsManagementListFiltersComponentState>,
        private readonly translocoHelper: TranslationHelperService,
        private readonly statusTranslationService: ListStatusTranslationService
    ) {
        this.localStore.setState({
            flightZonePurposes: [],
            institutions: [],
            anspEmployees: [],
            statuses: [],
            appliedFiltersCount: 0,
            hasSupervisorPermissions: false,
            searchInputPlaceholder: undefined,
            labelsValueMap: undefined,
        });
    }

    public ngOnInit(): void {
        this.onFormValueChanges();
        this.updateLabelsValueMap();
    }

    public ngOnDestroy(): void {
        // NOTE: Need to clear the lists in store so that the updateLabelsValueMap method gets a clean list on the first emit
        this.filtersListsClear.emit();
    }

    protected clearFilters(): void {
        this.filtersFormGroup.reset();
    }

    protected getStatusTranslationsMap(): Record<string, string> {
        const hasSupervisorPermissions = this.localStore.selectSnapshotByKey("hasSupervisorPermissions");

        return this.statusTranslationService.getStatusTranslationsMap(hasSupervisorPermissions);
    }

    private onFormValueChanges(): void {
        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged<FlightZoneListFilters>(equal), untilDestroyed(this))
            .subscribe((value) => {
                if (this.filtersFormGroup.invalid) {
                    return;
                }

                this.updateAppliedFiltersCount();
                this.filtersChange.emit(this.nullifyEmptyFilters(value));
            });
    }

    private updateAppliedFiltersCount(): void {
        const values = Object.values(this.filtersFormGroup.value);
        const appliedFiltersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ appliedFiltersCount });
    }

    private nullifyEmptyFilters(filters: FlightZoneListFilters): FlightZoneListFilters {
        return JSON.parse(JSON.stringify(filters), (key, value) => {
            if ((Array.isArray(value) || typeof value === "string") && value.length < 1) {
                return null;
            }

            return value;
        });
    }

    private updateLabelsValueMap(): void {
        merge(
            this.institutions$.pipe(
                // NOTE: Autocomplete filter narrows the list of institutions, which may remove necessary elements from the LabelsValueMap.
                // To prevent this we only take the first emit of the list.
                filter((institutions) => !!institutions.length),
                first(),
                map((institutions) => ObjectUtils.convertToMap(institutions, "id", (el) => el.name))
            ),
            this.anspEmployees$.pipe(
                map((anspEmployees) => ObjectUtils.convertToMap(anspEmployees, "id", (el) => `${el.firstName} ${el.lastName}`))
            ),
            of(this.getStatusTranslationsMap())
        )
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.localStore.patchState((state) => ({
                    labelsValueMap: {
                        ...state.labelsValueMap,
                        ...value,
                    },
                }));
            });
    }
}
