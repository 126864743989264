import { Injectable } from "@angular/core";

import { Store } from "@ngxs/store";
import { AdministrationActions } from "../state/administration.actions";

@Injectable({
    providedIn: "root",
})
export class AdministrationClearInstitutionDetailsResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store.dispatch(new AdministrationActions.ClearInstitutionDetails());
    }
}
