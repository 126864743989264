import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UserDetails, UsersListItem, UsersListTableColumns } from "../../models/administration.models";

interface UsersListComponentState {
    users: UsersListItem[];
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    displayedColumns: UsersListTableColumns;
    page: Page | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-users-list[users][displayedColumns]",
    templateUrl: "./users-list.component.html",
    styleUrls: ["users-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UsersListComponent {
    protected readonly users$ = this.localStore.selectByKey("users");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");
    protected readonly page$ = this.localStore.selectByKey("page");

    @Input()
    public set users(value: UsersListItem[] | undefined) {
        this.localStore.patchState({ users: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set hasDataRetrievalError(value: BooleanInput) {
        this.localStore.patchState({ hasDataRetrievalError: coerceBooleanProperty(value) });
    }

    @Input()
    public set displayedColumns(value: UsersListTableColumns) {
        this.localStore.patchState({ displayedColumns: value });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ page: value });
    }

    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly listRefresh = new EventEmitter<void>();
    @Output() public readonly userDelete = new EventEmitter<UserDetails>();

    constructor(
        private readonly localStore: LocalComponentStore<UsersListComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            users: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            displayedColumns: [],
            page: undefined,
        });
    }

    protected tryDeleteUser(userDetails: UserDetails): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dssAdminLibAdministration.usersList.deleteUserDialogTitle", {
                        userName: `${userDetails.firstName} ${userDetails.lastName}`,
                    }),
                    confirmationText: this.translocoService.translate(
                        "dssAdminLibAdministration.usersList.deleteUserDialogConfirmationText"
                    ),
                    declineButtonLabel: this.translocoService.translate("dssAdminLibAdministration.usersList.cancelLabel"),
                    confirmButtonLabel: this.translocoService.translate("dssAdminLibAdministration.usersList.confirmUserRemovalLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.userDelete.emit(userDetails));
    }
}
