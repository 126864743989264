<ng-container *ngrxLet="isProcessing$; let isProcessing">
    <button
        *ngrxLet="assignment$; let assignment"
        class="button-warn"
        [disabled]="isProcessing"
        type="button"
        (click)="reject(assignment?.reviewStatus)"
    >
        {{
            (assignment?.reviewStatus
                ? "dssAdminLibFlightZoneManagement.applicationPreview.rejectReviewButtonLabel"
                : "dssAdminLibFlightZoneManagement.applicationPreview.rejectButtonLabel"
            ) | transloco
        }}
    </button>
</ng-container>
