import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, NavigationEnd, Params, Router } from "@angular/router";
import {
    ApplicationManagementListItemStatus,
    ApplicationType,
    DssUserRoles,
    FlightZoneListFilters,
    FlightZonesManagementList,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, distinctUntilChanged, filter, startWith, switchMap } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { FlightZoneListsActions } from "../../state/flight-zone-lists.actions";
import { FlightZoneListsState, FlightZoneListsStateModel } from "../../state/flight-zone-lists.state";

interface SubmittedListLink {
    path: string;
    label: string;
    dispatchAction: new (filterParams: Params, applicationType: ApplicationType) => unknown;
    totalCount$: Observable<number>;
}

@UntilDestroy()
@Component({
    selector: "dss-admin-lib-submitted-lists-container",
    templateUrl: "./submitted-lists-container.component.html",
    styleUrls: ["./submitted-lists-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmittedListsContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly listFiltersCapabilities$ = this.store.select(FlightZoneListsState.listFiltersCapabilities);
    protected readonly institutionsFilterOptions$ = this.store.select(FlightZoneListsState.institutionsFilterOptions);
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.AnspSupervisor)));
    protected readonly submittedListsLinks$ = this.createSubmittedListsLinksObservable();
    protected readonly statuses$: Observable<ApplicationManagementListItemStatus[] | undefined> = this.getChildRouteDataObservable().pipe(
        map((data) => data.statusFilterOptions)
    );
    protected readonly isAnspEmployeesFilterEnabled$: Observable<boolean> = this.getChildRouteDataObservable().pipe(
        map((data) => !!data.isAnspEmployeesFilterEnabled)
    );

    private get applicationType(): ApplicationType {
        return this.route.snapshot.data.applicationType;
    }

    constructor(private readonly store: Store, private readonly route: ActivatedRoute, private readonly router: Router) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
        this.store.dispatch(new FlightZoneListsActions.GetInstitutionsFilterOptions());
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected findInstitutionByText(searchValue?: string): void {
        this.store.dispatch(new FlightZoneListsActions.GetInstitutionsFilterOptions(searchValue));
    }

    protected clearFilterLists(): void {
        this.store.dispatch(new FlightZoneListsActions.ClearFilterLists());
    }

    private getChildRouteDataObservable(): Observable<Data> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(this.route.firstChild?.data),
            switchMap(() => this.route.firstChild?.data ?? EMPTY)
        );
    }

    private createSubmittedListsLinksObservable(): Observable<SubmittedListLink[]> {
        return this.hasSupervisorPermissions$.pipe(
            map((isSupervisor) => {
                if (isSupervisor) {
                    return [
                        {
                            path: "unassigned",
                            label: "dssAdminLibFlightZoneManagement.submittedLists.unassignedListHeader",
                            dispatchAction: FlightZoneListsActions.GetUnassignedApplicationsList,
                            totalCount$: this.getTotalCountObservable(FlightZoneListsState.unassignedApplicationsList),
                        },
                        {
                            path: "for-approval",
                            label: "dssAdminLibFlightZoneManagement.submittedLists.forApprovalListHeader",
                            dispatchAction: FlightZoneListsActions.GetApplicationsForApprovalList,
                            totalCount$: this.getTotalCountObservable(FlightZoneListsState.applicationsForApprovalList),
                        },
                        {
                            path: "assigned-to-me",
                            label: "dssAdminLibFlightZoneManagement.submittedLists.assignedToMeListHeader",
                            dispatchAction: FlightZoneListsActions.GetApplicationsAssignedToUserList,
                            totalCount$: this.getTotalCountObservable(FlightZoneListsState.applicationsAssignedToUserList),
                        },
                        {
                            path: "assigned",
                            label: "dssAdminLibFlightZoneManagement.submittedLists.assignedListHeader",
                            dispatchAction: FlightZoneListsActions.GetAssignedApplicationsList,
                            totalCount$: this.getTotalCountObservable(FlightZoneListsState.assignedApplicationsList),
                        },
                    ];
                }

                return [
                    {
                        path: "unassigned",
                        label: "dssAdminLibFlightZoneManagement.submittedLists.unassignedListHeader",
                        dispatchAction: FlightZoneListsActions.GetUnassignedApplicationsList,
                        totalCount$: this.getTotalCountObservable(FlightZoneListsState.unassignedApplicationsList),
                    },
                    {
                        path: "assigned-to-me",
                        label: "dssAdminLibFlightZoneManagement.submittedLists.assignedToMeListHeader",
                        dispatchAction: FlightZoneListsActions.GetApplicationsAssignedToUserList,
                        totalCount$: this.getTotalCountObservable(FlightZoneListsState.applicationsAssignedToUserList),
                    },
                ];
            })
        );
    }

    private getTotalCountObservable(
        selector: (state: FlightZoneListsStateModel) => FlightZonesManagementList | undefined
    ): Observable<number> {
        return this.store.select(selector).pipe(map((listData) => listData?.page.totalElements ?? 0));
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route.firstChild,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams
            .pipe(distinctUntilChanged(), withLatestFrom(this.submittedListsLinks$), untilDestroyed(this))
            .subscribe(([filterParams, listLinks]) => {
                const listsFetchActions = listLinks.map(({ dispatchAction }) => new dispatchAction(filterParams, this.applicationType));

                this.store.dispatch(listsFetchActions);
            });
    }
}
