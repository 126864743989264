import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FlightZoneApiService, FlightZoneSharedModule } from "@dtm-frontend/dss-shared-lib";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, TranslationHelperService, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { FlightZoneManagementModule } from "../flight-zone-management/flight-zone-management.module";
import { ConsultationsCompletedContainerComponent } from "./components/applications-for-consultation-list/consultations-completed-container/consultations-completed-container.component";
import { ConsultationsInProgressContainerComponent } from "./components/applications-for-consultation-list/consultations-in-progress-container/consultations-in-progress-container.component";
import { ApplicationsForConsultationListComponent } from "./components/applications-for-consultation-list/list/applications-for-consultation-list.component";
import { ApplicationsForConsultationTabsContainerComponent } from "./components/applications-for-consultation-list/tabs-container/applications-for-consultation-tabs-container.component";
import { ConsultationPreviewComponent } from "./components/consultation-preview/consultation-preview.component";
import { ConsultationReviewConfirmDialogComponent } from "./components/consultation-preview/consultation-review-confirm-dialog/consultation-review-confirm-dialog.component";
import { FlightZoneConsultationState } from "./state/flight-zone-consultation.state";

@NgModule({
    declarations: [
        ConsultationReviewConfirmDialogComponent,
        ConsultationPreviewComponent,
        ApplicationsForConsultationListComponent,
        ConsultationsInProgressContainerComponent,
        ApplicationsForConsultationTabsContainerComponent,
        ConsultationsCompletedContainerComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatTooltipModule,
        MatTableModule,
        MatMenuModule,
        MatTabsModule,
        NgxsModule.forFeature([FlightZoneConsultationState]),
        LetModule,
        PushModule,
        RouterModule,
        SharedToastModule.forRoot(),
        SharedI18nModule,
        CesiumMapModule,
        SharedUiModule,
        FlightZoneSharedModule,
        FlightZoneManagementModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssAdminLibFlightZoneConsultation",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: FlightZoneApiService,
            useValue: undefined,
        },
        TranslationHelperService,
    ],
    exports: [ApplicationsForConsultationListComponent],
})
export class FlightZoneConsultationModule {
    public static forRoot(): ModuleWithProviders<FlightZoneConsultationModule> {
        return {
            ngModule: FlightZoneConsultationModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useClass: FlightZoneApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<FlightZoneConsultationModule> {
        return {
            ngModule: FlightZoneConsultationModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useValue: null,
                },
            ],
        };
    }
}
