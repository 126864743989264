import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { Observable } from "rxjs";

@Component({
    selector: "dss-admin-lib-notam-number-confirm-dialog",
    templateUrl: "./notam-number-confirm-dialog.component.html",
    styleUrls: ["./notam-number-confirm-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotamNumberConfirmDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    public readonly notamSeriesFormControl = new UntypedFormControl("", [Validators.required]);
    public readonly notamNumberFormControl = new UntypedFormControl("", [Validators.required]);
    public readonly notamNumberForm = new UntypedFormGroup({
        notamSeries: this.notamSeriesFormControl,
        number: this.notamNumberFormControl,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { notamSeries$: Observable<string[]> },
        private readonly dialogRef: MatDialogRef<NotamNumberConfirmDialogComponent>
    ) {}

    public confirm(): void {
        this.notamNumberForm.markAllAsTouched();

        if (this.notamNumberForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.dialogRef.close(this.notamNumberForm.value);
    }
}
