<mat-card>
    <dtm-ui-card-header>
        {{ "dssSharedLibUserProfile.contact.sectionTitle" | transloco }}
    </dtm-ui-card-header>

    <dtm-ui-label-value
        class="email-label-value"
        [label]="'dssSharedLibUserProfile.contact.emailLabel' | transloco"
        [value]="email$ | ngrxPush"
    >
    </dtm-ui-label-value>

    <dtm-ui-editable-box
        #phoneNumberEditableBox
        *ngrxLet="phoneNumber$ as phoneNumber"
        [label]="'dssSharedLibUserProfile.contact.phoneInputLabel' | transloco"
        [canLeaveEditModeOnSave]="false"
        (save)="save(phoneNumberEditableBox, { phoneNumber: phoneNumberControl.value! })"
        (closeEdition)="close()"
    >
        <div valueLabelSlot>
            <dtm-ui-country-flag-display [country]="phoneNumber.countryCode ?? undefined"></dtm-ui-country-flag-display>
            <span>{{ phoneNumber | formatPhoneNumber }}</span>
        </div>
        <ng-container editableContentSlot>
            <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
                <div class="field-error">
                    <ng-container *dtmUiFieldHasError="phoneNumberControl; name: ['required']">
                        {{ "dssSharedLibUserProfile.validationErrors.requiredFieldError" | transloco }}
                    </ng-container>
                    <ng-container *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'">
                        {{ "dssSharedLibUserProfile.validationErrors.invalidPhoneNumberError" | transloco }}
                    </ng-container>
                </div>
            </dtm-ui-phone-number-field>
        </ng-container>
    </dtm-ui-editable-box>
</mat-card>
