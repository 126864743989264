<div class="grid">
    <dss-shared-lib-applications-list-filters
        *ngrxLet="listFiltersCapabilities$ as listFiltersCapabilities"
        [initialFilters]="initialFilters"
        [anspEmployees]="listFiltersCapabilities?.anspEmployees"
        [shouldDisableSendDateFilter]="true"
        [shouldDisableAuthorFilter]="true"
        (filtersChange)="applyFilters($event)"
    ></dss-shared-lib-applications-list-filters>

    <dss-admin-lib-notam-list
        *ngrxLet="notamList$ as notamList"
        [displayedColumns]="displayedColumns"
        [notams]="notamList?.content"
        [page]="notamList?.page"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
        (pageChange)="changePage($event)"
        (listRefresh)="refreshList()"
    ></dss-admin-lib-notam-list>
</div>
