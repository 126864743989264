import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AnspCaseNumberComponentState {
    isProcessing: boolean;
    anspCaseNumber: string | undefined;
    isExpanded: boolean;
    isReadOnly: boolean;
}

const MAX_CASE_NUMBER_LENGTH = 50;

@Component({
    selector: "dss-admin-lib-ansp-case-number[anspCaseNumber]",
    templateUrl: "./ansp-case-number.component.html",
    styleUrls: ["./ansp-case-number.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AnspCaseNumberComponent {
    protected readonly anspCaseNumberControl = new FormControl<string>("", {
        validators: [Validators.maxLength(MAX_CASE_NUMBER_LENGTH)],
        nonNullable: true,
    });

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly anspCaseNumber$ = this.localStore.selectByKey("anspCaseNumber");
    protected readonly isReadOnly$ = this.localStore.selectByKey("isReadOnly");

    @Input()
    public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input()
    public set anspCaseNumber(value: string | undefined) {
        this.localStore.patchState({ anspCaseNumber: value });
        this.anspCaseNumberControl.patchValue(value ?? "");
    }

    @Input() public set isReadOnly(value: BooleanInput) {
        this.localStore.patchState({ isReadOnly: coerceBooleanProperty(value) });
    }

    @Output() private readonly anspCaseNumberChange = new EventEmitter<string>();

    constructor(private readonly localStore: LocalComponentStore<AnspCaseNumberComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            anspCaseNumber: undefined,
            isExpanded: false,
            isReadOnly: false,
        });
    }

    protected changeExpansionState(value: boolean): void {
        this.localStore.patchState({ isExpanded: value });
    }

    protected cancelChanges(): void {
        const lastSavedCaseNumber = this.localStore.selectSnapshotByKey("anspCaseNumber");

        this.changeExpansionState(false);
        this.anspCaseNumberControl.reset(lastSavedCaseNumber);
    }

    protected submitCaseNumber(): void {
        if (!this.anspCaseNumberControl.valid) {
            return;
        }

        this.anspCaseNumberChange.emit(this.anspCaseNumberControl.getRawValue());
        this.changeExpansionState(false);
    }
}
