import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged, filter, skip, Subject, switchMap, withLatestFrom } from "rxjs";
import { first } from "rxjs/operators";
import { Institution } from "../../../models/flight-zone-shared.models";

interface InstitutionsFilterComponentState {
    institutions: Institution[];
    selectedInstitutionId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-shared-lib-institutions-filter[institutions]",
    templateUrl: "./institutions-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InstitutionsFilterComponent), multi: true },
    ],
})
export class InstitutionsFilterComponent implements ControlValueAccessor {
    protected readonly institutionControl = new FormControl<Institution | undefined>(undefined);
    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: string | undefined) => void = FunctionUtils.noop;
    private readonly selectedInstitutionMatchTriggerSubject = new Subject<void>();

    protected readonly institutions$ = this.localStore.selectByKey("institutions");

    @Input() public set institutions(value: Institution[] | undefined) {
        this.localStore.patchState({ institutions: value ?? [] });

        if (value?.length) {
            this.selectedInstitutionMatchTriggerSubject.next();
        }
    }

    @Output() public readonly institutionSearchTextChange = new EventEmitter<string>();

    constructor(private readonly localStore: LocalComponentStore<InstitutionsFilterComponentState>) {
        this.localStore.setState({
            institutions: [],
            selectedInstitutionId: undefined,
        });

        this.institutionControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                // NOTE: First value of institutionControl emits before filters initialization, thus always with empty value.
                // Skip it to use filters initial value
                skip(1),
                untilDestroyed(this)
            )
            .subscribe((institution) => {
                this.localStore.patchState({ selectedInstitutionId: institution?.id });
                this.propagateChange(institution?.id ?? "");
            });

        this.matchSelectedInstitutionWithList();
    }

    public registerOnChange(fn: (value: string | undefined) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: string | undefined): void {
        this.localStore.patchState({ selectedInstitutionId: value });
    }

    private matchSelectedInstitutionWithList(): void {
        this.selectedInstitutionMatchTriggerSubject
            .asObservable()
            .pipe(
                // NOTE: selectedInstitutionMatchTriggerSubject is just trigger for rest of logic, so we observe only first emitted value
                first(),
                switchMap(() => this.localStore.selectByKey("selectedInstitutionId")),
                withLatestFrom(this.localStore.selectByKey("institutions").pipe(filter((institutions) => !!institutions.length))),
                untilDestroyed(this)
            )
            .subscribe(([institutionId, allInstitutions]) => {
                const selected = allInstitutions.find((inst) => inst.id === institutionId);

                this.institutionControl.patchValue(selected, { emitEvent: false });
            });
    }
}
