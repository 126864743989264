import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FlightZoneCapabilitiesState } from "@dtm-frontend/dss-shared-lib";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import turfBbox from "@turf/bbox";
import { first, map } from "rxjs/operators";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

@Component({
    selector: "dss-admin-lib-dashboard",
    templateUrl: "./dashboard.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);
    protected readonly initialViewbox$ = this.getInitialViewboxObservable();

    constructor(private readonly store: Store) {}

    private getInitialViewboxObservable() {
        return this.capabilities$.pipe(
            RxjsUtils.filterFalsy(),
            first(),
            map((capabilities) => {
                const viewbox = capabilities.preferences.initialViewbox;
                const bbox = turfBbox(viewbox);

                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
            })
        );
    }
}
